import React, { useEffect } from "react";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds/modals";
import { FlexRow, SLButton } from "../../components/core/utility";
import { css } from "@emotion/react";
import Alert from "../../assets/icons/triangle_alert.svg";
import styled from "styled-components";
import { resetTab } from "../../../utility/navigation";
import { MediumText } from "../../components/core/Utility/index";

const SchedulingFeesModal = ({ handleCancel, handleConfirm }) => {
  const StyledModal = css`
    max-height: 99vmin;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
    width: 70rem;
    @media (max-width: 767px) {
      width: 650px !important;
      height: auto;
      min-height: 14.5rem;
      padding: 1rem 1rem 1rem 1rem;
      padding-right: 2px;
    }

    & mark {
      background-color: transparent !important;
    }
  `;
  const CustomAlert = styled.img`
    width: 1rem;
    height: 1rem;
    margin-right: 4px;
  `;

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        resetTab();
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  return (
    <Modal
      id="Scheduling Fees Modal"
      role="dialog"
      ariaLabel="Scheduling Fees Modal"
      opened={true}
      fullScreenDialog={false}
      disableOutsideClick={true}
      hideCloseButton={true}
      css={StyledModal}
    >
      <ModalBody style={{ fontSize: "20px", "line-height": "25px", "font-weight": "600px", "text-align": "center" }}>
        <MediumText>
          <CustomAlert role="alert" src={Alert}></CustomAlert>You are attempting to schedule a customer in a state where additional scheduling fees
          may apply to them.
        </MediumText>
      </ModalBody>
      <FlexRow css={{ justifyContent: "flex-end" }}>
        <SLButton aria-label={`Confirm`} role="button" css={{ width: "10rem", marginLeft: "auto" }} size="large" onClick={() => handleConfirm()}>
          {`Confirm`}
        </SLButton>
        <SLButton aria-label={`Cancel`} role="button" secondary css={{ width: "10rem" }} size="large" onClick={() => handleCancel()}>
          {`Cancel`}
        </SLButton>
      </FlexRow>
    </Modal>
  );
};

export default SchedulingFeesModal;
