import { Comments } from "../../api/accounts/url";
import { ReportsMilestoneDetails } from "../../api/reports/url";
import { postRequest, postRequestWithParams } from "../RestServices";

const response = {
  status: 200,
  data: {
    statusCode: "200",
    message: "Success",
    data: {
      salesForceOpportunityId: "b111",
      superLibraOpportunityId: "1111",
      erpPoNumber: "",
      bpNumber: "12344",
      salesForceAccountId: "a111",
      orderType: null,
      accountName: "new",
      opportunityCreatedDate: "2022-01-10T11:09:32.000+00:00",
      states: [
        {
          state: "New",
          creationTime: "2022-01-10T11:09:32.000+00:00",
          description: null,
        },
        {
          state: "M1WaitingBp",
          creationTime: "2022-01-10T11:09:32.000+00:00",
          description: null,
        },
        {
          state: "M1GotBp",
          creationTime: "2022-01-10T11:09:32.000+00:00",
          description: null,
        },
        {
          state: "M1Failed",
          creationTime: "2022-01-10T11:09:32.000+00:00",
          description: null,
        },
      ],
    },
  },
};
const response1 = {
  status: 200,
  data: {
    statusCode: "200",
    message: "Success",
    data: {
      opportunityInfo: {
        salesForceOpportunityId: "0068F000005ZVkmQAG",
        orderType: "New Business",
        orderSubType: "Intial Sale",
        contractLevelBillingTrigger: "A05",
        selfInstall: false,
        opportunityCreatedDate: "2023-03-15 11:29:23.303",
        opportunityCloseDate: "2023-03-15 11:29:23.303",
        opportunitySaleConfirmedDate: null,
        salesForceAccountName: null,
        salesForceOpportunityName: null,
        salesForceOpportunityType: null,
        salesConfirmedDate: null,
        modifiedAt: "2023-03-15 11:29:23.303",
        stage: "OrderFulfillmentFailed",
        errorMessage: null,
        numberOfDaysStuck: null,
        salesForceAccountId: null,
        accountName: null,
        salesForceAccountNo: null,
        bpNumber: null,
        erpPoNumber:
          "Order 5002099403 created from Opportunity 0068F000005ZVkmQAG\n\nSAP Order ID: 5002099403\n\nSAP Item ID: 0003038698\nSAP Item Tech Ref ID: 0001505992.0003038698\nSAP Item ID: 0003038699\nSAP Item Tech Ref ID: 0001505992.0003038699\n\nI: Partners have alternative addresses\nI: Partners have alternative addresses\nI: Partners have alternative addresses\nI: Partners have alternative addresses\nI: Partners have alternative addresses\nI: Partners have alternative addresses\nS: Provider Order 5002099403 created\n\n\nTimestamp: 20230315-112844.31578Z",
        totalQuantity: 2,
        shippedQuantity: 0,
        vzcnumber: null,
      },
      accountInfo: {
        createdAt: null,
        modifiedAt: null,
      },
      contractAccountInfo: {
        sfContractAccountId: null,
        shippingUUID: null,
        billingUUID: null,
        erpContractAccountNumber: null,
        createdAt: null,
        modifiedAt: null,
      },
      contactInfo: [
        {
          sfContactId: "0038F00000KP9dWQAT",
          contactBusinessPartnerId: "3200448079",
          createdAt: "2023-03-15 11:27:15.563",
          modifiedAt: "2023-03-15 11:28:10.743",
          contactRole: "Primary - REVEAL",
          stage: "Associated",
        },
      ],
      vehicleData: [
        {
          technicalRefId: "0001505992.0003038698",
          status: null,
          errorMessage: null,
          workTicketNumber: null,
          inboundShipmentDate: null,
          activationDate: null,
          vehicleTrackingId: "9000000000505034920",
          outboundShipmentDate: "2023-03-15 14:06:22.4272394",
          outboundShipmentEffectiveDate: "2023-03-15 12:47:58.3296108",
          firstReportedDate: null,
          installDate: null,
          installationMethod: null,
          billingStartDate: null,
          location: "AUS",
          phoneNumber: "97122452067",
          selfCheckIn: false,
          esn: "767322357",
        },
        {
          technicalRefId: "0001505992.0003038698",
          status: null,
          errorMessage: null,
          workTicketNumber: null,
          inboundShipmentDate: null,
          activationDate: null,
          vehicleTrackingId: "9000000000505034921",
          outboundShipmentDate: "2023-03-15 14:09:27.6137976",
          outboundShipmentEffectiveDate: "2023-03-15 12:47:58.3426128",
          firstReportedDate: null,
          installDate: null,
          installationMethod: null,
          billingStartDate: null,
          location: "AUS",
          phoneNumber: "97122452068",
          selfCheckIn: false,
          esn: "767322358",
        },
      ],
      cameraData: [],
    },
  },
};
export async function getMilestoneData(opportunityId) {
  // return response1;
  const request = {
    sfOpportunityId: opportunityId,
  };
  try {
    return await postRequestWithParams(ReportsMilestoneDetails, request);
  } catch (err) {
    return {
      error: err,
    };
  }
}

export async function postComment(data) {
  try {
    return await postRequest(Comments, data);
  } catch (err) {
    return err;
  }
}
