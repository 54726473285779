import { COLUMN_WIDTHS } from "../../constants/ApplicationConstants";

export const VENDOR_INFO_COL = [
  {
    key: "Vendor ID",
    value: "vendorId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Vendor id unique number. Click to sort by this column",
  },
  {
    key: "Partner ID",
    value: "partnerId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Partner id unique number. Click to sort by this column",
  },
  {
    key: "Partner ",
    value: "partnerName",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
    ariaLabel: "Installer vendor associated with main vendor. Click to sort by this column",
  },
  {
    key: "Email",
    value: "email",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
    ariaLabel: "Installer vendor email associated with main vendor. Click to sort by this column",
  },
  {
    key: "Status",
    value: "status",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Installer vendor Status. Click to sort by this column",
  },
  {
    key: "Address1",
    value: "address1",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
    ariaLabel: "Installer vendor address1. Click to sort by this column",
  },
  {
    key: "Address2",
    value: "address2",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
    ariaLabel: "Installer vendor address2. Click to sort by this column",
  },
  {
    key: "City",
    value: "city",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Installer vendor city. Click to sort by this column",
  },
  {
    key: "Postal Code",
    value: "postalCode",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Installer vendor postal code. Click to sort by this column",
  },
  {
    key: "State",
    value: "state",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Installer vendor state. Click to sort by this column",
  },
  {
    key: "Country",
    value: "country",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Installer vendor country. Click to sort by this column",
  },
  {
    key: " EV Certified",
    value: "evCertified",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Installer vendor active on map. Click to sort by this column",
  },
  {
    key: "Phone",
    value: "phone",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    ariaLabel: "Installer vendor phone. Click to sort by this column",
  },
  {
    key: "Comments",
    value: "ddf",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    ellipsis: false,
    containsImage: false,
    containsMagnifier: true,
    width: "100px",
    style: { justifyContent: "center" },
  },
];

export const INSTALLER_LIST_COL = [
  {
    key: "Installer ID",
    value: "installerId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.xsmall,
  },
  {
    key: "Installer Name",
    value: "installerName",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Active",
    value: "active",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: "80px",
  },
  {
    key: "Phone",
    value: "phone",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Address",
    value: "address",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "City",
    value: "city",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "State",
    value: "state",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xsmall,
  },
  {
    key: "Country",
    value: "country",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xsmall,
  },
];

export const VENDORS_LIST_COL = [
  {
    key: "Vendor ID",
    value: "vendorId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.xsmall,
  },
  {
    key: "Vendor Name",
    value: "vendorName",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Installer",
    value: "installer",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: true,
    width: COLUMN_WIDTHS.xsmall,
  },
  {
    key: "Sales Org",
    value: "salesOrg",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Active",
    value: "active",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: "80px",
  },
  {
    key: "Contact Name",
    value: "contactName",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Contact Phone",
    value: "contactPhone",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Contact Email",
    value: "contactEmail",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Sync To Map",
    value: "syncToMap",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: true,
    width: COLUMN_WIDTHS.xsmall,
  },
];
