import { createContext, useEffect, useState, useCallback } from "react";
import { Loader } from "@vds/loaders";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds/modals";
import { Body } from "@vds/typography";
import { Button } from "@vds/buttons";
import { setPrivilegesHelper } from "../utility/privileges";
import { getPrivileges } from "../services/userService/getPrivilege";
import { useOktaAuth } from "@okta/okta-react";

export const PrivilegeContext = createContext();
export const PrivilegeContextProvider = (props) => {
  const [privileges, setPrivileges] = useState([]);
  const [role, setRole] = useState("");
  const [privilegesFetched, setPrivilegesFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertModal, setAlertModal] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const checkIsAuthenticated = useCallback(async () => {
    setAlertModal(false);
    setLoading(true);
    let response = await getPrivileges();
    if (response.status === 200 && response?.data) {
      setRole(response?.data?.data?.roles ?? "");
      setPrivileges(setPrivilegesHelper(response?.data?.data?.privileges ?? []));
      setPrivilegesFetched(true);
      setLoading(false);
    } else {
      setLoading(false);
      setAlertModal(true);
    }
  }, []);

  const getOktaCredentials = useCallback(async () => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      await oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info);
        })
        .catch((err) => {
          setErrorMsg("Something went wrong while fetching user data" + err);
        });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    checkIsAuthenticated();
    getOktaCredentials();
  }, [checkIsAuthenticated, authState, oktaAuth]);
  return (
    <>
      <Loader active={loading} />
      <Modal opened={alertModal} disableOutsideClick closeButton={null}>
        <ModalTitle>Error</ModalTitle>
        <ModalBody>
          <Body size="large" bold>
            We are currently experiencing difficulty in processing your request. Please try again sometime.
          </Body>
        </ModalBody>
        <ModalFooter>
          <Button onClick={checkIsAuthenticated}>Retry</Button>
        </ModalFooter>
      </Modal>
      <PrivilegeContext.Provider
        value={{
          privileges: privileges,
          role: role,
          userInfo: userInfo,
        }}
      >
        {privilegesFetched && props.children}
      </PrivilegeContext.Provider>
    </>
  );
};
