import { getRequestWithPathVariable } from "../RestServices";
import * as urls from "../../api/checkin/index";
export const getCases = async (pathVariable) => {
  try {
    const _response = await getRequestWithPathVariable(urls.GET_CASES, pathVariable);
    return _response;
  } catch (err) {
    return err;
  }
};
