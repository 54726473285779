import React, { useContext, useEffect, useState } from "react";
import { HARDWARE_LIST_FLYOUT_MENU_COL } from "../../constants/VehicleListConstants";
import { GridComponent } from "../../components/core/Table/GridComponent";

import { C4SContext } from "../../context/c4sContext";
import { getFullName, TYPE_DROPDOWN_VALUES } from "./utils";

const HardwareListFlyOutMenu = ({ rowData }) => {
  const { isSourceCSP } = useContext(C4SContext);
  const [hardwareListFlyOutMenuColumns, setHardwareListFlyOutMenuColumns] = useState([]);

  useEffect(() => {
    rowData.materials.map((material) => {
      material.displayType = getFullName(TYPE_DROPDOWN_VALUES, material.type);
      return material;
    });
    let data = HARDWARE_LIST_FLYOUT_MENU_COL.map((records) => {
      return records.key === "Order Type" ? { ...records, style: { display: isSourceCSP.isSourceCSP ? "" : "none" } } : records;
    });
    setHardwareListFlyOutMenuColumns(data);
  }, []);

  return (
    <>
      <div css={{ width: "100%", padding: "0 1.5rem 1.5rem 1.5rem" }}>
        <GridComponent
          isExportEnabled={false}
          noDataMessage="No data available to display."
          hideGlobalFilter={true}
          rows={rowData.materials}
          columns={hardwareListFlyOutMenuColumns}
          paginator={false}
          flyOutMenu={true}
          border="1px solid #e9ecef"
          whiteSpace="normal"
        />
      </div>
    </>
  );
};
export default HardwareListFlyOutMenu;
