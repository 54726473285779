import React from "react";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds/modals";

const AuthRequiredModal = ({ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }) => {
  const closeModal = () => {
    setAuthRequiredModalOpen(false);
  };

  const confirmModal = () => {
    setAuthRequiredModalOpen(false);
    triggerLogin();
  };

  return (
    <Modal
      showModal={authRequiredModalOpen}
      onClose={closeModal}
      onActionClick={confirmModal}
      open={authRequiredModalOpen}
      closeOnDocumentClick={false}
      closeOnDimmerClick={false}
      ariaLabel="Testing Modal"
      fullScreenDialog={false}
      hideCloseButton={false}
    >
      <ModalTitle>Authentication Required</ModalTitle>
      <ModalBody style={{ fontSize: "26px", marginTop: "10px" }}>Do you want to re-authenticate?</ModalBody>

      <ModalFooter
        buttonData={{
          primary: {
            children: "Yes",
            onClick: () => confirmModal(),
          },
          close: {
            children: "No",
            onClick: () => closeModal(),
          },
        }}
      />
    </Modal>
  );
};
export default AuthRequiredModal;
