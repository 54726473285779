import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const materialIdSearch = async (data, source, isIndividualReturn) => {
  try {
    if (isIndividualReturn) return await postRequest(urls.MMID_SEARCH_INDIVIDUALRETURN, data);
    if (source) return await postRequest(urls.MMID_SEARCH_CSP, data);
    return await postRequest(urls.MMID_SEARCH_CASE, data);
  } catch (err) {
    return err;
  }
};
