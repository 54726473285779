import { createContext, useState } from "react";

export const UploadContext = createContext();
export const UploadProvider = (props) => {
  const [batchNumber, setBatchNumber] = useState("");
  const [updateBatchNumber, setUpdateBatchNumber] = useState("");
  const [esns, setEsns] = useState([]);
  const [duplicateEsns, setDuplicateEsns] = useState([]);

  return (
    <UploadContext.Provider
      value={{
        batchNumber: { batchNumber, setBatchNumber },
        updateBatchNumber: { updateBatchNumber, setUpdateBatchNumber },
        esns: { esns, setEsns },
        duplicateEsns: { duplicateEsns, setDuplicateEsns },
      }}
    >
      {props.children}
    </UploadContext.Provider>
  );
};
