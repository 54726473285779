import { getProperty } from "../../utility/getProperty";
import { postRequest } from "../RestServices";
import * as urls from "../../api/checkin/index";
export const getCheckVehicleDetailsAlreadyExist = async (payload) => {
  try {
    const _response = await postRequest(urls.CHECK_VEHICLE_DETAILS_EXIST, payload);
    const vdata = getProperty(_response, "data", []);
    return { vehData: vdata };
  } catch (err) {
    return {
      vehData: err?.response?.data,
    };
  }
};
