export const PARTIAL_COMPLETION_REASONS = [
  "Action Needed - Contract Update",
  "Action Needed - Fallout",
  "Action Needed - Unresponsive",
  "Cancelled By Leading System",
  "Customer Action Needed",
  "Customer Waiting On Equipment",
  "Financial Inquiries",
  "Installation Completed",
  "Lack Of Engagement",
  "Ready To Schedule",
  "Scheduled",
  "Sidetracked - Call Back Request",
  "Sidetracked - Customer Delay",
  "Sidetracked - Inventory Shortage",
  "Sidetracked - No Vehicle",
];
export const SCHEDULE_TIME_TIMEZONE = {
  EST: "Eastern Standard Time",
  PST: "Pacific Standard Time",
  MT: "Mountain Standard Time",
  CT: "Central Standard Time",
  HST: "Hawaii Standard Time",
  AKST: "Alaska Standard Time",
  AWST: "Australian Western Standard Time",
  GMT: "Greenwich Mean Time",
  CET: "Central European Standard Time",
};
export const ERP_STATUS = ["In Process", "Completed"];
export const ERP_SUB_STATUS = [
  "Field Work Completed",
  "Completed",
  "Cancelled",
  "Field Work Incomplete",
  "Incorrect Ticket - Case Closure",
  "Partially Completed ",
  "Incorrect Troubleshooting",
  "Field Work Not Required",
  "Field Work Not Required/ HW Return",
  "Full Appt No Show",
  "Cancelled Covid-19",
];
export const PROCESSING_CODE = [
  { id: "ZTML", value: "Mileage" },
  { id: "ZTEH", value: "Hotel Expenses" },
  { id: "ZTVF", value: "Vendor Fees" },
];

export const salesOrgBasedCurrency = {
  SO_3001: { currency: "AUD", unitOfMeasaure: "KM", currencySymbol: "$" },
  SO_6901: { currency: "NZD", unitOfMeasaure: "KM", currencySymbol: "$" },
  SO_1001: { currency: "EUR", unitOfMeasaure: "KM", currencySymbol: "€" },
  SO_1201: { currency: "EUR", unitOfMeasaure: "KM", currencySymbol: "€" },
  SO_4203: { currency: "GBP", unitOfMeasaure: "Miles", currencySymbol: "£" },
  SO_4211: { currency: "EUR", unitOfMeasaure: "KM", currencySymbol: "€" },
  SO_3701: { currency: "EUR", unitOfMeasaure: "KM", currencySymbol: "€" },
  SO_1101: { currency: "GBP", unitOfMeasaure: "Miles", currencySymbol: "£" },
  SO_2901: { currency: "CAD", unitOfMeasaure: "KM", currencySymbol: "$" },
  SO_9001: { currency: "USD", unitOfMeasaure: "Miles", currencySymbol: "$" },
  SO_5501: { currency: "MXN", unitOfMeasaure: "KM", currencySymbol: "$" },
};

export const salesOrgIdNameList = {
  UKR: [
    "1101 VZ Connect UK Ltd",
    // "4203 VZ Connect Ireland" ,
  ],
  IRL: ["4211 VZC Ireland Limited"],
  FRA: ["1201 VZC France SAS"],
  NLD: ["3701 VZC Netherlands"],
  BEL: ["3701 VZC Netherlands"],
  DEU: ["1001 VZ Connect Germ GmbH"],
  AUT: ["1001 VZ Connect Germ GmbH"],
  CAN: ["2901 VZ Connect CanadaInc"],
  USA: ["9001 VZC Fleet USA LLC"],
  MEX: ["5501 MX Mexico"],
  AUS: ["3001 Verizon Connect Aust"],
  NZL: ["6901 VZ Connect NZ"],
  GBR: ["1101 VZC UK Limited"],
};

export const COUNTRY_LIST = {
  AUT: "AT",
  AUS: "AU",
  BEL: "BE",
  CAN: "CA",
  DEU: "DE",
  FRA: "FR",
  GBR: "GB",
  IRL: "IE",
  MEX: "MX",
  NLD: "NL",
  NZL: "NZ",
  USA: "US",
};
