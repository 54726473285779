import { postRequestFile } from "../RestServices";
import * as urls from "../../api/aggregator";
export const uploadDataManualActivation = async (payload, carrier, headers) => {
  try {
    const _response = await postRequestFile(urls.MANUAL_ACTIVATION + `/${carrier}`, payload, headers);
    return _response;
  } catch (err) {
    return err.response.data;
  }
};
