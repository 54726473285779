import { postRequest } from "../RestServices";
import * as urls from "../../api/userService/url";
import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { localResponse, localSchedulerResponse } from "./mockPrivilegeResponse";
const client = axios.create();
export const getPrivileges = async () => {
  try {
    let mock = new MockAdapter(client);
    if (process.env.REACT_APP_ENV === "local") {
      mock.onPost(urls.GET_ROLE_AND_PRIVILEGES).reply(200, localResponse);
      return await client.post(urls.GET_ROLE_AND_PRIVILEGES);
    } else if (process.env.REACT_APP_ENV === "localscheduler") {
      mock.onPost(urls.GET_ROLE_AND_PRIVILEGES).reply(200, localSchedulerResponse);
      return await client.post(urls.GET_ROLE_AND_PRIVILEGES);
    } else {
      return await postRequest(urls.GET_ROLE_AND_PRIVILEGES);
    }
  } catch (err) {
    return err;
  }
};
export const getPreferences = async (payload) => {
  try {
    const response = await postRequest(urls.GET_PREFERENCES, payload);
    return response;
  } catch (err) {
    return err;
  }
};
export const setPreferences = async (payload) => {
  try {
    const response = await postRequest(urls.SET_PREFERENCES, payload);
    return response;
  } catch (err) {
    return err;
  }
};
