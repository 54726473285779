import React, { Suspense, useMemo } from "react";
import { Loader } from "@vds/loaders";

// Constants
import { TabContentWrapper } from "./HomePage.style";
import { APPEND_TO_TAB_ID, CHANGE_PAGE } from "../../constants/ApplicationConstants";

// Utilities

// Common component
import { getPageById, getPageMetadataById, getPageNameById } from "../../utility/navigation";
import { BreadcrumbItem, Breadcrumbs } from "@vds/breadcrumbs";
import { messageBus } from "../../utility/messageBus";

const Component = ({ prop, component, baseComponent }) => {
  let Page = getPageById(component, baseComponent);
  return <Page {...prop} component={component}></Page>;
};

function RouteComponent({ list, addCss, props }) {
  const { componentName } = list;
  let pagesList = [{ pageId: componentName, id: list.tabId, props: props }];
  if (list.subPages) {
    let subPages = list.subPages.map((item) => ({
      pageId: item.pageId,
      props: item.props,
      id: item.id,
    }));
    pagesList = [...pagesList, ...subPages];
  }
  const Context = useMemo(
    function getContext() {
      return getPageMetadataById(componentName)?.context ?? ((props) => <>{props.children}</>);
    },
    [componentName]
  );

  return (
    <TabContentWrapper
      noTabWindow={
        list.tabLabel === "Open Jobs" ||
        list.tabLabel === "Vendor Management" ||
        list.tabLabel === "Vehicle Intake" ||
        list.tabLabel === "View Work Ticket" ||
        list.tabLabel === "CONNECT 360" ||
        list.tabLabel === "MMID Portal" ||
        list.tabLabel === "Metric Dashboard" ||
        list.tabLabel === "VENDOR MANAGEMENT LANDING" ||
        list.tabLabel === "Open Jobs V1" ||
        list.tabLabel === "CONNECT 360 ADMIN"
          ? true
          : false
      }
      metricDashboard={list.tabLabel === "Metric Dashboard" ? true : false}
      mmidPortal={list.tabLabel === "MMID Portal"}
      adminVendor={list.tabLabel === "VENDOR MANAGEMENT LANDING"}
      // css={{ maxWidth: "1272px", padding: "10px 30px" }}
      id={`${list.tabId}${APPEND_TO_TAB_ID}`}
      key={list.tabId}
      className={!addCss ? "sr-only" : ""}
      aria-hidden={!addCss}
    >
      {(getPageMetadataById(componentName)?.breadcrumbs ?? true) && (
        <Breadcrumbs>
          {pagesList.map((item, index) => (
            <BreadcrumbItem active={false} key={item.pageId}>
              <span
                onClick={() => {
                  messageBus.trigger(CHANGE_PAGE, { index: index });
                }}
                css={{
                  fontWeight: index === (list?.currentIndex ?? 0) ? "700" : "400",
                  fontSize: "1rem",
                  fontFamily: "Verizon-NHG-eDS",
                  color: "black",
                }}
              >
                {getPageNameById(item.pageId, componentName)}
              </span>
            </BreadcrumbItem>
          ))}
        </Breadcrumbs>
      )}
      <Context>
        {pagesList.map((item, index) => (
          <div
            key={item.id}
            css={{
              display: index === (list?.currentIndex ?? 0) ? "block" : "none",
            }}
          >
            <Suspense
              fallback={
                <>
                  <Loader active={true} />
                </>
              }
            >
              <Component prop={item.props} component={item.pageId} baseComponent={componentName}></Component>
            </Suspense>
          </div>
        ))}
      </Context>
    </TabContentWrapper>
  );
}

export default RouteComponent;
