import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const DETAILED_MMID_POSSUIRS_COL = [
  {
    key: "SAP ID",
    value: "sapContractId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Tech Ref ID",
    value: "techRefId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.lsmall,
  },
  {
    key: "Ordered",
    value: "orderedQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Pending Shipment Hardware",
    value: "pendingQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Pending Shipment Accessories",
    value: "pendingShipAccessQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Shipped Hardware",
    value: "shippedQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Shipped Accessories",
    value: "shippedAccessoryQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Scheduled",
    value: "scheduledQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Unscheduled",
    value: "unscheduledQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Installed",
    value: "installedQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Remaining",
    value: "remainingQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Self-Installed",
    value: "selfInstalledQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Return Hardware",
    value: "returnHardwareQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Return Accessory",
    value: "returnAccessoryQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
];
