import styled from "@emotion/styled";
import { DropdownSelectMarketing } from "@vds/selects";

const sidebarWidth = "115px",
  iconWidth = "30px",
  iconHeight = "30px",
  leftEntryHeight = "70px",
  fontSize = "16px";
export const Container = styled.div`
  display: block;
  height: 100%;
  position: relative;
  width: ${sidebarWidth};
`;
export const BottomSubSubMenuContainterInner = styled.div`
  padding-top: 6px;
  padding-bottom: 5px;
`;
export const BottomSubMenu = styled.div`
  padding: 15px 3px;
  font-family: Verizon-NHG-eDS;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #d8dada;
  letter-spacing: 0.5px;
`;
export const BottomSubMenuWithSub = styled(BottomSubMenu)`
  padding: 17px 0px;
  border-top: 1px solid #d8dada;
  border-bottom: 1px solid #d8dada;
`;
export const BottomSubSubMenu = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding: 2px 20px 2px 8px;
  span:hover {
    font-weight: bold;
  }
  span {
    font-weight: ${({ active }) => (active ? "bold" : "normal")};
  }
`;
export const SubMenuBorder = styled.div`
  padding: 10px;
  span,
  div {
    color: ${({ theme }) => theme.text};
  }
`;
export const BottomSubSubMenuContainter = styled.div`
  background-color: ${({ theme }) => theme.background7};
  height: 196px;
  width: 224px;
  overflow-y: auto;
  border-radius: 4px;
  z-index: 9999;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  bottom: -1px;
  left: 239px;
  text-align: left;
  display: none;
  padding: 0px;
  ${SubMenuBorder}:hover & {
    display: block;
  }
  ${BottomSubSubMenu}:hover & {
    display: block;
  }
  ${BottomSubMenuWithSub}:hover & {
    display: block;
  }
  ${BottomSubSubMenuContainterInner}:hover & {
    display: block;
  }
  ${BottomSubSubMenu} span:hover & {
    display: block;
  }
  ${BottomSubSubMenu} div:hover & {
    display: block;
  }
`;

export const MainNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: ${sidebarWidth};
  background-color: #000;
  border-right: 1px solid ${({ theme }) => theme.border3};
  position: fixed;
  z-index: 10;
`;

export const SubNavContainer = styled.ul`
  background-color: ${({ theme }) => theme.background1};
  height: calc(100vh - 52px);
  width: 200px;
  z-index: 99;
  position: absolute;
  top: 46px;
  left: ${sidebarWidth};
  text-align: left;
  display: none;
  border-right: 1px solid #d8dada;
  list-style: none;
  padding: 0;
  &:hover,
  &:focus {
    display: block;
  }
`;

export const StyledContainer = styled.button`
  width: ${sidebarWidth};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${leftEntryHeight};
  justify-content: center;
  margin: 2px auto;
  text-decoration: none;
  background: transparent;
  border: none;
  cursor: pointer;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  @media (max-height: 750px) {
    max-height: 65px;
    /* min-height: 50px;
    height: unset; */
  }
  @media (max-height: 650px) {
    max-height: 60px;
  }

  &:hover,
  &:focus {
    border-left: 4px solid #d52b1e;
    opacity: 1;
  }

  &:hover + ${SubNavContainer},&:focus + ${SubNavContainer},&:active + ${SubNavContainer} {
    display: block;
  }
`;

export const BorderContainter = styled.li`
  border-color: transparent;
  cursor: pointer;
  opacity: 1;
  background: transparent;
  width: ${sidebarWidth};
`;

export const SubNavTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  padding: 10px 12px;
  border-bottom: 3px solid #d8dada;
  font-family: Verizon-NHG-eDS;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

export const SubNavMenu = styled.li`
  border-bottom: 1px solid #d8dada;
  background: transparent;
  font-size: 16px;
  line-height: 1.2rem;
  padding: 10px 12px;
  border-left: 4px solid transparent;
  font-family: Verizon-NHG-eDS;
  font-weight: 700;
  color: #747676;
  &:hover,
  &:focus {
    border-left: 4px solid #d52b1e;
    color: ${({ theme }) => theme.text};
  }
  a {
    font-size: 14px;
    line-height: 1.2rem;
    color: ${({ theme }) => theme.textAlternative};
    text-decoration: none;
  }
`;

export const MenuText = styled.span`
  font-size: ${fontSize};
  /* line-height: 13px; */
  text-align: center;
  text-decoration: none;
  color: #fff;
  /* @media (max-height: 750px) {
    font-size: 13px;
  } */
`;

export const WrapTop = styled.div`
  background-color: #000;
  text-align: center;
  /* max-height: 1000px; */
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const WrapBottom = styled.ul`
  background-color: #000;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const MenuContent = styled.ul`
  height: calc(100vh - 122px);
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  max-height: 650px;
  @media (max-height: 825px) {
    max-height: 639px;
    overflow: hidden auto;
  }
  @media (max-height: 780px) {
    max-height: 589px;
    overflow: hidden auto;
  }
  @media (max-height: 720px) {
    max-height: 586px;
    overflow: hidden auto;
  }
  ::-webkit-scrollbar {
    width: 0.15em;
    height: 0.25em;
  }
  ::-webkit-scrollbar-track-piece {
    background: #000;
  }
  ::-webkit-scrollbar-thumb {
    background: #646464;
  }
`;
export const BottomContainter = styled.div`
  text-align: center;
  cursor: pointer;
`;
export const UserCircle = styled.div`
  height: 41px;
  width: 41px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
`;

export const BottomSubMenuContainter = styled.div`
  background-color: ${({ theme }) => theme.background};
  height: auto;
  width: auto;
  min-width: 300px;
  z-index: 99;
  position: absolute;
  border: 1px solid ${({ theme }) => theme.text};
  bottom: 13px;
  left: 115px;
  text-align: left;
  display: none;
  ${BottomContainter}:hover & {
    display: block;
  }
  ${BottomSubSubMenu}:hover & {
    display: block;
  }
  ${BottomSubSubMenuContainter}:hover & {
    display: block;
  }
  ${SubMenuBorder}:hover & {
    display: block;
  }
  ${BottomSubMenu}:hover & {
    display: block;
  }
  ${StyledContainer}:focus & {
    display: block;
  }
`;

export const StandAloneLogo = styled.img`
  height: 52px;
  width: 40px;
  max-width: 72px;
  @media (max-height: 720px) {
    margin-bottom: -10px;
  }
`;

export const BasicMenuIcon = styled.img`
  height: auto;
  width: auto;
  max-width: ${iconWidth};
  max-height: ${iconHeight};
  padding-bottom: 2px;
  /* @media (max-height: 750px) {
    max-width: 25px;
    max-height: 25px;
  } */
`;

export const ProfilePicture = styled.img`
  height: 41px;
  width: 41px;
  background-color: #fff;
  border-radius: 50%;
`;

//Our changes starts here
export const SubNavMenuDisabled = styled.div`
  font-size: 14px;
  line-height: 1.2rem;
  padding: 3px 12px;
  border-left: 4px solid transparent;
  font-family: Verizon-NHG-eDS;
  font-weight: 700;
  color: ${(props) => props.color || "lightGray"};
`;

export const IconWrapper = styled.object`
  height: auto;
  width: auto;
  max-width: 18px;
  max-height: 18px;
  margin-left: 5px;
  pointer-events: none;
`;

export const CustomDropdown = styled(DropdownSelectMarketing)`
  font-size: 14px;
  width: 180px !important;
  button {
    height: 44px !important;
  }
  button > div {
    justify-content: flex-end !important;
  }
  button > div > div:first-of-type {
    padding-right: 80px;
    font-size: 16px;
  }
  ul > li > div {
    font-size: 16px;
  }
  ul {
    width: 300px;
    left: -115px;
  }
  ul > li {
    justify-content: space-between;
  }
`;
