import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const getJobDuration = async (jobId = "", saleOrgId, sapId) => {
  try {
    let queryParam = jobId ? `?jobId=${jobId}&salesOrgId=${saleOrgId}` : `?salesOrgId=${saleOrgId}`;
    queryParam = sapId ? queryParam + `&sapId=${sapId}` : queryParam;
    return await getRequest(urls.GET_JOBDURATION + queryParam);
  } catch (err) {
    return err;
  }
};
