import { useContext, useEffect, useState, useRef, lazy } from "react";
import { GridComponent } from "../../../components/core/Table/GridComponent";
import { controlUnitColumns, selectedCamerasColumns, standAloneCamerasColumns } from "./ColumnData";
import { Button } from "@vds/buttons";
import { Spacer, SmallText, FlexRow } from "../../../components/core/Utility";
import { Loader } from "@vds/loaders";
import { notify } from "../../../utility/getProperty";
import { assignBundleCameras, assignCamera, assignStandAloneCamera, getStandAloneCamerasForControlUnit } from "../../../services/Camera";
import { Row, Col } from "@vds/grids";
import { AssignCameraContext } from "../../../context/cameraContext";
import { Header, SLDropDown, SLInput } from "../../../components/core/Controls";
import { DetailsPanel } from "./DataPanel";

export const AssignAndConfirmCamera = ({ controlUnitData, workTicketData, assignControlUnit }) => {
  const [camerasToAssign, setCamerasToAssign] = useState([]);
  const [assignedCameras, setAssignedCameras] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { vtuEsnDetails } = useContext(AssignCameraContext);
  const { vtuEsnDetails: vtuDetails } = vtuEsnDetails;
  const [disableAssignButton, setDisableAssignButton] = useState(true);
  const [disableAssignSubmitButton, setDisableAssignSubmitButton] = useState(true);
  const [assignLabel, setAssignLabel] = useState("Assign & Confirm");
  const [selectedCamera, setSelectedCamera] = useState({});
  const [channelNumber, setChannelNumber] = useState(0);
  const [assignedFunction, setAssignedFunction] = useState("");
  const [isDVRCamera, setIsDVRCamera] = useState(false);
  const [isProductTypeModalOpen, setIsProductTypeModalOpen] = useState(false);
  const [isChannelNumberModalOpen, setIsChannelNumberModalOpen] = useState(false);
  const [notification, setNotification] = useState();
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [channelNumbers, setChannelNumbers] = useState([1, 2, 3, 4]);
  const [assignedFunctions, setAssignedFunctions] = useState(["PASSENGER_SIDE", "DRIVER_SIDE"]);

  const genericResponse = "Unexpected Error";

  useEffect(() => {
    fetchData();
    setIsDVRCamera(controlUnitData?.sku?.includes("CP4"));
    preProcessData();
    if (camerasToAssign?.length < 1) setDisableAssignSubmitButton(false);
  }, [controlUnitData]);

  useEffect(() => {
    preProcessData();
  }, [channelNumber, assignedFunction]);

  const fetchData = async () => {
    const camObj = {
      universalAccountId: controlUnitData.universalAccountId,
      controlUnitEsn: controlUnitData.esn,
    };
    setIsLoading(true);
    const response = await getStandAloneCamerasForControlUnit(camObj);
    const availableCameras = response.data?.data?.availableCameras;
    const assignedCameras = response?.data?.data?.assignedCameras;
    if (response?.status === 200) {
      let availableCamerasArr = availableCameras;
      availableCamerasArr.map((r) => {
        if (r.state === "Installed") r["confirmStatus"] = r.isConfirmed !== true ? "Not Confirmed" : "Assigned and Confirmed";
        else r["confirmStatus"] = "Not Assigned";
        return r;
      });
      setCamerasToAssign(availableCamerasArr ?? []);
      setAssignedCameras(assignedCameras ?? []);
    } else {
      notify(response?.data?.message ?? genericResponse, "error", setNotification);
    }
    setIsLoading(false);
  };

  const preProcessData = () => {
    assignedCameras?.map((cam) => {
      setChannelNumbers(removeItem(channelNumbers, cam?.channelNumber));
      setAssignedFunctions(removeItem(assignedFunctions, cam?.cameraProductType));
    });

    selectedCameras?.map((cam) => {
      setChannelNumbers(removeItem(channelNumbers, cam?.channelNumber));
      setAssignedFunctions(removeItem(assignedFunctions, cam?.cameraProductType));
    });
  };

  const removeItem = (arr, value) => {
    return arr?.filter((item) => item != value);
  };

  const removeCamera = (arr, camera) => {
    return arr.filter((item) => item?.esn !== camera?.esn);
  };

  const camerasSelectionHandler = (data) => {
    setSelectedCamera(data);
    if (data && Object.keys(data).length > 0) {
      data.state === "Installed" && data.isConfirmed !== true ? setAssignLabel("Confirm") : setAssignLabel("Assign & Confirm");
      if (isDVRCamera && data?.cameraProductType?.includes("Side")) {
        setIsProductTypeModalOpen(true);
        setIsChannelNumberModalOpen(true);
      } else {
        if (isDVRCamera) {
          setIsChannelNumberModalOpen(true);
        } else {
          setIsChannelNumberModalOpen(false);
        }
        setIsProductTypeModalOpen(false);
      }
      setDisableAssignButton(false);
    } else {
      setDisableAssignButton(true);
    }
  };

  const getUserEmail = () => {
    try {
      const serializedToken = JSON.parse(sessionStorage.getItem("okta-token-storage"));
      if (serializedToken === null) {
        return undefined;
      }
      return serializedToken.idToken.claims.email;
    } catch (e) {
      return "userName";
    }
  };

  const assignHandler = async () => {
    if (selectedCameras && Object.keys(selectedCameras).length > 0) {
      setIsLoading(true);
      const payload = {
        controlUnit: assignControlUnit,
        cameras: selectedCameras,
      };
      const response = await assignBundleCameras(payload);
      setIsLoading(false);
      if (response.status === 200) {
        notify(response?.data?.message ?? "Camera assigned and confirmed successfully", "success", setNotification);
      } else {
        notify(response?.data ?? "Error assigning cameras", "error", setNotification);
      }
    } else if (camerasToAssign?.length < 1) {
      setIsLoading(true);
      const payload = {
        controlUnit: assignControlUnit,
        cameras: [],
      };
      const response = await assignBundleCameras(payload);
      setIsLoading(false);
      if (response.status === 200) {
        notify(response?.data?.message ?? "Camera assigned and confirmed successfully", "success", setNotification);
      } else {
        notify(response?.data ?? "Error assigning cameras", "error", setNotification);
      }
    } else {
      notify("Please select camera to assign and confirm", "error", setNotification);
    }
  };

  const resetHandler = () => {
    fetchData();
    setIsDVRCamera(controlUnitData?.sku?.includes("CP4"));
    preProcessData();
    setSelectedCameras([]);
    setSelectedCamera();
    // setChannelNumbers([1, 2, 3, 4]);
  };

  const handleAddCameraForAssigning = () => {
    if ((isDVRCamera && channelNumber === 0) || (isProductTypeModalOpen && assignedFunction === "")) {
      notify("Please select Channel Number and AssignedFunction Values for CP4 cameras!", "error", setNotification);
      return;
    }
    const camera = {
      esn: selectedCamera?.esn,
      controlUnitEsn: controlUnitData?.esn,
      location: selectedCamera?.location,
      channelNumber: channelNumber,
      assignedFunction:
        assignedFunction === "" ? (selectedCamera?.cameraProductType != null ? selectedCamera?.cameraProductType : "OTHER") : assignedFunction,
      userId: getUserEmail(),
    };
    setSelectedCameras((prev) => {
      if (prev?.filter((cam) => cam?.esn === camera?.esn) <= 0) {
        return [...prev, camera];
      } else {
        notify("Selected Camera already added for assignment", "error", setNotification);
        return;
      }
    });
    setCamerasToAssign(removeCamera(camerasToAssign, selectedCamera));
    setChannelNumbers(removeItem(channelNumbers, channelNumber));
    setAssignedFunctions(removeItem(assignedFunctions, assignedFunction));
    setDisableAssignSubmitButton(false);
  };

  const handleSetChannelNumber = (data) => {
    setChannelNumber(data?.target?.value);
    if (!isProductTypeModalOpen) {
      setAssignedFunction(selectedCamera?.cameraProductType);
    }
  };

  const handleSetProductType = (data) => {
    setAssignedFunction(data?.target?.value);
  };

  const gridSpacing = "34px";

  return (
    <>
      {notification}
      <DetailsPanel vtuDetails={vtuDetails} controlUnitData={controlUnitData}></DetailsPanel>
      <Loader active={isLoading} />
      <Spacer height={gridSpacing} />

      <GridComponent
        isRadioSelectEnabled
        selectionMode="single"
        noDataMessage="No available Cameras to Assign"
        rows={camerasToAssign}
        columns={standAloneCamerasColumns}
        title="Available Cameras to Asssign"
        getSelectedItems={camerasSelectionHandler}
        // isEditEnabled={true}
      />

      <Spacer height={gridSpacing} />
      <Spacer height={gridSpacing} />
      <Col>
        <div css={{ margin: "20px 0" }}>
          <Row>
            <div className="dvrDetails" hidden={!isChannelNumberModalOpen}>
              <Col>
                <SmallText bold>Channel Number</SmallText>
                <SLDropDown width={"200px"} name="Channel Number" onChange={handleSetChannelNumber}>
                  <option value={0}>Channel Number</option>
                  {channelNumbers.map((i) => {
                    return (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    );
                  })}
                </SLDropDown>
              </Col>
            </div>
            <div className="dvrDetails" hidden={!isProductTypeModalOpen}>
              <Col>
                <SmallText bold>Camera Product Type </SmallText>
                <SLDropDown width={"200px"} name="Product Type" onChange={handleSetProductType}>
                  <option value={""}>Camera Type</option>
                  {assignedFunctions.map((i) => {
                    return (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    );
                  })}
                </SLDropDown>
              </Col>
            </div>
          </Row>
        </div>
        <Row>
          <Col>
            <Button css={{ marginLeft: "auto" }} disabled={disableAssignButton} onClick={handleAddCameraForAssigning}>
              {" (+) Add Camera to Assign"}
            </Button>
          </Col>
          <Col>
            <Button css={{ marginLeft: "auto" }} onClick={resetHandler}>
              {"Reset"}
            </Button>
          </Col>
        </Row>

        <Spacer height={gridSpacing} />
        <Spacer height={gridSpacing} />
        <GridComponent
          isRadioSelectEnabled={false}
          noDataMessage="No cameras selected"
          rows={selectedCameras}
          columns={selectedCamerasColumns}
          title="Selected Cameras"
        />

        <Row>
          <Button css={{ marginLeft: "auto" }} disabled={disableAssignSubmitButton} onClick={assignHandler}>
            {assignLabel}
          </Button>
        </Row>
      </Col>

      <Spacer height={gridSpacing} />
      <GridComponent
        isRadioSelectEnabled={false}
        noDataMessage="No Assigned Cameras Available for the selected Control Unit"
        rows={assignedCameras}
        columns={standAloneCamerasColumns}
        title="Assigned Cameras"
      />
    </>
  );
};
export default AssignAndConfirmCamera;

export const AssignAndConfirmCameraMetadata = {
  name: "Assign And Confirm Camera",
  id: "assign_standalone_camera",
  component: lazy(() => import("./AssignAndConfirmCamera")),
  route: "/assign_standalone_camera",
};
