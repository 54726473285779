import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const getCountryList = async () => {
  try {
    return await getRequest(urls.GET_COUNTRY_LIST);
  } catch (err) {
    return err;
  }
};
