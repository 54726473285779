import { postRequest } from "../RestServices";
import * as urls from "../../api/orders/url";

export const retryShipmentTask = async (payload) => {
  try {
    const _response = await postRequest(urls.retryShipmentTask, payload);
    return _response;
  } catch (err) {
    return err;
  }
};
