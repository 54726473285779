import { Icon } from "@vds/icons";
import React, { useState, useEffect } from "react";
import { RETURN_FLYOUT_MENU_COL } from "../../constants/ReturnTableConstants";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { FlexRow } from "../../components/core/utility";
import edit from "../../assets/icons/edit.png";
import { hasPrivilege } from "../../../utility/privileges";
import { Privileges } from "../../../constants/PrivilegeConstants";

export const ReturnTableFlyOutMenu = ({ rowData, handleReturnTicketModal, getReturnDeleteData }) => {
  const [isReturnStatusSubmitted, setIsReturnStatusSubmitted] = useState(false);

  useEffect(() => {
    if (rowData.status.toLowerCase() === "return_created") {
      setIsReturnStatusSubmitted(true);
    }
  }, [rowData]);

  const showActionIcon = (row) => (
    <FlexRow>
      <div onClick={() => handleReturnTicketModal("edit", row, rowData)}>
        <img
          style={{ width: "15px", cursor: "pointer", marginRight: "20px" }}
          alt="edit-icon"
          aria-label="Edit Icon"
          src={edit}
          aria-controls="overlay_panel"
        />
      </div>
      <div onClick={() => getReturnDeleteData(row)}>
        <Icon
          className="trash-icon"
          ariaLabel="delete"
          alt="delete-icon"
          name="trash"
          size="medium"
          color="#000000"
          style={{ cursor: "pointer", outline: "none" }}
        />
      </div>
    </FlexRow>
  );
  return (
    <div css={{ width: "100%", padding: "0 1.5rem 1.5rem 1.5rem" }}>
      <GridComponent
        isExportEnabled={false}
        noDataMessage="No data available to display."
        hideGlobalFilter={true}
        rows={rowData.returnMaterial}
        columns={RETURN_FLYOUT_MENU_COL}
        showActionIcon={isReturnStatusSubmitted && !hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY) && showActionIcon}
        paginator={false}
        flyOutMenu={true}
        border="1px solid #e9ecef"
      />
    </div>
  );
};
