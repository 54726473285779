export const Privileges = {
  ENABLE_SP_MENU: "ENABLE_SP_MENU",
  DISABLE_MAIN_MENU: "DISABLE_MAIN_MENU",
  DISABLE_MULTI_TABBED_WINDOW: "DISABLE_MULTI_TABBED_WINDOW",
  ENABLE_SCHEDULING_PORTAL_MENU: "ENABLE_SCHEDULING_PORTAL_MENU",
  MANAGE_VENDOR_STATES_TABLE: "MANAGE_VENDOR_STATES_TABLE",
  ENABLE_WORKTICKET_PORTAL_MENU: "ENABLE_WORKTICKET_PORTAL_MENU",
  ENABLE_PAGES_ORDER_MILESTONES_RETRY: "ENABLE_PAGES_ORDER_MILESTONES_RETRY",
  ENABLE_UNASSIGN_CAMERAS: "ENABLE_UNASSIGN_CAMERAS",
  ENABLE_READONLY_C360: "ENABLE_READONLY_C360",
  ENABLE_SCHEDULING_PORTAL_READONLY: "ENABLE_SCHEDULING_PORTAL_READONLY",
  ENABLE_WORKTICKET_PORTAL_READONLY: "ENABLE_WORKTICKET_PORTAL_READONLY",
  ENABLE_C360_ADMIN_MMID_READONLY: "ENABLE_C360_ADMIN_MMID_READONLY",
  ENABLE_C360_ADMIN_AUTO_VENDOR_READONLY: "ENABLE_C360_ADMIN_AUTO_VENDOR_READONLY",
  ENABLE_C360_ADMIN_AUTO_VENDOR_PORTAL: "ENABLE_C360_ADMIN_AUTO_VENDOR_PORTAL",
  ENABLE_C360_ADMIN_MMID_PORTAL: "ENABLE_C360_ADMIN_MMID_PORTAL",
  ENABLE_C360_ADMIN_MANUAL_INSTALLER_PORTAL: "ENABLE_C360_ADMIN_MANUAL_INSTALLER_PORTAL",
  ENABLE_C360_ADMIN_MANUAL_INSTALLER_READONLY: "ENABLE_C360_ADMIN_MANUAL_INSTALLER_READONLY",
  ENABLE_SCHEDULING_PORTAL_NEW_MENU: "ENABLE_SCHEDULING_PORTAL_NEW_MENU",
  ENABLE_SCHEDULING_PORTAL_NEW_READONLY: "ENABLE_SCHEDULING_PORTAL_NEW_READONLY",
  ENABLE_DEVICE_CHECKIN: "ENABLE_DEVICE_CHECKIN",
  ENABLE_RETURN_EQUIPMENT: "ENABLE_RETURN_EQUIPMENT",
  ENABLE_SHIPMENT_DATA: "ENABLE_SHIPMENT_DATA",
  ENABLE_SWAP_DEVICE: "ENABLE_SWAP_DEVICE",
  ENABLE_UPDATE_ACTIVATE_UNITS: "ENABLE_UPDATE_ACTIVATE_UNITS",
  ENABLE_UPLOAD_UNITS: "ENABLE_UPLOAD_UNITS",
  ENABLE_ASSIGN_CAMERAS: "ENABLE_ASSIGN_CAMERAS",
  ENABLE_SWAP_CAMERAS: "ENABLE_SWAP_CAMERAS",
  ENABLE_ORDER_PAGE: "ENABLE_ORDER_PAGE",
  ENABLE_DEVICE_REPORT: "ENABLE_DEVICE_REPORT",
  ENABLE_ORDER_REPORT: "ENABLE_ORDER_REPORT",
  ENABLE_RBAC_EDITOR: "ENABLE_RBAC_EDITOR",
  ENABLE_SCHEDULER_EVENTS: "ENABLE_SCHEDULER_EVENTS",
  ENABLE_CREATE_VEHICLE: "ENABLE_CREATE_VEHICLE",
  ENABLE_REVEAL_SEARCH: "ENABLE_REVEAL_SEARCH",
};
