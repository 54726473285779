import { Button } from "@vds/buttons";
import { Line } from "@vds/lines";
import { Loader } from "@vds/loaders";
import { Notification } from "@vds/notifications";
import { Body, Title } from "@vds/typography";
import { isEmpty } from "lodash";
import { lazy, useCallback, useContext, useEffect, useState } from "react";
import { generate } from "shortid";
import styled from "styled-components";
import InProgress from "../../assets/icons/Process.svg";
import Completed from "../../assets/icons/Success.svg";
import Failed from "../../assets/icons/failed.svg";
import { Header, SLDropDown, SLInput } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { FlexColumn, FlexRow } from "../../components/core/Utility";
import { UNIT_DATA_CONSTANT } from "../../constants/UnitDataConstants";
import { CheckinContext } from "../../context/checkinContext";
import { getUnitHistory } from "../../services/VTU/getUnitHistory";
import { getCases } from "../../services/checkin/getCases";
import { getVTUDetails } from "../../services/checkin/getVTUDetails";
import { updateRevealVehicle } from "../../services/checkin/updateRevealVehicle";
import { getProperty } from "../../utility/getProperty";
import ViewWorkTicketInfo from "./ViewWorkTicketInfo";
import { addPage, resetTab } from "../../utility/navigation";
import { CheckEsnStatusPage } from "./CheckEsnStatus";
import { VIEW_SF_CASES_INFO_COL } from "../../constants/CheckInConstants";
import { getVehicleDetailsByVin } from "../../services/checkin/getVehicleDetailsByVin";
import { getCheckVehicleDetailsAlreadyExist } from "../../services/checkin/getCheckVehicleDetailsAlreadyExist";
import { updateVtuTable } from "../../services/checkin/updateServiceRequestNote";

const CustomImage = styled.div`
  display: flex;
`;
const ContentWrapper = styled.div`
  padding: 20px 12px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SecondInputField = styled.input`
  padding: 20px 4px;
  margin-bottom: 10px;
  width: 77%;
  height: 35px;
`;

const Label = styled.label`
  width: 80%;
  margin-bottom: 0px;
  size: large;
  font-weight: bold;
`;
const Spacer = styled.div`
  margin-top: ${(props) => (props.top ? props.top : "0px")};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : "20px")};
  ${(props) => props.width && "width: " + props.width};
`;

const VINCount = styled.div`
  position: absolute;
  right: -20px;
`;

const NotificationWrapper = styled(Notification)`
  & div {
    justify-content: flex-start;
    max-width: 100%;
    & span:first-child {
      flex: 8 1 0%;
    }
  }
`;

export default function EditWorkTicket({ input, workTicketNr, productID, objectId }) {
  const { esn, workTicket } = useContext(CheckinContext);
  const [errValue, setErrValue] = useState("");
  const { esn: setEsn } = esn;
  const { workTicket: setWorkTicket } = useState(workTicket);
  const [peripheralsStatus, setPeripheralsStatus] = useState(null);
  const [cases, setCases] = useState([]);
  const [unitHistory, setUnitHistory] = useState([]);
  const [notification, setNotification] = useState();
  const [formData, setFormData] = useState({
    installername: "",
    vin: "",
    vehicleNum: "",
    vehicleName: "",
    licensePlate: "",
    year: "",
    make: "",
    model: "",
    engineOnVal: "0",
    odoMeterVal: "0",
    odoMeterUnitsVal: "",
    fuelTypeVal: "",
    vehicleSizeVal: "",
    installDateVal: "",
    esn: "",
    reporting: "",
    boxModel: "",
    WTANDInstallationID: "",
    account: "",
    installer: "",
    DBA: "",
    phone: "",
    carrier: "",
    checkinValidated: false,
    checkinComplete: false,
    modifiedBy: "",
    universalAccountId: "",
    action: "",
    vehicleTrackingId: "",
    workOrderNumber: `${workTicketNr}|${productID}`,
    GSM: "",
    location: "",
    peripheralsStatus_driverID: "",
    peripheralsStatus_LogBook: "",
    fSPValidated: "",
    workTicketNumber: "",
    iCCID: "",
    batchFileName: "",
    createdAt: "",
    createdBy: "",
    driverID: "",
    group: "",
    highwayMpg: "",
    installationID: "",
    installedBy: "",
    notes: "",
    odometerLive: "",
    tankCapacity: "",
    technicalID: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [noteUpdated, setNoteUpdated] = useState("");
  const [noteLog, setNoteLog] = useState([]);
  const [fieldsPreviousValue, setFieldsPreviousValue] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);

  const notify = (content, type) => {
    setNotification(<NotificationWrapper key={generate()} type={type} title={content} fullBleed={true} onCloseButtonClick={() => {}} />);
  };

  // handler function to update formData
  const handleChange = (e) => {
    setPrviousValue(e);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // function to update form previous data
  const setPrviousValue = (e) => {
    let _fileds = { ...fieldsPreviousValue };
    _fileds[e.target.name] = formData[e.target.name];
    setFieldsPreviousValue(_fileds);
  };

  const submitVehicleUpdateToReveal = async (buttonAction) => {
    try {
      setIsLoading(true);
      if (buttonAction !== "ResetUnit") {
        if (formData?.verficationStatus === "" || formData?.verficationStatus === null) {
          notify("Please select verification status", "error");
          return null;
        } else if (formData?.verficationStatus === "Fail") {
          if (formData?.notes === null || formData?.notes === "") {
            notify("Notes are required when verification status is Fail", "error");
            return null;
          } else if (formData?.notes?.length < 6) {
            notify("More Notes are required when verification status is Fail", "error");
            return null;
          } else {
            const response = await updateVtuTable({
              esn: formData?.esn,
              note: formData?.notes,
              action: "note",
            });
          }
        }
      }
      return await updateRevealVehicle({
        esn: setEsn,
        installerName: formData.installername,
        vin: formData.vin,
        vehicleSerialNumber: formData.vehicleNum,
        vehicleLabel: formData.vehicleName,
        vehicleRegNumber: formData.licensePlate,
        year: formData.year,
        make: formData.make,
        model: formData.model,
        engineOn: formData.engineOnVal,
        odometer: formData.odoMeterVal,
        odometerUnits: formData.odoMeterUnitsVal,
        fuelType: formData.fuelTypeVal,
        vehicleSize: formData.vehicleSizeVal,
        installDate: Date.parse(formData.installDateVal),
        universalAccountId: formData.account,
        location: formData.location,
        gSM: formData.GSM,
        workOrderNumber: formData.workOrderNumber,
        vehicleTrackingId: formData.vehicleTrackingId,
        action: buttonAction,
        modifiedBy: formData.modifiedBy,
        installedBy: formData.installedBy,
        installetionCompany: formData.installer,
        notes: formData.notes,
        workTicketNumber: workTicketNr,
        installationID: formData.installationID,
        boxModel: formData.boxModel,
        boxType: formData.boxType,
        dba: formData.DBA,
        phone: formData.phone,
        carrier: formData.carrier,
        driverID: formData.driverID,
        iCCID: formData.iCCID,
        group: formData.group,
        tankCapacity: formData.tankCapacity,
        cityMpg: formData.cityMpg,
        highwayMpg: formData.highwayMpg,
        odometerLive: formData.odometerLive,
        fSPValidated: formData.fSPValidated,
        verficationStatus: formData.verficationStatus,
        accountName: formData.accountName,
        createdBy: formData.createdBy,
        createdAt: formData.createdAt,
        technicalID: formData.technicalID,
        batchFileName: formData.batchFileName,
        objectId: objectId,
        checkInValidated: formData.checkinValidated,
        checkInComplete: formData.checkinComplete,
      }).then(async (response) => {
        setIsLoading(false);
        if (response?._vehicle?.data?.success) {
          switch (buttonAction) {
            case "SubmitAndNext":
              notify("Submit Unit is Successful", "success");
              setTimeout(() => {
                resetTab();
              }, 3000);
              break;
            case "SubmitUnit":
              notify("Submit Unit is Successful", "success");
              break;
            case "ResetUnit":
              notify("Reset Unit is Successful", "success");
              break;
          }
        } else {
          notify(response?._vehicle?.data?.message ?? "Unexpected error", "error");
        }

        let error = getProperty(response, "error", "");
        if (error !== "") {
          throw error;
        }
      });
    } catch (e) {
      setIsLoading(false);
      setErrValue("Couldn't Update Vehicle Information In Reveal");
    }
  };
  const setVerificationData = (verifcationData) => {
    setFormData({
      ...formData,
      ...verifcationData,
    });
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getVTUDetails({ esn: input?.esn, workItemId: workTicketNr });
      if (response._vtu.data == null) {
        setErrValue("We couldn't find line items for workTicket:", "");
      } else {
        const data = response?._vtu?.data;
        const peripheralResponse = data?.peripheralsStatus ?? {};
        for (let key in peripheralResponse) {
          if (peripheralResponse[key] !== null && peripheralResponse[key] !== undefined) {
            setPeripheralsStatus(data?.peripheralsStatus);
            break;
          }
        }
        const InstallDate = unixTimestampToDateStr(data?.installDate === null ? Date.now() : data?.installDate);

        setFormData((prevState) => ({
          ...prevState,
          installername: data?.installerName,
          vin: data?.vin,
          vehicleNum: data?.vehicleSerialNumber,
          vehicleName: data?.vehicleLabel,
          licensePlate: data?.vehicleRegNumber,
          year: data?.year,
          make: data?.make,
          model: data?.model,
          engineOnVal: data?.engineOn ?? "0",
          odoMeterVal: data?.odometer ?? "0",
          odoMeterUnitsVal: data?.odometerUnits,
          fuelTypeVal: data?.fuelType,
          vehicleSizeVal: data?.vehicleSize,
          installDateVal: InstallDate,
          esn: data?.esn,
          reporting: data?.reportingStatus,
          boxModel: data?.boxModel,
          WTANDInstallationID: data?.workOrderNumber,
          account: data?.universalAccountId,
          GSM: data?.gsm,
          location: data?.location,
          workOrderNumber: data?.workOrderNumber ?? formData.workOrderNumber,
          vehicleTrackingId: data?.vehicleTrackingId,
          installer: data?.installetionCompany,
          DBA: data?.dba,
          phone: data?.phone,
          carrier: data?.carrier,
          checkinValidated: data?.checkinValidated,
          checkinComplete: data?.checkinComplete,
          modifiedBy: data?.modifiedBy,
          installedBy: data?.installedBy,
          notes: data?.notes,
          workTicketNumber: data?.workTicketNr,
          installationID: data?.installationID,
          boxType: data?.boxType,
          driverID: data?.driverID,
          iCCID: data?.iccid,
          group: data?.group,
          tankCapacity: data?.tankCapacity,
          cityMpg: data?.cityMpg,
          highwayMpg: data?.highwayMpg,
          odometerLive: data?.odometerLive,
          fSPValidated: data?.fspvalidated,
          verficationStatus: data?.checkinValidated ? "Pass" : data?.checkinComplete !== null ? (data?.checkinComplete ? "Fail" : "Incomplete") : "",
          accountName: data?.accountName,
          createdBy: data?.createdBy,
          createdAt: data?.createdAt,
          technicalID: data?.technicalID,
          batchFileName: data?.batchFileName,
          peripheralsStatus_driverID: data?.peripheralsStatus?.DriverID,
          peripheralsStatus_LogBook: data?.peripheralsStatus?.Logbook,
          fspComplete: data?.fspvalidated !== null ? (data?.fspvalidated ? "Pass" : "Fail") : "",
        }));

        let noteArr = data?.notes === "" || data?.notes === null ? [] : data?.notes.includes("\n") ? data?.notes.split("\n") : [data?.notes];
        setNoteLog(noteArr.length > 0 ? noteArr : []);
      }
    } catch (error) {
      setErrValue("We couldn't fetch the data for esn", input?.esn);
    } finally {
      setIsLoading(false);
    }
  }, [input]);

  const fetchCases = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getCases(input?.esn);

      const data = response?.data?.data;
      const casesData = data.map((item) => ({
        caseID: item.Id,
        Status: item.Status,
        dateOpened: item.Date_Opened,
      }));
      setCases(casesData);
    } catch (error) {
      setCases([]);
    } finally {
      setIsLoading(false);
    }
  }, [input]);
  const fetchUnitHistory = useCallback(async () => {
    setIsLoading(true);
    getUnitHistory(input?.esn).then((data) => {
      if (data.rows?.statusCode !== undefined && data.rows?.statusCode === "200") {
        if (data.rows?.data) {
          for (let row of data.rows.data) {
            if (row.ChangedFieldsList) {
              row.changeField = "";
              for (let filed of row.ChangedFieldsList) {
                filed.changeField = `${filed?.FieldName}: ${filed?.NewValue}`;
              }
            }
          }
          setUnitHistory(data.rows?.data);
        }
      } else {
        notify(data?.error?.message, "error");
      }
      setIsLoading(false);
    });
  }, [input]);
  useEffect(() => {
    const execute = async () => {
      await fetchData();
      await fetchCases();
      await fetchUnitHistory();
    };
    execute();
  }, [fetchData, fetchCases, fetchUnitHistory]);
  const renderImage = (data) => {
    if (data == "InProgress" || data == "In Progress") {
      return (
        <>
          <CustomImage>
            <img src={InProgress} height="16px" width="16px" css={{ marginRight: "8px" }} />
            <Body primitive="span">{data}</Body>
          </CustomImage>
        </>
      );
    } else if (data == "Failed") {
      return (
        <>
          <CustomImage>
            <img src={Failed} height="16px" width="16px" css={{ marginRight: "8px" }} />
            <Body primitive="span">{data}</Body>
          </CustomImage>
        </>
      );
    } else {
      return (
        <>
          <CustomImage>
            <img src={Completed} height="16px" width="16px" css={{ marginRight: "8px" }} />
            <Body primitive="span">{data}</Body>
          </CustomImage>
        </>
      );
    }
  };

  function unixTimestampToDateStr(unixTimestamp) {
    const date = new Date(unixTimestamp); // No need to multiply by 1000
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() is zero-indexed, so we must add 1
    const day = ("0" + date.getDate()).slice(-2); // pad single digit dates with a leading 0
    return `${year}-${month}-${day}`;
  }

  const inputWidth = "400px";

  const handleVinChange = async (e) => {
    if (formData?.vin?.length === 0) {
      return;
    }
    setIsLoading(true);
    const result = await handleCheckvehicleDetailsExist(e);
    if (result) {
      return await getVehicleDetailsByVin({
        vehicle: {
          VIN: formData?.vin,
          UniversalAccountId: formData?.account,
        },
        location: formData?.location,
      }).then(async (response) => {
        setIsLoading(false);
        let res = response?.vData?.data;
        if (response?.vData?.statusCode === "200") {
          if (res?.success && res?.vehicleDetailsRevealDTO !== null) {
            res = res?.vehicleDetailsRevealDTO;
            setFormData({
              ...formData,
              model: res?.Model,
              year: res?.Year,
              make: res?.Make,
              cityMpg: res?.cityMpg,
              highwayMpg: res?.HighwayMPG,
              tankCapacity: res?.FuelTankCapacity,
            });
          }
        } else {
          let res = response?.vData?.data;
          notify(res?.message, "error");
        }
      });
    } else {
      setIsLoading(false);
    }
  };
  const checkNull = (name) => {
    if (name === null || name === undefined || name?.length === 0) {
      return true;
    }
    return false;
  };

  // function to check  if form fields has changed
  const hasChanged = (e) => {
    if (fieldsPreviousValue[e.target.name] == undefined || fieldsPreviousValue[e.target.name] === undefined) {
      return false;
    }
    if (fieldsPreviousValue[e.target.name] == e.target.value) {
      return false;
    }
    return true;
  };
  const handleCheckvehicleDetailsExist = async (e) => {
    let req = {
      accountUid: formData?.account,
      vehicleSerialNumber: null,
      vehicleRegNumber: null,
      vehicleLabel: null,
      type: "",
      vin: null,
    };
    if (!hasChanged(e)) {
      return;
    } else {
      let _fileds = { ...fieldsPreviousValue };
      _fileds[e.target.name] = e.target.value;
      setFieldsPreviousValue(_fileds);
    }
    switch (e.target.name) {
      case "vehicleNum":
        if (checkNull(formData?.vehicleNum)) return;
        req.vehicleSerialNumber = formData?.vehicleNum;
        req.type = "vehicleserialnumber";
        break;
      case "vehicleName":
        if (checkNull(formData?.vehicleName)) return;
        req.vehicleLabel = formData?.vehicleName;
        req.type = "vehiclelabel";
        break;
      case "licensePlate":
        if (checkNull(formData?.licensePlate)) return;
        req.vehicleRegNumber = formData?.licensePlate;
        req.type = "vehicleregnumber";
        break;
      case "vin":
        if (checkNull(formData?.vin)) return;
        req.vin = formData?.vin;
        req.type = "vin";
        break;
    }
    setIsLoading(true);
    return await getCheckVehicleDetailsAlreadyExist(req).then((response) => {
      setIsLoading(false);
      if (response?.vehData?.statusCode === "200") {
        const res = response?.vehData?.data;
        notify(res?.message, "success");
        return true;
      } else {
        let res = response?.vehData?.data;
        notify(res?.message, "error");
        return false;
      }
    });
  };
  const ColumnData = ({ values, bold = false, padding = "0px" }) => {
    return (
      <>
        <FlexColumn paddingRight={padding}>
          {values.map((data, index) => {
            return (
              <>
                <Body key={index} bold={bold} size="large">
                  {data || <span>&nbsp;</span>}
                </Body>
                <div style={{ height: "4px" }} />
              </>
            );
          })}
        </FlexColumn>
      </>
    );
  };

  const setNotes = (note) => {
    setNoteUpdated(note);
  };

  const onVerificationCallback = (isVerified) => (isVerified ? setDisableSubmit(false) : setDisableSubmit(true));

  return (
    <>
      <Header>Edit Workticket Unit</Header>
      {notification}
      {errValue !== "" ? (
        <Notification type="error" title onCloseButtonClick={() => setErrValue("")} subtitle={errValue} fullBleed={false} inlineTreatment={false} />
      ) : (
        <></>
      )}
      {isLoading ? (
        <Loader active={isLoading} />
      ) : (
        <>
          <div>
            <Spacer top="10px" bottom="10px">
              <Title size="medium" bold={true}>
                {formData.reporting !== null ? formData.reporting : "No Status"}
              </Title>
            </Spacer>
            <FlexRow justifyContent="space-between" paddingTop="10px">
              <FlexRow width="500px">
                <ColumnData bold values={["ESN:", "Box Model:", "WT#|Installation ID:", "Account:"]} padding="10px" />
                <ColumnData
                  values={[formData.esn, formData.boxModel, [workTicketNr, productID].join("|") + (formData.installationID || ""), formData.account]}
                />
              </FlexRow>
              <FlexRow width="500px">
                <ColumnData bold values={["Installer:", "DBA:", "Phone:", "Carrier:"]} padding="10px" />
                <ColumnData values={[formData.installername, formData.DBA, formData.phone, formData.carrier]} />
              </FlexRow>
            </FlexRow>
          </div>
          <div style={{ padding: "10px 0" }}>
            <Line type="light"></Line>
          </div>
          <FlexRow justifyContent="space-between">
            <FlexColumn width="517px">
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold>
                  {" "}
                  Installer Name<span style={{ color: "red", fontSize: "1.4em" }}>*</span> :
                </Body>
                <SLInput width={inputWidth} name="installername" type="text" value={formData?.installername} error={false} onChange={handleChange} />
              </FlexRow>
              <FlexRow
                alignItems="center"
                justifyContent="space-between"
                style={{
                  position: "relative",
                }}
              >
                <Body bold>
                  {" "}
                  VIN<span style={{ color: "red", fontSize: "1.4em" }}>*</span> :{" "}
                </Body>
                <SLInput
                  width={inputWidth}
                  name="vin"
                  type="text"
                  value={formData?.vin}
                  error={formData?.vin?.length > 17}
                  errorText={"The field VIN must be a string with a maximum length of 17."}
                  onChange={handleChange}
                  onBlur={handleVinChange}
                />
                <VINCount>{formData?.vin?.length}</VINCount>
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold> Vehicle #:</Body>
                <SLInput
                  width={inputWidth}
                  name="vehicleNum"
                  type="text"
                  value={formData?.vehicleNum}
                  error={false}
                  onChange={handleChange}
                  onBlur={(e) => handleCheckvehicleDetailsExist(e)}
                />
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold> Install Date: </Body>
                <SecondInputField name="installDateVal" type="date" value={formData?.installDateVal} onChange={handleChange} />
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold>
                  {" "}
                  Vehicle Name<span style={{ color: "red", fontSize: "1.4em" }}>*</span> :{" "}
                </Body>
                <SLInput
                  width={inputWidth}
                  name="vehicleName"
                  type="text"
                  value={formData?.vehicleName}
                  error={false}
                  onChange={handleChange}
                  onBlur={(e) => handleCheckvehicleDetailsExist(e)}
                />
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold>
                  {" "}
                  License Plate<span style={{ color: "red", fontSize: "1.4em" }}>*</span> :{" "}
                </Body>
                <SLInput
                  width={inputWidth}
                  name="licensePlate"
                  type="text"
                  value={formData?.licensePlate}
                  error={false}
                  onChange={handleChange}
                  onBlur={(e) => handleCheckvehicleDetailsExist(e)}
                />
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold>
                  {" "}
                  Make<span style={{ color: "red", fontSize: "1.4em" }}>*</span> :{" "}
                </Body>
                <SLInput
                  width={inputWidth}
                  name="make"
                  type="text"
                  value={formData?.make}
                  error={false}
                  onChange={handleChange}
                  // onBlur={(e) => handleCheckvehicleDetailsExist(e)}
                />
              </FlexRow>
            </FlexColumn>
            <FlexColumn width="517px">
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold> Year: </Body>
                <SLInput width={inputWidth} name="year" type="text" value={formData?.year} error={false} onChange={handleChange} />
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold> Engine On For: </Body>
                <SLInput width={inputWidth} name="engineOnVal" type="text" value={formData?.engineOnVal} error={false} onChange={handleChange} />
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold> Odometer: </Body>
                <SLInput width={inputWidth} name="odoMeterVal" type="text" value={formData?.odoMeterVal} error={false} onChange={handleChange} />
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold>Odometer Units : </Body>
                <SLDropDown
                  width={inputWidth}
                  name="odoMeterUnitsVal"
                  onChange={handleChange}
                  value={isEmpty(formData?.odoMeterUnitsVal) ? null : formData?.odoMeterUnitsVal}
                >
                  <option disabled selected hidden>
                    Please Select Units
                  </option>
                  <option value="mi">mi</option>
                  <option value="km">km</option>
                </SLDropDown>
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold>
                  {" "}
                  Fuel Type<span style={{ color: "red", fontSize: "1.4em" }}>*</span> :{" "}
                </Body>
                <SLDropDown
                  width={inputWidth}
                  name="fuelTypeVal"
                  onChange={handleChange}
                  value={isEmpty(formData?.fuelTypeVal) ? null : formData?.fuelTypeVal}
                >
                  <option disabled selected hidden>
                    Please Select Fuel type
                  </option>
                  <option value="Unleaded">Unleaded</option>
                  <option value="Diesel">Diesel</option>
                </SLDropDown>
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold> Model: </Body>
                <SLInput width={inputWidth} name="model" type="text" value={formData?.model} error={false} onChange={handleChange} />
              </FlexRow>
              <FlexRow alignItems="center" justifyContent="space-between">
                <Body bold> Vehicle Size: </Body>
                <SLDropDown
                  width={inputWidth}
                  name="vehicleSizeVal"
                  onChange={handleChange}
                  value={isEmpty(formData?.vehicleSizeVal) ? null : formData?.vehicleSizeVal}
                >
                  <option disabled selected hidden>
                    Please select vehicle size
                  </option>
                  <option value="LightDuty">Light-Duty</option>
                  <option value="MediumDuty">Medium-Duty</option>
                  <option value="HeavyDuty">Heavy-Duty</option>
                </SLDropDown>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <Button
              css={{ marginRight: "1rem" }}
              size="large"
              onClick={() => {
                submitVehicleUpdateToReveal("SubmitUnit");
              }}
              disabled={formData.checkinComplete ? false : disableSubmit}
            >
              Submit Unit
            </Button>
            <Button
              css={{ marginRight: "1rem" }}
              size="large"
              onClick={() => {
                submitVehicleUpdateToReveal("SubmitAndNext");
              }}
              disabled={formData.checkinComplete ? false : disableSubmit}
            >
              Submit & Next
            </Button>
            <Button
              css={{ marginRight: "1rem" }}
              size="large"
              onClick={() => {
                submitVehicleUpdateToReveal("ResetUnit");
              }}
            >
              Reset Unit
            </Button>
          </FlexRow>
          <div style={{ padding: "10px" }}>
            <Line type="light" />
          </div>
          <ViewWorkTicketInfo
            esn={formData?.esn}
            boxModel={formData?.boxModel}
            workTicketNumber={[workTicketNr, productID].join("|")}
            account={formData?.account}
            installer={formData?.installername}
            dba={formData?.DBA}
            verficationStatus={formData?.verficationStatus}
            fspComplete={formData?.fspComplete}
            phone={formData?.phone}
            carrier={formData?.carrier}
            reporting={formData?.reporting}
            lastModifiedBy={formData?.modifiedBy}
            cases={cases}
            peripheralsStatus={peripheralsStatus}
            fetchData={fetchData}
            fetchUnitHistory={fetchUnitHistory}
            data={formData}
            setVerificationData={setVerificationData}
            noteUpdated={noteUpdated}
            setNotes={setNotes}
            noteLog={noteLog}
            setNoteLog={setNoteLog}
            onVerificationCallback={onVerificationCallback}
          />
        </>
      )}
      <GridComponent
        isSelectionEnabled={false}
        isExportEnabled={true}
        noDataMessage="Unit history is Empty"
        rows={unitHistory}
        renderDataWithImage={renderImage}
        columns={UNIT_DATA_CONSTANT}
        title={
          <Title size="small" bold>
            Unit History
          </Title>
        }
        itemsPerPageDropDownValues={[
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 40, value: 40 },
          { label: 50, value: 50 },
        ]}
        scrollHeight="800px"
      />
      <Spacer height="20px" />
      <GridComponent
        rows={cases ? cases : []}
        isExportEnabled={true}
        columns={VIEW_SF_CASES_INFO_COL}
        noDataMessage="No cases found within the past 3 months"
        title={
          <Title size="small" bold>
            Salesforce Cases
          </Title>
        }
        itemsPerPageDropDownValues={[
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 40, value: 40 },
          { label: 50, value: 50 },
        ]}
        scrollHeight="800px"
        scrollable="false"
        scrollDirection=""
      />
      <></>
    </>
  );
}
export const EditWorkTicketPage = {
  name: "Edit Work ticket",
  id: "editworkticketpage",
  component: lazy(() => import("./EditWorkTicket")),
  route: "/editworkticket",
};
