import React from "react";
import styled from "@emotion/styled";
import { TableBody, TableRow, Cell, TableHeader, TableHead } from "@vds/tables";
import { CustomisedTable, CustomSmallText } from "./DocumentFlowTable";

const Container = styled.div`
  ::-webkit-scrollbar {
    display: block !important;
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    display: inline-block !important;
    background-color: #c0c0c0 !important;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  text-align: left;
  margin-bottom: 5px;
`;

export const MaterialSOInfoTable = ({ data, title, property }) => {
  const openLinkInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <>
      <CustomisedTable role="table">
        <TableHead role="row">
          <TableHeader role="columnheader">
            <CustomSmallText bold>{title}</CustomSmallText>
          </TableHeader>
        </TableHead>
        <TableBody role="rowgroup">
          <TableRow role="row">
            <Container css={{ height: "5rem", overflowY: "auto" }}>
              <Cell role="cell" css={{ borderRight: "none !important" }}>
                <CustomSmallText css={{ whiteSpace: "break-spaces" }}>
                  {data?.map((item, idx) =>
                    idx === data.length - 1 ? (
                      property === "trackingUrl" ? (
                        <LinkButton
                          key={idx}
                          role="link"
                          aria-label={`${item[property]}`}
                          onClick={() => openLinkInNewTab(item[property])}
                        >{`${item[property]}`}</LinkButton>
                      ) : (
                        item[property]
                      )
                    ) : property === "trackingUrl" ? (
                      <LinkButton
                        role="link"
                        aria-label={`${item[property]}`}
                        key={idx}
                        onClick={() => openLinkInNewTab(item[property])}
                      >{`${item[property]}`}</LinkButton>
                    ) : (
                      `${item[property]}, `
                    )
                  )}
                </CustomSmallText>
              </Cell>
            </Container>
          </TableRow>
        </TableBody>
      </CustomisedTable>
    </>
  );
};
