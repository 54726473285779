import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const RETURN_TABLE_COL = [
  {
    key: "Internal Ticket",
    value: "returnId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "162px",
  },
  {
    key: "Status",
    value: "status",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: true,
    width: "155px",
  },
  {
    key: "Created On",
    value: "createdAt",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
    isDate: true,
    dateFormat: "MMM D, yyyy",
  },
];

export const RETURN_FLYOUT_MENU_COL = [
  {
    key: "MMID",
    value: "mmid",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Quantity",
    value: "returnQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Return Number",
    value: "returnNumber1erp",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Delivery Order",
    value: "deliveryDoc1erp",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "ESN",
    value: "esn",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.medium,
  },
];
