import { getRequest } from "../RestServices";
import * as urls from "../../api/aggregator/index";

import { getProperty } from "./../../utility/getProperty";
export const CheckStatusService = async () => {
  try {
    const _response = await getRequest(urls.CheckStatus);
    const _VtuDetails = getProperty(_response, "data.data", []);
    return { rows: _VtuDetails };
  } catch (err) {
    return {
      error: err,
    };
  }
};
