import React, { useState, useContext, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Title, Body } from "@vds/typography";
import Icon from "@vds/icons";
import { SLButton } from "../../components/core/Controls";
import { CompactCol, FlexColumn, FlexRow, SmallText, Spacer } from "../../components/core/Utility";
import { StyledModal } from "./RescheduleModal";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { Loader } from "@vds/loaders";
import { sessionData } from "../../utility/sessionData";
import { completeJob } from "../../services/scheduler/completeJob";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { css } from "@emotion/react";
import { range } from "lodash";
import { Dropdown } from "primereact/dropdown";
import { PARTIAL_COMPLETION_REASONS } from "../../constants/PartialCompletionReasons";
import { Grid, Row } from "@vds/grids";

export const CustomisedInput = css`
  border: 0.0625rem solid #d8dada !important;
  border-bottom: 0.0625rem solid #000000 !important;
  width: 25%;
  height: 2.75rem;
  box-shadow: none !important;
  border-radius: 0;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-dropdown:focus,
  .p-dropdown:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
`;

export const CustomisedModal = css`
  width: 660px;
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }
  @media screen and (max-width: 990px) {
    width: 760px !important;
    max-width: 85vw;
  }

  & mark {
    background-color: transparent !important;
  }
`;

export const CustomisedReasonDropdown = css`
  width: 25vw;
  @media screen and (max-width: 990px) {
    width: 38vw;
  }
  @media screen and (min-width: 1400px) {
    width: 20vw;
  }
  @media screen and (min-width: 1650px) {
    width: 16vw;
  }
  @media screen and (min-width: 2100px) {
    width: 14vw;
  }
`;

const CompleteJob = ({ jobDetails, setComponent, notify, getJob }) => {
  updateNewrelicStats();
  const { accountId, source } = useContext(AccountDetailsContext);
  const [isLoading, setLoader] = useState(false);
  const [installedVehicles, setInstalledVehicles] = useState(jobDetails.vehicles);
  const [isreasonsDropdown, setIsReasonsDropdown] = useState(false);
  const [reason, setReason] = useState("");

  const completeJobs = async () => {
    setLoader(true);
    let payload = {
      serviceJobId: jobDetails.serviceJobId,
      installCompletedQuantity: installedVehicles,
      partialCompletionReason: reason,
    };
    let response = await completeJob(payload, jobDetails.jobId);
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
      sessionData(jobDetails.jobId);
      setLoader(false);
      setComponent(null);
      notify("Successfully updated job status", "success");
      getJob(accountId.accountId, source.source);
    } else {
      setLoader(false);
      setComponent(null);
      notify("Unable to update job status due to an unexpected error", "error");
    }
  };
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setComponent(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const handlePartialReasons = (selectedVehicleCount) => {
    setInstalledVehicles(selectedVehicleCount);
    if (selectedVehicleCount < jobDetails.vehicles) {
      setIsReasonsDropdown(true);
    } else {
      setIsReasonsDropdown(false);
      setReason("");
    }
  };

  return (
    <Modal
      ariaLabel="Complete Job Modal"
      opened={true}
      fullScreenDialog={false}
      disableOutsideClick={true}
      css={CustomisedModal}
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <Title size="medium">Complete job?</Title>
          <div onClick={() => setComponent(null)} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
            <Icon name="close" size="medium" color="#000000" style={{ cursor: "pointer", outline: "none" }} />
          </div>
        </FlexRow>
      }
    >
      <ModalBody style={{ paddingLeft: "0", paddingRight: "0" }}>
        <Loader active={isLoading} fullscreen={false} />
        <Body>If any installs from the scheduled job have not been completed yet, you need to schedule a new job.</Body>
        <Spacer top="1.2rem" />
        <Grid>
          <Row>
            <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
              <Spacer top="2px" />
              <Body>How many installs were completed?</Body>
              <Spacer top="14px" />
              <Dropdown
                value={installedVehicles}
                options={range(1, jobDetails.vehicles + 1)}
                onChange={(e) => handlePartialReasons(e.value)}
                css={[CustomisedInput, { width: "10vw" }]}
                scrollHeight="7.5rem"
              />
            </CompactCol>
            <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
              {isreasonsDropdown && (
                <>
                  <SmallText>Reason for partial install (Optional)</SmallText>
                  <Spacer top="10px" />
                  <Dropdown
                    value={reason}
                    placeholder="Please select"
                    options={PARTIAL_COMPLETION_REASONS}
                    onChange={(e) => setReason(e.value)}
                    css={[CustomisedInput, CustomisedReasonDropdown]}
                    scrollHeight="8rem"
                  />
                </>
              )}
            </CompactCol>
          </Row>
        </Grid>
      </ModalBody>
      {/* <ModalFooter> */}
      <FlexRow style={{ justifyContent: "flex-end", paddingTop: "7rem" }}>
        <SLButton size="large" style={{ width: "10rem" }} onClick={completeJobs}>
          Confirm
        </SLButton>
        <SLButton size="large" secondary style={{ width: "10rem" }} onClick={() => setComponent(null)}>
          Cancel
        </SLButton>
      </FlexRow>
      {/* </ModalFooter> */}
    </Modal>
  );
};
export default CompleteJob;
