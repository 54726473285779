import { MediumText, SmallText } from "../../components/core/Utility";
import { Tooltip } from "primereact/tooltip";
import { generate } from "shortid";
import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds/modals";
import { Button } from "@vds/buttons";
const Text = ({ value, className }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <SmallText
        css={{ textDecoration: "underline", cursor: "pointer", maxWidth: "250px", textOverflow: "ellipsis" }}
        className={className}
        primitive="span"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {value}
      </SmallText>
      <Modal opened={showModal} disableOutsideClick closeButton={null}>
        <ModalTitle>Error Message</ModalTitle>
        <ModalBody css={{ paddingLeft: "0" }}>
          <MediumText primitive="span">{value}</MediumText>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowModal(false)}>Close</Button>
          <Button css={{ marginLeft: "20px" }} onClick={() => navigator.clipboard.writeText(value)}>
            Copy to Clipboard
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export const errorBody = (rowData, data) => {
  const value = rowData[data?.field];
  if (!value) {
    return "--";
  }
  let className = rowData?.salesForceOpportunityId ?? generate();
  className = `Error${className}`;
  return (
    <>
      <Tooltip css={{ maxWidth: "500px" }} target={`.${className}`} content={value} mouseTrack mouseTrackLeft={10}></Tooltip>
      <Text value={value} className={className}></Text>
    </>
  );
};
