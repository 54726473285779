import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const getSlots = async (transactionId, isMore) => {
  try {
    return await getRequest(urls.GET_TIME_SLOTS + `/${transactionId}?isMore=${isMore}`);
  } catch (err) {
    return err;
  }
};
