import { COL_WIDTH } from "./ReportTable";

export const API_STATS_REPORT_COLUMNS = [
  {
    header: "JOB_ID",
    field: "jobID",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "API_TYPE",
    field: "apiType",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "VENDOR",
    field: "vendor",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "RESPONSE_TIME",
    field: "responseTime",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "MESSAGE",
    field: "message",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "RESULT",
    field: "result",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.large },
  },
  {
    header: "ERROR",
    field: "error",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.medium },
  },
  {
    header: "CREATED_DATE(UTC)",
    field: "createdDate",
    isFilterEnabled: false,
    dataType: "date",
    dateFormat: "MMM D, yyyy",
    style: { minWidth: COL_WIDTH.medium },
  },
  {
    header: "CREATED_TIME(UTC)",
    field: "createdTime",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.medium },
  },
  {
    header: "CREATED_BY",
    field: "createdBy",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.large },
  },
  {
    header: "REQUEST_CONTROL_ID",
    field: "requestControlId",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.xlarge },
  },
  {
    header: "TRANSACTION_ID",
    field: "transactionId",
    isFilterEnabled: false,
    dataType: "text",
    style: { minWidth: COL_WIDTH.medium },
  },
];
