//VTU Endpoints

const BaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VTU}`;

export const GETBYESN = `${BaseUrl}/vehiclesbyesn`;
export const RETURN_EQUIPMENT = `${BaseUrl}/rma`;
export const GETDETAILSBYESN = `${BaseUrl}/search`;
export const GETESNSBYACCOUNT = `${BaseUrl}/account/esn`;
export const GETBYACCOUNTID = `${BaseUrl}/vehicles`;
export const GETBYTECHREFID = `${BaseUrl}/vehiclesbytechrefid`;
export const CHECKSTATUS = `${BaseUrl}/status`;
export const SWAPSIM = `${BaseUrl}/swapSIM/esn`;
export const SIMSWAP = `${BaseUrl}/simSwap`;
export const FULLBOXSWAP = `${BaseUrl}/fullboxswap`;
export const ESNWITHACCOUNT = `${BaseUrl}/accounts`;
export const DETAILSFORACCOUNT = `${BaseUrl}/devices`;
export const LOCALSWAP = `${BaseUrl}/localSwap`;
export const UNIT_HISTORY = `${BaseUrl}/getUnitHistory`;
export const DECOMISSIONVEHICLEUPLOAD = `${BaseUrl}/uploaddecommissioncsv`;
export const DECOMISSIONVEHICLEDWONLOAD = `${BaseUrl}/decommissionTemplate`;
export const AUDIT_LOGS = `${BaseUrl}/auditlogs`;
export const CFWARRANTYSTATUS = `${BaseUrl}/cfWarrantyInfo`;