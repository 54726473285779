import { getProperty } from "../../utility/getProperty";
import { postRequest } from "../RestServices";
import * as urls from "../../api/checkin/index";
export const getVTUDetails = async (payload) => {
  try {
    const _response = await postRequest(urls.GET_VTU_DETAILS, payload);
    const _vtu_detail = getProperty(_response, "data", {});
    return { _vtu: _vtu_detail };
  } catch (err) {
    return {
      error: err,
    };
  }
};
