import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const installersMapping = async (payload) => {
  try {
    return await postRequest(urls.INSTALLERS_MAPPING, payload);
  } catch (err) {
    return err?.response;
  }
};
