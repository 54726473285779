import React, { lazy, useState } from "react";
import mapImg from "../../assets/images/map.png";
import { Grid, Row, Col } from "@vds/grids";
import { Button } from "@vds/buttons";
import { Header, SLButton } from "../../components/core/Controls";
import { FlexColumn, FlexRow, MediumText, SmallText, Spacer, UltraMediumText } from "../../components/core/Utility";
import { CustomDropdown, CustomInput, CalendarWrapper } from "./CreateNewJob";
import { sessionData, jobDurationFormatter, handleStartDate } from "../../utility/sessionData";
import { InputNumber } from "primereact/inputnumber";
import { css } from "@emotion/react";
import styled from "styled-components";
import moment from "moment";

export const CustomisedInput = css`
  border: 0.0625rem solid #d8dada;
  width: 30%;
  height: 2.75rem;
  margin-right: 0.5rem;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin-top: 0px;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
`;
const CustomLabel = css`
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Verizon-NHG-eTX, Helvetica, Ariel, Sans-serif;
`;

export default function AlternateManualSchedule() {
  const [installer, setInstaller] = useState("");
  const [distance, setDistance] = useState("");
  const [startDate, setStartDate] = useState(moment().format("MM/DD/YYYY"));
  // const [scheduleTime, setScheduleTime] = useState("1:00 AM");
  const [scheduleHour, setScheduleHour] = useState(1);
  const [scheduleMinutes, setScheduleMinutes] = useState("00");
  const [meridian, setMeridian] = useState("AM");

  const handleFields = (field, value) => {
    switch (field) {
      case "Installer":
        setInstaller(value);
        break;
      case "Distance":
        if (value && value !== "0") {
          setDistance(parseInt(value));
        } else {
          setDistance("");
        }
        break;
      case "Job Start Date": {
        let date = moment(value, "MMMM DD,YYYY").format("MM/DD/YYYY");
        setStartDate(date);
        break;
      }
      case "Hour":
        setScheduleHour(value);
        // setScheduleTime(value + ":" + scheduleMinutes + " " + (value > 11 ? "PM" : "AM"));
        break;
      case "Minutes":
        setScheduleMinutes(value);
        // setScheduleTime(scheduleHour + ":" + value + " " + (scheduleHour > 11 ? "PM" : "AM"));
        break;
      case "Meridian":
        setMeridian(value);
        break;
      default:
        break;
    }
  };

  return (
    // <>
    //   <Grid
    //     bleed="1272"
    //     rowGutter="10px"
    //     colSizes={{
    //       mobile: 1,
    //       tablet: 2,
    //       desktop: 3,
    //     }}
    //   >
    //     <Row css={{ marginTop: "2rem" }}>
    //       <Col css={{ minWidth: "50%", paddingLeft: "0" }}>
    //         <Header>Schedule Job</Header>
    //         <FlexRow css={{ marginTop: "20px" }}>
    //           <SmallText bold>Job Type:&nbsp;</SmallText>
    //           <SmallText>Support/RMA</SmallText>
    //         </FlexRow>
    //         <FlexRow>
    //           <SmallText bold>Requested Date:&nbsp;</SmallText>
    //           <SmallText>06/10/2023</SmallText>
    //         </FlexRow>
    //         <FlexRow>
    //           <SmallText bold>Duration:&nbsp;</SmallText>
    //           <SmallText>1.5 Hours</SmallText>
    //           <SmallText bold>&nbsp;Vehicle Count:&nbsp;</SmallText>
    //           <SmallText>3</SmallText>
    //         </FlexRow>
    //         <FlexRow>
    //           <SmallText bold>Work Ticket Number:&nbsp;</SmallText>
    //           <SmallText>390030</SmallText>
    //         </FlexRow>
    //         <FlexRow>
    //           <SmallText bold>Job ID:&nbsp;</SmallText>
    //           <SmallText>SP_005000761SJ</SmallText>
    //         </FlexRow>
    //         <FlexRow css={{ marginBottom: "2rem" }}>
    //           <SmallText bold>Address:&nbsp;</SmallText>
    //           <SmallText>187 TOWNE PARK DR, LAWRENCEVILLE, GA, 30044</SmallText>
    //         </FlexRow>
    //         <CustomDropdown
    //           width="60%"
    //           type="text"
    //           label="Installer"
    //           marginTop="1rem"
    //           marginBottom="0.3rem"
    //           // disabled={isFieldsDisabled}
    //           // disableValue={!csp.value.name}
    //           // error={csp.error}
    //           // errorText={csp.errorText}
    //           value={installer ? installer : "Please select"}
    //           onChange={(event) => handleFields("Installer", event.target.value)}
    //         >
    //           <option disabled style={{ backgroundColor: "lightgrey" }}>
    //             Please select
    //           </option>
    //           <option key="Orbital Kentucky" value="Orbital Kentucky">
    //             Orbital Kentucky
    //           </option>
    //         </CustomDropdown>
    //         <CustomInput
    //           // width="40%"
    //           boxWidth="15%"
    //           type="text"
    //           label="Distance"
    //           // disabled={isFieldsDisabled}
    //           // error={name.error}
    //           // errorText={name.errorText}
    //           // success={name.success}
    //           value={distance}
    //           // maxLength={50}
    //           onChange={(event) => handleFields("Distance", event.target.value)}
    //         />
    //         <CalendarWrapper>
    //           <CustomInput
    //             width="88%"
    //             type="calendar"
    //             label="Select Job Start Date"
    //             // disabled={isFieldsDisabled}
    //             // error={startDate.error}
    //             // errorText={startDate.errorText}
    //             // success={startDate.success}
    //             value={startDate}
    //             // selectedDate={new Date(startDate.value)}
    //             minDate={new Date(handleStartDate())}
    //             onChange={(event) => handleFields("Job Start Date", event)}
    //           />
    //         </CalendarWrapper>
    //         {/* <CustomDropdown
    //         width="60%"
    //         type="text"
    //         label="Scheduled Time"
    //         marginBottom="0.3rem"
    //         // disabled={isFieldsDisabled}
    //         // disableValue={!csp.value.name}
    //         // error={csp.error}
    //         // errorText={csp.errorText}
    //         value={scheduleTime ? scheduleTime : "Please select"}
    //         onChange={(event) => setScheduleTime(event.target.value)}
    //       >
    //         <option disabled style={{ backgroundColor: "lightgrey" }}>
    //           Please select
    //         </option>
    //         <option key="2.30 PM" value="2.30 PM">
    //           2.30 PM
    //         </option>
    //       </CustomDropdown> */}
    //         <label css={CustomLabel}>Scheduled Time</label>
    //         <FlexRow css={{ marginBottom: "0.3rem", marginTop: "0.3rem" }}>
    //           <InputNumber
    //             value={scheduleHour}
    //             min={1}
    //             max={23}
    //             showButtons
    //             onChange={(e) => handleFields("Hour", e.value)}
    //             css={CustomisedInput}
    //           ></InputNumber>
    //           <FlexRow alignContent="center" css={{ marginRight: "0.5rem" }}>
    //             :
    //           </FlexRow>
    //           <CustomDropdown
    //             width="25%"
    //             type="text"
    //             // label="Scheduled Time"
    //             // marginBottom="0.3rem"
    //             // disabled={isFieldsDisabled}
    //             // disableValue={!csp.value.name}
    //             // error={csp.error}
    //             // errorText={csp.errorText}
    //             value={scheduleMinutes}
    //             onChange={(event) => handleFields("Minutes", event.target.value)}
    //           >
    //             {/* <option disabled style={{ backgroundColor: "lightgrey" }}>
    //             Please select
    //           </option> */}
    //             <option key="00" value="00">
    //               00
    //             </option>
    //             <option key="15" value="15">
    //               15
    //             </option>
    //             <option key="30" value="30">
    //               30
    //             </option>
    //             <option key="45" value="45">
    //               45
    //             </option>
    //           </CustomDropdown>
    //           {/* <FlexRow alignContent="center" css={{ marginLeft: "0.5rem" }}>
    //           {scheduleTime}
    //         </FlexRow> */}
    //         </FlexRow>
    //       </Col>
    //       <Col css={{ minWidth: "50%", paddingRight: "0" }}>
    //         <img src={mapImg} alt="map" css={{ width: "100%", height: "100%" }} />
    //       </Col>
    //     </Row>
    //   </Grid>
    //   <FlexRow css={{ marginTop: "1.5rem" }}>
    //     <Button primary disabled={!installer || !distance || !startDate} css={{ marginLeft: "0px" }}>
    //       Schedule
    //     </Button>
    //     <Button
    //       secondary
    //       css={{ marginLeft: "0.5rem" }}
    //       // onClick={() => handleConfirmation("cancel")}
    //     >
    //       Cancel
    //     </Button>
    //   </FlexRow>
    // </>
    <>
      <Grid
        bleed="1272"
        rowGutter="10px"
        colSizes={{
          mobile: 1,
          tablet: 2,
          desktop: 3,
        }}
      >
        <Row css={{ marginTop: "2rem" }}>
          <Header>Schedule Job</Header>
          <Col css={{ minWidth: "50%", paddingLeft: "0" }}>
            <FlexRow css={{ marginTop: "20px" }}>
              <SmallText bold>Job Type:&nbsp;</SmallText>
              <SmallText>Support/RMA</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Requested Date:&nbsp;</SmallText>
              <SmallText>06/10/2023</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Duration:&nbsp;</SmallText>
              <SmallText>1.5 Hours</SmallText>
              <SmallText bold>&nbsp;Vehicle Count:&nbsp;</SmallText>
              <SmallText>3</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Work Ticket Number:&nbsp;</SmallText>
              <SmallText>390030</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Job ID:&nbsp;</SmallText>
              <SmallText>SP_005000761SJ</SmallText>
            </FlexRow>
            <FlexRow>
              <SmallText bold>Address:&nbsp;</SmallText>
              <SmallText>187 TOWNE PARK DR, LAWRENCEVILLE, GA, 30044</SmallText>
            </FlexRow>
            {/* <img src={mapImg} alt="map" css={{ width: "100%", height: "100%" }} /> */}
          </Col>
          <Col css={{ minWidth: "50%", paddingLeft: "0", paddingRight: "0", marginTop: "1rem" }}>
            <CustomDropdown
              width="60%"
              type="text"
              label="Installer"
              marginBottom="0.3rem"
              // disabled={isFieldsDisabled}
              // disableValue={!csp.value.name}
              // error={csp.error}
              // errorText={csp.errorText}
              value={installer ? installer : "Please select"}
              onChange={(event) => handleFields("Installer", event.target.value)}
            >
              <option disabled style={{ backgroundColor: "lightgrey" }}>
                Please select
              </option>
              <option key="Orbital Kentucky" value="Orbital Kentucky">
                Orbital Kentucky
              </option>
            </CustomDropdown>
            <CustomInput
              boxWidth="15%"
              type="text"
              label="Distance"
              // disabled={isFieldsDisabled}
              // error={name.error}
              // errorText={name.errorText}
              // success={name.success}
              value={distance}
              // maxLength={50}
              onChange={(event) => handleFields("Distance", event.target.value)}
            />
            {/* <CalendarWrapper>
              <CustomInput
                type="calendar"
                label="Select Job Start Date"
                value={startDate}
                minDate={new Date(handleStartDate())}
                onChange={(event) => handleFields("Job Start Date", event)}
              />
            </CalendarWrapper>
            <label css={CustomLabel}>Scheduled Time</label>
            <FlexRow css={{ marginBottom: "0.3rem", marginTop: "0.3rem" }}>
              <InputNumber
                value={scheduleHour}
                min={1}
                max={11}
                showButtons
                onChange={(e) => handleFields("Hour", e.value)}
                css={CustomisedInput}
              ></InputNumber>
              <FlexRow alignContent="center" css={{ marginRight: "0.5rem" }}>
                :
              </FlexRow>
              <CustomDropdown
                width="30%"
                type="text"
                value={scheduleMinutes}
                onChange={(event) => handleFields("Minutes", event.target.value)}
              >
                <option key="00" value="00">
                  00
                </option>
                <option key="30" value="30">
                  30
                </option>
              </CustomDropdown>
              <CustomDropdown
                width="30%"
                marginLeft="0.5rem"
                type="text"
                value={meridian}
                onChange={(event) => handleFields("Meridian", event.target.value)}
              >
                <option key="AM" value="AM">
                  AM
                </option>
                <option key="PM" value="PM">
                  PM
                </option>
              </CustomDropdown>
            </FlexRow>
            <FlexRow css={{ marginTop: "1.5rem" }}>
              <Button primary disabled={!installer || !distance || !startDate} css={{ marginLeft: "0px" }}>
                Schedule
              </Button>
              <Button
                secondary
                css={{ marginLeft: "0.5rem" }}
              >
                Cancel
              </Button>
            </FlexRow> */}
          </Col>
        </Row>
        <Row>
          <img src={mapImg} alt="map" css={{ width: "100%", height: "100%" }} />
          <Col css={{ minWidth: "55%", paddingLeft: "0", marginTop: "1rem" }}>
            <CalendarWrapper>
              <CustomInput
                type="calendar"
                label="Select Job Start Date"
                value={startDate}
                selectedDate={new Date(startDate)}
                minDate={new Date(moment().format("MM/DD/YYYY"))}
                onChange={(event) => handleFields("Job Start Date", event)}
              />
            </CalendarWrapper>
            <label css={CustomLabel}>Scheduled Time</label>
            <FlexRow css={{ marginBottom: "0.3rem", marginTop: "0.3rem" }}>
              <InputNumber
                value={scheduleHour}
                min={1}
                max={12}
                showButtons
                onChange={(e) => handleFields("Hour", e.value)}
                css={CustomisedInput}
              ></InputNumber>
              <FlexRow alignContent="center" css={{ marginRight: "0.5rem" }}>
                :
              </FlexRow>
              <CustomDropdown width="30%" type="text" value={scheduleMinutes} onChange={(event) => handleFields("Minutes", event.target.value)}>
                <option key="00" value="00">
                  00
                </option>
                <option key="30" value="30">
                  30
                </option>
              </CustomDropdown>
              <CustomDropdown
                width="30%"
                marginLeft="0.5rem"
                type="text"
                value={meridian}
                onChange={(event) => handleFields("Meridian", event.target.value)}
              >
                <option key="AM" value="AM">
                  AM
                </option>
                <option key="PM" value="PM">
                  PM
                </option>
              </CustomDropdown>
            </FlexRow>
            <FlexRow css={{ marginTop: "1.5rem" }}>
              <Button primary disabled={!installer || !distance || !startDate} css={{ marginLeft: "0px" }}>
                Schedule
              </Button>
              <Button secondary css={{ marginLeft: "0.5rem" }}>
                Cancel
              </Button>
            </FlexRow>
          </Col>
        </Row>
      </Grid>
    </>
  );
}

export const AlternateManualEntryMetadata = {
  name: "Alternate Manual Entry",
  id: "alternate_manual_schedule",
  component: lazy(() => import("./AlternateManualSchedule")),
  route: "/alternate_manual_schedule",
};
