import React, { lazy, useState, useEffect, useContext, useRef } from "react";
import { Header, SLButton } from "../../components/core/Controls";
import { Notification } from "@vds/notifications";
import { FlexColumn, FlexRow, MediumText, SmallText, Spacer, UltraMediumText } from "../../components/core/Utility";
import { CustomDropdown } from "./CreateNewJob";
import { Modal, ModalFooter, ModalTitle } from "@vds/modals";
import styled from "styled-components";
import { Icon } from "@vds/icons";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";
import moment from "moment";
import { Loader } from "@vds/loaders";
import { getSchedule } from "../../services/scheduler/getSchedule";
import { createSchedule } from "../../services/scheduler/createSchedule";
import { groupBy, maxBy, sortBy, sortedUniqBy } from "lodash";
import { generate } from "shortid";
import { Line } from "@vds/lines";
import { resetTab } from "../../../utility/navigation";
import { Body, Micro } from "@vds/typography";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { getReasonlist } from "../../services/scheduler/getReasonlist";
import { rescheduleJob } from "../../services/scheduler/rescheduleJob";
import { StyledModal } from "./RescheduleModal";
import { durationUnitCalculation, handleStartDate, sessionData } from "../../utility/sessionData";
import ConfirmationModal from "./ConfirmationModal";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { TextLink } from "@vds/buttons";
import { SlotButtons } from "./SlotsButton";
import RescheduleReasonModal from "./RescheduleReasonModal";
import { getSlots } from "../../services/scheduler/getSlots";
import { cancelBooking } from "../../services/scheduler/cancelBooking";
import { css } from "@emotion/react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import StartDateChangeModal from "./StartDateChangeModal";

const customSpinner = css`
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  // width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  opacity: 0.8;
  & svg {
    width: 6%;
  }
  .CircularProgressbar-path {
    stroke: green;
  }
  .CircularProgressbar-text {
    fill: #000000;
    font-size: 25px;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & div:nth-child(2) {
    margin-right: 10%;
    cursor: pointer;
  }
`;

const SlotsContainer = styled.div`
  width: 45rem;
  @media (max-width: 767px) {
    width: 40rem;
  }
`;

const Footer = styled.div`
  height: auto;
  bottom: 0px;
  position: fixed;
  padding-bottom: 10px;
  background-color: white;
  width: 100%;
  border-top: 1px solid black;
`;

export default function ScheduleJob({ jobData, expandJobVendorID }) {
  const { accountDetails } = useContext(AccountDetailsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [jobType] = useState(jobData.jobDetails[0].jobType);
  const [requestedDate, setRequestedDate] = useState(moment(jobData.startDate).format("MM/DD/YYYY"));
  const [apptLength] = useState(jobData.appointmentLength);
  const [vehicleCount] = useState(jobData.jobDetails[0].vehicleCount);
  const [vendorId, setVendorId] = useState(jobData.vendorId);
  const [jobId] = useState(jobData.id);
  const [csp] = useState(jobData.jobDetails[0].cspSfdcId);
  const [workTicketNumber] = useState(jobData.jobDetails[0].workTickets.map((workTickets) => workTickets.workTicket).join(","));
  const [serviceJobId] = useState(jobData.serviceJobId);
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spotenabledDates, setSpotEnabledDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date(jobData.startDate));
  const [availableDateInfo, setavailableDateInfo] = useState([]);
  const [totalMiles, settotalMiles] = useState([]);
  const [notification, setNotification] = useState();
  const [slotDetails, setSlotDetails] = useState({});
  const [selectedSlot, setSelectedSlot] = useState({});
  const [submitDisability, setSubmitDisability] = useState(true);
  const [availabilityDates, setAvailabilityDates] = useState([]);
  const [reschedule] = useState(jobData.reschedule);
  const [reason, setReason] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [rescheduleReasonList, setRescheduleReasonList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const [viewCount, setViewCount] = useState(3);
  const [kbArticleLink] = useState("https://vzt-kb.vtitel.com/Reveal/Reveal_Support/Reveal_Scheduling/Scheduling_and_Shipping_Scenarios");
  const [timezone, setTimezone] = useState(jobData.timezone || "");
  const [rescheduleReasonModalStatus, setRescheduleReasonModalStatus] = useState({
    isModalOpen: false,
    slotTime: "",
    reasonList: [],
  });
  let footerRef = useRef(null);
  const [additionalSlotsBtn, setAdditionalSlotsBtn] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const [oldResponseLength, setOldResponseLength] = useState(0);
  const [sameResponse, setSameResponse] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [spinnerValue, setSpinnerValue] = useState(0);
  const [firstEnabledDate] = useState(handleStartDate()); // useState(moment().add(2, "days").format("MM/DD/YYYY"));
  const [lastEnabledDate, setlastEnabledDate] = useState(handleStartDate(false).add(59, "days").format("MM/DD/YYYY"));
  const [datesNeeded, setdatesNeeded] = useState();
  const [startDateChangeModal, setStartDateChangeModal] = useState(null);
  const [confirmedStartDate, setConfirmedStartDate] = useState(true);
  let spinnerPercentage;

  useEffect(() => {
    if (confirmedStartDate) {
      getJobTransactionId();
    }
    return () => setConfirmedStartDate(false);
  }, [requestedDate, confirmedStartDate]);

  useEffect(() => {
    if (slotDetails?.slot) {
      document.getElementById("Scheduler").addEventListener("click", handleClickOutside, false);
    }
    return () => document.getElementById("Scheduler")?.removeEventListener("click", handleClickOutside, false);
  }, [slotDetails]);

  const handleClickOutside = (event) => {
    if (event.target.id !== "SlotButton" && !footerRef.current.contains(event.target)) {
      setSlotDetails({});
      setSubmitDisability(true);
    }
  };

  const notify = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  const sortedMiles = (groupedByMiles) => {
    let sorted = [];
    Object.keys(groupedByMiles)
      .sort((a, b) => Number(a) - Number(b))
      .reduce((idx, mile) => {
        let oneMile = {};
        if (mile) {
          oneMile[mile] = groupedByMiles[mile];
          sorted.push(oneMile);
        }
      }, {});
    return sorted;
  };

  const handlePercentCalculation = (isMore = false) => {
    let seconds = 0;
    let totalSeconds = isMore ? 11 : 15;
    spinnerPercentage = setInterval(() => {
      seconds += 1;
      let percent = Math.round((seconds * 100) / totalSeconds);
      percent > 98 ? setSpinnerValue(99) : setSpinnerValue(percent);
    }, 1000);
  };

  const clearSpinner = () => {
    setSpinner(false);
    clearInterval(spinnerPercentage);
    setSpinnerValue(0);
  };

  const getJobTransactionId = async () => {
    updateNewrelicStats();
    let name, fName, lName;
    name = jobData.contactName.split(" ");
    fName = name.length > 1 ? name.slice(0, name.length - 1).join(" ") : name[0];
    lName = name.length > 1 ? name[name.length - 1] : "";
    setFirstName(fName);
    setLastName(lName);
    let address2 = jobData.address2 ? jobData.address2 + ", " : "";
    setAddress(jobData.address1 + ", " + address2 + jobData.city + ", " + jobData.stateCode + ", " + jobData.zipCode);
    let data = {
      Header: {
        requestControlId: "vzc_" + Date.now(),
      },
      Body: {
        header: {
          clientAppName: "VZC",
          clientAppUserName: "ECOM",
        },
        vendorId: expandJobVendorID ? expandJobVendorID : reschedule ? vendorId : null,
        addressLine1: jobData.address1,
        addressLine2: jobData.address2,
        city: jobData.city,
        state: jobData.stateCode,
        zipCode: jobData.zipCode,
        serviceRequestType: jobType,
        startingDate: moment(requestedDate).format("YYYYMMDD"),
        customerType: accountDetails.vztSegment,
        firstName: fName,
        lastName: lName,
        accountName: accountDetails.customerName,
        jobDuration: apptLength * 60,
        serviceJobId: reschedule ? jobData.serviceJobId : "",
      },
    };
    handlePercentCalculation(false);
    setSpinner(true);
    let response = await getSchedule(jobId, data);
    if (response.status === 200 && response?.data?.data.transactionId !== "") {
      getTimeSlots(response.data.data.transactionId, false);
      setTransactionId(response.data.data.transactionId);
    } else {
      clearSpinner();
      setSpotEnabledDates([]);
      setdatesNeeded("");
      notify(
        <>
          <span>No availability at this time. Please follow the fall back process by clicking </span>{" "}
          <TextLink
            href={kbArticleLink}
            size="small"
            type="inline"
            tabIndex="0"
            role="link"
            ariaLabel="kbArticleLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </TextLink>
        </>,
        "error"
      );
    }
  };

  const getTimeSlots = async (transactionId, isMore = false) => {
    isMore && handlePercentCalculation(true);
    isMore && setSpinner(true);
    let response = await getSlots(transactionId, isMore);
    if (response.status === 200 && response?.data?.data.length) {
      let availableDateInfoResponse = [];
      response.data.data.map((timeSlots) => {
        let allSlots = timeSlots.availabilityDates.availableDateInfo;
        allSlots.map((slots) => {
          slots["vendorId"] = timeSlots.vendorId;
        });
        availableDateInfoResponse.push(...allSlots);
      });
      let responseLength = availableDateInfoResponse.length;
      !isMore &&
        setTimeout(
          () => {
            setAdditionalSlotsBtn(true);
          },
          responseLength ? 5000 : 0
        );
      isMore && responseLength > 0 ? responseLength === oldResponseLength && setSameResponse(true) : setOldResponseLength(responseLength);
      let datesNeeded = maxBy(response.data.data, "datesNeeded");
      datesNeeded = datesNeeded?.datesNeeded ? datesNeeded.datesNeeded : "10";
      setdatesNeeded(datesNeeded);
      let availableDates = availableDateInfoResponse.map((date) => {
        if (
          moment(date.availableDate).format("MM/DD/YYYY") >= requestedDate &&
          moment(date.availableDate).format("MM/DD/YYYY") < moment(requestedDate).add(datesNeeded, "days").format("MM/DD/YYYY")
        ) {
          return moment(date.availableDate).format("MM-DD-YYYY");
        }
      });
      setSpotEnabledDates(availableDates);
      let availability = availableDateInfoResponse.filter((response) => {
        return availableDates.includes(moment(response.availableDate).format("MM-DD-YYYY"));
      });
      availability = groupBy(availability, "availableDate");
      setavailableDateInfo(availability);
      let groupedByMiles = groupBy(availability[moment(selectedDate).format("YYYYMMDD")], "totalMiles");
      setAvailabilityDates(sortedMiles(groupedByMiles));
    } else {
      !isMore && setAdditionalSlotsBtn(true);
      setSpotEnabledDates([]);
      setdatesNeeded("");
      isMore &&
        notify(
          <>
            <span>No availability at this time. Please follow the fall back process by clicking </span>{" "}
            <TextLink
              href={kbArticleLink}
              size="small"
              type="inline"
              tabIndex="0"
              role="link"
              ariaLabel="kbArticleLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </TextLink>
          </>,
          "error"
        );
    }
    clearSpinner();
  };

  const handleAvailableDates = ({ date, view }) => {
    if (view === "month") {
      let currentDate = moment(date).format("MM-DD-YYYY");
      let startDate = moment(requestedDate).format("MM-DD-YYYY");
      let datesNeededFromRequestedDate = moment(requestedDate).add(datesNeeded, "days").format("MM-DD-YYYY");

      if (currentDate >= startDate && currentDate < datesNeededFromRequestedDate && !spotenabledDates.includes(currentDate)) {
        return date;
      }
    }
  };

  const spotAvailableDates = ({ date, view }) => {
    if (view === "month") {
      let currentDate = moment(date).format("MM-DD-YYYY");
      let today = moment(new Date()).format("MM-DD-YYYY");
      //console.log(currentDate, today, moment(date.toDateString()).isAfter(new Date().toDateString()), moment(date.toDateString()).isSame(new Date().toDateString()), "date compare")
      if (Date.parse(currentDate) >= Date.parse(today) && spotenabledDates.includes(moment(date).format("MM-DD-YYYY"))) {
        return "text";
      }
    }
  };

  const handleDate = (e) => {
    let currentDate = moment(e).format("MM/DD/YYYY");
    let datesNeededFromRequestedDate = moment(requestedDate).add(datesNeeded, "days").format("MM/DD/YYYY");
    setSelectedDate(e);
    settotalMiles([]);
    setSlotDetails({});
    setSelectedSlot({});
    if (currentDate < requestedDate || currentDate >= datesNeededFromRequestedDate) {
      setAdditionalSlotsBtn(false);
      setSameResponse(false);
      setStartDateChangeModal(
        <StartDateChangeModal
          setConfirmModal={setStartDateChangeModal}
          date={e}
          setRequestedDate={setRequestedDate}
          setConfirmedStartDate={setConfirmedStartDate}
        />
      );
    } else {
      setAdditionalSlotsBtn(true);
    }
    let availability = sortedMiles(groupBy(availableDateInfo[moment(e).format("YYYYMMDD")], "totalMiles"));
    setViewMore(false);
    setViewCount(3);
    setAvailabilityDates(availability);
    setSubmitDisability(true);
  };

  const handleSlot = (selectedTime, index) => {
    setVendorId(selectedTime.vendorId);
    setSelectedSlot(selectedTime);
    settotalMiles({ totalMiles: selectedTime.totalMiles, vendor: selectedTime.serviceProviderName });
    setSubmitDisability(false);
    let slotTime = moment(`${selectedTime.availableDate} ${selectedTime.slotStartTime}`).format("dddd, MMMM DD, YYYY h:mm A");
    let durationUnit = durationUnitCalculation(selectedTime);
    setSlotDetails({
      index: index,
      slot: true,
      vendor: selectedTime.serviceProviderName,
      slotTime: `${slotTime} - ${moment(slotTime).add(durationUnit, selectedTime.slotLength).format("h:mm A")}`,
    });
    if (reschedule) {
      setRescheduleReasonModalStatus({
        ...rescheduleReasonModalStatus,
        slotTime: moment(`${selectedTime.availableDate} ${selectedTime.slotStartTime}`).format("dddd, MMMM DD, YYYY [at] h:mm A"),
        timezone: timezone,
      });
    }
  };

  const handleJobSchedule = async (notes) => {
    updateNewrelicStats();
    let data = {
      Header: {
        requestControlId: "vzc_" + Date.now(),
      },
      Body: {
        header: {
          clientAppName: "VZC",
          clientAppUserName: "ECOM",
        },
        ignoreCache: false,
        vendorId: vendorId,
        startDate: requestedDate,
        orderNum: jobId,
        serviceJobType: jobType,
        apptInfo: {
          accountName: accountDetails.customerName,
          homePhone: jobData.phone.replace(/\D/g, ""),
          workPhone: "",
          mobilePhone: "",
          primaryPhoneType: "Home",
          emailAddress: jobData.email,
          firstName: firstName,
          lastName: lastName,
          SpecialInstructions: notes,
          installApptSelectedDate: selectedSlot,
        },
        serviceAddress: {
          lastName: lastName,
          firstName: firstName,
          address1: jobData.address1,
          address2: jobData.address2,
          city: jobData.city,
          state: jobData.stateCode,
          postalCode: jobData.zipCode,
          country: "US",
        },
        vehicleInformation: [
          {
            vin: "VIN1243SFDGAGFG1234",
            type: "Truck",
            make: "",
            model: "",
            inventory: [
              {
                name: "VTU1",
                count: "1",
                action: "REMOVE",
              },
              {
                name: "VTU2",
                count: "1",
                action: "INSTALL",
              },
              {
                name: "CAMERA",
                count: "1",
                action: "INSTALL-",
              },
            ],
          },
        ],
        vehicleCount: vehicleCount,
        requestControlID: "",
        languagePref: "E",
        mdnPromo: "Y",
        serviceCost: "60.0",
        eventCorrelationId: "",
        launchType: "FCL",
        addressType: "SFU",
        CustomerType: "PE",
        workTicketNumber: workTicketNumber,
      },
    };

    setIsLoading(true);
    let response = await createSchedule(jobId, data);
    if (response.status === 200 && response?.data?.data?.respMsg.toLowerCase() === "success") {
      sessionData(jobId);
      settotalMiles([]);
      setSlotDetails({});
      setSelectedSlot({});
      setSubmitDisability(true);
      notify("Job scheduled successfully", "success");
      setTimeout(() => {
        setNotification(null);
        setIsLoading(false);
        sessionStorage.setItem("LastScheduledJobId", JSON.stringify(jobId));
        resetTab();
      }, 5000);
    } else {
      // setSlotDetails({});
      setIsLoading(false);
      notify("Unable to schedule job due to an unexpected error.", "error");
    }
  };

  const handleReschedule = async (selectedReason) => {
    updateNewrelicStats();
    setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false });
    let data = {
      Header: {
        transactionId: "VZC_" + Date.now(),
        client_name: "VZC",
        isReschedule: true,
      },
      Body: {
        header: {
          clientAppName: "VZC",
          clientAppUserName: "",
          clientAppUserFirstName: firstName,
          clientAppUserLastName: lastName,
          clientAppUserEmail: jobData.email,
          clientAppUserRole: "REP",
          isReschedule: true,
        },
        vendorId: vendorId,
        startDate: moment(requestedDate).format("MM/DD/YYYY"),
        zipcode: jobData.zipCode,
        orderNum: jobId,
        serviceJobId: serviceJobId,
        rescheduleReasonCode: selectedReason.code,
        comments: "",
        rescheduleReason: selectedReason.description,
        serviceJobType: jobType,
        updateBy: "",
        signature: "",
        vehicleCount: vehicleCount,
        createdBy: accountDetails.sfdcUsername,
        apptInfo: {
          installApptSelectedDate: selectedSlot,
        },
      },
    };

    setIsLoading(true);
    let response = await rescheduleJob(jobId, data);
    if (response.status === 200) {
      sessionData(jobId);
      notify("Job Rescheduled successfully", "success");
      setAlertModal(false);
      setTimeout(() => {
        setNotification(null);
        setIsLoading(false);
        sessionStorage.setItem("LastScheduledJobId", JSON.stringify(jobId));
        resetTab();
      }, 5000);
    } else {
      // setSlotDetails({})
      setAlertModal(false);
      setIsLoading(false);
      notify("Unable to reschedule job due to an unexpected error", "error");
    }
  };

  const handleConfirmation = (type) => {
    if (type === "reschedule") {
      getRescheduleReasonList();
    } else {
      setConfirmModal(
        <ConfirmationModal
          setConfirmModal={setConfirmModal}
          type={type}
          reschedule={reschedule}
          selectedSlot={selectedSlot}
          timezone={timezone}
          handleSupplyNotes={type === "cancel" ? handleBookingCancellation : handleJobSchedule}
        />
      );
    }
  };

  const getRescheduleReasonList = async () => {
    updateNewrelicStats();
    setIsLoading(true);
    let response = await getReasonlist("VZC_Reschedule");
    if (response.status === 200 && response?.data?.statusCode === "200") {
      setIsLoading(false);
      setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: true, reasonList: response.data.data });
    } else {
      setIsLoading(false);
      notify("Unable to retrieve reschedule reasons.", "error");
    }
  };

  const handleViewMore = (milesLength) => {
    setViewCount(viewCount + 3);
    if (viewCount >= milesLength) {
      setViewMore(true);
    }
  };

  const handleMoreSlots = () => {
    setAdditionalSlotsBtn(false);
    getTimeSlots(transactionId, true);
  };

  const handleBookingCancellation = async (reason) => {
    let data = {
      modifiedBy: accountDetails.sfdcUsername,
      closedReason: reason,
    };
    let response = await cancelBooking(jobId, data);
    setIsLoading(true);
    if (response.status === 200 && response?.data?.statusCode === "200") {
      setIsLoading(false);
      resetTab();
    } else {
      setIsLoading(false);
      notify("Unable to cancel due to an unexpected error.", "error");
    }
  };

  return (
    <>
      {rescheduleReasonModalStatus.isModalOpen && (
        <RescheduleReasonModal
          rescheduleReasonModalStatus={rescheduleReasonModalStatus}
          setRescheduleReasonModalStatus={setRescheduleReasonModalStatus}
          handleReschedule={handleReschedule}
        />
      )}
      {confirmModal}
      {startDateChangeModal}
      <div id="Scheduler">
        <Loader active={isLoading} fullscreen />
        {spinner && (
          <div css={customSpinner}>
            <CircularProgressbar
              value={spinnerValue}
              text={`${spinnerValue}%`}
              styles={{ path: { stroke: spinnerValue > 49 ? `green` : `orange` } }}
            />
          </div>
        )}
        <Header>{reschedule ? `Reschedule Job` : `Schedule Job`}</Header>
        {notification}
        <Spacer top="20px" />
        <FlexRow>
          <SmallText bold>Job Type:&nbsp;</SmallText>
          <SmallText>{jobType}</SmallText>
        </FlexRow>
        <FlexRow>
          <SmallText bold>Requested Date:&nbsp;</SmallText>
          <SmallText>{requestedDate}</SmallText>
        </FlexRow>
        <FlexRow>
          <SmallText bold>Duration:&nbsp;</SmallText>
          <SmallText>{apptLength > 1 ? apptLength + " Hours" : apptLength + " Hour"}</SmallText>
          <SmallText bold>&nbsp;Vehicle Count:&nbsp;</SmallText>
          <SmallText>{vehicleCount}</SmallText>
          {/* <MediumText primitive="span">{apptLength}</MediumText> */}
        </FlexRow>
        <FlexRow>
          <SmallText bold>Work Ticket Number:&nbsp;</SmallText>
          <SmallText>{workTicketNumber}</SmallText>
        </FlexRow>
        <FlexRow>
          <SmallText bold>Job ID:&nbsp;</SmallText>
          <SmallText>{jobId}</SmallText>
        </FlexRow>
        <FlexRow>
          <SmallText bold>Address:&nbsp;</SmallText>
          <SmallText>{address}</SmallText>
        </FlexRow>
        <Spacer top="20px" />
        <MediumText css={{ marginTop: "1rem" }} bold>
          Select Date
        </MediumText>
        <Calendar
          tileDisabled={handleAvailableDates}
          showNeighboringMonth={false}
          formatShortWeekday={(locale, date) => ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]}
          calendarType="US"
          tileClassName={spotAvailableDates}
          onChange={(e) => handleDate(e)}
          value={selectedDate}
          minDate={new Date(firstEnabledDate)}
          maxDate={
            new Date(moment(requestedDate).add(datesNeeded, "days").format("MM/DD/YYYY")) > new Date(lastEnabledDate)
              ? new Date(
                  moment(requestedDate)
                    .add(datesNeeded - 1, "days")
                    .format("MM-DD-YYYY")
                )
              : new Date(lastEnabledDate)
          }
        />
        <Spacer top="20px" />

        <FlexRow>
          <SmallText>For availability dates&nbsp;</SmallText>
          {new Date(requestedDate) > new Date(firstEnabledDate) && (
            <>
              <SmallText bold>before&nbsp;</SmallText>
              <SmallText css={{ textDecoration: "underline" }}>{moment(new Date(requestedDate)).format("MMMM Do")}</SmallText>
            </>
          )}

          {new Date(requestedDate) < new Date(lastEnabledDate) &&
            new Date(moment(new Date(requestedDate)).add(datesNeeded, "days").format("MM/DD/YYYY")) <= new Date(lastEnabledDate) && (
              <>
                {new Date(requestedDate) > new Date(firstEnabledDate) && <SmallText>&nbsp;or&nbsp;</SmallText>}
                <SmallText bold>after&nbsp;</SmallText>
                <SmallText css={{ textDecoration: "underline" }}>
                  {datesNeeded
                    ? moment(requestedDate)
                        .add(datesNeeded - 1, "days")
                        .format("MMMM Do")
                    : moment(requestedDate).format("MMMM Do")}
                </SmallText>
              </>
            )}
          <SmallText>,&nbsp;please click on any of the dates in bold.</SmallText>
        </FlexRow>
        <MediumText css={{ marginTop: "1rem" }} bold>
          <SmallText>Availability Shown {datesNeeded ? datesNeeded + " days" : "1 day"} from Requested Date</SmallText>
          <Spacer top="20px" />
          {!reschedule && `Choose a Time`}
        </MediumText>
        <SmallText>Times are displayed in your customer&apos;s time zone.</SmallText>
        <Spacer top="20px" />
        <div style={reschedule ? { marginBottom: slotDetails.slot ? "265.26px" : "91px" } : { marginBottom: slotDetails.slot ? "178.65px" : "91px" }}>
          <>
            {availableDateInfo[moment(selectedDate).format("YYYYMMDD")] ? (
              <>
                {availabilityDates.map((miles, index) => {
                  let mile = Object.keys(miles)[0];
                  let morning = [];
                  let afternoon = [];
                  let availableSlots = viewMore ? availabilityDates : availabilityDates.slice(0, viewCount);
                  availableSlots.includes(miles) &&
                    Object.values(miles).map((slots) => {
                      slots.map((slot) => {
                        slot.slotStartTime < "1200" ? morning.push(slot) : afternoon.push(slot);
                      });
                    });
                  return (
                    <React.Fragment key={mile}>
                      {availableSlots.includes(miles) && (
                        <FlexColumn>
                          <FlexRow style={{ backgroundColor: "lightgray", padding: "4px 5px 0px 5px", width: "fit-content", marginBottom: "5px" }}>
                            <UltraMediumText marginBottom="5px">{mile} miles return</UltraMediumText>
                          </FlexRow>
                          <SlotsContainer style={{ marginBottom: "1rem" }}>
                            {morning.length > 0 && (
                              <>
                                <p css={{ color: "#747676", fontSize: "11px", fontWeight: "600", margin: "0" }}>Morning</p>
                                {sortBy(morning, "slotStartTime").map((availability, indexValue) => {
                                  return (
                                    <div key={indexValue}>
                                      <SlotButtons
                                        indexValue={indexValue}
                                        slotDetails={slotDetails}
                                        reschedule={reschedule}
                                        availability={availability}
                                        jobData={jobData}
                                        handleSlot={handleSlot}
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            )}
                            <div style={{ clear: "both" }}></div>
                            {afternoon.length > 0 && (
                              <>
                                <p css={{ color: "#747676", fontSize: "11px", fontWeight: "600", margin: "0" }}>Afternoon</p>
                                {sortBy(afternoon, "slotStartTime").map((availability, indexValue) => {
                                  return (
                                    <div key={indexValue}>
                                      <SlotButtons
                                        indexValue={indexValue}
                                        slotDetails={slotDetails}
                                        reschedule={reschedule}
                                        availability={availability}
                                        jobData={jobData}
                                        handleSlot={handleSlot}
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </SlotsContainer>
                          {index !== availabilityDates.length - 1 && <Line type="xLight" inverted={false} />}
                          {index !== availabilityDates.length - 1 && <Spacer top="20px"></Spacer>}
                        </FlexColumn>
                      )}
                    </React.Fragment>
                  );
                })}
                {viewCount < availabilityDates.length && (
                  <SmallText
                    css={{ textDecoration: "underline", cursor: "pointer", marginBottom: "10px" }}
                    onClick={() => handleViewMore(availabilityDates.length)}
                  >
                    View more times
                  </SmallText>
                )}
              </>
            ) : (
              <>
                <UltraMediumText>No times available for the appointment length</UltraMediumText>
                <SmallText>Try selecting a different date.</SmallText>
                {!spotenabledDates.length && additionalSlotsBtn && (
                  <SLButton primary size="large" onClick={handleMoreSlots}>
                    Try Again
                  </SLButton>
                )}
              </>
            )}
            {spotenabledDates.length > 0 && additionalSlotsBtn && (
              <FlexRow>
                <MediumText style={{ padding: "1rem 1rem 0 0" }}>Do you want to see additional slots ?</MediumText>
                <SLButton primary size="large" onClick={handleMoreSlots}>
                  Yes
                </SLButton>
                <SLButton secondary size="large" onClick={() => setAdditionalSlotsBtn(false)}>
                  No
                </SLButton>
              </FlexRow>
            )}
            {sameResponse && (
              <MediumText bold size="large">
                Sorry, there are no additional slots available at this moment.
              </MediumText>
            )}
          </>
        </div>
        <div ref={footerRef}>
          <Line type="xLight" inverted={true} />
          <Footer>
            {slotDetails.slot && (
              <>
                <MediumText css={{ marginTop: "1rem" }} bold>
                  Confirm Details
                </MediumText>
                <UltraMediumText marginBottom="0px" css={{ marginTop: "1rem", marginBottom: "5px" }}>
                  {totalMiles.totalMiles} miles return
                </UltraMediumText>
                <UltraMediumText fontWeight="normal">
                  {slotDetails.slotTime} {timezone}
                </UltraMediumText>
              </>
            )}
            <FlexRow css={{ marginTop: "1rem" }}>
              <SLButton primary disabled={submitDisability} onClick={() => handleConfirmation(reschedule ? `reschedule` : `schedule`)}>
                {reschedule ? `Reschedule` : `Schedule`}
              </SLButton>
              <SLButton secondary onClick={() => handleConfirmation("cancel")}>
                Cancel
              </SLButton>
            </FlexRow>
          </Footer>
        </div>
      </div>
    </>
  );
}
export const ScheduleJobMetadata = {
  name: "Schedule Job",
  id: "schedule_job",
  component: lazy(() => import("./ScheduleJob")),
  route: "/schedule_job",
};
export const RescheduleJobMetadata = {
  name: "Reschedule Job",
  id: "Reschedule_job",
  component: lazy(() => import("./ScheduleJob")),
  route: "/schedule_job",
};
