import { putRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const deleteWorkTicket = async (data) => {
  try {
    const { ticketID, ...payload } = data;
    return await putRequest(`${urls.DELETE_WORK_TICKET}/${ticketID}`, payload);
  } catch (error) {
    return error;
  }
};
