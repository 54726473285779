import * as urls from "../../api/C4S Decommission - Replacement/url";
import { postRequest } from "../../../services/RestServices";

export const deleteReturnItem = async (dataParam, payload) => {
  try {
    return await postRequest(`${urls.DELETE_RETURN_ITEMS}/${dataParam}`, payload);
  } catch (error) {
    return error;
  }
};
