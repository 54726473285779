import { Modal, ModalBody } from "@vds/modals";
import React, { useEffect, useState } from "react";
import { Title } from "@vds/typography";
import { CustomSLButton, FlexColumn, FlexRow, Spacer, SmallText } from "../../components/core/utility";
import Icon from "@vds/icons";
import { CustomInput } from "./AddAVehicle";
import _ from "lodash";
import { css } from "@emotion/react";
import { QUANTITY_REGEX } from "../../constants/ValidationConstants";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;
export const AddMoreVehiclesModal = ({ addMoreVehiclesModal, setAddMoreVehiclesModal, handleAddMoreVehicles }) => {
  const [quantity, setQuantity] = useState({ value: "", error: false, errorText: "Enter required quantity", success: false });
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setAddMoreVehiclesModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    quantity.success ? setIsAddBtnDisabled(false) : setIsAddBtnDisabled(true);
  }, [quantity.success]);

  const handleAdd = async () => {
    setAddMoreVehiclesModal(false);
    for (let i = 0; i < quantity.value; i++) {
      handleAddMoreVehicles();
    }
  };
  const handleFields = (value) => {
    if (value && value.length) {
      if (QUANTITY_REGEX.test(value)) {
        setQuantity({ value, error: false, errorText: "", success: true });
      } else {
        setQuantity({ value, error: true, errorText: "Enter required quantity", success: false });
      }
    } else {
      setQuantity({ value, error: true, errorText: "Enter required quantity", success: false });
    }
  };

  return (
    <>
      <Spacer top="1rem" />
      <Modal
        role="dialog"
        ariaLabel="Add Vehicle"
        hideCloseButton={false}
        opened={addMoreVehiclesModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div css={{ paddingLeft: "1.5rem" }}>
              <Title size="medium">Add Vehicle</Title>
            </div>
            <div onClick={() => setAddMoreVehiclesModal(false)} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ padding: "16px 0 16px 16px !important" }}>
          <Spacer top="1rem" />
          <SmallText>How many vehicles would you like to add?</SmallText>
          <Spacer top="1rem" />
          <FlexColumn>
            <CustomInput
              formWidth="60%"
              type="number"
              label="Quantity"
              aria-label="Quantity"
              value={quantity.value}
              error={quantity.error}
              errorText={quantity.errorText}
              success={quantity.success}
              onChange={(e) => handleFields(e.target.value)}
              paddingRight="5px"
            />
          </FlexColumn>
          <Spacer top="1rem" />
          <FlexRow>
            <CustomSLButton
              css={{ padding: "0 10px 0 10px" }}
              primary
              aria-label="Add"
              role="button"
              disabled={isAddBtnDisabled}
              aria-disabled={isAddBtnDisabled}
              onClick={() => {
                handleAdd();
              }}
            >
              Add
            </CustomSLButton>
            <CustomSLButton aria-label="Cancel" role="button" secondary onClick={() => setAddMoreVehiclesModal(false)}>
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalBody>
      </Modal>
    </>
  );
};
