let cachedToken = null;
/**
 * Gets the current access token.
 * It checks the session storage for the 'okta-token-storage' item and extracts the access token.
 * @returns {string|null} The access token if available, otherwise null.
 */
export const getToken = () => {
  if (cachedToken === null) {
    const storedData = sessionStorage.getItem("okta-token-storage");
    if (storedData) {
      try {
        const tokenData = JSON.parse(storedData);
        cachedToken = tokenData?.accessToken?.accessToken || null;
      } catch (error) {
        cachedToken = null;
      }
    }
  }
  return cachedToken;
};

/**
 * Clears the cached token.
 */
export const updateToken = () => {
  cachedToken = null;
};
