import React, { lazy, useContext, useEffect, useState } from "react";
import { RETURN_INDIVIDUAL_HARDWARE_COL } from "../../constants/AddHardwareConstants";
import { Header } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { CustomSLButton, FlexRow, SmallText, Spacer, UltraMediumText } from "../../components/core/utility";
import { ValidateAddress } from "./ValidateAddress";
import { Icon } from "@vds/icons";
import edit from "../../assets/icons/edit.png";
import { C4SContext } from "../../context/c4sContext";
import { CustomDropdown, CustomInput } from "./AddAVehicle";
import _ from "lodash";
import { Loader } from "@vds/loaders";
import { redirectToPrevPage, switchTab } from "./utils";
import { DISTRIBUTION_CHANNEL_DROPDOWN_VALUES, DIVISION_DROPDOWN_VALUES } from "../../constants/C4SDecomConstants";
import { TextArea } from "@vds/inputs";
import styled from "styled-components";
import { EMAIL_REGEX_C360, NAME_REGEX } from "../../constants/C4SValidationConstants";
import { IndividualReturnHardwareModal } from "./IndividualReturnHardwareModal";
import DeleteVehicleMMIDModal from "./DeleteVehicleMMIDModal";
import moment from "moment";
import { multiSalesOrgBillingCountries, salesOrgList } from "./AddHardware";
import { individualReturn } from "../../services/C4S Decommission - Replacement/individualReturn";
import { Notification } from "@vds/notifications";
import { generate } from "shortid";
import useOutsideClick from "../../customHook/use-outside-click";

const TextAreaContainer = styled.div`
  width: 90%;
  padding-right: 5px;
  & div:nth-child(2n) {
    max-width: 100vw !important;
  }
`;

export default function IndividualReturn({ props }) {
  const cspSessionData = JSON.parse(sessionStorage.getItem("CSPDetails"));
  const { isSourceCSP, cspDetails, workTicketInfo } = useContext(C4SContext);
  const [isSaveButtonsDisabled, setIsSaveButtonsDisabled] = useState(true);
  const [returnHardwareMMIDTableList, setReturnHardwareMMIDTableList] = useState([]);
  const [editReturnHardwareMMID, setEditReturnHardwareMMID] = useState({});
  const [deleteReturnHardwareModal, setDeleteReturnHardwareModal] = useState(false);
  const [deleteReturnHardwareMMID, setDeleteReturnHardwareMMID] = useState({});
  const [address, setAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    timezone: "",
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    country: { value: null, options: [], error: false, errorText: "Please select country", success: false },
    state: { value: null, options: [], allStateOptions: null, error: false, errorText: "Please select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
  });
  const [isLoading, setLoader] = useState(false);
  const [addReturnHardwareModal, setAddReturnHardwareModal] = useState(false);
  const [salesOrg, setSalesOrg] = useState({ value: "", error: false, errorText: "Please validate the Sales Org.", success: false });
  const [billingCountry, setBillingCountry] = useState("");
  const [salesOrgDropDown, setSalesOrgDropDown] = useState([]);
  const [typeMMIDList, setTypeMMIDList] = useState(new Set());
  const [uniqueRecordMapping, setUniqueRecordMapping] = useState(new Map());
  const [division, setDivision] = useState({
    value: workTicketInfo.workTicketInfo.division,
    error: false,
    errorText: "Please validate the Division.",
    success: false,
  });
  const [distributionchannel, setDistributionchannel] = useState({
    value: workTicketInfo.workTicketInfo.distributionChannel,
    error: false,
    errorText: "Please validate the Distribution Channel.",
    success: false,
  });
  const [notesToWareHouse, setNotesToWareHouse] = useState("");
  const crmReferenceId = cspDetails?.cspDetails?.cspData?.data?.data?.CASEDetails.length
    ? cspDetails?.cspDetails?.cspData?.data?.data?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseNumber
    : "" || cspDetails?.cspDetails?.cspData?.data?.data?.CSP.length
    ? cspDetails?.cspDetails?.cspData?.data?.data?.CSP.filter((csp) => csp.DefaultCSP === "1")[0].CRM_Ref_ID
    : "";
  const [contactName, setContactName] = useState({
    value: "",
    error: false,
    errorText: "",
    success: false,
  });
  const [email, setEmail] = useState({
    value: "",
    error: false,
    errorText: "",
    success: false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    error: false,
    errorText: "",
    success: false,
  });
  let contractAccountNumberArr = (cspSessionData?.CONTRACT_ACCOUNT_ID || []).filter((ca) => ca !== null && ca !== undefined && ca !== "");
  const [contractAccount, setContractAccount] = useState({
    value: contractAccountNumberArr && contractAccountNumberArr.length === 1 ? contractAccountNumberArr[0] : "",
    error: false,
    errorText: "",
    success: contractAccountNumberArr && contractAccountNumberArr.length === 1 ? true : false,
  });

  const [selectedContractAccountNumber, setselectedContractAccountNumber] = useState({
    value: "",
    error: false,
    errorText: "",
    success: false,
  });

  const [notification, setNotification] = useState();
  useEffect(() => {
    if (isSourceCSP.isSourceCSP) {
      let salesOrgValue = cspDetails.cspDetails?.salesOrgName ? `(${cspDetails.cspDetails?.salesOrgName || ""})` : "";
      salesOrgValue = `${cspDetails.cspDetails?.salesOrgId || ""} ${salesOrgValue}`;
      setSalesOrg((prevState) => ({ ...prevState, value: salesOrgValue, error: false, success: true }));
    } else {
      let billingCountry = cspDetails.cspDetails?.cspData?.data?.data?.BillingCountry;
      setBillingCountry(billingCountry);
      let salesOrgDropDown = salesOrgList[billingCountry]?.map((item) => item.salesOrgId.replace("SO_", "") + " " + "(" + item.salesOrgName + ")");
      setSalesOrgDropDown(salesOrgDropDown);
      let salesOrgValue = cspDetails.cspDetails?.cspData?.data?.data?.CASEDetails[0]?.salesOrgName
        ? `(${cspDetails.cspDetails?.cspData?.data?.data?.CASEDetails[0]?.salesOrgName || ""})`
        : "";
      salesOrgValue = `${cspDetails.cspDetails?.cspData?.data?.data?.CASEDetails[0]?.salesOrg || ""} ${salesOrgValue}`;
      setSalesOrg((prevState) => ({ ...prevState, value: salesOrgValue, error: false, success: true }));
    }
  }, []);
  const handleClickOutside = () => {
    setNotification(null);
  };
  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    if (
      address.verified &&
      contactName.success &&
      phoneNumber.success &&
      contractAccount.success &&
      email.success &&
      returnHardwareMMIDTableList.length > 0 &&
      salesOrg.success &&
      crmReferenceId
    ) {
      setIsSaveButtonsDisabled(false);
    } else {
      setIsSaveButtonsDisabled(true);
    }
  }, [
    address.verified,
    contactName.success,
    phoneNumber.success,
    email.success,
    contractAccount.success,
    returnHardwareMMIDTableList.length,
    salesOrg.success,
    crmReferenceId,
  ]);

  const handleFields = (field, value) => {
    switch (field) {
      case "name": {
        if (value && value.length) {
          if (NAME_REGEX.test(value)) {
            setContactName({ value, error: false, errorText: "Enter name", success: true });
          } else {
            setContactName({ value, error: true, errorText: "Enter a valid name", success: false });
          }
        } else {
          setContactName({ value: "", error: true, errorText: "Enter name", success: false });
        }
        break;
      }
      case "email": {
        if (value && value.length) {
          if (EMAIL_REGEX_C360.test(value)) {
            setEmail({ value, error: false, errorText: "Enter email", success: true });
          } else {
            setEmail({ value, error: true, errorText: "Enter a valid email", success: false });
          }
        } else {
          setEmail({ value: "", error: true, errorText: "Enter email", success: false });
        }
        break;
      }
      case "phone": {
        let phoneRegex = /^([^a-zA-Z])+$/;
        if (value && value.length) {
          if (phoneRegex.test(value)) {
            setPhoneNumber({ value, error: false, errorText: "Enter phone number", success: true });
          } else {
            setPhoneNumber({ value, error: true, errorText: "Enter a valid phone number", success: false });
          }
        } else {
          setPhoneNumber({ value, error: true, errorText: "Enter phone number", success: false });
        }
        break;
      }
      case "division": {
        value && value.length
          ? setDivision({ value, error: false, errorText: "Select division", success: true })
          : setDivision({ value: "", error: true, errorText: "Select division", success: false });
        break;
      }
      case "distributionchannel": {
        value && value.length
          ? setDistributionchannel({ value, error: false, errorText: "Select distribution channel", success: true })
          : setDistributionchannel({ value: "", error: true, errorText: "Select distribution channel", success: false });
        break;
      }
      case "contactAccount": {
        let phoneRegex = /^([^a-zA-Z])+$/;
        if (value && value.length) {
          if (phoneRegex.test(value)) {
            setContractAccount({ value, error: false, errorText: "Enter contract account", success: true });
            setselectedContractAccountNumber({ value, error: false, errorText: "Enter contract account", success: true });
          } else {
            setContractAccount({ value, error: true, errorText: "Enter a valid contract account", success: false });
            setselectedContractAccountNumber({ value, error: true, errorText: "Enter a valid contract account", success: false });
          }
        } else {
          setContractAccount({ value, error: true, errorText: "Enter contract account", success: false });
          setselectedContractAccountNumber({ value, error: true, errorText: "Enter contract account", success: false });
        }
        break;
      }
    }
  };

  const handleSalesOrg = (e) => {
    setSalesOrg((prevState) => ({ ...prevState, value: e.target.value, error: false, success: true }));
  };

  const showActionIcon = (row) => (
    <FlexRow>
      <div onClick={() => handleRecordAction("edit", row)}>
        <img
          style={{ width: "15px", cursor: "pointer", marginRight: "20px" }}
          alt="edit-icon"
          aria-label="Edit Icon"
          src={edit}
          aria-controls="overlay_panel"
        />
      </div>
      <div onClick={() => handleRecordAction("delete", row)}>
        <Icon
          className="trash-icon"
          aria-label="Delete"
          alt="delete-icon"
          name="trash"
          size="medium"
          color="#000000"
          style={{ cursor: "pointer", outline: "none" }}
        />
      </div>
    </FlexRow>
  );

  const handleRecordAction = (action, row) => {
    if (action === "edit") {
      setEditReturnHardwareMMID(row);
      setAddReturnHardwareModal(true);
    } else if (action === "delete") {
      setDeleteReturnHardwareMMID(row);
      setDeleteReturnHardwareModal(true);
    }
  };

  const handleReturnHardwareDeleteMMID = (rowData) => {
    let tableData = _.cloneDeep(returnHardwareMMIDTableList).filter((item) => item.id !== rowData.id);
    let newSet = new Set();
    let newMap = new Map();
    tableData.map((record) => {
      newSet.add(`${record.type} ${record.materialId}`);
      newMap.set(record.id, `${record.type} ${record.materialId}`);
    });
    setTypeMMIDList(newSet);
    setUniqueRecordMapping(newMap);
    setReturnHardwareMMIDTableList(tableData);
  };
  let emptyMaterialLineId = [],
    emptyMaterialId = [],
    emptyProcessingCode = [],
    emptyUnitMeasure = [],
    emptyMaterialName = [];

  const captureMaterialId = (materialName, materialId) => {
    switch (materialName) {
      case "materialLineId": {
        emptyMaterialLineId.push(materialId);
        break;
      }
      case "materialId": {
        emptyMaterialId.push(materialId);
        break;
      }
      case "materialName": {
        emptyMaterialName.push(materialId);
        break;
      }
      case "unitMeasure": {
        emptyUnitMeasure.push(materialId);
        break;
      }
      case "processingCode": {
        emptyProcessingCode.push(materialId);
        break;
      }
    }
  };

  const notify = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const handleSubmitReturn = async () => {
    let materialLineIDMapping = new Map();
    let itemNo = 0;
    let materials = returnHardwareMMIDTableList.map((returnItem) => {
      let key = `${returnItem.type} ${returnItem.materialId}`;
      let materialLineId = "";
      if (materialLineIDMapping.has(key)) {
        materialLineId = materialLineIDMapping.get(key);
      } else {
        itemNo += 10;
        let formattedItemNo = String(itemNo)?.padStart(6, "0");
        materialLineIDMapping.set(key, formattedItemNo);
        materialLineId = formattedItemNo;
      }
      return {
        id: returnItem?.mId,
        materialId: returnItem?.materialId,
        materialName: returnItem?.itemDescription,
        summaryQty: returnItem?.quantity,
        materialLineId: materialLineId,
        unitMeasure: "EA",
        techRefId: "",
        processingCode: returnItem?.type,
        installedQuantity: "",
        chargePrice: "",
        family: "",
        customerCharge: "",
        serviceProcessType: "",
        invoiceRelevant: "",
        endTime: "",
        customerPONumber: cspSessionData?.CSP_CUstomer_PO_Number,
        customerPONumberKut: cspSessionData?.CSP_CUstomer_PO_Number,
        baseItemId: "",
        esnToBeReturned: returnItem?.esn,
        returnReason: returnItem?.returnReason,
        sapId: returnItem?.sapId ? returnItem?.sapId : null,
        contractId: returnItem?.contractId ? returnItem?.contractId : null,
        contractName: returnItem?.contractName?.length > 0 ? returnItem?.contractName : null,
      };
    });
    let data = {
      contactName: contactName.value,
      emailAddress: email.value,
      phoneNumber: phoneNumber.value,
      crmReferenceId: crmReferenceId,
      assignTo: cspSessionData?.Employee_ID,
      documentType: "ZSRQ",
      erpTicketType: "ZSRQ",
      documentDate: moment(new Date()).format("YYYYMMDD"),
      erpVendorId: "",
      salesRepId: cspSessionData?.Employee_ID,
      notesToTechnician: "",
      subStatus: "",
      oldDeliveryNo: "",
      requestDate: moment(new Date()).format("YYYYMMDD"),
      address1: address.address1.value,
      address2: address.address2.value ?? null,
      city: address.city.value,
      country: address.country.value.code,
      stateCode: address.state.value.erpStateCode,
      zipCode: address.zip.value,
      timezone: address.timezone,
      addressByPass: address.addressByPass,
      locationFound: address.locationFound,
      caseId: cspSessionData.CASEDetails.length ? cspSessionData.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseSFDCID : "",
      caseNumber: crmReferenceId,
      accountId: cspSessionData?.AccountID,
      customerName: cspSessionData?.AccountName,
      customerNumber: cspSessionData?.AccountNo,
      vztSegment: cspSessionData?.VZCSegement,
      createdBy: `${cspSessionData?.firstName} ${cspSessionData?.lastName}`,
      modifiedBy: null,
      contractAccount: contractAccount.value,
      salesOrg: salesOrg.value.split(" ")[0],
      currencyCode: cspSessionData?.currencyCode,
      distributionChannel: distributionchannel.value,
      division: division.value,
      erpBusinessPartnerId: cspSessionData?.ERP_Partner_Number,
      erpProductRecipientPartyId: cspSessionData?.ResellerBusinessPartnerNumber || cspSessionData?.ERP_Partner_Number,
      materials: materials,
      createdByEmail: cspSessionData?.email,
      createdByEid: cspSessionData?.Employee_ID,
      assignedTo:
        cspSessionData?.source === "CSP"
          ? cspSessionData?.CSP.length
            ? cspSessionData?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwner
            : null
          : cspSessionData?.CASEDetails.length
          ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwner
          : null,
      assignedToEmail:
        cspSessionData?.source === "CSP"
          ? cspSessionData?.CSP.length
            ? cspSessionData?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwnerEmail
            : null
          : cspSessionData?.CASEDetails.length
          ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwnerEmail
          : null,
      assignedToEid:
        cspSessionData?.source === "CSP"
          ? cspSessionData?.CSP.length
            ? cspSessionData?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwnerEId
            : null
          : cspSessionData?.CASEDetails.length
          ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwnerEId
          : null,
      partners: [
        {
          party: cspSessionData?.ResellerBusinessPartnerNumber || cspSessionData?.ERP_Partner_Number || "",
          partRoleCode: "AG",
        },
        {
          party: cspSessionData?.ERP_Primary_Contact_NUmber ?? "",
          partRoleCode: "AP",
        },
        {
          party: cspSessionData?.ERP_Partner_Number,
          partRoleCode: "WE",
          accountName: cspSessionData?.AccountName,
          contactName: contactName.value,
          contactPhone: phoneNumber.value,
          contactEmail: email.value,
          street: address.address1.value,
          city: address.city.value,
          state: address.state.value.erpStateCode,
          country: address.country.value.code,
          zip: address.zip.value,
        },
      ],
    };
    let errorMsg = [];
    let missingFieldsArray = [];
    for (const key of Object.keys(data)) {
      if (key === "currencyCode" || key === "distributionChannel" || key === "division" || key === "salesRepId" || key === "assignTo") {
        if (data[key] === "" || data[key] === undefined || data[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      return setTimeout(() => {
        notify(
          "Unable to submit return due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error"
        );
      }, 10);
    } else if (
      cspSessionData?.ResellerBusinessPartnerNumber === null &&
      cspSessionData?.ERP_Primary_Contact_NUmber === null &&
      cspSessionData?.ERP_Partner_Number === null
    ) {
      return setTimeout(() => {
        notify(
          "Unable to submit return due to missing information. Kindly contact IT team for support. Missing information: Reseller Business Partner Number).",
          "error",
          false
        );
      }, 10);
    } else {
      data?.materials?.filter((data) => {
        for (const key of Object.keys(data)) {
          if (key === "materialName" || key === "materialId" || key === "materialLineId" || key === "unitMeasure" || key === "processingCode") {
            if (data[key] === "" || data[key] === undefined || data[key] === null) {
              captureMaterialId(key, data.materialId);
            }
          }
        }
      });
      if (emptyMaterialId.length > 0) errorMsg.push(` ${"Material Id"} is missing for ${emptyMaterialId.toString()}`);
      if (emptyMaterialLineId.length > 0) errorMsg.push(` ${"Material Line Id"} is missing for ${emptyMaterialLineId.toString()}`);
      if (emptyMaterialName.length > 0) errorMsg.push(` ${"Material Name"} is missing for ${emptyMaterialName.toString()}`);
      if (emptyUnitMeasure.length > 0) errorMsg.push(` ${"Unit of Measure"} is missing for ${emptyUnitMeasure.toString()}`);
      if (emptyProcessingCode.length > 0) errorMsg.push(` ${"Processing Code"} is missing for ${emptyProcessingCode.toString()}`);
      if (errorMsg.length > 0) {
        return setTimeout(() => {
          notify(
            <>
              {"Unable to submit return due to below missing information. Kindly contact IT team for support. Missing Information: "}
              {errorMsg.map((msg) => (
                <div key={generate()}>{msg}</div>
              ))}
            </>,
            "error",
            false
          );
        }, 10);
      }
    }
    setLoader(true);
    let response = await individualReturn(data);
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success" && response?.data?.data?.workticketId) {
      setNotification(<Notification key={generate()} title="WorkTicket created successfully" type="success" ref={clickRef} />);
      setTimeout(() => {
        setNotification(null);
        switchTab(1);
        setLoader(false);
      }, 3000);
    } else {
      setNotification(
        <Notification key={generate()} title="We are currently experiencing issues, please try again" type="error" fullBleed={true} ref={clickRef} />
      );
      setLoader(false);
    }
  };

  return (
    <>
      {notification}
      <Loader active={isLoading} fullscreen />
      {addReturnHardwareModal && (
        <IndividualReturnHardwareModal
          addReturnHardwareModal={addReturnHardwareModal}
          setAddReturnHardwareModal={setAddReturnHardwareModal}
          salesOrgId={salesOrg.value?.split("(")[0].trim()}
          returnHardwareMMIDTableList={returnHardwareMMIDTableList}
          setReturnHardwareMMIDTableList={setReturnHardwareMMIDTableList}
          editReturnHardwareMMID={editReturnHardwareMMID}
          typeMMIDList={typeMMIDList}
          setTypeMMIDList={setTypeMMIDList}
          uniqueRecordMapping={uniqueRecordMapping}
          setUniqueRecordMapping={setUniqueRecordMapping}
        />
      )}

      {deleteReturnHardwareModal && (
        <DeleteVehicleMMIDModal
          deleteVehicleModal={deleteReturnHardwareModal}
          setDeleteVehicleModal={setDeleteReturnHardwareModal}
          deletedVehicleMMID={deleteReturnHardwareMMID}
          handleVehicleMMIDRecordDelete={handleReturnHardwareDeleteMMID}
        />
      )}

      <Header>Return</Header>
      <Spacer bottom="10px" />
      <ValidateAddress
        address={address}
        setAddress={setAddress}
        vehicleData={props}
        showHistoricalAddressDropdown={!props}
        setLoader={setLoader}
        title={"Customer Address"}
        subTitle={"Enter customer information and make sure to validate the address."}
      />
      <Spacer bottom="1rem" />
      <UltraMediumText css={{ marginTop: "0.8rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
        Contact Details
      </UltraMediumText>
      <FlexRow css={{ width: "90%" }}>
        <>
          <CustomInput formWidth="22.5%" type="text" label="CRM Reference ID" value={crmReferenceId} disabled paddingRight="5px" />
          <CustomInput
            formWidth="22.5%"
            type="text"
            label="Contact Name"
            value={contactName.value}
            error={contactName.error}
            errorText={contactName.errorText}
            success={contactName.success}
            maxLength={50}
            paddingRight="5px"
            onChange={(e) => handleFields("name", e.target.value)}
          />
          <CustomInput
            formWidth="22.5%"
            type="text"
            label="Email Address"
            value={email.value}
            error={email.error}
            errorText={email.errorText}
            success={email.success}
            paddingRight="5px"
            onChange={(e) => handleFields("email", e.target.value)}
          />
          <CustomInput
            formWidth="22.5%"
            type="text"
            label="Phone Number"
            value={phoneNumber.value}
            error={phoneNumber.error}
            errorText={phoneNumber.errorText}
            success={phoneNumber.success}
            maxLength={10}
            paddingRight="5px"
            onChange={(e) => handleFields("phone", e.target.value)}
          />
          <TextAreaContainer>
            <SmallText fontWeight="normal">Notes to Warehouse</SmallText>
            <TextArea
              className="cccc"
              readOnly={false}
              required={false}
              disabled={false}
              error={false}
              value={notesToWareHouse}
              onChange={(e) => setNotesToWareHouse(e.target.value)}
              maxLength={500}
              autoResize="none"
              placeholder="Type here"
            />
          </TextAreaContainer>
        </>
      </FlexRow>

      <Spacer bottom="1rem" />
      <FlexRow css={{ width: "90%" }}>
        <>
          <div css={{ width: "22.5%" }}>
            {!isSourceCSP.isSourceCSP ? (
              multiSalesOrgBillingCountries.includes(billingCountry) ? (
                <div>
                  <CustomDropdown
                    label="Sales Org"
                    errorText={salesOrg.errorText}
                    error={salesOrg.error}
                    value={salesOrg.value ? salesOrg.value : ""}
                    // inlineLabel={false}
                    onChange={(event) => handleSalesOrg(event)}
                    paddingRight="5px"
                  >
                    {salesOrgDropDown.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </CustomDropdown>
                </div>
              ) : (
                <CustomInput
                  type="text"
                  label="Sales Org"
                  value={salesOrg.value ? salesOrg.value : ""}
                  errorText={salesOrg.errorText}
                  error={salesOrg.error}
                  success={salesOrg.success}
                  disabled
                  paddingRight="5px"
                />
              )
            ) : (
              <CustomInput
                type="text"
                label="Sales Org"
                value={salesOrg.value ? salesOrg.value : ""}
                errorText={salesOrg.errorText}
                error={salesOrg.error}
                success={salesOrg.success}
                disabled
                paddingRight="5px"
              />
            )}
          </div>
          <div css={{ width: "22.5%" }}>
            <CustomDropdown
              label="Division"
              value={division.value ? division.value : "Please select"}
              errorText={division.errorText}
              error={division.error}
              success={division.success}
              onChange={(e) => handleFields("division", e.target.value)}
              marginBottom="10px"
              paddingRight="5px"
            >
              <option disabled>Please select</option>
              {DIVISION_DROPDOWN_VALUES.map((record) => (
                <option key={record} value={record.code}>
                  {`${record.code} - ${record.description}`}
                </option>
              ))}
            </CustomDropdown>
          </div>
          <div css={{ width: "22.5%" }}>
            <CustomDropdown
              label="Distribution Channel"
              value={distributionchannel.value ? distributionchannel.value : "Please select"}
              errorText={distributionchannel.errorText}
              error={distributionchannel.error}
              success={distributionchannel.success}
              onChange={(e) => handleFields("distributionchannel", e.target.value)}
              marginBottom="10px"
              paddingRight="5px"
            >
              <option disabled>Please select</option>
              {DISTRIBUTION_CHANNEL_DROPDOWN_VALUES.map((record) => (
                <option key={record} value={record.code}>
                  {`${record.code} - ${record.description}`}
                </option>
              ))}
            </CustomDropdown>
          </div>
          <div css={{ width: "22.5%" }}>
            {contractAccountNumberArr && contractAccountNumberArr?.length <= 1 ? (
              <CustomInput
                formWidth="100%"
                type="text"
                label="Contract Account"
                value={contractAccount.value}
                error={contractAccount.error}
                errorText={contractAccount.errorText}
                success={contractAccount.success}
                disabled
                paddingRight="5px"
                onChange={(e) => handleFields("contactAccount", e.target.value)}
              />
            ) : (
              <CustomDropdown
                label="Contract Account"
                value={selectedContractAccountNumber.value ? selectedContractAccountNumber.value : "Please select"}
                errorText={selectedContractAccountNumber.errorText}
                error={selectedContractAccountNumber.error}
                success={selectedContractAccountNumber.success}
                onChange={(e) => handleFields("contactAccount", e.target.value)}
                marginBottom="10px"
                paddingRight="5px"
              >
                <option disabled>Please select</option>
                {contractAccountNumberArr.map((record, index) => (
                  <option key={index}>{record}</option>
                ))}
              </CustomDropdown>
            )}
          </div>
        </>
      </FlexRow>

      <Spacer bottom="10px" />
      <UltraMediumText css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0rem">
        Hardware
      </UltraMediumText>
      <GridComponent
        isExportEnabled={false}
        noDataMessage="No data available to display"
        rows={returnHardwareMMIDTableList}
        showActionIcon={showActionIcon}
        columns={RETURN_INDIVIDUAL_HARDWARE_COL}
        hideGlobalFilter={true}
        whiteSpace="normal"
        paginator={false}
      />
      <Spacer top="10px" />
      <CustomSLButton
        secondary
        onClick={() => {
          setAddReturnHardwareModal(true);
          setEditReturnHardwareMMID(null);
        }}
      >
        Add Hardware
      </CustomSLButton>
      <Spacer top="10px" />
      <Spacer top="2rem" />
      <FlexRow>
        <CustomSLButton aria-label="Submit Return" primary onClick={handleSubmitReturn} disabled={isSaveButtonsDisabled}>
          Submit Return
        </CustomSLButton>
        <CustomSLButton
          secondary
          onClick={() => {
            redirectToPrevPage();
          }}
          aria-label="Cancel"
        >
          Cancel
        </CustomSLButton>
      </FlexRow>
    </>
  );
}

export const IndividualReturnMetadata = {
  name: "Return",
  id: "individual_return",
  component: lazy(() => import("./IndividualReturn")),
  route: "/individual_return",
};
