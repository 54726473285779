import { getProperty } from "../../utility/getProperty";
import { postRequest } from "../RestServices";
import * as urls from "../../api/checkin/index";
export const getVehicleDetailsByVin = async (payload) => {
  try {
    const _response = await postRequest(urls.VEHICLE_DETAILS_BY_VIN, payload);
    const vdata = getProperty(_response, "data", []);
    return { vData: vdata };
  } catch (err) {
    return {
      vData: err?.response?.data,
    };
  }
};
