import { postRequest } from "../RestServices";
import * as urls from "../../api/checkin/index";
import { getProperty } from "../../utility/getProperty";
export const CheckEsnStatusService = async (payload) => {
  try {
    const _response = await postRequest(urls.CHECK_ESN_STATUS, payload);
    const _vtu = getProperty(_response, "data", []);
    return { vtuData: _vtu };
  } catch (err) {
    return {
      error: err,
    };
  }
};
