import { getRequest } from "../RestServices";
import * as urls from "../../api/VTU/url";
import { getProperty } from "../../utility/getProperty";

export const getUnitHistory = async (esnNamber) => {
  try {
    const _response = await getRequest(urls.UNIT_HISTORY + `/${esnNamber}`);
    const _VtuDetails = getProperty(_response, "data", []);
    return { rows: _VtuDetails };
  } catch (err) {
    return {
      error: err,
    };
  }
};
