import { createContext, useState } from "react";

export const ReturnEquipmentContext = createContext();
export const ReturnEquipmentProvider = (props) => {
  const [esnDetails, setEsnDetails] = useState({});
  const [esn, setEsn] = useState("");
  return (
    <ReturnEquipmentContext.Provider
      value={{
        esnDetails: { esnDetails, setEsnDetails },
        esn: { esn, setEsn },
      }}
    >
      {props.children}
    </ReturnEquipmentContext.Provider>
  );
};
