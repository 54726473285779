import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import styled from "@emotion/styled";
import { Spacer, MediumText, SmallText, FlexRow } from "../utility";
import { Wrapper } from "./GridStyles";
import { Col, Row } from "@vds/grids";
import { Input } from "@vds/inputs";
import { Tooltip } from "@vds/tooltips";
import { ColumnHeader } from "./ColumnHeader";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode, FilterOperator, FilterService } from "primereact/api";
import moment from "moment";
import { Parser } from "json2csv";
import SvgIcon from "../../../../components/core/SvgIcon";
import { CustomIconWrapper } from "../../../../pages/HomePage/HomePage.style";
import { Icon } from "@vds/icons";
import { classNames } from "primereact/utils";
import { css } from "@emotion/react";
import { InputText } from "primereact/inputtext";
import { DropdownSelect } from "@vds/selects";
import { CUSTOM_FILTER_TYPE, EDIT_FIELD_TYPES } from "../../../../constants/ApplicationConstants";
import searchIcon from "../../../assets/icons/search_blk.png";
import csvIcon from "../../../assets/icons/export-csv.png";
import saveFileIcon from "../../../assets/icons/save_blk.png";
import _ from "lodash";
import { Button } from "@vds/buttons";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { ListBox } from "primereact/listbox";
import CustomFilterStyles from "./CustomFilterStyles.css";

const EllipsisColumn = css`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const formattedData = (data) => {
  return data == null || data === "" ? "--" : data;
};

const formatDate = (value, dateFormat, timezone = "") => {
  return dateFormat
    ? value
      ? `${moment(value).format(dateFormat)} ${timezone}`.trim()
      : formattedData(value)
    : value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
};

const rowsDefault = [{ row: "" }];
const colsDefault = [{ cols: "" }];

const InlineSpan = styled.span`
  display: inline-flex;
  align-items: center;
`;

const InlineInput = styled(Input)`
  display: inline-flex !important;
`;

const StyledDropdown = styled(Dropdown)`
  width: 25px;
  > span {
    left: 0px !important;
  }
`;

const IconWrapper = styled.div`
  /* :hover{
background: ${({ theme }) => theme.background};
} */
  :focus {
    border: 1px solid black !important;
    outline: none;
    box-shadow: none;
  }
  height: 24px;
  width: 24px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
`;

const StyledCheckbox = styled(Input)`
  font-weight: 100;
  font-size: 14px !important;
  /* align-self: center; */
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px 0 15px;
  height: 30px;
  border-bottom: 1px solid black;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

const CustomTooltip = styled(Tooltip)`
  > span > button > span:nth-child(2) > span {
    width: 24px;
    height: 24px;
    left: 10px;
  }
  > span > button > span:nth-child(2) > svg {
    display: none;
  }
  > span > button > span:nth-child(3) > span {
    width: max-content;
    left: 8px;
    padding: 0.5rem 0 0.5rem 0.5rem;
    bottom: 1.3rem;
    > span:nth-child(1) > span > span {
      font-size: 10px;
      margin-bottom: none;
      font-weight: 500;
    }
    > span:nth-child(2) {
      width: 24px;
      height: 24px;
      left: 12px;
    }
  }
`;

const Magnifier = styled.div`
  & svg {
    cursor: pointer;
    :focus {
      outline: none;
      box-shadow: none;
    }
  }
`;
const CaseNumberFormatter = styled(SmallText)`
  & span {
    font-weight: ${(props) => (props.isBold ? "700" : "")};
  }
`;

export const GridComponent = ({
  title,
  /** method for hyperlink click */
  hyperLinkClicked,
  /** message in case of no records*/
  noDataMessage = "No data to display",
  /** true if checkbox is needed*/
  isSelectionEnabled = false,
  /** method to get the selected checkbox data*/
  getSelectedItems,
  /** data */
  rows = { rowsDefault },
  /**field mapping, is a list of objects in the from
  {
    key: "Header name",
    value: "field name in data",
  },*/
  columns = { colsDefault },
  isExportEnabled = false,
  flyOutMenu,
  border,
  hideGlobalFilter,
  isHyperLink,
  rowExpansionTemplate,
  renderDataWithImage,
  renderMagnifierTemplate,
  caseIdentifier,
  showActionIcon,
  whiteSpace,
  paginator,
  customHeaderControls,
  selectionMode = "multiple",
  expandedRow,
  onRowEditTemplate,
  threeDotsTemplate,
  resizableColumns = true,
  dropDownValues,
  enableGlobalSearch = false,
  enableLogExport = false,
  exportLog,
  isEditEnabled = false,
  onSaveClick,
  initialRows = rows,
  exportFileName,
  fieldValueMappings,
  searchKeyboardValue = "",
  additionalGlobalSearchFields = [],
  selectionAutoFocus = true,
  onDropDownClick,
  headerBanner,
  //added customFilterDropDownData as part of VZC42148-106
  customFilterDropDownData = {},
  itemsPerPageDropDownValues = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: "All", value: rows.length },
  ],
}) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rowsInAPage, setRowsInAPage] = useState(itemsPerPageDropDownValues[0].value);
  const dt = useRef(null);
  const [exportCheckboxSelected, setExportCheckboxSelected] = useState(false);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const [paginatorError, setPaginatorError] = useState(false);
  const [paginatorErrorText, setPaginatorErrorText] = useState("Press 'Enter' key to go to this page.");
  const [updatedRows, setUpdatedRows] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [modifiedRows, setModifiedRows] = useState([]);

  useEffect(() => {
    initFilters();
  }, []);

  useEffect(() => {
    if (searchKeyboardValue.length > 0) {
      const value = searchKeyboardValue;
      let updatedFilters = { ...filters };
      updatedFilters["global"].value = value;
      setFilters(updatedFilters);
      setGlobalFilterValue(value);
    } else {
      initFilters();
    }
  }, [searchKeyboardValue]);

  useEffect(() => {
    setUpdatedRows(rows);
  }, [rows]);

  useEffect(() => {
    determineSaveEnabled();
  }, [updatedRows, initialRows]);

  const determineSaveEnabled = () => {
    let updatedItems = _.differenceWith(updatedRows, initialRows, _.isEqual);
    updatedItems.length == 0 ? setSaveDisabled(true) : setSaveDisabled(false);
    setModifiedRows(updatedItems);
  };

  const onPageInputChange = (event) => {
    setCurrentPage(event.target.value);
  };

  useEffect(() => {
    setSelectedItem([]);
    setExpandedRows(expandedRow);
  }, [rows]);

  const onCustomPage = (event) => {
    setFirst(event.first);
    setRowsInAPage(event.rows);
    setCurrentPage(event.page + 1);
    setPaginatorError(false);
    setPaginatorErrorText("Press 'Enter' key to go to this page.");
  };

  const onSelect = (e) => {
    setSelectedItem(e.value);
    getSelectedItems(e.value);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = { ...filters };
    updatedFilters["global"].value = value;
    setFilters(updatedFilters);
    setGlobalFilterValue(value);
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      const page = parseInt(currentPage);
      if (page < 1 || page > options.totalPages) {
        setPaginatorError(true);
        setPaginatorErrorText(`Value must be between 1 and ${options.totalPages}.`);
      } else {
        const firstPage = currentPage ? options.rows * (page - 1) : 0;
        setFirst(firstPage);
        setPaginatorError(false);
        setPaginatorErrorText("Press 'Enter' key to go to this page.");
      }
    }
  };

  const pageTemplate = {
    layout: "CurrentPageReport RowsPerPageDropdown PrevPageLink PageLinks NextPageLink",
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <SmallText primitive="span">Items per page: </SmallText>
          <StyledDropdown value={options.value} aria-label={options.value} options={itemsPerPageDropDownValues} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <FlexRow
          flexGrow="1"
          css={{
            "@media only screen and (max-width: 950px)": {
              flexBasis: "100%",
            },
          }}
        >
          <InlineSpan>
            <Spacer right="15px">
              <SmallText css={{ display: "inline-block" }} primitive="span">
                Go to:
              </SmallText>
              <InlineInput
                width="25px"
                error={paginatorError}
                value={currentPage}
                onKeyDown={(e) => onPageInputKeyDown(e, options)}
                onChange={onPageInputChange}
              />
              {paginatorError && <Tooltip title={paginatorErrorText} size="medium" surface="light" />}
            </Spacer>
            <Spacer right="15px">
              <SmallText css={{ display: "inline-block" }} primitive="span">
                Displaying items {options.first} - {options.last} of {options.totalRecords}
              </SmallText>
            </Spacer>
          </InlineSpan>
        </FlexRow>
      );
    },
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          role="button"
          className={classNames(options.className, "border-round")}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <Icon name="left-arrow" size="small" />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          role="button"
          className={classNames(options.className, "border-round")}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <Icon name="right-arrow" size="small" />
        </button>
      );
    },
  };

  const initFilters = () => {
    const obj = Object.fromEntries(
      columns.map((col) => {
        if (col.isDate) {
          return [col.value, { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }];
        } else if (col.customFilterType === CUSTOM_FILTER_TYPE.dropDown || col.customFilterType === CUSTOM_FILTER_TYPE.listBox) {
          return [col.value, { value: null, matchMode: FilterMatchMode.CONTAINS }];
        } else {
          return [col.value, { operator: FilterOperator.AND, constraints: [{ value: null }] }];
        }
      })
    );
    obj["global"] = { value: null, matchMode: "customGlobalFilter" };
    setFilters(obj);
    setGlobalFilterValue("");
  };

  const updateRowData = (e, rowData, field) => {
    rowData[field] = e.target.value;
    let dependentFields = columns.find((col) => col.value == field).dependentFields;
    let newRows = [...updatedRows];
    let rowToBeUpdated = newRows.find((row) => row === rowData);
    let mappingList = fieldValueMappings[field];
    if (dependentFields) {
      //update values for dependent fields
      dependentFields.forEach((dependentField) => {
        rowToBeUpdated[dependentField] = mappingList.find((mappingData) => mappingData[field] == e.target.value)[dependentField];
      });
    }
    rowToBeUpdated[field] = e.target.value;
    setUpdatedRows(newRows);
  };

  const getEditTemplate = (rowData, colAttributes, data) => {
    switch (colAttributes.editFieldType) {
      case EDIT_FIELD_TYPES.dropDown:
        return (
          <Dropdown
            placeholder={rowData[data.field] ?? "Select"}
            aria-label={rowData[data.field] ?? "Select"}
            value={rowData[data.field]}
            css={{ width: "-webkit-fill-available" }}
            options={dropDownValues[data.field]}
            onFocus={(e) => onDropDownClick(e, rowData, data.field)}
            onChange={(e) => updateRowData(e, rowData, data.field)}
          />
        );
      case EDIT_FIELD_TYPES.inputNumber:
        return rowData.mmid.startsWith("13") ? (
          <InputNumber
            style={colAttributes.editFieldStyle}
            value={rowData[data.field]}
            aria-label={rowData[data.field]}
            onValueChange={(e) => updateRowData(e, rowData, data.field)}
          />
        ) : null;
      default:
        return (
          <InputText
            style={colAttributes.editFieldStyle}
            value={rowData[data.field]}
            aria-label={rowData[data.field]}
            onChange={(e) => updateRowData(e, rowData, data.field)}
          />
        );
    }
  };

  const getBodyToDisplay = (rowData, data) => {
    let colAttributes = columns.find((col) => col.value == data.field);
    if (colAttributes.isCheckboxes) {
      return renderCheckboxes(rowData, data);
    }
    if (colAttributes.isEditable) {
      return getEditTemplate(rowData, colAttributes, data);
    }
    if (colAttributes.containsMagnifier) {
      return (
        <Magnifier onClick={() => renderMagnifierTemplate(rowData)}>
          <Icon name="search" aria-label="search-icon" alt="search-icon" size="medium" color="#000000" />
        </Magnifier>
      );
    }
    if (colAttributes.checkSouce) {
      return (
        <CaseNumberFormatter primitive="span" isBold={caseIdentifier(rowData[data.field])}>
          {rowData[data.field]}
        </CaseNumberFormatter>
      );
    } else {
      if (colAttributes.isHyperLink) {
        if (colAttributes.isList) {
          return <SmallText primitive="span">{getListToDisplay()}</SmallText>;
        } else {
          if (isHyperLink && !isHyperLink(rowData)) {
            return <SmallText primitive="span">{formattedData(rowData[data.field])}</SmallText>;
          }
          return (
            <SmallText
              primitive="span"
              style={{
                "text-decoration": "underline",
                cursor: "pointer",
                "pointer-events": colAttributes.isHyperLinkDisabled ? "none" : "all",
                opacity: colAttributes.isHyperLinkDisabled ? "0.5" : "1",
              }}
              onClick={() => hyperLinkClicked(rowData, colAttributes.key)}
            >
              {renderData(rowData[data.field], colAttributes, rowData)}
            </SmallText>
          );
        }
      } else if (colAttributes.ellipsis) {
        return (
          <SmallText primitive="span" css={EllipsisColumn}>
            {renderData(rowData[data.field], colAttributes)}
          </SmallText>
        );
      } else {
        return renderContentForNonHyperlink(rowData, colAttributes, data);
      }
    }
  };

  function getListToDisplay(rowData, colAttributes, data) {
    return rowData[data.field].map((itemInList, index) => {
      return (
        <>
          <SmallText
            primitive="span"
            key={index}
            style={{ "text-decoration": "underline", cursor: "pointer" }}
            onClick={() => hyperLinkClicked(rowData, colAttributes.key)}
          >
            {formattedData(itemInList[colAttributes.itemToDisplayInList])}
          </SmallText>
          <br />
        </>
      );
    });
  }

  const renderData = (data, colAttributes, rowData) => {
    if (colAttributes.constantValue) {
      return formattedData(colAttributes.constantValue);
    }
    return colAttributes.containsImage ? renderDataWithImage(formattedData(data), rowData) : formattedData(data);
  };

  const renderContentForNonHyperlink = (rowData, colAttributes, data) => {
    if (colAttributes.isList) {
      return <SmallText primitive="span">{getListToDisplay(rowData, colAttributes, data)}</SmallText>;
    } else if (colAttributes.isDate) {
      return <SmallText primitive="span">{formatDate(rowData[data.field], colAttributes.dateFormat, rowData.timezone)}</SmallText>;
    } else {
      return <SmallText primitive="span">{renderData(rowData[data.field], colAttributes, rowData)}</SmallText>;
    }
  };

  const clearFilter = () => {
    initFilters();
  };

  const formatData = (dataToExport) => {
    let dataColumns = columns.filter((item) => {
      return item.isDate;
    });
    return dataToExport.map((item) => {
      dataColumns.forEach((columnItem) => {
        if (item[columnItem.value] instanceof Date) {
          item[columnItem.value] = formatDate(item[columnItem.value], columnItem.dateFormat);
        }
      });
      return item;
    });
  };

  const exportCSV = () => {
    const filename = exportFileName + "_" + moment().format("MM-DD-YYYY");
    let dataToExport = exportCheckboxSelected ? selectedItem : rows;
    dataToExport = formatData(dataToExport);
    let fieldsToExclude = columns.map((col) => {
      if (col.containsImage) {
        return col.value;
      }
    });
    var fieldsToExport = _.cloneDeep(dataToExport).map((data) => _.omit(data, fieldsToExclude));
    const exportColumnsForCSV = columns
      .filter((col) => !col.containsImage)
      .map((col) => {
        return { label: col.key, value: col.value };
      });
    const json2csvParser = new Parser({ fields: exportColumnsForCSV });
    const csv = json2csvParser.parse(fieldsToExport);
    saveFile(csv, filename ?? "data", "text/csv", ".csv");
  };

  const saveFile = (buffer, fileName, type, extension) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        const data = new Blob([buffer], {
          type: type,
        });

        module.default.saveAs(data, fileName + extension);
      }
    });
  };

  const renderHeader = () => {
    return (
      <>
        {headerBanner}
        <FlexRow justifyContent="space-between">
          <FlexRow alignItems="flex-end">
            <MediumText bold>{title}</MediumText>
          </FlexRow>
          <FlexRow>
            {customHeaderControls}
            {enableGlobalSearch && (
              <SearchInputWrapper>
                <SearchInput
                  role="search"
                  aria-label={globalFilterValue}
                  value={globalFilterValue}
                  type="text"
                  onChange={onGlobalFilterChange}
                  placeholder="Search"
                ></SearchInput>
                <img aria-label="searchIcon" role="img" src={searchIcon} height="20px" width="20px"></img>
              </SearchInputWrapper>
            )}
            {!hideGlobalFilter && (
              <IconWrapper tabIndex={0} onClick={clearFilter}>
                <span className="p-button-icon p-c p-button-icon-left pi pi-filter-slash"></span>
              </IconWrapper>
            )}
            {isExportEnabled && (
              <IconWrapper tabIndex={2} onClick={exportCSV}>
                <CustomTooltip title="CSV" size="large"></CustomTooltip>
                <img src={csvIcon}></img>
              </IconWrapper>
            )}
            {enableLogExport && (
              <IconWrapper tabIndex={2} onClick={() => exportLog()}>
                <CustomTooltip title="History Log" size="large"></CustomTooltip>
                <img css={{ height: "20px" }} src={saveFileIcon}></img>
              </IconWrapper>
            )}
            {isSelectionEnabled && isExportEnabled && (
              <StyledCheckbox
                selected={exportCheckboxSelected}
                role="checkbox"
                aria-checked={exportCheckboxSelected}
                type="checkbox"
                name="default"
                width="auto"
                onChange={() => {
                  setExportCheckboxSelected(!exportCheckboxSelected);
                }}
              >
                Export Selected items only
              </StyledCheckbox>
            )}
            {isEditEnabled && (
              <Button
                aria-label="Save"
                role="button"
                disabled={saveDisabled}
                aria-disabled={saveDisabled}
                size="small"
                onClick={() => onSaveClick(modifiedRows)}
              >
                Save
              </Button>
            )}
          </FlexRow>
        </FlexRow>
      </>
    );
  };

  const renderEmptyMessage = () => {
    return <SmallText>{noDataMessage}</SmallText>;
  };

  const onRowExpand = (event) => {
    setExpandedRows([event.data]);
  };

  const onRowCollapse = () => {
    setExpandedRows([]);
  };

  const [checked, setChecked] = useState(false);
  const renderCheckboxes = (rowData, data) => {
    return (
      <div
        css={css`
          padding-left: 10px;
        `}
      >
        <Checkbox role="checkbox" aria-checked={rowData.upsell} checked={rowData.upsell}></Checkbox>
      </div>
    );
  };

  //added this function as part of VZC42148-106
  const customFilterTemplate = (options) => {
    let filterType = columns.find((col) => col.value == options.field).customFilterType;
    let dropDownOptions = [];
    let placeholder = customFilterDropDownData[options.field]?.placeholder ?? "Search";
    if (filterType === CUSTOM_FILTER_TYPE.listBox || filterType === CUSTOM_FILTER_TYPE.dropDown) {
      dropDownOptions = customFilterDropDownData[options.field]?.data?.map((item) => {
        return { label: item };
      });
    }
    switch (filterType) {
      case CUSTOM_FILTER_TYPE.listBox:
        return (
          <ListBox
            value={options.value}
            options={dropDownOptions}
            aria-label={dropDownOptions}
            onChange={(e) => options.filterApplyCallback(e.value, options.index)}
            filter
            filterPlaceholder={placeholder}
            optionLabel="label"
            optionValue="label"
            emptyMessage="No match found"
            virtualScrollerOptions={{ itemSize: 50, orientation: "vertical", scrollHeight: "200px" }}
          />
        );
    }
  };

  return (
    <div>
      <Wrapper
        colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
        autoResizeHead={true}
        fontSize="14px"
        colGutter={true}
        whiteSpace={whiteSpace}
        flyOutMenu={flyOutMenu}
        border={border}
      >
        <DataTable
          header={renderHeader()}
          sortMode="multiple"
          removableSort
          ref={dt}
          resizableColumns={resizableColumns}
          columnResizeMode={resizableColumns ? "expand" : "fit"}
          scrollable
          scrollHeight="400px"
          scrollDirection="horizontal"
          filterDisplay="menu"
          emptyMessage={renderEmptyMessage()}
          filters={filters}
          responsiveLayout="scroll"
          value={updatedRows}
          selectionMode={isSelectionEnabled ? "checkbox" : ""}
          selectionPageOnly={isSelectionEnabled}
          selection={selectedItem}
          onSelectionChange={(e) => onSelect(e)}
          paginator={paginator}
          paginatorPosition="bottom"
          first={first}
          rows={rowsInAPage}
          stripedRows
          totalRecords={rows}
          onPage={onCustomPage}
          paginatorTemplate={pageTemplate}
          pageLinkSize={3}
          expandedRows={expandedRows}
          onRowExpand={onRowExpand}
          onRowCollapse={onRowCollapse}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRowIcon="pi pi-chevron-up"
          collapsedRowIcon="pi pi-chevron-down"
          globalFilterFields={[...columns.map((column) => column.value), ...additionalGlobalSearchFields]}
          selectionAutoFocus={selectionAutoFocus}
        >
          {isSelectionEnabled && (
            <Column
              className="columnCheckBox"
              columnKey="1"
              key={"checkbox"}
              selectionMode={selectionMode}
              style={{ height: "34px", flex: "inherit" }}
            ></Column>
          )}
          {
            //added it as part of VZC42148-106
            columns.map((item) => {
              return item.isCustomFilter ? (
                <Column
                  key={item.key}
                  field={item.value}
                  columnKey={item.key}
                  body={getBodyToDisplay}
                  header={<ColumnHeader col={item.key} />}
                  style={{
                    "min-width": `${item.width || "200px"}`,
                    width: `${item.width || "200px"}`,
                    // "max-width": `${item.width || "200px"}`,
                    "min-height": "34px",
                    padding: "4px 0 4px 0!important",
                    backgroundColor: flyOutMenu && "white",
                    ...item.style,
                  }}
                  sortable={item.isSortable}
                  filter={item.isFilterEnabled}
                  frozen={item.isFrozen}
                  filterElement={customFilterTemplate}
                  showFilterMatchModes={false}
                  filterMenuClassName="custom-filter"
                  filterClear={<></>}
                  filterApply={<></>}
                ></Column>
              ) : (
                <Column
                  key={item.key}
                  field={item.value}
                  columnKey={item.key}
                  body={getBodyToDisplay}
                  header={<ColumnHeader col={item.key} ariaLabel={item.ariaLabel} />}
                  style={{
                    "min-width": `${item.width || "200px"}`,
                    width: `${item.width || "200px"}`,
                    // "max-width": `${item.width || "200px"}`,
                    "min-height": "34px",
                    padding: "4px 0 4px 0!important",
                    backgroundColor: flyOutMenu && "white",
                    ...item.style,
                  }}
                  sortable={item.isSortable}
                  filter={item.isFilterEnabled}
                  frozen={item.isFrozen}
                ></Column>
              );
            })
          }
          {threeDotsTemplate && <Column body={threeDotsTemplate} style={{ width: "3.5em", height: "auto", flex: "inherit" }} />}
          {onRowEditTemplate && <Column body={onRowEditTemplate} style={{ width: "2.5em", height: "auto", flex: "inherit" }} />}
          {rowExpansionTemplate && <Column expander style={{ width: "2.5em", height: "auto", flex: "inherit" }} />}
          {showActionIcon && (
            <Column
              header={<ColumnHeader col="Actions" />}
              body={showActionIcon}
              style={{
                width: flyOutMenu ? "9.8%" : "180px",
                overflowX: flyOutMenu && "hidden",
                height: "auto",
                flex: "inherit",
                backgroundColor: flyOutMenu && "white",
              }}
            />
          )}
        </DataTable>
      </Wrapper>
    </div>
  );
};
