import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getWorkTicketVINList = async (workTicketId) => {
  try {
    return await getRequest(urls.GET_WORKTICKET_VIN_LIST + `/${workTicketId}`);
  } catch (err) {
    return err;
  }
};
