import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getCSPDetails = async (accountId, id, userId) => {
  try {
    return await getRequest(urls.GET_CSP_DETAILS + `/${accountId}/${id}/${userId}`);
  } catch (err) {
    return err;
  }
};
