import { Icon } from "@vds/icons";
import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: ${({ position }) => position};
  display: block;
  border: 1px solid #000;
  z-index: 9;
  font-size: ${({ smallItem }) => (smallItem ? "12px" : "14px")};
  width: 218px;
  background: ${({ backgroundColor }) => backgroundColor};
  background: ${({ theme }) => theme.background3};
`;
const Item = styled.button`
  padding: ${({ smallItem, clicked }) => {
    let temp = clicked ? "4px 8px 4px 6px" : "4px 8px 4px 24px";
    return smallItem ? temp : "13px";
  }};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-weight: ${({ smallItem }) => (smallItem ? "normal" : "700")};
  color: ${(props) => (props.disabled ? "#d8dada" : props.theme.text)};
  font-family: Verizon-NHG-eDS;
  border: none;
  text-align: left;
  width: 100%;
  background: transparent;
  &:hover {
    background: ${(props) => (props.disabled ? "transparent" : props.theme.background5)};
  }
  border-top: 1px solid #e9ecef;
`;
const FlexDiv = styled.div`
  display: flex;
`;
const IconStyled = styled.span`
  padding-right: 7px;
  svg > path {
    stroke: ${({ theme }) => theme.text};
  }
`;

export const DrillDownMenu = ({
  dropDownRef,
  open,
  items,
  onClick,
  left,
  right,
  width,
  top,
  clicked = "",
  backgroundColor = "#fff",
  smallItem = false,
  position = "fixed",
}) => {
  return (
    <Wrapper
      ref={dropDownRef}
      backgroundColor={backgroundColor}
      smallItem={smallItem}
      style={{
        display: open ? "block" : "none",
        left: left || "auto",
        right: right || "auto",
        top: top || "auto",
        width: width || "165px",
      }}
      position={position}
    >
      {items.map((i) => (
        <Item key={i.id} onClick={() => onClick(i)} smallItem={smallItem} clicked={clicked === i.id} disabled={i.disabled || false}>
          <FlexDiv>
            {clicked === i.id && (
              <IconStyled>
                <Icon name={"checkmark"} color={"black"} size="extraSmall"></Icon>
              </IconStyled>
            )}
            {i.name}
          </FlexDiv>
        </Item>
      ))}
    </Wrapper>
  );
};
