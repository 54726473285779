import { Comments, OcComments } from "../../api/accounts/url";
import { BuyoutStatus, MilestoneDetails } from "../../api/orders/url";
import { postRequest } from "../RestServices";

const response = {
  status: 200,
  data: {
    statusCode: "200",
    message: "Success",
    data: {
      salesForceOpportunityId: "b111",
      superLibraOpportunityId: "1111",
      erpPoNumber: "",
      bpNumber: "12344",
      salesForceAccountId: "a111",
      orderType: null,
      accountName: "new",
      opportunityCreatedDate: "2022-01-10T11:09:32.000+00:00",
      states: [
        {
          state: "New",
          creationTime: "2022-01-10T11:09:32.000+00:00",
          description: null,
        },
        {
          state: "M1WaitingBp",
          creationTime: "2022-01-10T11:09:32.000+00:00",
          description: null,
        },
        {
          state: "M1GotBp",
          creationTime: "2022-01-10T11:09:32.000+00:00",
          description: null,
        },
        {
          state: "M1Failed",
          creationTime: "2022-01-10T11:09:32.000+00:00",
          description: null,
        },
      ],
    },
  },
};
const response1 = {
  status: 200,
  data: {
    data: {
      states: [
        {
          state: "New",
        },
        {
          state: "M1WaitingBp",
        },
        {
          state: "M1GotBp",
        },
        // {
        //   state: "M1Failed",
        // },
        {
          state: "M1UpdateBPtoSFDC",
        },
        {
          state: "M1Completed",
        },
        {
          state: "M2FailedCreateCAInSFDC",
        },
        {
          state: "M2CreatedCAInSFDC",
        },
        {
          state: "M2WaitingAddressIds",
        },
        {
          state: "M2WaitingContactBP",
        },
        {
          state: "M2GotBP",
        },
        {
          state: "M2ContactBPUpdatedInSFDC",
        },
        {
          state: "M2CAAddressIdUpdated",
        },
        {
          state: "M2WaitingAssociation",
        },
        {
          state: "M2ContactAssociated",
        },
        {
          state: "M2WaitingCACreateInMDG",
        },
        {
          state: "M2CreatedCAInMDG",
        },
        {
          state: "M2Failed",
        },
      ],
    },
  },
};
export async function getMilestoneData(opportunityId) {
  // return response1;
  const request = {
    slOppId: opportunityId,
  };
  try {
    return await postRequest(MilestoneDetails, request);
  } catch (err) {
    return {
      error: err,
    };
  }
}

export async function getBuyoutStatus(opportunityId) {
  const request = {
    sfOppId: opportunityId,
  };
  try {
    return await postRequest(BuyoutStatus, request);
  } catch (err) {
    return {
      error: err,
    };
  }
}

export async function postComment(data) {
  try {
    return await postRequest(Comments, data);
  } catch (err) {
    return err;
  }
}

export async function postCommentOc(data) {
  try {
    return await postRequest(OcComments, data);
  } catch (err) {
    return err;
  }
}
