import { Global, ThemeProvider, useTheme } from "@emotion/react";
import { VDSManager } from "@vds/utilities";
import _isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useRef, useState } from "react";
import { generate } from "shortid";
import { GlobalStyles, StyledGridForNav } from "../../assets/styles/Theme.style";
import { TabWindowOverlay } from "../../components/features/HomePageHelperComponents/TabWindowOverlay";
import {
  ADD_PAGE,
  CHANGE_PAGE,
  FORCE_CLOSE_TAB,
  ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE,
  OPEN_TAB_EVENT,
  RESET_TAB,
  UPDATE_TAB,
} from "../../constants/ApplicationConstants";
import { getProperty } from "../../utility/getProperty";
import { messageBus } from "../../utility/messageBus";
import { getPageMetadataById, isIframe, redirectToPageInAddress, updateAddress } from "../../utility/navigation";
import WelcomePage from "./../WelcomePage";
import { BodyWrapper, ColPaddingZero, RightSection } from "./HomePage.style";
import LeftNavigationCom from "./LeftNavigationCom";
import TopHeaderPanel from "./TopHeaderPanel";
import { Privileges } from "../../constants/PrivilegeConstants";
import { Privilege } from "../../components/core/Privilege";
import { isEqual } from "lodash";
import { leftNavigationMenus } from "../integrationConfig";
import { TabContextProvider } from "../../context/tabContext";

function getTabLabel(tabLabel = "", count = 1) {
  return { typeCount: count, tabLabel };
}

/** Validates if tablabel as duplicate name.
 * returns unique tablabel with count
 */
function validateDuplicateLabel(tabLabel, similiarTabList = []) {
  let tabLabelMap = new Map();
  similiarTabList.forEach((tab) => tabLabelMap.set(`${tab.typeCount} ${tab.tabLabel}`, true));

  for (let step = 1; step <= tabLabelMap.size + 1; step++) {
    if (!tabLabelMap.has(`${step} ${tabLabel}`)) {
      return { typeCount: step, tabLabel };
    }
  }
  return { typeCount: 1, tabLabel };
}

function appendCountToTabLabel(tabData, tabList) {
  if (tabData) {
    const componentName = tabData.componentName;
    const tabLabel = tabData.tabLabel;
    if (tabLabel && tabList && tabList.length > 0) {
      const similiarTabList = tabList.filter((t) => t.componentName === componentName);
      if (similiarTabList && similiarTabList.length > 0) {
        const { typeCount, tabLabel: label } = validateDuplicateLabel(tabLabel, similiarTabList);
        return { typeCount, tabLabel: label };
      }
    }
    return getTabLabel(tabLabel, 1);
  }
  return { typeCount: "", tabLabel: "" };
}

function getUpdatedStateOnTabAdd(tabListData = [], tabData = {}) {
  let updatedtabOptions = [];

  if (tabData instanceof Array) {
    updatedtabOptions = tabData.map((tab) => {
      const { typeCount, tabLabel } = appendCountToTabLabel(tab, tabListData);
      tab.typeCount = typeCount;
      tab.tabLabel = tabLabel;
      tab.tabId = tab.tabId || generate();
      return tab;
    });
  } else {
    const { typeCount, tabLabel } = appendCountToTabLabel(tabData, tabListData);
    tabData.typeCount = typeCount;
    tabData.tabLabel = tabLabel;
    tabData.tabId = tabData.tabId || generate();
    updatedtabOptions.push(tabData);
  }
  return updatedtabOptions;
}

function updateTabCount(tabsData) {
  let countMap = new Map();
  tabsData.forEach((item, index, array) => {
    if (countMap.has(item.tabLabel)) {
      countMap.set(item.tabLabel, countMap.get(item.tabLabel) + 1);
    } else {
      countMap.set(item.tabLabel, 1);
    }
    array[index].typeCount = countMap.get(item.tabLabel);
  });
}

export function HomePage(props) {
  const [tabData, dispatch] = useState({ tabListData: [], selectedIndex: 0 });
  const originalTheme = useTheme();
  const [UnAvailableSection, setUnAvailableSection] = useState(false);
  const [unAvailableHeight, setUnAvailableHeight] = useState(0);
  const stateRef = useRef({ tabListData: [], selectedIndex: 0 });

  const setMyState = (data) => {
    stateRef.current = data;
    dispatch(data);
  };

  useEffect(() => {
    const userActionLog = () => {
      try {
        if (!tabData || !Array.isArray(tabData.tabListData) || tabData.selectedIndex == null || tabData.selectedIndex == undefined) return;
        const { tabListData, selectedIndex } = tabData;
        const selectedTab = tabListData[selectedIndex];
        if (!selectedTab) return;
        const { tabLabel, subPages } = selectedTab;
        sessionStorage.setItem(
          "Controller",
          leftNavigationMenus.find((menu) => menu.subMenus.some((subMenu) => subMenu.name === tabLabel))?.name || ""
        );
        sessionStorage.setItem("Action", tabLabel ?? "");
        if (Array.isArray(subPages) && subPages.length) {
          const lastSubPage = subPages[subPages.length - 1];
          sessionStorage.setItem("Path", lastSubPage?.pageId ?? "");
        } else {
          sessionStorage.removeItem("Path");
        }
      } catch (error) {
        /* empty */
      }
    };
    userActionLog();
  }, [tabData]);

  const closeTab = useCallback((e, id) => {
    e && e.stopPropagation();
    e && e.preventDefault();
    const filteredTabListData = getProperty(stateRef, "current.tabListData", []).filter((t) => t.tabId !== id);
    let newSelectedIndex = filteredTabListData.length - 1;
    newSelectedIndex >= 0
      ? setMyState({
          tabListData: filteredTabListData,
          selectedIndex: newSelectedIndex,
        })
      : setMyState({ tabListData: filteredTabListData, selectedIndex: null });
    messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
      type: "CLOSE",
      tabList: filteredTabListData,
      selectedIndex: newSelectedIndex,
    });
  }, []);
  function tabValidation(tabOptions, localTabListData = []) {
    const singleInstance = getPageMetadataById(tabOptions.componentName)?.singleInstance ?? true;
    const _updatedIndex =
      !_isEmpty(localTabListData) && singleInstance
        ? localTabListData.findIndex((item) => item.tabLabel === tabOptions.tabLabel && isEqual(item.props, tabOptions.props))
        : -1;
    return { _updatedIndex, isUpdateTab: true };
  }
  const handleOpenTab = useCallback((localTabData) => {
    const existingTabData = getProperty(stateRef, "current.tabListData", []);
    const existingIndex = getProperty(stateRef, "current.selectedIndex", 0);
    let { _updatedIndex, isUpdateTab } = tabValidation(localTabData, existingTabData);

    if (isUpdateTab && _updatedIndex == -1) {
      const updatedTabOptions = getUpdatedStateOnTabAdd(existingTabData, localTabData);
      const _newTabListData = [...existingTabData, ...updatedTabOptions];

      setMyState({
        tabListData: _newTabListData,
        selectedIndex: _newTabListData.length - 1,
      });
      messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
        type: "ADD",
        tabList: _newTabListData,
        selectedIndex: _newTabListData.length,
        props: localTabData.props,
      });
    } else if (isUpdateTab && existingIndex !== _updatedIndex) {
      setMyState({
        ...getProperty(stateRef, "current", {}),
        selectedIndex: _updatedIndex,
      });
      messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
        type: "CHANGE",
        tabList: getProperty(stateRef, "current.tabListData", []),
        selectedIndex: _updatedIndex,
      });
    }
  }, []);

  const forceCloseTab = useCallback(
    (payload) => {
      closeTab(null, payload);
    },
    [closeTab]
  );

  function blockTabsUpdateCallbkack(tabsData, localSelectedIndex) {
    // const route = tabsData[localSelectedIndex] ? getPageMetadataById(tabsData[localSelectedIndex].componentName).route : "/";
    const route = tabsData[localSelectedIndex] ? tabsData[localSelectedIndex].url : "/";
    updateAddress(`/#${route}`);
    updateTabCount(tabsData);
    setMyState({ tabListData: tabsData, selectedIndex: localSelectedIndex });
    messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
      type: "CHANGE",
      tabList: tabsData,
      selectedIndex: localSelectedIndex,
    });
  }
  const addPage = (payload) => {
    if (payload.id) {
      const selectedTab = stateRef.current.tabListData.at(stateRef.current.selectedIndex);
      const subPage = { id: generate(), pageId: payload.id, props: payload.props };
      if (!selectedTab.subPages) {
        selectedTab.subPages = [subPage];
        selectedTab.currentIndex = 1;
      } else {
        selectedTab.subPages = selectedTab.subPages.slice(0, selectedTab.currentIndex);
        selectedTab.subPages.push(subPage);
        selectedTab.currentIndex = selectedTab.subPages.length;
      }
      const newTabData = { ...stateRef.current };
      dispatch(newTabData);
      stateRef.current = newTabData;
      messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
        type: "SYNC_PAGE",
        updatedTab: selectedTab,
      });
    }
  };

  const changePage = (payload) => {
    if (payload.index || payload.index === 0) {
      const selectedTab = stateRef.current.tabListData[stateRef.current.selectedIndex];
      selectedTab.currentIndex = payload.index;
      const newTabData = { ...stateRef.current };
      dispatch(newTabData);
      stateRef.current = newTabData;
      messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
        type: "SYNC_PAGE",
        updatedTab: selectedTab,
      });
    }
  };

  const resetTab = () => {
    const tab = { ...stateRef.current };
    const prevTabId = tab.tabListData[tab.selectedIndex].tabId;
    tab.tabListData[tab.selectedIndex].tabId = generate();
    delete tab.tabListData[tab.selectedIndex].subPages;
    delete tab.tabListData[tab.selectedIndex].currentIndex;
    setMyState(tab);
    messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
      type: "RESET_TAB",
      prevTabId: prevTabId,
      selectedTab: tab.tabListData[tab.selectedIndex],
    });
  };

  const updateTab = (tab) => {
    const existingTabData = stateRef.current.tabListData;
    const selectedIndex = stateRef.current.selectedIndex;
    const { _updatedIndex } = tabValidation(tab, existingTabData);
    tab.tabId = generate();
    if (_updatedIndex === -1) {
      existingTabData[selectedIndex] = tab;
      updateTabCount(existingTabData);
      setMyState({
        tabListData: existingTabData,
        selectedIndex: selectedIndex,
      });
      messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
        type: "SYNC_ADDRESS",
        tab: tab,
        selectedIndex: selectedIndex,
      });
    } else {
      existingTabData[_updatedIndex] = tab;
      setMyState({
        tabListData: existingTabData,
        selectedIndex: _updatedIndex,
      });
      messageBus.trigger(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, {
        type: "SYNC_ADDRESS",
        tab: tab,
        selectedIndex: _updatedIndex,
      });
    }
  };
  useEffect(() => {
    const _unsubscribeOpenTab = messageBus.on(OPEN_TAB_EVENT, handleOpenTab);
    const _unsubscribeCloseTab = messageBus.on(FORCE_CLOSE_TAB, forceCloseTab);
    const _unsubscribeAddPage = messageBus.on(ADD_PAGE, addPage);
    const _unsubscribeChangePage = messageBus.on(CHANGE_PAGE, changePage);
    const _unsubscribeResetTab = messageBus.on(RESET_TAB, resetTab);
    const _unsubscribeUpdateTab = messageBus.on(UPDATE_TAB, updateTab);
    redirectToPageInAddress();
    window.onhashchange = () => redirectToPageInAddress(true);
    return () => {
      _unsubscribeAddPage();
      _unsubscribeChangePage();
      _unsubscribeOpenTab();
      _unsubscribeCloseTab();
      _unsubscribeResetTab();
      _unsubscribeUpdateTab();
      window.onhashchange = undefined;
    };
  }, [forceCloseTab, handleOpenTab]);
  const unAvailableChange = (bool = false) => {
    setUnAvailableSection(bool);
    if (bool) {
      const unavailableSection = document.getElementById("unavailableSection");
      let height = 0;
      if (unavailableSection) {
        const boundingClientRect = unavailableSection.getBoundingClientRect();
        height = getProperty(boundingClientRect, "height", 0) + 4;
      }
      setUnAvailableHeight(height);
    } else {
      setUnAvailableHeight(0);
    }
  };
  const { selectedIndex, tabListData } = tabData;

  const Navigator = () => {
    return (
      <LeftNavigationCom
        handleLogoutUser={props.handleLogoutUser}
        name={props.name}
        email={props.email}
        error={props.error}
        unAvailableChange={unAvailableChange}
      />
    );
  };

  const MultiTabs = () => {
    return (
      <TopHeaderPanel
        tabListData={tabListData}
        selectedIndex={selectedIndex}
        handleOpenTab={handleOpenTab}
        blockTabsUpdateCallbkack={blockTabsUpdateCallbkack}
      />
    );
  };

  const navigationBar = !isIframe(window) ? (
    <Navigator />
  ) : (
    <Privilege privilege={Privileges.DISABLE_MAIN_MENU} hide>
      <Navigator />
    </Privilege>
  );
  const multiTabBar = !isIframe(window) ? (
    <MultiTabs />
  ) : (
    <Privilege privilege={Privileges.DISABLE_MULTI_TABBED_WINDOW} hide>
      <MultiTabs />
    </Privilege>
  );

  return (
    <VDSManager>
      <Global styles={GlobalStyles} />
      <BodyWrapper>
        <StyledGridForNav>
          {navigationBar}
          <ThemeProvider
            theme={
              (originalTheme,
              {
                unAvailableTimer: UnAvailableSection,
                unAvailableHeight: unAvailableHeight,
              })
            }
            unAvailableTimer={UnAvailableSection}
          >
            <RightSection id="RightSectionContainer">
              <main>
                <div role="region" aria-label="tab area">
                  <ColPaddingZero className="customTab">
                    <TabContextProvider>
                      {multiTabBar}
                      <TabWindowOverlay />
                      {tabListData instanceof Array && tabListData.length > 0 ? <></> : <WelcomePage />}
                    </TabContextProvider>
                  </ColPaddingZero>
                </div>
              </main>
            </RightSection>
          </ThemeProvider>
        </StyledGridForNav>
      </BodyWrapper>
    </VDSManager>
  );
}
