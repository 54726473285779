import { getPreferences, setPreferences } from "../services/userService/getPrivilege";

let dataTobeSaved = new Map();
let timer = null;

export function saveGridComponentState(state, prefix) {
  state.tableWidth = null;
  const sortFields = JSON.stringify(state.multiSortMeta);
  const pref = {
    userId: state.userId,
    prefix: prefix,
    preferences: [
      {
        preference: "rows",
        value: state.rows,
      },
      {
        preference: "sort",
        value: sortFields,
      },
      {
        preference: "selectedColumns",
        value: JSON.stringify(state.selectedColumns),
      },
      {
        preference: "columnOrder",
        value: JSON.stringify(state.columnOrder),
      },
      {
        preference: "columnWidths",
        value: state.columnWidths,
      },
    ],
  };
  dataTobeSaved.set(prefix, pref);
  if (timer === null) {
    timer = setTimeout(() => {
      dataTobeSaved.forEach((item) => setPreferences(item));
      dataTobeSaved = new Map();
      timer = null;
    }, 1000);
  }
}

export async function fetchGridComponentState(prefix) {
  let response = await getPreferences({ prefix: prefix });
  // let response = JSON.parse(window.localStorage.getItem("dt-state-demo-custom") ?? []);
  response = response?.data?.data;
  const tableParams = {};
  response?.map((item) => {
    const itemName = item.preference;
    if (itemName === "sort") {
      tableParams["multiSortMeta"] = JSON.parse(item.value);
    } else if (itemName === "rows") {
      tableParams[itemName] = Number.parseInt(item.value);
    } else if (itemName === "columnWidths") {
      tableParams[itemName] = item.value;
    } else {
      tableParams[itemName] = JSON.parse(item.value);
    }
  });
  return tableParams;
}
