import moment from "moment";
import { messageBus } from "../../../utility/messageBus";

export function handleAddPage(pageId, props = {}) {
  messageBus.trigger("addPage", { pageId, props });
}

export function handleChangePage({ index }) {
  messageBus.trigger("changePage", { index });
}

export function redirectToPrevPage() {
  messageBus.trigger("redirectToPrevPage");
}

export function switchTab(index, props = {}) {
  messageBus.trigger("switchTab", { index, props });
}

export const getInstallType = (installType) => {
  let value = installType ? installType.toLowerCase().trim() : "";
  return value && (value === "self install" || value === "cmi") ? "CMI" : value === "pro install" || value === "vmi" ? "VMI" : "";
};

export const TYPE_DROPDOWN_VALUES = [
  { description: "Hardware shipment", code: "ZHWS" },
  { description: "Hardware accessory shipment", code: "ZHWA" },
  { description: "Hardware shipment exchange", code: "ZZHS" },
  // { description: "Hardware misc", code: "ZHS1" },
  { description: "Accessory Shipment Exchange", code: "ZZAE" },
];
export const INDIVIDUAL_RETURN_TYPE_LIST = [
  { description: "Return Hardware", code: "ZZHR", requiredEsn: true },
  { description: "Return Accessories", code: "ZHAR", requiredEsn: false },
];

export const getFullName = (array, code) => {
  if (code) {
    let data = array.find((item) => item.code === code && item);
    return Object.keys(data).length && `${data.code} - ${data.description}`;
  }
  return null;
};

export const PROCESSING_TYPE_VALUES = [
  { description: "Return Hardware", code: "ZZHR" },
  { description: "Return Accessories", code: "ZHAR" },
];

export const DURATION = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

export const getSyncToMapData = (installerList) => {
  let data = installerList?.map((installer) => {
    return {
      status: installer?.status ?? "",
      installerId: installer?.installerId ?? "",
      installerName: installer?.installerName,
      installerPhone: installer?.phone,
      installerEmail: installer?.email,
      installerAddress1: installer?.address1,
      installerAddress2: installer?.address2,
      installerCity: installer?.city,
      installerState: installer?.state,
      installerZipCode: installer?.zipCode,
      installerCountry: installer?.country,
      tierRating: installer?.tierRating,
      lcv: installer?.lcv,
      hgv: installer?.hgv,
      immobilizer: installer?.immobilizer,
      businessPartnerName: installer?.businessPartnerName,
      technicianName: installer?.technicianName,
      shopOnly: installer?.shopOnly,
      garageOnly: installer?.garageOnly,
      cmi: installer?.cmi,
      vmi: installer?.vmi,
      legacyInstallerId: installer?.legacyInstallerId,
      createdAt: moment(new Date(installer?.createdAt)).format("YYYY-MM-DD HH:mm:ss.SSS"),
      modifiedAt: moment(new Date(installer?.modifiedAt)).format("YYYY-MM-DD HH:mm:ss.SSS"),
      comments: installer?.comments,
      locusId: installer?.locusId,
      government: installer?.government,
      latitude: installer?.latitude,
      longitude: installer?.longitude,
    };
  });
  return data;
};

export const getMapUrl = (mapUrl, latitude, longitude, customerNumber) => {
  let mapURL = `${mapUrl}/index.html`;
  if (latitude && longitude) {
    mapURL += `?marker=${longitude};${latitude};;phone%20309-377-3838;;${customerNumber}`;
  }
  return mapURL;
};
