import { useState, createContext } from "react";
export const C4SContext = createContext();
export const C4SProvider = (props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [notification, setNotification] = useState();
  const [cspDetails, setCspDetails] = useState({
    cspData: null,
    accountId: "",
    caseId: "",
    cspId: "",
    customerName: "",
    customerNumber: "",
    vztSegment: "",
    salesOrgName: "",
    salesOrgId: "",
    firstName: JSON.parse(sessionStorage.getItem("FirstName")) ?? "",
    lastName: JSON.parse(sessionStorage.getItem("LastName")) ?? "",
    erpPartnerNumber: "",
  });
  const [recordsUploaded, setRecordsUploaded] = useState({
    totalVTU: "",
    totalVIN: "",
  });
  const [source, setSource] = useState("");
  const [isSourceCSP, setIsSourceCSP] = useState(false);
  const [limitWTCreation, setIsLimitWTCreation] = useState(false);
  const [historicalAddressDetails, setHistoricalAddressDetails] = useState([]);
  const [techRefIdList, setTechRefIdList] = useState([]);
  const [isGovernmentAcc, setIsGovernmentAcc] = useState(false);
  const [workTicketInfo, setWorkTicketInfo] = useState({
    employeeId: "",
    customerPONumber: "",
    contractAccount: "",
    crmRefId: "",
    distributionChannel: "",
    division: "",
    erpPartnerContactNumber: "",
  });
  const [possuir, setPossuir] = useState();
  const [countryListDetails, setCountryListDetails] = useState(null);
  const [stateListDetails, setStateListDetails] = useState(null);
  const [quantityPayload, setQuantityValPayload] = useState(null);
  return (
    <C4SContext.Provider
      value={{
        isButtonDisabled: { isButtonDisabled, setIsButtonDisabled },
        cspDetails: { cspDetails, setCspDetails },
        notification: { notification, setNotification },
        recordsUploaded: { recordsUploaded, setRecordsUploaded },
        source: { source, setSource },
        isSourceCSP: { isSourceCSP, setIsSourceCSP },
        historicalAddressDetails: { historicalAddressDetails, setHistoricalAddressDetails },
        techRefIdList: { techRefIdList, setTechRefIdList },
        isGovernmentAcc: { isGovernmentAcc, setIsGovernmentAcc },
        workTicketInfo: { workTicketInfo, setWorkTicketInfo },
        limitWTCreation: { limitWTCreation, setIsLimitWTCreation },
        possuir: { possuir, setPossuir },
        countryListDetails: { countryListDetails, setCountryListDetails },
        stateListDetails: { stateListDetails, setStateListDetails },
        quantityPayload: { quantityPayload, setQuantityValPayload },
      }}
    >
      {props.children}
    </C4SContext.Provider>
  );
};
