import React, { useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import * as FileSaver from "file-saver";
import { MediumText } from "../../../components/core/Utility";
import searchIcon from "../../../assets/icons/search_blk.png";
import csvIcon from "../../../assets/icons/export-csv.png";
import xlsIcon from "../../../assets/icons/export-xls.png";
import { TableWrapper, GlobalFilterWrapper } from "./ReportTableStyle";
import moment from "moment";

export const COL_WIDTH = {
  xsmall: "8rem",
  small: "10rem",
  medium: "12rem",
  large: "14rem",
  xlarge: "16rem",
};

export default function ReportTable({ columnHeaders, rowData, fileName }) {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const dt = useRef(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const paginationTemplate = {
    layout: "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    RowsPerPageDropdown: (options) => {
      return (
        <>
          <MediumText primitive="span">Items per page: </MediumText>
          <Dropdown value={options.value} options={options.options} onChange={options.onChange} />
        </>
      );
    },
  };

  const exportExcel = (csv) => {
    import("xlsx").then((xlsx) => {
      const filename = fileName + "_" + moment().format("MM-DD-YYYY");
      const headers = [columnHeaders.map((col) => col.header)];
      const worksheet = xlsx.utils.json_to_sheet(rowData, { origin: "A2", skipHeader: true });
      xlsx.utils.sheet_add_aoa(worksheet, headers, { origin: "A1" });
      const workbook = { Sheets: { [filename]: worksheet }, SheetNames: [filename] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: csv ? "csv" : "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: csv ? "text/csv;charset=utf-8" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, filename, csv ? ".csv" : ".xlsx");
    });
  };

  const header = (
    <>
      <GlobalFilterWrapper>
        <input value={globalFilterValue} type="text" onChange={onGlobalFilterChange} placeholder="Search"></input>
        <img src={searchIcon} height="20px" width="20px"></img>
      </GlobalFilterWrapper>
      <div onClick={() => exportExcel(true)}>
        <img src={csvIcon}></img>
      </div>
      <div onClick={() => exportExcel(false)}>
        <img src={xlsIcon}></img>
      </div>
      {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
      <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" /> */}
      {/* <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
    </>
  );
  return (
    <TableWrapper>
      <DataTable
        id="report"
        reft={dt}
        header={header}
        value={rowData}
        showGridlines
        stripedRows
        scrollable
        scrollHeight="40rem"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
        paginatorTemplate={paginationTemplate}
        filters={filters}
        emptyMessage="No data available to display"
      >
        {columnHeaders.map((col, i) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            dataType={col.dataType}
            filter={col.isFilterEnabled}
            style={{ ...col.style }}
          />
        ))}
      </DataTable>
    </TableWrapper>
  );
}
