import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const validateAddress = async (payload) => {
  try {
    return await postRequest(urls.VALIDATE_ADDRESS, payload);
  } catch (error) {
    return error;
  }
};
