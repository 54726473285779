import * as urls from "../../api/C4S Decommission - Replacement/url";
import { getRequest } from "../../../services/RestServices";

export const workTicketList = async ({ accountId, cspId, caseId }) => {
  try {
    return await getRequest(urls.WORK_TICKET_TABLE_LIST + `/${accountId}?cspId=${cspId}&caseId=${caseId}`);
  } catch (err) {
    return err;
  }
};
