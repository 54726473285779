import * as urls from "../../api/C4S Decommission - Replacement/url";
import { postRequest } from "../../../services/RestServices";

export const workticketUpdate = async (payload) => {
  try {
    return await postRequest(urls.WORKTICKET_UPDATE, payload);
  } catch (err) {
    return err;
  }
};
