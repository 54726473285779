import * as urls from "../../api/scheduler/url";
import { getRequest } from "../../../services/RestServices";

export const getVendorsList = async () => {
  try {
    return await getRequest(urls.FETCH_VENDORS_LIST);
  } catch (err) {
    return err;
  }
};
