import React, { useContext } from "react";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { SmallText, FlexRow } from "../../components/core/Utility";

const CustomerDetails = () => {
  const { accountDetails } = useContext(AccountDetailsContext);
  return (
    <div css={{ marginTop: "1.25rem" }}>
      <FlexRow tabIndex="0">
        <SmallText css={{ marginBottom: "0" }} bold>
          Customer Name: &nbsp;
        </SmallText>
        <SmallText css={{ marginBottom: "0" }} fontWeight="normal">
          {accountDetails.customerName}
        </SmallText>
      </FlexRow>
      <FlexRow tabIndex="0">
        <SmallText css={{ marginBottom: "0" }} bold>
          Customer Account: &nbsp;
        </SmallText>
        <SmallText css={{ marginBottom: "0" }} fontWeight="normal">
          {accountDetails.customerNumber}
        </SmallText>
      </FlexRow>
      <FlexRow tabIndex="0">
        <SmallText css={{ marginBottom: "0" }} bold>
          VZT Segment: &nbsp;
        </SmallText>
        <SmallText css={{ marginBottom: "0" }} fontWeight="normal">
          {accountDetails.vztSegment}
        </SmallText>
      </FlexRow>
    </div>
  );
};
export default CustomerDetails;
