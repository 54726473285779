let privileges = new Set();

export function setPrivilegesHelper(list) {
  privileges = new Set();
  list.forEach(privileges.add, privileges);
  return privileges;
}

export function hasPrivilege(privilege, privilegesStore = privileges) {
  if (Array.isArray(privilege)) {
    for (let item of privilege) {
      if (privilegesStore.has(item)) return true;
    }
  } else {
    if (privilegesStore.has(privilege)) return true;
  }
  return false;
}
