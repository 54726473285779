import { getRequest, postRequest, putRequest } from "../RestServices";
import * as urls from "../../api/VTU/url";
import * as connector from "../../api/sfdcConnector";
export const checkStatus = async (payload) => {
  try {
    return await getRequest(`${urls.CHECKSTATUS}/${payload}`);
  } catch (err) {
    return err;
  }
};

export const swapSim = async (payload) => {
  try {
    return await getRequest(`${urls.SWAPSIM}/${payload}`);
  } catch (err) {
    return err;
  }
};

export const getByESN = async (payload) => {
  payload = { esn: payload };
  try {
    return await postRequest(urls.GETBYESN, payload);
  } catch (err) {
    return err;
  }
};
export const getESNsByAccount = async (payload) => {
  try {
    return await postRequest(urls.GETESNSBYACCOUNT, payload);
  } catch (err) {
    return err;
  }
};

export const getDetailsByESN = async (payload) => {
  try {
    return await postRequest(`${urls.GETDETAILSBYESN}/${payload}`);
  } catch (err) {
    return err;
  }
};

export const swapSimByESN = async (payload) => {
  try {
    return await putRequest(urls.SIMSWAP, payload);
  } catch (err) {
    return err;
  }
};

export const fullboxswap = async (payload) => {
  try {
    return await postRequest(urls.FULLBOXSWAP, payload);
  } catch (err) {
    return err;
  }
};

export const esnsWithAccount = async (payload) => {
  try {
    return await postRequest(urls.ESNWITHACCOUNT, payload);
  } catch (err) {
    return err;
  }
};
export const detailsForAccount = async (payload) => {
  try {
    return await postRequest(urls.DETAILSFORACCOUNT, payload);
  } catch (err) {
    return err;
  }
};

export const salesforceCases = async (payload) => {
  try {
    return await postRequest(`${connector.GETSALESFORCECASES}/${payload}`);
  } catch (err) {
    return err;
  }
};

export const vtuLocalSwap = async (oldESN, newESN) => {
  try {
    return await getRequest(`${urls.LOCALSWAP}/${oldESN}/${newESN}`);
  } catch (err) {
    return err;
  }
};
