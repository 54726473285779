import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const UNIT_DATA_CONSTANT = [
  {
    key: "Time",
    value: "Time",
    filterType: "Date",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    maxWidth: COLUMN_WIDTHS.small,
    dateFormat: "MM/DD/YYYY, HH:MM A",
    isDate: true,
  },
  {
    key: "User",
    value: "User",
    filterType: "Status",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isUser: true,
    isList: false,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    maxWidth: COLUMN_WIDTHS.medium,
  },
  {
    key: "Method",
    value: "Method",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    maxWidth: COLUMN_WIDTHS.small,
  },
  {
    key: "Changed Fields",
    value: "FieldNameWithChangeList",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isList: false,
    isWrap: true,
    itemToDisplayInList: "changeField",
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },
];
