import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const addJob = async (payload) => {
  try {
    return await postRequest(urls.ADD_JOB, payload);
  } catch (err) {
    return err;
  }
};
