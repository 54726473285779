import { Notification } from "@vds/notifications";
import get from "lodash/get";
import { generate } from "shortid";
import styled from "@emotion/styled";

const NotificationWrapper = styled(Notification)`
  & div {
    justify-content: flex-start;
    max-width: 100%;
    & span:first-child {
      flex: 8 1 0%;
    }
  }
`;

/**
 * @description Checks if a property is present inside an object
 * If not, returns the default value.
 * @param {*} obj
 * @param {*} property
 * @param {*} defaultValue
 */
export const getProperty = (obj = {}, property = "dummy", defaultValue = null) => {
  return get(obj, property, defaultValue);
};

export const notify = (content, type, setNotification) => {
  setNotification(<NotificationWrapper key={generate()} type={type} title={content} fullBleed={true} />);
};

export const getLabelFromProperty = (name) => {
  const separatedWords = name.replace(/([a-z])([A-Z])/g, "$1 $2");
  return separatedWords[0].toUpperCase() + separatedWords.substring(1);
};
