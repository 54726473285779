import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const SAVE_HARDWARE_CASE_COL = [
  {
    key: "MMID",
    value: "materialId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "16%",
  },
  {
    key: "Description",
    value: "description",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "42%",
    style: { whiteSpace: "break-spaces" },
  },
  {
    key: "Ordered Quantity",
    value: "orderQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "21%",
  },
  {
    key: "Charged Amount",
    value: "chargeAmtCurrency",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "21%",
  },
];
