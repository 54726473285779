import React from "react";
import { SmallText } from "../../components/core/utility";
import styled from "@emotion/styled";
import { Table, TableBody, TableRow, Cell, TableHeader, TableHead } from "@vds/tables";
import { css } from "@emotion/react";

export const CustomisedTable = styled(Table)`
  border-top: unset;
  font-family: Verizon-NHG-eTX;
  margin-top: 2rem;
  margin-bottom: 0rem;
  & thead {
    border: 1px solid #d6d2d2;
    & tr {
      & th {
        padding: unset;
        height: 1.85rem;
        padding: 4px 14px 2px 14px;
        border-right: 1px solid #d6d2d2;
      }
    }
  }
  & tbody {
    border: 1px solid #d6d2d2;
    & tr {
      & td {
        padding: unset;
        height: 1.85rem;
        padding: 4px 14px 2px 14px;
        border-right: 1px solid #d6d2d2;
    }
  }
`;
export const CustomSmallText = styled(SmallText)`
  white-space: nowrap;
`;
export const customTableHeader = css`
  @media screen and (max-width: 1024px) {
    // #col1 {
    //   width: 10%;
    // }
    #col2 {
      width: 20%;
      padding-left: 8px;
    }
    #col3 {
      width: 24%;
      padding-left: 8px;
    }
    #col4 {
      width: 23%;
      padding-left: 8px;
    }
    #col5 {
      width: 19%;
      padding-left: 8px;
    }
  }
`;

export const DocumentFlowTable = ({ data }) => {
  return (
    <CustomisedTable css={customTableHeader} role="table">
      <TableHead role="row">
        <TableHeader role="columnheader" id="col2">
          <CustomSmallText bold>SO (Sales Order)</CustomSmallText>
        </TableHeader>
        <TableHeader role="columnheader" id="col3">
          <CustomSmallText bold>DN (Delivery Number)</CustomSmallText>
        </TableHeader>
        <TableHeader role="columnheader" id="col4">
          <CustomSmallText bold>PO (Purchase Order)</CustomSmallText>
        </TableHeader>
        <TableHeader role="columnheader" id="col5">
          <CustomSmallText bold>Return Delivery</CustomSmallText>
        </TableHeader>
      </TableHead>
      <TableBody role="rowgroup">
        <TableRow role="row">
          <Cell role="cell" id="col2">
            <CustomSmallText>{data.salesOrder}</CustomSmallText>
          </Cell>
          <Cell role="cell" id="col3">
            <CustomSmallText>{data.deliveryDocNumber}</CustomSmallText>
          </Cell>
          <Cell role="cell" id="col4">
            <CustomSmallText>{data?.x_purchaseOrder}</CustomSmallText>
            {/* {data?.purchaseOrder.map((purchaseOrder, index) => (
              <CustomSmallText key={index}>{purchaseOrder}</CustomSmallText>
            ))} */}
          </Cell>
          <Cell role="cell" id="col5">
            {/* {data?.returnDelivery.map((returnDelivery, index) => (
              <CustomSmallText key={index}>{returnDelivery}</CustomSmallText>
            ))} */}
          </Cell>
        </TableRow>
      </TableBody>
    </CustomisedTable>
  );
};

export default DocumentFlowTable;
