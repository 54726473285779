import EventEmitter from "eventemitter3";

/**
 * @description Used for communication between different entities.
 */
export class MessageBus {
  //Emitter instance.
  #emitter = new EventEmitter();

  /**
   * @description Constructor function.
   */
  constructor() {
    //This is intentional
  }

  /**
   * @description Starts listening to event.
   * @usage messageBus.on( SOME_EVENT, handlerFunction );
   * @param {*} event Event name.
   * @param {*} listener Handler function.
   * @param {*} context Execution context.
   */
  on(event, listener, context) {
    this.#emitter.on(event, listener, context);
    return () => {
      this.off(event, listener, context);
    };
  }

  /**
   * @description Fires an event.
   * @usage messageBus.trigger( SOME_EVENT, { } );
   * @param {*} event Event name.
   * @param {*} payload Event data.
   */
  trigger(event, payload) {
    this.#emitter.emit(event, payload);
  }

  /**
   * @description Listens to event only once.
   * @usage messageBus.once( SOME_EVENT, handlerFunction );
   * @param {*} event Event name.
   * @param {*} listener Event listener.
   * @param {*} context Execution context.
   */
  once(event, listener, context) {
    this.#emitter.once(event, listener, context);
    return () => {
      this.off(event, listener, context);
    };
  }

  /**
   * @description Unsubscribe to the event.
   * @usage messageBus.off( SOME_EVENT, handlerFunction );
   * @param {*} event Event name.
   * @param {*} listener Event handler.
   * @param {*} context Execution context.
   */
  off(event, listener, context) {
    this.#emitter.removeListener(event, listener, context);
  }
}

/**
 * @usage import { messageBus } from '@messageBus';
 * @subscribe const x = messageBus.on( EVENT_NAME, handlerFunction );
 * @unsubscribe x();
 * @trigger messageBus.trigger( EVENT_NAME, { data: {} } );
 */
export const messageBus = new MessageBus();
