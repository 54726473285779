import React from "react";
import { SmallText } from "../../components/core/utility";
import styled from "@emotion/styled";
import { Table, TableBody, TableRow, Cell, TableHeader, TableHead } from "@vds/tables";

export const CustomisedTable = styled(Table)`
  border-top: unset;
  font-family: Verizon-NHG-eTX;
  margin-top: 2rem;
  margin-bottom: 0rem;
  width:90%;
  & thead {
    border: 0.0625rem solid #d8dada;
    & tr {
      & th {
        padding: unset;
        height: 1.85rem;
        padding: 4px 2px 2px 14px;
        border-right: 1px solid #d6d2d2;
       
      }
    }
  }
  & tbody {
    border: 0.0625rem solid #d8dada;
    & tr {
      & td {
        padding: unset;
        height: 1.85rem;
        padding: 4px 2px 2px 14px;
        border-right: 1px solid #d6d2d2;
    }
  }
`;

export const MMIDLevelDocumentTable = ({ data }) => {
  return (
    <CustomisedTable topLine="none" role="table">
      <TableHead role="row">
        <TableHeader role="columnheader" width="14.5%">
          <SmallText bold>Pending</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="16.5%">
          <SmallText bold>Ordered</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="15.5%">
          <SmallText bold>Shipped</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="15.5%">
          <SmallText bold>Scheduled</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="15.5%">
          <SmallText bold>Unscheduled</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="15.5%">
          <SmallText bold>Installed</SmallText>
        </TableHeader>
      </TableHead>
      <TableBody role="rowgroup">
        <TableRow role="row">
          <Cell role="cell">
            {data?.pending.map((pending, index) => (
              <SmallText key={index}>{pending}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.ordered.map((ordered, index) => (
              <SmallText key={index}>{ordered}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.shipped.map((shipped, index) => (
              <SmallText key={index}>{shipped}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.scheduled.map((scheduled, index) => (
              <SmallText key={index}>{scheduled}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.unscheduled.map((unscheduled, index) => (
              <SmallText key={index}>{unscheduled}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.installed.map((installed, index) => (
              <SmallText key={index}>{installed}</SmallText>
            ))}
          </Cell>
        </TableRow>
      </TableBody>
      <TableHead role="row">
        <TableHeader role="columnheader" width="14.5%">
          <SmallText bold>Remaining</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="16.5%">
          <SmallText bold>Self-Installed</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="15.5%">
          <SmallText bold>Shipped Accessories</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="15.5%">
          <SmallText bold>Return Hardware</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="15.5%">
          <SmallText bold>Return Accessory</SmallText>
        </TableHeader>
        <TableHeader role="columnheader" width="15.5%">
          <SmallText bold></SmallText>
        </TableHeader>
      </TableHead>
      <TableBody role="rowgroup">
        <TableRow role="row">
          <Cell role="cell">
            {data?.remaining.map((remaining, index) => (
              <SmallText key={index}>{remaining}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.selfInstalled.map((selfInstalled, index) => (
              <SmallText key={index}>{selfInstalled}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.shippedAccessories.map((shippedAccessories, index) => (
              <SmallText key={index}>{shippedAccessories}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.returnHardware.map((returnHardware, index) => (
              <SmallText key={index}>{returnHardware}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            {data?.returnAccessory.map((returnAccessory, index) => (
              <SmallText key={index}>{returnAccessory}</SmallText>
            ))}
          </Cell>
          <Cell role="cell">
            <SmallText></SmallText>
          </Cell>
        </TableRow>
      </TableBody>
    </CustomisedTable>
  );
};

export default MMIDLevelDocumentTable;
