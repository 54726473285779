import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Col } from "@vds/grids";

import { Table } from "@vds/tables";

export const GlobalStyles = css`
  body {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    p {
      color: ${({ theme }) => theme.text} !important;
    }

    Body {
      color: ${({ theme }) => theme.text};
    }
    button[type="primary"] {
      background-color: ${({ theme }) => theme.text};
      border: 0.0625rem solid ${({ theme }) => theme.text};
      color: ${({ theme }) => theme.background};
    }
    button[type="secondary"] {
      border: 0.0625rem solid ${({ theme }) => theme.text};
      color: ${({ theme }) => theme.text};
      background-color: transparent;
    }
    h5,
    h4,
    h1,
    h3,
    h2,
    input,
    h5 span {
      color: ${({ theme }) => theme.text} !important;
      caret-color: ${({ theme }) => theme.text} !important;
    }
    button[type="primary"][disabled] {
      background-color: rgb(216, 218, 218);
      border: 0.0625rem solid rgb(216, 218, 218);
      opacity: 0.5;
    }
    button[type="secondary"][disabled] {
      color: rgb(216, 218, 218);
      border: 0.0625rem solid rgb(216, 218, 218);
      opacity: 0.5;
    }
    h4 {
      color: ${({ theme }) => theme.text} !important;
    }
    svg:not([aria-label="checkmark icon"]) > g > path:not([stroke="white"], [class="st0"]) {
      color: ${({ theme }) => theme.text};
    }
    div[role="radiogroup"] > label > div > div:not(:first-child) > div {
      background-color: ${({ theme }) => theme.text} !important;
    }
    div[role="radiogroup"] > label > div {
      color: ${({ theme }) => theme.text};
    }
    input[type="radio"] + .radio-label:before {
      background: ${({ theme }) => theme.background} !important;
    }
    label[disabled] span {
      color: #d8dada;
    }
    label > .styledInner {
      border: 1px solid ${({ theme }) => theme.text};
      > svg > g > path {
        stroke: ${({ theme }) => theme.text};
      }
    }
    label > span {
      color: ${({ theme }) => theme.text};
    }
    // Datepicker
    .p-calendar.p-inputwrapper {
      width: 100%;
      /* display: inline-block; */
    }
    input.p-inputtext {
      left: auto;
      border-left: solid;
    }
    div.p-column-filter-buttonbar > button.p-button {
      position: relative;
    }
    div.p-column-filter-add-rule > button.p-button {
      position: relative;
      display: inline-flex;
      justify-content: center;
      margin-top: -10px;
    }
    div.p-column-filter-constraint > div {
      button {
        height: 28px;
        display: contents;
      }
      /* height: 28px; */
    }
    div > div.p-column-filter-add-rule > {
      margin-top: -40px;
    }
    div.p-dropdown > span {
      height: 28px;
      left: 0px;
      margin-top: 0px;
    }
    .p-inputtext {
      height: 28px;
      border-left: 0;
      margin-top: -2px;
      border-radius: 0;
      border-bottom: 1px solid ${({ theme }) => theme.text};
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.background};
      width: calc(100% - 38px);
      position: relative;
      left: 38px;
      font-size: 12px;
    }
    .p-button,
    /* .p-button:enabled:hover, */
    .p-button.p-datepicker-trigger {
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.text};
      border: 1px solid lightgrey;
      border-color: lightgrey;
      border-right: 0;
      border-bottom: 1px solid ${({ theme }) => theme.text};
      border-radius: 0;
      box-shadow: none;
      height: 28px;
      margin-top: -2px;
      position: absolute;
      left: 0;
      width: 38px;
    }
    .p-inputtext:enabled:hover,
    .p-inputtext:enabled:focus {
      border-color: lightgrey !important;
      border-bottom-color: ${({ theme }) => theme.text} !important;
      box-shadow: none !important;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-title {
      color: ${({ theme }) => theme.text};
      font-size: 14px;
    }
    .p-datepicker .p-datepicker-header {
      border-bottom: none;
      padding-bottom: 0 !important;
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.background};
    }
    .p-datepicker table th {
      padding: 0px !important;
      color: #717070 !important;
      font-size: 14px !important;
    }
    .p-datepicker table td {
      padding: 0 !important;
      font-size: 12px;
      font-weight: bold;
      color: ${({ theme }) => theme.text};
    }
    .p-calendar .p-datepicker {
      width: 300px;
      @media (max-width: 1350px) {
        width: 275px;
        padding: 0;
      }
    }
    .p-datepicker table td.p-datepicker-today > span,
    .p-datepicker table td.p-datepicker-today > span.p-highlight {
      color: white;
      background: black;
      border-radius: 0;
    }
    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      background: ${({ theme }) => theme.background};
    }
    .p-datepicker table td > span.p-highlight,
    p-datepicker table td.p-datepicker-today > span.p-highlight {
      background: ${({ theme }) => theme.text};
      color: ${({ theme }) => theme.background};
      border-radius: 0;
    }
    .p-datepicker:not(.p-datepicker-inline) {
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.background};
      border-radius: 0;
      // top: ${({ position }) => (position === "top" ? "0" : "40px !important")};
    }
    .pi-calendar {
      width: 18px;
    }
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.text};
    }
    .pi-calendar:before {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><g id="Layer_2" data-name="Layer 2" style="fill : ${({
        theme,
      }) => {
        if (theme.name === "dark") return "lightgrey";
        else return "black";
      }}"><g id="Layer_1-2" data-name="Layer 1"><path d="M5.7,5.46c.85,0,1.54.47,1.54,1.25S6.68,7.93,5.57,7.93H5.14V8.6h.44c1.19,0,1.87.47,1.87,1.37s-.7,1.49-1.68,1.49A1.72,1.72,0,0,1,3.84,9.69H3a2.54,2.54,0,0,0,2.82,2.5c1.44,0,2.55-.78,2.55-2.13A1.72,1.72,0,0,0,6.92,8.25v0A1.52,1.52,0,0,0,8.09,6.73c0-1.23-1-1.95-2.36-1.95A2.27,2.27,0,0,0,3.22,7.11h.84A1.54,1.54,0,0,1,5.7,5.46Z"/><path d="M10.83,12h.87V4.91H11c-.13,1-.88,1.36-1.75,1.38v.58h1.55Z"/><path d="M0,0V15H15V0ZM13.88,13.88H1.12V3.26H13.88ZM1.12,2.14v-1H13.88v1Z"/></g></g></svg>');
    }
    .p-contextmenu {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.background1};
      position: fixed;
      width: 146px;
      font-size: 12px;
      display: block !important;
    }
    .p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
      background: ${({ theme }) => theme.background6};
    }
    .p-contextmenu .p-menuitem-link {
      padding: 0.25rem 1.5rem;
      font-size: 12px;
      .p-menuitem-text {
        color: ${({ theme }) => theme.text} !important;
      }
    }
    .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background: ${({ theme }) => theme.background6};
    }
    .p-overlaypanel {
      .p-overlay-content {
        font-family: Verizon-NHG-eTX;
        font-size: 12px;
      }
      ::before {
        display: none !important;
      }
      ::after {
        display: none !important;
      }
    }
  }
`;
const lightThemeConfig = {
  name: "light",
  text: "#000",
  textAlternative: "#747676",
  background: "#FFF",
  background1: "#F6F6F6",
  background2: "#F6F6F6",
  background3: "#FFF",
  background4: "rgba(0, 0, 0, 0.8);",
  background5: "#f8f9fa",
  background6: "#e9ecef",
  background7: "rgba(238, 238, 238, 0.95)",
  background8: "#f2f2f2",
  background9: "#d8dada",
  border: "#d8dada",
  border1: "#000",
  border2: "#d8dada",
  border3: "#000",
  border4: "#979797",
  border5: "#e9ecef",
  border6: "grey",
  border7: "#d8dada",
  border8: "#d8dada",
  border9: "#747676",
};
const darkThemeConfig = {
  name: "dark",
  text: "#D8DADA",
  textAlternative: "#D8DADA",
  background: "#1F1F1F",
  background1: "#333333",
  background2: "#1F1F1F",
  background3: "#1F1F1F",
  background4: "rgba(256, 256, 256, 0.6);",
  background5: "#1F1F1F",
  background6: "#333333",
  background7: "#1F1F1F",
  background8: "#434343",
  background9: "#5C5C5C",
  border: "#666666",
  border1: "#666666",
  border2: "#000",
  border3: "rgba(255,255,255,0.14)",
  border4: "#000",
  border5: "#1F1F1F",
  border6: "#3e3e3e",
  border7: "#d8dada",
  border8: "transparent",
  border9: "#d8dada",
};

/**Do not change/delete the existing Object key names.
 *New Object keys can be added*/
export const themes = {
  lightTheme: lightThemeConfig,
  darkTheme: darkThemeConfig,
};

export const StyledGrid = styled(Grid)`
  width: 100%;
  max-width: 100%;
  margin: 0;
  height: auto;
  background: ${({ theme }) => theme.background};
`;
export const StyledGridWrapper = styled.div`
  width: 100%;
  > div > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: auto;
    background: ${({ theme }) => theme.background};
    > div > div {
      max-width: 100%;
      width: 100%;
      background: ${({ theme }) => theme.background};
    }
  }
`;

export const StyledGridWrapper1 = styled.div`
  padding-bottom: 12px;
  > div > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: auto;
    background: ${({ theme }) => theme.background};
    > div > div {
      max-width: 100%;
      width: 100%;
      background: ${({ theme }) => theme.background};
    }
  }
`;

export const StyledGridForNav = styled.div`
  width: 100%;
  max-width: 100%;
  margin-right: 55px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ColWithoutPadding = styled(Col)`
  overflow-wrap: anywhere;
  padding-right: 0rem;
  padding-left: 0rem;
`;

export const FloatRight = styled.div`
  float: right;
`;

export const StyledTable = styled(Table)`
  border-color: ${({ theme }) => theme.text};
  th,
  td {
    color: ${({ theme }) => theme.text};
  }
`;
