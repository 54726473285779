import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import Icon from "@vds/icons";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexRow, SmallText } from "../../components/core/utility";
import { C4SContext } from "../../context/c4sContext";
import { Notification } from "@vds/notifications";
import { generate } from "shortid";
import { Loader } from "@vds/loaders";
import { deleteVehicle } from "../../services/C4S Decommission - Replacement/deleteVehicle";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { handleAddPage, redirectToPrevPage } from "./utils";
import { VehicleListTableMetadata } from "./VehicleListTable";
import useOutsideClick from "../../customHook/use-outside-click";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

const DeleteVehicle = ({ deleteVINModal, setDeleteVINModal, selectedVINs, vehicleIntakeList, setLoader }) => {
  const { cspDetails, notification, recordsUploaded } = useContext(C4SContext);
  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setDeleteVINModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const handleDeleteVIN = async () => {
    updateNewrelicStats();
    setLoader(true);
    let VINId = selectedVINs.map((VINs) => VINs.id);
    let response = await deleteVehicle({
      modifiedBy: `${cspDetails.cspDetails.firstName} ${cspDetails.cspDetails.lastName}`,
      id: VINId,
    });

    if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
      notification.setNotification(null);

      if (vehicleIntakeList.length === selectedVINs.length) {
        redirectToPrevPage();
      } else {
        handleAddPage(VehicleListTableMetadata.id);
      }
      setLoader(false);
      setDeleteVINModal(false);
    } else {
      notification.setNotification(
        <Notification key={generate()} type="error" title="We are currently experiencing issues, please try again" fullBleed={true} ref={clickRef} />
      );
      setLoader(false);
      setDeleteVINModal(false);
    }
  };

  return (
    <>
      <Modal
        role="dialog"
        ariaLabel="Delete Vehicle"
        hideCloseButton={false}
        opened={deleteVINModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div>
              <Title size="medium">Delete Vehicle</Title>
            </div>
            <div
              onClick={() => {
                setDeleteVINModal(false);
              }}
              style={{ marginRight: "1rem", marginTop: "0.3rem" }}
            >
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ paddingLeft: "0", paddingRight: "0", marginTop: "0.5rem" }}>
          <SmallText>
            Are you sure you would like to delete {selectedVINs.length > 1 ? selectedVINs.length + " vehicles?" : selectedVINs.length + " vehicle?"}
          </SmallText>
        </ModalBody>
        <ModalFooter>
          <FlexRow style={{ paddingTop: "2rem" }}>
            <CustomSLButton
              primary
              aria-label="Delete"
              role="button"
              onClick={() => {
                handleDeleteVIN();
              }}
            >
              Delete
            </CustomSLButton>
            <CustomSLButton
              secondary
              aria-label="Cancel"
              role="button"
              onClick={() => {
                setDeleteVINModal(false);
              }}
            >
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default DeleteVehicle;
