import { postRequestFile } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const uploadCsv = async (data, progress) => {
  try {
    return await postRequestFile(urls.UPLOAD_CSV, data, progress);
  } catch (err) {
    return err;
  }
};
