import { COLUMN_WIDTHS } from "../../constants/ApplicationConstants";

export const SHIPMENT_EVENTS_COLUMNS= [
  {
    key: "esn",
    value: "esn",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "SHIPMENT_RECEIVED",
    value: "SHIPMENT_RECEIVED",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "DECOMM_SHIPMENT_RECEIVED",
    value: "DECOMM_SHIPMENT_RECEIVED",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "RETURN_SHIPMENT_RECEIVED",
    value: "RETURN_SHIPMENT_RECEIVED",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "technical RefId",
    value: "technicalRefId",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "CreateVehicleSuccess",
    value: "CreateVehicleSuccess",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Decommission in Reveal",
    value: "DecommissionVehicleSuccess",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Decomission in SAP",
    value: "decomSAPSuccess",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "errorMessage",
    value: "errorMessage",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Device Type",
    value: "deviceType",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  }
];
