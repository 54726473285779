import { COLUMN_WIDTHS, CUSTOM_FILTER_TYPE } from "./ApplicationConstants";

export const VEHICLE_INTAKE_COL = [
  {
    key: "",
    value: "none",
    containsImage: true,
    style: { minWidth: "25px", maxWidth: "25px", visibility: "hidden" },
    ariaLabel: "Error column",
  },
  {
    key: "VIN",
    value: "vin",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    // width: COLUMN_WIDTHS.small,
    width: "165px",
  },
  {
    key: "YMM",
    value: "ymm",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Install Type",
    value: "installType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Sales Org",
    value: "salesOrg",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Shipping Address",
    value: "shippingAddress",
    isFilterEnabled: true,
    filterType: "String",
    customFilterType: CUSTOM_FILTER_TYPE.listBox,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    ellipsis: true,
    width: COLUMN_WIDTHS.large,
    // width: "350px",
  },
];

export const HARDWARE_LIST_COL = [
  {
    key: "",
    value: "none",
    containsImage: true,
    style: { minWidth: "25px", maxWidth: "25px", visibility: "hidden" },
    ariaLabel: "Error column",
  },
  {
    key: "Shipping Address",
    value: "shippingAddress",
    isFilterEnabled: true,
    customFilterType: CUSTOM_FILTER_TYPE.listBox,
    filterType: "String",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    ellipsis: true,
    width: "420px",
  },
  {
    key: "Install Type",
    value: "installType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Sales Org",
    value: "salesOrg",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.xmedium,
  },
];

export const HARDWARE_LIST_FLYOUT_MENU_COL = [
  {
    key: "MMID",
    value: "materialId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "100px",
  },
  {
    key: "Item Description",
    value: "itemDesc",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Type",
    value: "displayType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "150px",
  },
  {
    key: "Tech Ref ID",
    value: "techRefId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.lsmall,
  },
  {
    key: "Order Type",
    value: "orderType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Quantity",
    value: "orderQty",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
];

export const VEHICLE_LIST_TABLE_FEATURE = [
  {
    key: "MMID",
    value: "materialId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "100px",
  },
  {
    key: "Item Description",
    value: "materialName",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Type",
    value: "vmfType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "150px",
  },
  {
    key: "Tech Ref ID",
    value: "techRefId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.lsmall,
  },
  {
    key: "Order Type",
    value: "orderType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
];
