import React from "react";
import styled from "@emotion/styled";
import { Table, TableBody, TableRow, Cell, TableHeader, TableHead } from "@vds/tables";
import { SmallText } from "../../components/core/utility";

export const CustomisedTable = styled(Table)`
  border-top: unset;
  font-family: Verizon-NHG-eTX;
  margin-top: 2rem;
  margin-bottom: 0rem;
  table-layout: fixed;
  & thead {
    border: 1px solid #d6d2d2;
    & tr {
      & th {
        padding: unset;
        height: 1.85rem;
        padding: 4px 14px 2px 14px;
        border-right: 1px solid #d6d2d2;
      }
    }
  }
  & tbody {
    border: 1px solid #d6d2d2;
    & tr {
      & td {
        padding: unset;
        height: 1.85rem;
        padding: 4px 14px 2px 14px;
        border-right: 1px solid #d6d2d2;
      }
    }
  }
`;
export const CustomSmallText = styled(SmallText)`
  white-space: nowrap;
  text-wrap: wrap;
`;

export const MigratedSchedulingData = ({ data }) => {
  return (
    <>
      <div tabIndex={0}>
        <CustomisedTable role="table" tabIndux={0}>
          <TableHead role="row" tabIndux={0}>
            <TableHeader tabIndux={0} role="columnheader" id="x1ErpSalesRepId">
              <CustomSmallText tabIndux={0} bold>
                Erp Sales Rep ID
              </CustomSmallText>
            </TableHeader>
            <TableHeader role="columnheader" id="xcontactMobile">
              <CustomSmallText bold>Contact Mobile</CustomSmallText>
            </TableHeader>
            <TableHeader role="columnheader" id="xschedulingNotes">
              <CustomSmallText bold>Scheduling Notes</CustomSmallText>
            </TableHeader>
            <TableHeader role="columnheader" id="xrequestStartDateTime">
              <CustomSmallText bold>Request Start Date Time</CustomSmallText>
            </TableHeader>
            <TableHeader role="columnheader" id="xnotesToTechnician">
              <CustomSmallText bold>Notes To Technician</CustomSmallText>
            </TableHeader>
          </TableHead>
          <TableBody role="rowgroup" tabIndux={0}>
            <TableRow role="row">
              <Cell role="cell" id="x1ErpSalesRepId">
                <SmallText aria-label={data.x1ErpSalesRepId}>{data.x1ErpSalesRepId}</SmallText>
              </Cell>
              <Cell role="cell" id="xcontactMobile">
                <SmallText aria-label={data.xcontactMobile}>{data.xcontactMobile}</SmallText>
              </Cell>
              <Cell role="cell" id="xschedulingNotes">
                <SmallText aria-label={data.xschedulingNotes}>{data.xschedulingNotes}</SmallText>
              </Cell>
              <Cell role="cell" id="xrequestStartDateTime">
                <SmallText aria-label={data.xrequestStartDateTime}>{data.xrequestStartDateTime}</SmallText>
              </Cell>
              <Cell role="cell" id="xnotesToTechnician">
                <SmallText aria-label={data.xnotesToTechnician}>{data.xnotesToTechnician}</SmallText>
              </Cell>
            </TableRow>
          </TableBody>
        </CustomisedTable>
      </div>
      <div tabIndex={0}>
        <CustomisedTable role="table">
          <TableHead role="row">
            <TableHeader role="columnheader" id="xrequestEndDateTime">
              <CustomSmallText bold>Request End Date Time</CustomSmallText>
            </TableHeader>
            <TableHeader role="columnheader" id="xvendorMessage">
              <CustomSmallText bold>Vendor Message</CustomSmallText>
            </TableHeader>
            <TableHeader role="columnheader" id="xvendorId">
              <CustomSmallText bold>Vendor ID</CustomSmallText>
            </TableHeader>
            <TableHeader role="columnheader" id="xfieldNotes">
              <CustomSmallText bold>Field Notes</CustomSmallText>
            </TableHeader>
            <TableHeader role="columnheader" id="xc4sObjectId">
              <CustomSmallText bold>C4S Object ID</CustomSmallText>
            </TableHeader>
          </TableHead>
          <TableBody role="rowgroup">
            <TableRow role="row">
              <Cell role="cell" id="xrequestEndDateTime">
                <SmallText aria-label={data.xrequestEndDateTime}>{data.xrequestEndDateTime}</SmallText>
              </Cell>
              <Cell role="cell" id="xvendorMessage">
                <SmallText aria-label={data.xvendorMessage}>{data.xvendorMessage}</SmallText>
              </Cell>
              <Cell role="cell" id="xvendorId">
                <SmallText aria-label={data.xvendorId}>{data.xvendorId}</SmallText>
              </Cell>
              <Cell role="cell" id="xfieldNotes">
                <SmallText aria-label={data.xfieldNotes}>{data.xfieldNotes}</SmallText>
              </Cell>
              <Cell role="cell" id="xc4sObjectId">
                <SmallText aria-label={data.xc4sObjectId}>{data.xc4sObjectId}</SmallText>
              </Cell>
            </TableRow>
          </TableBody>
        </CustomisedTable>
      </div>
    </>
  );
};

export default MigratedSchedulingData;
