import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getFeatureList = async (type) => {
  try {
    return await getRequest(urls.GET_FEATURE_LIST + `/${type}`);
  } catch (err) {
    return err;
  }
};
