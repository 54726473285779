import axios from "axios";
import { getToken } from "../utility/tokenOperations";
const fetchClient = () => {
  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Create instance
  let axiosInstance = axios.create(defaultOptions);

  /**  Set the AUTH token for any request*/
  axiosInstance.interceptors.request.use(function (config) {
    let token = getToken();
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    config.headers["Controller"] = sessionStorage.getItem("Controller") ?? "";
    config.headers["Action"] = sessionStorage.getItem("Action") ?? "";
    config.headers["Path"] = sessionStorage.getItem("Path") ?? "";
    return config;
  });
  return axiosInstance;
};

export default fetchClient();
