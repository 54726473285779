import React from "react";
const SvgIcon = ({ type = "" }) => {
  const zoomIn = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 18.3 18.4">
      <title>zoomin</title>
      <g>
        <path
          d="M15.5,2.9C12-0.6,6.3-0.6,2.8,2.9s-3.5,9.2,0,12.7s9.2,3.5,12.7,0C19,12,19,6.3,15.5,2.9z M14.7,14.8c-3,3.1-8,3-11.1,0
        c-3.1-3-3.1-8,0-11.1c3.1-3,8.1-3,11.1,0C17.8,6.8,17.8,11.7,14.7,14.8z"
        />
        <polygon points="9.7,4.7 8.5,4.7 8.5,8.6 4.6,8.6 4.6,9.8 8.5,9.8 8.5,13.8 9.7,13.8 9.7,9.8 13.6,9.8 13.6,8.6 9.7,8.6 	" />
      </g>
    </svg>
  );
  const chevronleft = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-left"
    >
      <polyline points="15 18 9 12 15 6"></polyline>
    </svg>
  );
  const chervonsleft = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevrons-left"
    >
      <polyline points="11 17 6 12 11 7"></polyline>
      <polyline points="18 17 13 12 18 7"></polyline>
    </svg>
  );
  const chevronright = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  );
  const chervonsright = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevrons-right"
    >
      <polyline points="13 17 18 12 13 7"></polyline>
      <polyline points="6 17 11 12 6 7"></polyline>
    </svg>
  );

  switch (type) {
    case "chervons-right":
      return chervonsright();
    case "chevron-right":
      return chevronright();
    case "chervons-left":
      return chervonsleft();
    case "chevron-left":
      return chevronleft();
    case "zoom_in":
      return zoomIn();
    default:
      return null;
  }
};

export default SvgIcon;
