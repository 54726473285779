//update active units endponits
const deviceActivationbaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVICE_ACTIVATION}`;

export const ACTIVATE_UNITS = deviceActivationbaseUrl + `/activate`;

const aggregatorUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_AGGREGATOR}`;
export const GET_VEHICLES = aggregatorUrl + `/devices/get-inactive-camera-vtu`;

const verizonConnectorBaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VERIZON}`;
export const GET_VERIZON_RATE_PLANS = verizonConnectorBaseUrl + `/getRatePlans`;

const attConnectorBaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ATT}`;
export const GET_ATT_PROFILES = attConnectorBaseUrl + `/getattprofiles`;

const vodafoneConnectorBaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VODAFONE}`;
export const GET_VODAFONE_PROFILES = vodafoneConnectorBaseUrl + `/getVodafoneProfiles`;
