import React, { useState } from "react";
import styled from "@emotion/styled";
import { Header } from "../components/core/Controls";
import okta from "../assets/images/okta.png";
import { useOktaAuth } from "@okta/okta-react";
const LoginError = ({ location }) => {
  const { oktaAuth } = useOktaAuth();
  const ContentWrapper = styled.div`
  text-align: center
  padding: 10px 10px;
`;
  const BorderWrapper = styled.div`
    border: 10px solid #007dc1;
    width: 30%;
    height: 300px;
    border-radius: 100px;
  `;
  const OktaImage = styled.img`
    width: 55%;
    display: block;
  `;
  const Note = styled.h5`
    width: 55%;
    color: red;
  `;
  const ButtonLogin = styled.button`
text-align: center
padding: 10px 10px;
margin-top:2vw;
width:45%;
height:70px;
color:white;
font-size: 1.5em;
display:block;
margin-left:auto;
margin-right:auto;
background-color :#007dc1;
border-radius:12px;
`;
  const [hasErrorData, setErrors] = useState(location.state);
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };
  return (
    <center>
      <Header>Error while we were trying to redirect you to the Application</Header>
      <ContentWrapper>
        <h2> Error: {hasErrorData?.error}</h2>
        <h2>Error_Description: {hasErrorData?.error_description}</h2>
      </ContentWrapper>
      <BorderWrapper>
        <OktaImage src={okta} />
        <ButtonLogin onClick={triggerLogin}>Sign in with Okta</ButtonLogin>
      </BorderWrapper>
      <Note>If you have any questions , please contact : super-libra-dev-support@verizon.com</Note>
    </center>
  );
};

export default LoginError;
