import React from "react";
import { Modal, ModalBody, ModalTitle } from "@vds/modals";
const AuthRequiredModalDOS = ({ authRequiredModalOpenDOS }) => {
  return (
    <Modal
      showModal={authRequiredModalOpenDOS}
      open={authRequiredModalOpenDOS}
      closeOnDocumentClick={false}
      closeOnDimmerClick={false}
      fullScreenDialog={false}
      hideCloseButton={false}
    >
      <ModalTitle>Unauthorized User</ModalTitle>
      <ModalBody style={{ fontSize: "21px", marginTop: "15px", padding: "12px" }}>Please make sure you have access to SuperLibra 2.0</ModalBody>
    </Modal>
  );
};
export default AuthRequiredModalDOS;
