import { putRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const updateNotes = async (data) => {
  try {
    const { jobID, payload } = data;
    return await putRequest(`${urls.UPDATE_OPEN_JOBS_NOTES}/${jobID}`, payload);
  } catch (error) {
    return error;
  }
};
