import React, { lazy, Suspense, useState, useEffect } from "react";
import { LeftSection } from "./HomePage.style";
import noop from "lodash/noop";
import { useOktaAuth } from "@okta/okta-react";
const LeftNavigation = lazy(() => import("../../components/features/HomePageHelperComponents/LeftNavigation"));

function LeftNavigationCom({ handleLogoutUser = noop }) {
  const [userInfo, setUserInfo] = useState({});
  const { authState, oktaAuth } = useOktaAuth();
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    const getUserInfo = async () => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
      } else {
        await oktaAuth
          .getUser()
          .then((info) => {
            setUserInfo(info);
          })
          .catch((err) => {
            setErrorMsg("Something went wrong while fetching user data" + err);
          });
      }
    };
    getUserInfo();
  }, [authState, oktaAuth]);
  return (
    <>
      <LeftSection>
        <Suspense fallback={<></>}>
          <LeftNavigation handleLogoutUser={handleLogoutUser} name={userInfo.name} email={userInfo.email} error={errorMsg} />
        </Suspense>
      </LeftSection>
    </>
  );
}

export default LeftNavigationCom;
