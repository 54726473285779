import React, { useEffect } from "react";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds/modals";
import { SLButton } from "../../components/core/Controls";
import Icon from "@vds/icons";
import { StyledModal } from "./RescheduleModal";
import moment from "moment";
import { FlexRow, SmallText, Spacer } from "../../components/core/Utility/index";
import { Title } from "@vds/typography";

const StartDateChangeModal = ({ setConfirmModal, date, setRequestedDate, setConfirmedStartDate }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setConfirmModal(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const confirmModal = () => {
    setConfirmedStartDate(true);
    setRequestedDate(moment(date).format("MM/DD/YYYY"));
    setConfirmModal(null);
  };

  return (
    <Modal
      hideCloseButton={false}
      opened={true}
      disableOutsideClick
      css={StyledModal}
      role="dialog"
      ariaLabel="Please confirm your date selection"
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <Title size="medium">Please confirm your date selection</Title>
          <div onClick={() => setConfirmModal(null)} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
            <Icon
              role="img"
              aria-label="close"
              alt="close-icon"
              name="close"
              size="medium"
              color="#000000"
              style={{ cursor: "pointer", outline: "none" }}
            />
          </div>
        </FlexRow>
      }
    >
      <ModalBody css={{ paddingLeft: "0", paddingRight: "0" }}>
        <Spacer top="1rem" />
        <SmallText>
          You have selected <b> {moment(date).format("MMM D, yyyy")}</b> to start your new availability search.
        </SmallText>
      </ModalBody>
      {/* <ModalFooter> */}
      <FlexRow css={{ justifyContent: "flex-end", marginTop: "2rem" }}>
        <SLButton aria-label="Confirm" role="button" css={{ width: "10rem", marginLeft: "auto" }} size="large" onClick={confirmModal}>
          Confirm
        </SLButton>
        <SLButton aria-label="Cancel" role="button" secondary css={{ width: "10rem" }} size="large" onClick={() => setConfirmModal(null)}>
          Cancel
        </SLButton>
      </FlexRow>
      {/* </ModalFooter> */}
    </Modal>
  );
};
export default StartDateChangeModal;
