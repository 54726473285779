import { postRequest } from "../RestServices";
import * as urls from "../../api/aggregator";
export const modifyBatch = async (payload) => {
  try {
    const _response = await postRequest(urls.MODIFYBATCH, payload);
    return _response.status;
  } catch (err) {
    return 500;
  }
};
