import { createContext, useState } from "react";

export const CheckinContext = createContext();
export const CheckinProvider = (props) => {
  const [workItems, setWorkItems] = useState("");
  const [workTicket, setWorkTicket] = useState([]);
  const [esn, setEsn] = useState("");
  return (
    <CheckinContext.Provider
      value={{
        workTicket: { workTicket, setWorkTicket },
        workItems: { workItems, setWorkItems },
        esn: { esn, setEsn },
      }}
    >
      {props.children}
    </CheckinContext.Provider>
  );
};
