import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const ATP_SUMMARY_CHECK_COL = [
  {
    key: "MMID",
    value: "materialId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: "100px",
  },
  {
    key: "Item Description",
    value: "itemDescription",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: true,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Requested Quantity",
    value: "totalQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "ATP Quantity",
    value: "quantityATP",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "ATP Date",
    value: "dateATP",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
    isDate: true,
    dateFormat: "MMM D, yyyy",
  },
];
