import { getProperty } from "../../utility/getProperty";
import { postRequest } from "../RestServices";
import * as urls from "../../api/checkin/index";
export const updateRevealVehicle = async (payload) => {
  try {
    const _response = await postRequest(urls.UPDATE_VEHICLE_DETAILS, payload);
    const _vehicle_detail = getProperty(_response, "data", {});

    return { _vehicle: _vehicle_detail };
  } catch (err) {
    return {
      _vehicle: err?.response,
    };
  }
};
