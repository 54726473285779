// Scheduler Endpoints

const BaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SCHEDULER_STG}`;

export const ADD_JOB = `${BaseUrl}/job`;
export const UPDATE_JOB = `${BaseUrl}/job`;
export const GET_JOBS = `${BaseUrl}/jobs`;
export const GET_SCHEDULE = `${BaseUrl}/appointments`;
export const CREATE_SCHEDULE = `${BaseUrl}/createSchedule`;
export const GET_REASONLIST = `${BaseUrl}/reason-list`;
export const CANCEL_SCHEDULE = `${BaseUrl}/cancelappt`;
export const GET_CSP_DETAILS = `${BaseUrl}/cspAccountDetails`;
export const RESCHEDULE_JOB = `${BaseUrl}/reschedule`;
export const COMPLETE_JOB = `${BaseUrl}/completejob`;
export const VALIDATE_ADDRESS = `${BaseUrl}/validateaddress`;
export const GET_JOBDURATION = `${BaseUrl}/jobduration`;
export const GET_TIME_SLOTS = `${BaseUrl}/vendorsAppAvailablity`;
export const GET_REPORT = `${BaseUrl}/report`;
export const CANCEL_BOOKING = `${BaseUrl}/cancelBookingApp`;
export const GET_VENDOR_STATES_AND_SELECTIONS = `${BaseUrl}/vendors/states`;
export const UPDATE_VENDOR_STATES = `${BaseUrl}/updateVendorStateAudit`;
export const GET_VENDOR_STATE_AUDIT_LOG = `${BaseUrl}/exportVendorStateAuditLog`;
export const GET_CHARGABLE_STATES = `${BaseUrl}/chargeablestates`;
