import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getStatesList = async () => {
  try {
    return await getRequest(urls.GET_STATES_LIST);
  } catch (err) {
    return err;
  }
};
