import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const getJobDuration = async (installType, jobId = "") => {
  try {
    if (!jobId) {
      return await getRequest(urls.GET_JOBDURATION + `?installType=${installType}`);
    } else {
      return await getRequest(urls.GET_JOBDURATION + `?installType=${installType}&jobId=${jobId}`);
    }
  } catch (err) {
    return err;
  }
};
