import React, { useState, useContext, useEffect, useRef } from "react";
import { SLButton } from "../../../components/core/Controls";
import { FlexRow, Spacer, SmallText } from "../../../components/core/Utility/index";
import "./../Stepper.css";
import { CustomInputDynamicWidth } from "./../CreateNewJob";
import { Notification } from "@vds/notifications";
import { generate } from "shortid";
import { AccountDetailsContext } from "../../../context/accountDetailsContext";
import { css } from "@emotion/react";
import { Table, TableHead, TableBody, TableRow, Cell, TableHeader } from "@vds/tables";
import { Loader } from "@vds/loaders";
import { salesOrgBasedCurrency } from "../../../constants/PartialCompletionReasons";
import Select from "react-select";
import { Icon } from "@vds/icons";
import styled from "@emotion/styled";
import { Tooltip } from "@vds/tooltips";
import xlsIcon from "../../../assets/icons/export-xls.png";
import { IconWrapper } from "./InstallPartnerFees";
import moment from "moment";
import { COLUMN_WIDTHS } from "../../../constants/ApplicationConstants";
import { getMaterialData } from "../../../services/scheduler/getMaterialData";

const CustomTableHeader = css`
  margin-bottom: 0px;
  table-layout: fixed;
  thead {
    /* border-bottom: 2px solid #000000; */
    border-bottom: none;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0rem;
      /* padding: 1rem 0.5rem 0.75rem 0.5rem; */
    }
  }
  tr {
    border-bottom: none;
    td {
      padding: 8px 2px 5px 0px;
      /* padding: 8px 2px 5px 15px; */
    }
  }
`;
const CustomSelectCSS = css`
  input {
    min-width: 55vh !important;
  }
`;

const CustomerFeesComp = ({
  formStep,
  previousStep,
  nextStep,
  stepsEnable,
  closePopup,
  customerFeesData,
  // descriptionList,
  jobDetails,
  salesOrg,
  notify,
}) => {
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState();
  const { customerFeeDesc, cspDetails, customerFees, totalCustomerFees, source } = useContext(AccountDetailsContext);
  const [descriptionList, setDescriptionList] = useState([]);
  //for accessibility
  const focusAddCustomerFees = useRef();
  const customerFeesTitle = useRef();
  const customStyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid gray" : "",
      // This line disable the blue border
      boxShadow: state.isFocused ? "1px solid gray" : "",
      "&:hover": {
        border: state.isFocused ? "1px solid gray" : "",
      },
    }),
  };

  const columns = [
    {
      key: "Label",
      value: "label",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Value",
      value: "value",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Text",
      value: "text",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Unit",
      value: "baseMeasurementUnit",
    },
  ];

  const materialDataRequest = async () => {
    let materialRequest = {};
    if (source.source === "csp") {
      materialRequest = {
        salesOrgId: salesOrg,
        flag: "FLAG_CUSTOMER_FEE",
      };
    } else {
      materialRequest = {
        salesOrgId: salesOrg,
        flag: "FLAG_CUSTOMER_FEE",
      };
    }
    setLoader(true);
    let response = await getMaterialData(materialRequest);
    if (response && response?.data && response?.data?.statusCode === "200" && response?.data?.data) {
      let materialInfo = response?.data?.data;
      let detailsList = [];
      materialInfo?.map((item) => {
        let details = {
          label: `${item.materialName}-${item.materialId}`,
          value: item.materialId,
          text: item.materialName,
          baseMeasurementUnit: item.baseMeasurementUnit,
        };
        detailsList.push(details);
      });
      setDescriptionList(detailsList);
      setLoader(false);
    } else {
      notify("Unable to fetch data due to an unexpected error", "error");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
      setLoader(false);
    }
  };

  const exportExcel = () => {
    var dataToExport = descriptionList;
    dataToExport = formatData(dataToExport);
    if (dataToExport.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(getDataForExport(dataToExport));
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
        saveFile(excelBuffer, "data", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8", ".xlsx");
      });
    }
    //for accessibility
    focusAddCustomerFees.current.focus();
  };

  const getDataForExport = (data) => {
    return data.map((item) => {
      return Object.fromEntries(
        columns.map((col) => [
          col.key,
          typeof item[col.value] === "object" || Array.isArray(item[col.value]) ? JSON.stringify(item[col.value]) : item[col.value],
        ])
      );
    });
  };

  const formatData = (dataToExport) => {
    let dataColumns = columns.filter((item) => {
      return item.isDate;
    });
    return dataToExport.map((item) => {
      dataColumns.forEach((columnItem) => {
        item[columnItem.value] = formatDate(item[columnItem.value], columnItem.dateFormat);
      });
      return item;
    });
  };
  const saveFile = (buffer, fileName, type, extension) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        const data = new Blob([buffer], {
          type: type,
        });

        module.default.saveAs(data, fileName + extension);
      }
    });
  };
  const formatDate = (value, dateFormat, timezone = "") => {
    if (!value) {
      return formattedData(value);
    }
    if (dateFormat) {
      return `${moment(value).format(dateFormat)} ${timezone}`.trim();
    }
    return value?.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };
  const formattedData = (data) => {
    return data == null ? "--" : data;
  };

  const handleSelectChange = (option) => {
    addMoreCustomerFees(option);
  };

  const deleteRow = (index) => {
    const tempArray = customerFees.customerFees.filter((item, i) => i !== index);
    customerFees.setCustomerFees(tempArray);
  };

  const addMoreCustomerFees = (option) => {
    let tempFees = customerFees.customerFees?.length > 0 ? [...customerFees.customerFees] : [];
    tempFees.push({
      customerItemDescription: { value: "", error: false, errorText: "Select Description", success: false },
      customerMmid: { value: "", error: false, errorText: "Select MMID", success: false },
      customerQuantity: { value: 0, error: false, errorText: "Enter Quantity", success: false },
      charge: {
        value: "",
        valueInt: "",
        error: false,
        errorText: "Enter Charge",
        success: false,
        showInputOne: true,
        showInputTwo: false,
      },
    });
    let desc = option.text;
    let mmid = option.value;
    tempFees[customerFees.customerFees?.length]["customerItemDescription"] = {
      value: desc,
      error: false,
      errorText: "Select Description",
      success: true,
    };
    tempFees[customerFees.customerFees?.length]["customerMmid"] = { value: mmid, error: false, errorText: "Enter MMID", success: true };
    tempFees[customerFees.customerFees?.length]["unit"] = {
      value: option.baseMeasurementUnit,
      error: false,
      errorText: "Enter Unit of Measure",
      success: true,
    };

    customerFees.setCustomerFees(tempFees);
  };

  useEffect(() => {
    materialDataRequest();
    //for accessibility
    customerFeesTitle.current.focus();
  }, []);

  useEffect(() => {
    //calculate total customer fees
    let total = 0;
    let newTotal = 0;
    let oldTotal = 0;
    if (customerFees.customerFees) {
      let multipliedVal = 0;
      customerFees.customerFees.forEach((el, index) => {
        const input = el && el?.customerQuantity && el?.customerQuantity?.value && el?.customerQuantity?.value?.replace(/[^0-9.]/g, "");
        if (el?.charge?.valueInt) {
          multipliedVal = el?.charge?.valueInt * parseFloat(input);
          newTotal = newTotal + parseFloat(multipliedVal);
        }
        if (el?.charge?.showInputOne) {
          let elementDiv = document.getElementById("chargeOne" + index);
          if (elementDiv) {
            let elementInput = elementDiv.querySelector("input");
            if (elementInput) {
              elementInput.blur();
            }
          }
        }
      });
      customerFeesData?.forEach((el) => {
        let inputCharge = null;
        if (el && el?.customerServiceCharge && isNaN(el?.customerServiceCharge))
          inputCharge = el && el?.customerServiceCharge && el?.customerServiceCharge.replace(/[^0-9.]/g, "");
        else inputCharge = el?.customerServiceCharge;
        //  const inputQty = el && el?.quantity && el?.quantity.replace(/[^0-9.]/g, "");
        if (inputCharge) {
          multipliedVal = inputCharge * parseFloat(el?.quantity);
          oldTotal = oldTotal + parseFloat(multipliedVal);
        }
      });
      total = newTotal;
      totalCustomerFees.setTotalCustomerFees(total?.toFixed(2));
    } else {
      totalCustomerFees.setTotalCustomerFees(0);
    }
  }, [customerFees.customerFees, customerFeesData]);

  let formattedValue = "--";

  const handleFields = (field, value, index = null) => {
    switch (field) {
      case "customerItemDescription": {
        let tempFees = [...customerFees.customerFees];
        const tempData = customerFeeDesc && customerFeeDesc?.customerFeeDesc.filter((data) => data.materialName === value);
        const data = tempData[0].materialId;
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Select Description", success: true };
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Select Description", success: false };
        }
        tempFees[index]["customerMmid"] = { value: data, error: false, errorText: "Enter MMID", success: true };
        tempFees[index]["unit"] = { value: tempData[0].baseMeasurementUnit, error: false, errorText: "Enter Unit of Measure", success: true };
        customerFees.setCustomerFees(tempFees);
        break;
      }
      case "customerMmid": {
        let tempFees = [...customerFees.customerFees];
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Enter MMID", success: true };
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Enter MMID", success: false };
        }
        customerFees.setCustomerFees(tempFees);
        break;
      }
      case "customerQuantity": {
        let tempFees = [...customerFees.customerFees];
        if (value && value > 0 && value < 10000) {
          tempFees[index][field] = { value, error: false, errorText: "Enter Quantity", success: true };
        } else {
          tempFees[index][field] = { value: 0, error: true, errorText: "Enter Valid Quantity", success: false };
        }
        customerFees.setCustomerFees(tempFees);
        break;
      }
      case "charge": {
        let tempFees = [...customerFees.customerFees];
        if (value && value.length) {
          let chargeRegex = /^([0-9.])+$/;
          if (chargeRegex.test(value)) {
            let formattedValue;
            if (source.source === "csp") {
              formattedValue =
                salesOrgBasedCurrency["SO_" + cspDetails?.cspDetails?.[0]?.Opp_Sales_Org]?.currencySymbol +
                value +
                " (" +
                salesOrgBasedCurrency["SO_" + cspDetails?.cspDetails?.[0]?.Opp_Sales_Org]?.currency +
                ")";
            } else {
              formattedValue =
                salesOrgBasedCurrency["SO_" + jobDetails?.salesOrg]?.currencySymbol +
                value +
                " (" +
                salesOrgBasedCurrency["SO_" + jobDetails?.salesOrg]?.currency +
                ")";
            }
            tempFees[index][field] = {
              value: formattedValue,
              valueInt: value,
              error: false,
              errorText: "Enter Charge",
              success: true,
              showInputOne: false,
              showInputTwo: true,
            };
          }
        } else {
          tempFees[index][field] = {
            value: "",
            valueInt: "",
            error: true,
            errorText: "Enter Valid Charge",
            success: false,
            showInputOne: false,
            showInputTwo: true,
          };
        }
        customerFees.setCustomerFees(tempFees);
        break;
      }
    }
  };

  const handleChargeInputsToggle = (index, one, two) => {
    let tempFees = [...customerFees.customerFees];
    tempFees[index]["charge"] = {
      ...tempFees[index]["charge"],
      showInputOne: one,
      showInputTwo: two,
    };
    customerFees.setCustomerFees(tempFees);
  };

  const notifyError = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  return (
    <>
      <Spacer top="4vw" />
      <FlexRow style={{ justifyContent: "space-between", padding: "0px 5px" }}>
        <div tabIndex={0} ref={customerFeesTitle} style={{ fontSize: "2rem", fontWeight: 700, color: "#000" }}>
          Customer Fees
        </div>
        <IconWrapper onClick={() => exportExcel()} ref={focusAddCustomerFees}>
          {/* <CustomTooltip title="Download Excel" size="large"></CustomTooltip> */}
          <img src={xlsIcon}></img>
        </IconWrapper>
      </FlexRow>
      {/* <Spacer top="1vw" /> */}
      <div style={{ padding: "5px", width: "50%", fontSize: "13px" }}>
        <Select options={descriptionList} onChange={handleSelectChange} isSearchable={true} css={CustomSelectCSS} styles={customStyle} />
      </div>
      <Spacer top="1rem" />
      {/* <div css={{ height: customerFeesData.length + customerFees?.customerFees.length > 4 ? "35vh" : "", overflowY: "auto" }}> */}
      <FlexRow style={{ padding: "5px", marginBottom: "0px", paddingBottom: "0px" }}>
        <Table tabIndex={0} role="table" topLine="none" css={CustomTableHeader}>
          <TableHead role="row ">
            <TableHeader role="columnheader" width="42%">
              <SmallText bold>Item Description</SmallText>
            </TableHeader>
            <TableHeader role="columnheader" width="30%">
              <SmallText bold>MMID</SmallText>
            </TableHeader>
            <TableHeader role="columnheader" width="29%">
              <SmallText bold>Quantity</SmallText>
            </TableHeader>
            <TableHeader role="columnheader" width="24%">
              <SmallText bold>Charge</SmallText>
            </TableHeader>
          </TableHead>
        </Table>

        <Loader active={isLoading} fullscreen={false} />
        <div css={{ height: "24vh", overflowY: "scroll", width: "100%" }}>
          {customerFeesData.length > 0 ? (
            <Table tabIndex={0} role="table" topLine="none" striped={false} css={CustomTableHeader}>
              <TableBody role="rowgroup">
                {customerFeesData?.map((job) => {
                  if (job?.customerServiceCharge != null && job?.customerServiceCharge != "") {
                    const input = job?.customerServiceCharge;
                    if (source.source === "csp") {
                      formattedValue =
                        salesOrgBasedCurrency["SO_" + cspDetails?.cspDetails?.[0]?.Opp_Sales_Org]?.currencySymbol +
                        input +
                        " (" +
                        salesOrgBasedCurrency["SO_" + cspDetails?.cspDetails?.[0]?.Opp_Sales_Org]?.currency +
                        ")";
                    } else {
                      formattedValue =
                        salesOrgBasedCurrency["SO_" + jobDetails?.salesOrg]?.currencySymbol +
                        input +
                        " (" +
                        salesOrgBasedCurrency["SO_" + jobDetails?.salesOrg]?.currency +
                        ")";
                    }
                  }
                  return (
                    <>
                      <TableRow role="row" key={job.materialId}>
                        <Cell role="cell" width="42%">
                          <SmallText>{job.materialName}</SmallText>
                        </Cell>
                        <Cell role="cell" width="30%">
                          <SmallText>{job.materialId}</SmallText>
                        </Cell>
                        <Cell role="cell" width="29%">
                          <SmallText>{job.quantity}</SmallText>
                        </Cell>
                        <Cell role="cell" width="24%">
                          <SmallText>{formattedValue}</SmallText>
                        </Cell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            customerFees.customerFees.length == 0 && (
              <Table tabIndex={0} role="table" topLine="none" striped={false} css={CustomTableHeader}>
                <TableBody role="rowgroup">
                  <TableRow role="row">
                    <Cell role="cell">
                      <SmallText>No Data</SmallText>
                    </Cell>
                  </TableRow>
                </TableBody>
              </Table>
            )
          )}
          {customerFees.customerFees &&
            customerFees.customerFees.length > 0 &&
            customerFees.customerFees.map((el, index) => {
              return (
                <FlexRow key={index} style={{ justifyContent: "space-between", padding: "5px", paddingBottom: "0px", paddingLeft: "0px" }}>
                  <CustomInputDynamicWidth
                    width="30%"
                    type="text"
                    // label={customerFeesData.length > 0 ? "" : "Item Description"}
                    // aria-label={customerFeesData.length > 0 ? "" : "Item Description"}
                    // name="description"
                    disabled
                    value={el.customerItemDescription.value}
                    error={el.customerItemDescription.error}
                    errorText={el.customerItemDescription.errorText}
                    success={el.customerItemDescription.success}
                    paddingRight="5px"
                  />
                  <CustomInputDynamicWidth
                    width="20%"
                    type="number"
                    // label={customerFeesData.length > 0 ? "" : "MMID"}
                    // aria-label={customerFeesData.length > 0 ? "" : "MMID"}
                    name="mmid"
                    disabled
                    key={"mmid" + index}
                    value={el.customerMmid.value}
                    error={el.customerMmid.error}
                    errorText={el.customerMmid.errorText}
                    success={el.customerMmid.success}
                    paddingRight="5px"
                  />
                  <CustomInputDynamicWidth
                    width="20%"
                    type="number"
                    // label={customerFeesData.length > 0 ? "" : "Quantity"}
                    // aria-label={customerFeesData.length > 0 ? "" : "Quantity"}
                    key={"quantity" + index}
                    value={el.customerQuantity.value}
                    error={el.customerQuantity.error}
                    errorText={el.customerQuantity.errorText}
                    success={el.customerQuantity.success}
                    onChange={(e) => handleFields("customerQuantity", e.target.value, index)}
                    paddingRight="5px"
                  />
                  {el.charge.showInputOne && (
                    <CustomInputDynamicWidth
                      width="20%"
                      type="text"
                      className="ChargeOne"
                      // label={customerFeesData.length > 0 ? "" : "Charge"}
                      // aria-label={customerFeesData.length > 0 ? "" : "Charge"}
                      key={"charge" + index}
                      value={el.charge.value}
                      readonly={true}
                      id={"chargeOne" + index}
                      error={el.charge.error}
                      errorText={el.charge.errorText}
                      success={el.charge.success}
                      onMouseOver={() => handleChargeInputsToggle(index, false, true)}
                      onFocus={() => handleChargeInputsToggle(index, false, true)}
                      onClick={() => handleChargeInputsToggle(index, false, true)}
                      paddingRight="5px"
                    />
                  )}
                  {el.charge.showInputTwo && (
                    <CustomInputDynamicWidth
                      width="20%"
                      type="text"
                      // label={customerFeesData.length > 0 ? "" : "Charge"}
                      // aria-label={customerFeesData.length > 0 ? "" : "Charge"}
                      key={"charge" + index}
                      value={el.charge.valueInt}
                      error={el.charge.error}
                      errorText={el.charge.errorText}
                      success={el.charge.success}
                      onMouseOver={() => handleChargeInputsToggle(index, true, false)}
                      onMouseOut={() => handleChargeInputsToggle(index, true, false)}
                      onBlur={() => handleChargeInputsToggle(index, true, false)}
                      onChange={(e) => handleFields("charge", e.target.value, index)}
                      paddingRight="5px"
                    />
                  )}
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => deleteRow(index)}>
                    <Icon
                      className="trash-icon"
                      name="trash"
                      size="medium"
                      color="#000000"
                      style={{ cursor: "pointer", outline: "none", marginTop: "7px" }}
                    />
                  </div>
                </FlexRow>
              );
            })}
        </div>
      </FlexRow>
      {/* </div> */}
      {/* {customerFeesData.length + customerFees?.customerFees.length > 3 && <Spacer top="1vw" />} */}
      <FlexRow style={{ justifyContent: "space-between", padding: "0px 5px", marginTop: "0.5rem" }}>
        <span tabIndex={0} style={{ fontSize: "1.3rem", fontWeight: 700, color: "#000" }}>
          Total Customer Fees
        </span>
        {source.source === "csp" ? (
          <span tabIndex={0} style={{ fontSize: "1.5rem", fontWeight: 700, color: "#000" }}>
            {salesOrgBasedCurrency["SO_" + cspDetails?.cspDetails?.[0]?.Opp_Sales_Org]?.currencySymbol}
            {totalCustomerFees.totalCustomerFees} ({salesOrgBasedCurrency["SO_" + cspDetails?.cspDetails?.[0]?.Opp_Sales_Org]?.currency})
          </span>
        ) : (
          <span tabIndex={0} style={{ fontSize: "1.5rem", fontWeight: 700, color: "#000" }}>
            {salesOrgBasedCurrency["SO_" + jobDetails?.salesOrg]?.currencySymbol}
            {totalCustomerFees.totalCustomerFees} ({salesOrgBasedCurrency["SO_" + jobDetails?.salesOrg]?.currency})
          </span>
        )}
      </FlexRow>
      <FlexRow style={{ justifyContent: "flex-start", paddingTop: "0.5rem" }}>
        {formStep > 0 && (
          <SLButton aria-label="Previous" role="button" size="large" style={{ width: "10rem", marginBottom: "0px" }} onClick={previousStep}>
            Previous
          </SLButton>
        )}
        <SLButton
          aria-label="Next"
          role="button"
          size="large"
          style={{ width: "10rem", marginBottom: "0px" }}
          disabled={stepsEnable.third && customerFees.customerFees.length > 0}
          aria-disabled={stepsEnable.third && customerFees.customerFees.length > 0}
          onClick={nextStep}
        >
          Next
        </SLButton>
        <SLButton aria-label="Cancel" role="button" size="large" secondary style={{ width: "10rem", marginBottom: "0px" }} onClick={closePopup}>
          Cancel
        </SLButton>
      </FlexRow>
    </>
  );
};
export default CustomerFeesComp;
