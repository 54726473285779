export const UPDATE_WORK_ORDER_DETAILS = [
  {
    key: "ESN",
    value: "esn",
    // width: calcWidth/7,
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    // width: COLUMN_WIDTHS.large,
  },
  {
    key: "DRID",
    value: "drid",
    // width: calcWidth/7,
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    // width: COLUMN_WIDTHS.large,
  },
  {
    key: "Box Type",
    value: "boxType",
    // width: calcWidth/7,
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    //width: COLUMN_WIDTHS.large,
  },
  {
    key: "MEID/ICCID",
    value: "iccid",
    // width: calcWidth/7,
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    //width: COLUMN_WIDTHS.large,
  },
  {
    key: "IMEI",
    value: "imei",
    // width: calcWidth/7,
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    //width: COLUMN_WIDTHS.large,
  },
  {
    key: "GSM / MDN",
    value: "gsm",
    // width: calcWidth/7,
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    // width: COLUMN_WIDTHS.large,
  },
  {
    key: "REVEAL ID",
    value: "revealID",
    // width: calcWidth/7,
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    //width: COLUMN_WIDTHS.large,
  },
];
