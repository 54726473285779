export const DIVISION_DROPDOWN_VALUES = [
  {
    code: "00",
    description: "Common",
  },
  {
    code: "01",
    description: "Telogis",
  },
  {
    code: "02",
    description: "Fleetmatics",
  },
  {
    code: "03",
    description: "NetworkFleet",
  },
  {
    code: "04",
    description: "Skywards",
  },
  {
    code: "05",
    description: "OEM",
  },
  {
    code: "06",
    description: "HUM",
  },
  {
    code: "10",
    description: "Division 10",
  },
  {
    code: "20",
    description: "Division 20",
  },
  {
    code: "V1",
    description: "Verizon STO",
  },
];
export const DISTRIBUTION_CHANNEL_DROPDOWN_VALUES = [
  {
    code: "00",
    description: "VZC Common",
  },
  {
    code: "01",
    description: "VZC Indirect",
  },
  {
    code: "02",
    description: "VZC Direct",
  },
  {
    code: "03",
    description: "VZC Govt.",
  },
  {
    code: "04",
    description: "VZC InterCo.",
  },
];
export const ERP_STATUS_DROPDOWN_VALUES = [
  {
    code: "1",
    description: "Open",
  },
  {
    code: "2",
    description: "In-Process",
  },
  {
    code: "5",
    description: "Completed",
  },
];
export const ERP_SUB_STATUS_DROPDOWN_VALUES = [
  {
    code: "101",
    description: "Field Work Completed",
  },
  {
    code: "111",
    description: "Completed",
  },
  {
    code: "121",
    description: "Cancelled",
  },
  {
    code: "131",
    description: "Field Work Incomplete	",
  },
  {
    code: "141",
    description: "Incorrect Ticket - Case Closure",
  },
  {
    code: "151",
    description: "Partially Completed",
  },
  {
    code: "161",
    description: "Incorrect Troubleshooting",
  },
  {
    code: "171",
    description: "Field Work Not Required",
  },
  {
    code: "181",
    description: "Field Work Not Required / HW Return",
  },
  {
    code: "191",
    description: "Full Appt No Show",
  },
  {
    code: "201",
    description: "Cancelled COVID-19",
  },
  {
    code: "211",
    description: "Full Shipment - Lost in Transit",
  },
  {
    code: "221",
    description: "Partial Shipment - Lost in Transit",
  },
];
