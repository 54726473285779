import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const GlobalFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px 0 15px;
  height: 30px;
  border-bottom: 1px solid black;
  margin: 0rem;
  input {
    outline: none;
    border: none;
    height: 25px;
    width: 75%;
  }
  img {
    width: 35px;
  }
`;

export const TableWrapper = styled.div`
  color: red;
  margin-right: 3rem;
  .p-datatable-scrollable .p-datatable-wrapper {
    border-right: 1px solid #e9ecef;
  }
  .p-datatable .p-datatable-thead > tr > th {
    padding: 0.5rem 0.8rem;
    justify-content: center;
    color: #000;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.8rem;
    color: #000;
  }
  .p-datatable {
    font-size: 14px;
    font-family: "Verizon-NHG-eDS";
  }
  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border: none;
  }
  .p-dropdown {
    border-radius: 0px;
    border-bottom: 1px solid #000;
  }
  .p-dropdown:not(.p-disabled):hover,
  .p-dropdown:not(.p-disabled).p-focus {
    border-color: #000;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    color: #000;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    font-size: 14px;
  }
  .p-link {
    font-size: 14px;
    color: #000;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border: 0.5px solid #6c757d;
    border-radius: 0px;
    box-shadow: none;
    background: none;
    color: #000;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    font-size: 14px;
    color: #000;
    background: #6c757d;
  }
  .p-paginator {
    justify-content: end;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-header {
    padding: 0.5rem;
    text-align: right;
    background: none;
    border: none;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: flex-end;

    img {
      padding-left: 1rem;
    }
  }
  .p-datatable-frozen-tbody {
    font-weight: bold;
  }

  .p-datatable-tbody .p-frozen-column {
    font-weight: bold;
    z-index: 50;
  }
  .p-datatable-scrollable .p-datatable-thead {
    z-index: 100;
  }
`;
