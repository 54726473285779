import { putRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const completeJob = async (payload, jobId) => {
  try {
    return await putRequest(urls.COMPLETE_JOB + `/${jobId}`, payload);
  } catch (err) {
    return err?.response;
  }
};
