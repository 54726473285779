export function updateNewrelicStats() {
  const loadToken = () => {
    try {
      const serializedToken = JSON.parse(sessionStorage.getItem("okta-token-storage"));
      if (serializedToken === null) {
        return undefined;
      }
      return serializedToken.idToken.claims.email;
    } catch (e) {
      return undefined;
    }
  };
  const email = loadToken();
  if (email) {
    window.newrelic.setCustomAttribute("email", email);
  }
}
