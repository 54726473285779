import { getRequest } from "../RestServices";
import * as urls from "../../api/accounts/url";

// const response = {
//   status: 200,
//   data: {
//     data: {
//       m1Status: "ON",
//       m1ModifiedAt: "2023-07-25T08:27:19.943+00:00",
//       m2Status: "ON",
//       m2ModifiedAt: "2023-07-25T08:27:19.943+00:00",
//     },
//   },
// };

export const milestoneDetails = async () => {
  try {
    return await getRequest(urls.MilestoneDetails);
  } catch (err) {
    return {
      error: err,
    };
  }
};
