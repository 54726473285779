import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexRow, SmallText, Spacer, UltraMediumText } from "../../components/core/utility";
import { Icon } from "@vds/icons";
import { Cell, Table, TableBody, TableHead, TableHeader, TableRow } from "@vds/tables";
import styled from "styled-components";
import { CustomInput } from "./AddAVehicle";
import { Checkbox } from "@vds/inputs";
import _ from "lodash";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 2rem 2rem 2rem 2rem;
  padding-right: 2px;
  border-radius: 0.5rem !important;
  height: auto;
  width: 60%;

  @media (max-width: 1440px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 2rem 2rem 2rem 2rem;
    padding-right: 2px;
  }
  div[data-testid="modal-body"] {
    padding: 0px;
    div {
      ::-webkit-scrollbar {
        display: block !important;
      }
      ::-webkit-scrollbar-thumb {
        display: inline-block !important;
        background-color: #c0c0c0 !important;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
    }
  }
  & footer {
    padding: 0;
  }
`;

const CustomTableHeader = css`
  // width: 95%;
  //margin-left: 0.5rem;
  margin-bottom: 0px;
  thead {
    border-top: 2px solid #000000;
    border-bottom: 1px solid #d8dada;
    margin-bottom: 3px;
    border-left: 0.0625rem solid #d8dada;
    border-right: 0.0625rem solid #d8dada;
    th {
      padding: 0.5rem 0rem 0.5rem 0.4rem;
    }
  }
  tbody {
    tr:nth-child(2n + 1) {
      background-color: rgb(246, 246, 246);
    }
  }
  td {
    padding: 5px 0px 0px 2px;
    vertical-align: top !important;
  }
`;

export const CustomIcon = styled.div`
  padding-top: 0.5rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  & svg {
    outline: none;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  margin: 0.5rem;
  & span {
    & span {
      & p {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: Verizon-NHG-eTX !important;
      }
    }
  }
`;

const CustomisedInput = styled(CustomInput)`
  & input {
    &:disabled {
      border-bottom: none;
    }
  }
  & span {
    height: ${(props) => (props.modalHeight ? props.modalHeight : "")};
  }
`;

export const PartialShipmentLostModal = ({
  partialShipmentLostModal,
  setPartialShipmentLostModal,
  setTransitModal,
  materialList,
  setPartialTransitLostMMID,
}) => {
  const [expandedItem, setExpandedItem] = useState(null);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [materialListArr, setMaterialListArr] = useState(_.cloneDeep(materialList));

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setPartialShipmentLostModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    let validInput = false;
    materialListArr.map((item) => {
      let esnlist = [];
      if (item.ESNQtyLost > 0) {
        validInput = true;
      }
      item.esn.map((items) => {
        [...items.esn.split(/[|\s]/g)]
          .map((item) => item.trim())
          .filter((esn) => esn !== "")
          .map((esnValue, index) => {
            return {
              id: items.id,
              esn: esnValue,
              selected: item?.ESNLost.includes(esnValue),
            };
          })
          .map((finalEsn) => {
            esnlist.push(finalEsn);
          });
        return items;
      });

      item.esn = esnlist;
    });
    validInput ? setIsNextDisabled(false) : setIsNextDisabled(true);
  }, [materialListArr]);

  const handleQtyChange = (value, material) => {
    value = value ? parseInt(value) : 0;
    let materialArr = materialListArr.map((mat) => {
      if (mat.lintItemUniqueId === material.lintItemUniqueId) {
        if (value <= material.totalQty) {
          mat.ESNQtyLost = value >= 0 ? value : 1;
        } else {
          mat.ESNQtyLost = material.totalQty;
        }
      }
      return mat;
    });
    setMaterialListArr(materialArr);
  };

  const handleESNSelect = (checked, value, material) => {
    if (checked) {
      let materialArr = materialListArr.map((mat) => {
        if (mat.materialId === material.materialId) {
          if (mat?.ESNLost) {
            mat?.ESNLost.push(value);
          } else {
            mat.ESNLost = [];
            mat?.ESNLost.push(value);
          }
          mat.ESNQtyLost = mat.ESNLost.length;
        }
        mat.esn.map((esns) => {
          if (esns.esn === value) {
            esns.selected = checked;
          } else if (mat?.ESNLost.includes(esns.esn)) {
            esns.selected = true;
          }
          return esns;
        });
        return mat;
      });
      setIsNextDisabled(false);
      setMaterialListArr(materialArr);
    } else {
      let materialArr = materialListArr.map((mat) => {
        if (mat.materialId === material.materialId) {
          mat.ESNLost = mat.ESNLost.filter((item) => item !== value);
          mat.ESNQtyLost = mat.ESNLost.length;
        }
        mat.esn.map((esns) => {
          if (esns.esn === value) {
            esns.selected = checked;
          } else if (mat?.ESNLost.includes(esns.esn)) {
            esns.selected = true;
          }
          return esns;
        });
        return mat;
      });
      setMaterialListArr(materialArr);
    }
  };

  const handleNext = () => {
    let mat = materialListArr.filter((item) => item.ESNQtyLost > 0);
    let MMIDDetailsMap = new Map();
    mat.map((item) => {
      let value = {
        materialName: item.materialName,
        esnList: item.ESNLost,
        qtyLost: item.ESNQtyLost,
      };
      MMIDDetailsMap.set(`${item.materialId}${item.techRefId}`, value);
    });
    setPartialTransitLostMMID(MMIDDetailsMap);
    setTransitModal(true);
  };

  return (
    <>
      <Modal
        role="dialog"
        ariaLabel="Lost Shipment"
        hideCloseButton={false}
        opened={partialShipmentLostModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <>
              <Title size="medium">Lost Shipment</Title>
            </>
            <div
              onClick={() => {
                setPartialShipmentLostModal(false);
              }}
              style={{ marginRight: "1rem", marginTop: "0.3rem" }}
            >
              <Icon
                aria-label="close"
                alt="close"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ paddingLeft: "0", paddingRight: "0", marginTop: "0.5rem" }}>
          <Spacer bottom="1rem" />
          <SmallText>Please select items that were lost in transit.</SmallText>
          <Spacer bottom="1rem" />
          <Table role="table" striped={true} topLine="none" css={CustomTableHeader}>
            <TableHead role="row">
              <TableHeader role="columnheader" width="30%">
                <SmallText bold>Item Description</SmallText>
              </TableHeader>
              <TableHeader role="columnheader" width="20%">
                <SmallText bold>MMID</SmallText>
              </TableHeader>
              <TableHeader role="columnheader" width="30%">
                <SmallText bold>TechRef ID</SmallText>
              </TableHeader>
              <TableHeader role="columnheader" width="10%">
                <SmallText bold>Quantity</SmallText>
              </TableHeader>
              <TableHeader role="columnheader" width="7%"></TableHeader>
            </TableHead>
          </Table>

          <div css={{ height: "35vh", overflowY: "scroll !important", border: "0.0625rem solid #d8dada" }}>
            <Table role="table" topLine="none" striped={true} bottomLine="secondary" css={CustomTableHeader}>
              <TableBody role="rowgroup">
                {materialListArr.length &&
                  materialListArr.map((material) => {
                    return (
                      <React.Fragment key={material.materialLineId}>
                        <TableRow role="row">
                          <Cell role="cell" width="30%" css={{ paddingLeft: "7px !important" }}>
                            <SmallText>{material.materialName}</SmallText>
                          </Cell>
                          <Cell role="cell" width="20%" css={{ paddingLeft: "3px !important" }}>
                            <SmallText>{material.materialId}</SmallText>
                          </Cell>
                          <Cell role="cell" width="30%">
                            <SmallText css={{ overflowWrap: "anywhere !important" }}>{material?.techRefId || "--"}</SmallText>
                          </Cell>
                          <Cell role="cell" width="10%">
                            <CustomisedInput
                              modalHeight="2rem"
                              aria-label={material.ESNQtyLost}
                              width="90%"
                              type="number"
                              error={false}
                              errorText=""
                              disabled={material?.esn.length ? true : false}
                              value={material.ESNQtyLost}
                              onChange={(e) => handleQtyChange(e.target.value, material)}
                            />
                          </Cell>
                          {material?.esn.length > 0 ? (
                            material.materialLineId === expandedItem ? (
                              <Cell role="cell" width="5%">
                                <CustomIcon role onClick={() => setExpandedItem(null)}>
                                  <Icon aria-label="up caret" alt="up-caret" name="up-caret" size="small" color="#000000" />
                                </CustomIcon>
                              </Cell>
                            ) : (
                              <Cell role="cell" width="5%">
                                <CustomIcon onClick={() => setExpandedItem(material.materialLineId)}>
                                  <Icon aria-label="down caret" alt="down-caret" name="down-caret" size="small" color="#000000" />
                                </CustomIcon>
                              </Cell>
                            )
                          ) : (
                            <Cell role="cell" width="5%"></Cell>
                          )}
                        </TableRow>
                        {material.materialLineId === expandedItem && (
                          <>
                            <div css={{ margin: "0.5rem 0.5rem 0 0.5rem" }}>
                              {material?.esn.length > 0 ? (
                                material?.esn.map((data) => (
                                  <CustomCheckbox
                                    role="checkbox"
                                    aria-checked={data?.selected ? data.selected : false}
                                    key={data.id}
                                    width="10px"
                                    label={data.esn}
                                    error={false}
                                    errorText=""
                                    value={data.esn}
                                    selected={data?.selected ? data.selected : false}
                                    onChange={(e) => handleESNSelect(e.target.checked, e.target.value, material)}
                                  />
                                ))
                              ) : (
                                <SmallText>No ESNs available.</SmallText>
                              )}
                            </div>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <FlexRow>
            <CustomSLButton aria-label="Next" role="button" primary disabled={isNextDisabled} aria-disabled={isNextDisabled} onClick={handleNext}>
              Next
            </CustomSLButton>
            <CustomSLButton
              secondary
              aria-label="Cancel"
              role="button"
              onClick={() => {
                setPartialShipmentLostModal(false);
              }}
            >
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PartialShipmentLostModal;
