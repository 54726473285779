import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const createSchedule = async (jobId, payload) => {
  try {
    return await postRequest(urls.CREATE_SCHEDULE + `/${jobId}`, payload);
  } catch (err) {
    return err;
  }
};
