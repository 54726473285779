import React, { useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexRow, SmallText } from "../../components/core/utility";
import { Icon } from "@vds/icons";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  border-radius: 0.5rem !important;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

const DeleteVehicleMMIDModal = ({ deleteVehicleModal, setDeleteVehicleModal, deletedVehicleMMID, handleVehicleMMIDRecordDelete }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setDeleteVehicleModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const handleDelete = () => {
    setDeleteVehicleModal(false);
    handleVehicleMMIDRecordDelete(deletedVehicleMMID);
  };

  return (
    <>
      <Modal
        role="dialog"
        ariaLabel="Delete Item"
        hideCloseButton={false}
        opened={deleteVehicleModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div>
              <Title size="medium">Delete Item</Title>
            </div>
            <div
              onClick={() => {
                setDeleteVehicleModal(false);
              }}
              style={{ marginRight: "1rem", marginTop: "0.3rem" }}
            >
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ paddingLeft: "0", paddingRight: "0", marginTop: "0.5rem" }}>
          <SmallText>Are you sure you would like to delete the item?</SmallText>
        </ModalBody>
        <ModalFooter>
          <FlexRow style={{ paddingTop: "2rem" }}>
            <CustomSLButton aria-label="Delete" role="button" primary onClick={handleDelete}>
              Delete
            </CustomSLButton>
            <CustomSLButton
              secondary
              aria-label="Cancel"
              role="button"
              onClick={() => {
                setDeleteVehicleModal(false);
              }}
            >
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default DeleteVehicleMMIDModal;
