import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const validateAddress = async (payload) => {
  try {
    return await postRequest(urls.VALIDATE_ADDRESS, payload);
  } catch (err) {
    return err;
  }
};
