import { postRequestFile } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const saveHardware = async (data, source) => {
  try {
    if (source) return await postRequestFile(urls.SAVE_HARDWARE_CSP, data);
    else return await postRequestFile(urls.SAVE_HARDWARE_CASE, data);
  } catch (err) {
    return err;
  }
};
