import React, { lazy, useContext, useState, useEffect } from "react";
import moment from "moment";
import { capitalize, isEqual } from "lodash";
import styled from "styled-components";
import { css } from "@emotion/react";
import { Loader } from "@vds/loaders";
import { Input, RadioButtonGroup } from "@vds/inputs";
import { Grid, Row } from "@vds/grids";
import { DropdownSelect } from "@vds/selects";
import { Notification } from "@vds/notifications";
import { TextLink } from "@vds/buttons";

import { generate } from "shortid";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { Header, SLButton } from "../../components/core/Controls";
import { FlexRow, FlexColumn, CompactCol, MediumText, SmallText, UltraMediumText } from "../../components/core/Utility";
import { addPage, resetTab } from "../../../utility/navigation";
import { sessionData, jobDurationFormatter, handleStartDate } from "../../utility/sessionData";
import {
  ADDRESS_REGEX,
  CITY_REGEX,
  WORKTICKETNUMBER_REGEX,
  EMAIL_REGEX,
  NAME_REGEX,
  SCHEDULER_PHONE_REGEX,
  VEHICLECOUNT_REGEX,
  ZIP_REGEX,
} from "../../constants/ValidationConstants";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { ScheduleJobMetadata } from "./ScheduleJob";
import CustomerDetails from "./CustomerDetails";
import { JobLengthModal } from "./JobLengthModal";
import { validateAddress } from "../../services/scheduler/validateAddress";
import { addJob } from "../../services/scheduler/addJob";
import { updateJob } from "../../services/scheduler/updateJob";
import { SCHEDULE_TIME_TIMEZONE } from "../../constants/PartialCompletionReasons";

import { getJobDuration } from "../../services/scheduler/getJobDuration";
import SchedulingFeesModal from "./SchedulingFeesModal";

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
    & label {
      & p {
        font-size: 12px;
        color: ${(props) => (props.disabled ? "#d8dada" : "")};
        & span {
          &::after {
            color: #747676;
          }
        }
      }
    }
  }
  }
  & select {
    font-size: 12px;
    color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      // color: ${(props) => (props.disabled || props.disableValue ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
`;
export const CustomInput = styled(Input)`
  padding-bottom: 0.5rem;
  height: auto;
  & label {
    & p {
      font-size: 12px;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & span {
    & span {
      & span {
        width: ${(props) => (props.boxWidth ? props.boxWidth : "")};
      }
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: ${(props) => (props.width ? props.width : "")};
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;
export const RadioButtonGroupWrapper = styled.div`
  div[role="radiogroup"] {
    border: 0.0625rem solid #d8dada;
    width: 70vmin;
    margin-bottom: 0.6rem;
    > div > label {
      > div:first-child > div {
        height: 1rem;
        width: 1rem;
        transform: translate(50%, 100%);
      }
      > div:last-child {
        font-size: 12px;
        font-family: Verizon-NHG-eTX;
        padding: 0.313rem 0.5rem;
        > span > span {
          font-size: 14px;
          font-family: Verizon-NHG-eTX;
        }
      }
    }
  }
`;
const CustomLoader = css`
  div[aria-label="loader overlay"] {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    top: 1.5rem;
    > div {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const CustomHistoricalLoader = css`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  margin-top: 0.5rem;
  div[aria-label="loader overlay"] {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    > div {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  & div {
    padding-left: 0.5rem;
  }
`;

const HistoricalAddressRow = css`
  @media screen and (min-width: 910px) {
    width: 50%;
    max-width: 37.25rem;
    min-width: 4.125rem;
  }
`;

const CustomSLButton = styled(SLButton)`
  & span {
    padding: 0.68rem 2rem;
  }
`;
export const CalendarWrapper = styled.div`
  > div > div:last-child {
    top: 3.5rem;
  }
`;
export default function CreateNewJob({ jobData, isExpandJob }) {
  const {
    accountId,
    source,
    accountDetails,
    cspDetails,
    caseDetails,
    jobDurationDetails,
    jobLengthHours,
    jobLengthMinutes,
    historicalAddressDetails,
    // chargeableStates,
  } = useContext(AccountDetailsContext);

  const [name, setName] = useState({
    value: "",
    error: false,
    errorText: "Enter name",
    success: false,
  });
  const [phone, setPhone] = useState({
    value: "",
    error: false,
    errorText: "Enter phone",
    success: false,
  });
  const [email, setEmail] = useState({
    value: "",
    error: false,
    errorText: "Enter email",
    success: false,
  });
  const [csp, setCsp] = useState({
    value: {
      id: "",
      name: "",
      owner: "",
    },
    error: true,
    errorText: "Unable to fetch csp details due to an unexpected error",
  });
  const [caseInfo, setCaseInfo] = useState({
    value: {
      id: "",
      number: "",
      owner: "",
    },
    error: true,
    errorText: "Unable to fetch case details due to an unexpected error",
  });
  const [workTicketNumber, setWorkTicketNumber] = useState({
    value: "",
    error: false,
    errorText: "Enter work ticket number",
    success: false,
  });
  const [jobType, setJobType] = useState(source.source === "case" ? "" : "INSTALL");
  const [startDate, setStartDate] = useState({
    value: handleStartDate(),
    error: false,
    errorText: "Select start date",
    success: true,
  });
  const [vehicleCount, setVehicleCount] = useState({
    value: "",
    error: false,
    errorText: "Enter vehicle count",
    success: false,
  });
  const [apptLength, setApptLength] = useState("");
  const [countryStates] = useState([
    "AL",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ]);

  const [address, setAddress] = useState({
    verified: false,
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: { value: "", error: false, errorText: "Enter address 1", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    state: { value: "", error: false, errorText: "Select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
    country: "",
  });
  const [addressFieldsIsDisable, setAddressFieldsIsDisable] = useState(true);
  const [isValidatingAddress, setIsValidatingAddress] = useState(false);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(true);
  const [checkAvailabilityIsDisable, setCheckAvailabilityIsDisable] = useState(true);
  const [verifyAddressIsDisable, setVerifyAddressIsDisable] = useState(true);
  const [verifyAddressIsShown, setVerifyAddressIsShown] = useState(true);
  const [suggestionsIsShown, setSuggestionsIsShown] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState(null);
  const [suggestedAddress, setSuggestedAddress] = useState({
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [typedAddress, setTypedAddress] = useState("");
  const [jobLengthModal, setJobLengthModal] = useState(false);
  const [isNewJob, setIsNewJob] = useState(true);
  const [existingJobQuantityList, setExistingJobQuantityList] = useState([]);
  const [timezone, setTimezone] = useState("");
  const [isValidatingHistoricalAddress, setIsValidatingHistoricalAddress] = useState(false);
  // const [schedulingFeesModal, setSchedulingFeesModal] = useState(null);
  const country = "USA";

  useEffect(() => {
    if (source.source === "case" && !caseDetails.caseDetails.length) {
      !accountDetails.customerName || !accountDetails.customerNumber || !accountDetails.vztSegment
        ? notify("Unable to get account details and case details due to an unexpected error", "error")
        : notify("Unable to get case details due to an unexpected error", "error");
    } else if (source.source !== "case" && !cspDetails.cspDetails.length) {
      !accountDetails.customerName || !accountDetails.customerNumber || !accountDetails.vztSegment
        ? notify("Unable to get account details and CSP account details due to an unexpected error", "error")
        : notify("Unable to get CSP account details due to an unexpected error", "error");
    }
    !historicalAddressDetails.historicalAddressDetails.length && handleNewAddress();
    if (jobData && Object.keys(jobData).length) {
      if (!isExpandJob) {
        setIsNewJob(false);
      }
      handleAdressFields("Address 1", jobData.addressOne);
      handleAdressFields("Address 2", jobData.addressTwo);
      handleAdressFields("City", jobData.city);
      handleAdressFields("State", jobData.stateCode);
      handleAdressFields("Zip", jobData.zipCode);
      handleFields("Name", jobData.contactName);
      handleFields("Phone", jobData.phone);
      handleFields("Email", jobData.email);
      handleFields("Work Ticket Number", jobData.jobDetails[0].workTickets.map((workTickets) => workTickets.workTicket).join(","));
      handleFields("Start Date", new Date(jobData.startDate));
      handleFields("Vehicle Count", jobData.jobDetails[0].vehicleCount);
      handleFields("Appt.Length", jobData.appointmentLength.toString());
      setVerifyAddressIsShown(false);
      setIsFieldsDisabled(false);
      setAddress((prevState) => ({ ...prevState, verified: true }));
      setJobType(jobData.jobDetails[0].jobType);
      jobLengthHours.setJobLengthHours(jobData.appointmentLength);
      jobLengthMinutes.setJobLengthMinutes(jobData.estAppointmentLength);
      setTimezone(SCHEDULE_TIME_TIMEZONE[jobData.timezone]);
      let usedAddress =
        jobData.addressOne +
        ", " +
        (jobData.addressTwo ? `${jobData.addressTwo}, ` : "") +
        jobData.city +
        ", " +
        jobData.stateCode +
        " " +
        jobData.zipCode +
        ", " +
        jobData.country;
      historicalAddressDetails.historicalAddressDetails.includes(usedAddress) && handleHistoricalAddressSelection(usedAddress, true);
      source.source === "case"
        ? setCaseInfo((prevState) => ({
            ...prevState,
            value: { id: jobData.jobDetails[0].caseSfdcId, number: jobData.jobDetails[0].caseNumber, owner: jobData.jobDetails[0].caseOwner },
            error: false,
          }))
        : setCsp((prevState) => ({
            ...prevState,
            value: { id: jobData.jobDetails[0].cspSfdcId, name: jobData.jobDetails[0].cspName, owner: jobData.jobDetails[0].cspOwner },
            error: false,
          }));
    } else {
      if (caseDetails.caseDetails.length && source.source === "case") {
        setCaseInfo((prevState) => ({
          ...prevState,
          value: {
            id: caseDetails.caseDetails[0].CaseSFDCID,
            number: caseDetails.caseDetails[0].CaseNumber,
            owner: caseDetails.caseDetails[0].CaseOwner,
          },
          error: false,
        }));
      } else if (cspDetails.cspDetails.length) {
        let cspAccDetails = cspDetails.cspDetails.filter((cspDetail) => cspDetail.DefaultCSP === "1")[0];
        setCsp((prevState) => ({
          ...prevState,
          value: { id: cspAccDetails.CSPID, name: cspAccDetails.CSPName, owner: cspAccDetails.CSPOwner },
          error: false,
        }));
      }
    }
    return () => {
      // jobDurationDetails.setJobDurationDetails([]);
      // jobLengthHours.setJobLengthHours(0);
      // jobLengthMinutes.setJobLengthMinutes(0);
    };
  }, []);

  // useEffect(() => {
  //   let showModal =
  //     chargeableStates?.chargeableStates &&
  //     chargeableStates?.chargeableStates.length !== 0 &&
  //     chargeableStates?.chargeableStates.find((item) => item === address.state.value) !== undefined;
  //   showModal && setSchedulingFeesModal(<SchedulingFeesModal handleCancel={() => resetTab()} handleConfirm={() => setSchedulingFeesModal(null)} />);
  // }, [chargeableStates.chargeableStates, address.state.value]);

  useEffect(() => {
    if (address.historicalAddress.value && !address.verified) {
      handleAddessVerification();
    }
    if (address.address1.success && !address.address2.error && address.city.success && address.state.success && address.zip.success) {
      if (verifyAddressIsDisable) {
        setVerifyAddressIsDisable(false);
      }
    } else if (!verifyAddressIsDisable) {
      setVerifyAddressIsDisable(true);
    }

    if (address.verified) {
      if (isFieldsDisabled) {
        setIsFieldsDisabled(false);
      }
      if (
        (source.source === "case" ? !caseInfo.error : !csp.error) &&
        workTicketNumber.success &&
        startDate.success &&
        vehicleCount.success &&
        jobLengthHours.jobLengthHours &&
        checkAvailabilityIsDisable
      ) {
        setCheckAvailabilityIsDisable(false);
      } else if (
        ((source.source === "case" ? caseInfo.error : csp.error) ||
          !workTicketNumber.success ||
          !startDate.success ||
          !vehicleCount.success ||
          !jobLengthHours.jobLengthHours) &&
        !checkAvailabilityIsDisable
      ) {
        setCheckAvailabilityIsDisable(true);
      }
    } else {
      setCheckAvailabilityIsDisable(true);
    }
  }, [
    address.verified,
    address.address1.success,
    address.address2.error,
    address.city.success,
    address.state.success,
    address.zip.success,
    csp.error,
    workTicketNumber.success,
    startDate.success,
    vehicleCount.success,
    jobLengthHours.jobLengthHours,
    address.historicalAddress.value,
  ]);

  const notify = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  const handleAdressFields = (field, value) => {
    if (address.verified) {
      setAddress((prevState) => ({ ...prevState, verified: false }));
      setVerifyAddressIsShown(true);
      setIsFieldsDisabled(true);
      setCheckAvailabilityIsDisable(true);
    }
    switch (field) {
      case "Address 1": {
        if (value.length) {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: false, errorText: "Enter address 1", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: true, errorText: "Enter a valid address 1", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address1: { value: "", error: true, errorText: "Enter address 1", success: false } }));
        }
        break;
      }
      case "Address 2": {
        if (value && value.length) {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: false, errorText: "Enter address 2", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: true, errorText: "Enter a valid address 2", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address2: { value: "", error: false, errorText: "Enter address 2", success: false } }));
        }
        break;
      }
      case "City": {
        value.length
          ? CITY_REGEX.test(value) && setAddress((prevState) => ({ ...prevState, city: { ...prevState.city, value, error: false, success: true } }))
          : setAddress((prevState) => ({ ...prevState, city: { ...prevState.city, value: "", error: true, success: false } }));
        break;
      }
      case "State": {
        // handleSchFeesModal(value);
        value.length
          ? setAddress((prevState) => ({ ...prevState, state: { ...prevState.state, value, error: false, success: true } }))
          : setAddress((prevState) => ({ ...prevState, state: { ...prevState.state, value, error: true, success: false } }));
        break;
      }
      case "Zip": {
        value = /[A-Za-z]/.test(value) ? value.replace(/[^\w]/g, "") : value;
        if (value.length) {
          if (ZIP_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: false, errorText: "Enter zip code", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: true, errorText: "Enter a valid zip code", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, zip: { value: "", error: true, errorText: "Enter zip code", success: false } }));
        }
        break;
      }
    }
  };

  const handleFields = (field, value) => {
    switch (field) {
      case "Name": {
        if (value && value.length) {
          if (NAME_REGEX.test(value)) {
            setName({ value, error: false, errorText: "Enter name", success: true });
          } else {
            setName({ value, error: true, errorText: "Enter a valid name", success: false });
          }
        } else {
          setName({ value: "", error: false, errorText: "Enter name", success: false });
        }
        break;
      }
      case "Phone": {
        let phoneRegex = value.replace(/[^\d]/g, "");
        if (phoneRegex && phoneRegex.length) {
          if (phoneRegex.length > 3) {
            value =
              phoneRegex.length < 7
                ? `(${phoneRegex.slice(0, 3)}) ${phoneRegex.slice(3)}`
                : `(${phoneRegex.slice(0, 3)}) ${phoneRegex.slice(3, 6)}-${phoneRegex.slice(6, 10)}`;
            SCHEDULER_PHONE_REGEX.test(phoneRegex.slice(0, 10))
              ? setPhone({ value, error: false, errorText: "Enter phone number", success: true })
              : setPhone({ value, error: true, errorText: "Enter a valid phone number", success: false });
          } else {
            setPhone({ value, error: true, errorText: "Enter a valid phone number", success: false });
          }
        } else {
          setPhone({ value: "", error: false, errorText: "Enter phone number", success: false });
        }
        break;
      }
      case "Email": {
        if (value && value.length) {
          if (EMAIL_REGEX.test(value)) {
            setEmail({ value, error: false, errorText: "Enter email", success: true });
          } else {
            setEmail({ value, error: true, errorText: "Enter a valid email", success: false });
          }
        } else {
          setEmail({ value: "", error: false, errorText: "Enter email", success: false });
        }
        break;
      }
      case "CSP": {
        let cspAccDetails = cspDetails.cspDetails.filter((cspDetail) => cspDetail.CSPName === value)[0];
        setCsp((prevState) => ({
          ...prevState,
          value: { id: cspAccDetails.CSPID, name: cspAccDetails.CSPName, owner: cspAccDetails.CSPOwner },
          error: false,
        }));
        break;
      }
      case "Work Ticket Number": {
        if (value.length) {
          if (WORKTICKETNUMBER_REGEX.test(value)) {
            setWorkTicketNumber({ value, error: false, errorText: "Enter work ticket number", success: true });
          } else {
            setWorkTicketNumber({ value, error: true, errorText: "Enter a valid work ticket number", success: false });
          }
        } else {
          setWorkTicketNumber({ value: "", error: true, errorText: "Enter work ticket number", success: false });
        }
        break;
      }
      case "Start Date": {
        let date = moment(value, "MMMM DD,YYYY").format("MM/DD/YYYY");
        setStartDate((prevState) => ({ ...prevState, value: date, error: false, success: true }));
        break;
      }
      case "Vehicle Count": {
        value
          ? VEHICLECOUNT_REGEX.test(value) && setVehicleCount((prevState) => ({ ...prevState, value, error: false, success: true }))
          : setVehicleCount((prevState) => ({ ...prevState, value: "", error: true, success: false }));
        break;
      }
    }
  };

  const handleAddessVerification = async () => {
    let isHistoricalAddress = address.historicalAddress.value ? true : false;
    let data = {
      inputAddress: {
        addressLine1: address.address1.value,
        addressLine2: address.address2.value ? address.address2.value : "",
        city: address.city.value,
        stateProvince: address.state.value,
        postalCode: address.zip.value,
        country: country,
      },
    };
    setAddressFieldsIsDisable(true);
    setVerifyAddressIsDisable(true);
    if (isHistoricalAddress) {
      setIsFieldsDisabled(true);
      setIsValidatingHistoricalAddress(true);
    } else {
      setIsValidatingAddress(true);
    }
    let response = await validateAddress(data);
    //setIsValidatingAddress(false);
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
      let responsedata = response.data.data;
      let stdAddress = responsedata?.standardizedInfo?.standardizedAddress;
      let userAddress = responsedata.inputAddress;
      setTimezone(responsedata?.location?.timezone);
      setAddress((prevState) => ({ ...prevState, country: country }));
      if (responsedata.standardizedInfo.passesVZValidation) {
        setVerifyAddressIsShown(false);
        let standardAddress = {
          address1: stdAddress.addressLine1.toLowerCase(),
          address2: stdAddress.subLocation ? stdAddress.subLocation.toLowerCase() : "",
          city: stdAddress.city.toLowerCase(),
          state: stdAddress.stateProvince.toLowerCase(),
          zip: stdAddress.postalCodeBase.toLowerCase(),
        };
        let userTypedAddress = {
          address1: userAddress.addressLine1.toLowerCase(),
          address2: userAddress.addressLine2 ? userAddress.addressLine2.toLowerCase() : "",
          city: userAddress.city.toLowerCase(),
          state: userAddress.stateProvince.toLowerCase(),
          zip: userAddress.postalCode.toLowerCase(),
        };
        if (!isEqual(standardAddress, userTypedAddress)) {
          setSuggestedAddress({
            address: `${stdAddress.addressLine1}, ${stdAddress.subLocation ? stdAddress.subLocation + ", " : ""}${stdAddress.city}, ${
              stdAddress.stateProvince
            }, ${stdAddress.postalCodeBase}, ${country}`,
            address1: stdAddress.addressLine1,
            address2: stdAddress.subLocation,
            city: stdAddress.city,
            state: stdAddress.stateProvince,
            zip: stdAddress.postalCodeBase,
          });
          setTypedAddress(
            `${userAddress.addressLine1}, ${userAddress.addressLine2 ? userAddress.addressLine2 + ", " : ""}${userAddress.city}, ${
              userAddress.stateProvince
            }, ${userAddress.postalCode}, ${country}`
          );
          setSuggestionsIsShown(true);
        } else {
          !isHistoricalAddress && setAddressFieldsIsDisable(false);
          setAddress((prevState) => ({ ...prevState, verified: true }));
        }
      } else {
        !isHistoricalAddress && setAddressFieldsIsDisable(false);
        isHistoricalAddress && handleNewAddress(false);
        setVerifyAddressIsDisable(false);
        let oldNotification = notification;
        notify("We couldn't verify the entered address and couldn't find a close match", "error");
        setTimeout(() => {
          setNotification(oldNotification);
        }, 5000);
      }
    } else {
      !isHistoricalAddress && setAddressFieldsIsDisable(false);
      isHistoricalAddress && handleNewAddress(false);
      setVerifyAddressIsDisable(false);
      let oldNotification = notification;
      notify("Address validation failed due to an unexpected error", "error");
      setTimeout(() => {
        setNotification(oldNotification);
      }, 5000);
    }
    isHistoricalAddress ? setIsValidatingHistoricalAddress(false) : setIsValidatingAddress(false);
  };

  const handleAddressSelection = (verified = false) => {
    if (verified) {
      handleAdressFields("Address 1", suggestedAddress.address1);
      handleAdressFields("Address 2", suggestedAddress.address2);
      handleAdressFields("City", suggestedAddress.city);
      handleAdressFields("State", suggestedAddress.state);
      handleAdressFields("Zip", suggestedAddress.zip);
    }
    setSuggestionsIsShown(false);
    setSuggestedAddress({
      address: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    });
    setTypedAddress("");
    setAddress((prevState) => ({ ...prevState, verified: true }));
    !address.historicalAddress.value && setAddressFieldsIsDisable(false);
  };
  const handleSubmit = () => {
    let minDate = handleStartDate();
    let selectedDate = new Date(startDate.value) < new Date(minDate) ? minDate : startDate.value;
    let data = {
      status: "OPEN",
      startDate: selectedDate,
      appointmentLength: jobLengthHours.jobLengthHours,
      estAppointmentLength: jobLengthMinutes.jobLengthMinutes,
      contactName: name.value,
      phone: phone.value,
      email: email.value,
      accountId: accountId.accountId,
      address1: address.address1.value,
      address2: address.address2.value ? address.address2.value : null,
      city: address.city.value,
      stateCode: address.state.value,
      country: country,
      zipCode: address.zip.value,
      timezone: timezone || "",
      customerName: accountDetails.customerName,
      customerNumber: accountDetails.customerNumber,
      vztSegment: accountDetails.vztSegment,
      modifiedAt: new Date(),
      modifiedBy: jobData && Object.keys(jobData).length ? accountDetails.sfdcUsername : null,
      createdAt: new Date(),
      createdBy: jobData && Object.keys(jobData).length ? null : accountDetails.sfdcUsername,
      isDeleted: false,
      extendedJobId: isExpandJob ? jobData.id : null,
      jobDetails: [
        {
          cspSfdcId: csp.value.id,
          primaryDevice: null,
          addOns: isNewJob ? "updated" : "changed",
          vehicleCount: vehicleCount.value,
          jobType: jobType,
          opportunityId: null,
          cspOwner: csp.value.owner,
          cspName: csp.value.name,
          caseSfdcId: caseInfo.value.id,
          caseOwner: caseInfo.value.owner,
          caseNumber: caseInfo.value.number,
          modifiedAt: new Date(),
          modifiedBy: jobData && Object.keys(jobData).length ? accountDetails.sfdcUsername : "",
          createdAt: new Date(),
          createdBy: jobData && Object.keys(jobData).length ? "" : accountDetails.sfdcUsername,
          isDeleted: false,
          workTickets: [
            {
              workTicket: workTicketNumber.value,
            },
          ],
        },
      ],
    };

    let durationDetails = jobDurationDetails.jobDurationDetails;
    if (durationDetails.length) {
      if (isNewJob) {
        durationDetails = jobDurationDetails.jobDurationDetails.filter((job) => job.quantity > 0);
      } else {
        durationDetails = jobDurationDetails.jobDurationDetails.filter(
          (job) => existingJobQuantityList.find((oldJobData) => oldJobData.id === job.id) || job.quantity > 0
        );
      }
      durationDetails = durationDetails.map((job) => {
        return { jobDurationId: job.id, quantity: job.quantity };
      });
    }
    data.jobDurationDetails = durationDetails;

    if (isNewJob) {
      handleJobCreation(data);
    } else {
      handleJobUpdate(data);
    }
  };

  const handleJobCreation = async (data) => {
    updateNewrelicStats();
    setLoader(true);
    if (!data.jobDurationDetails.length && isExpandJob) {
      let jobItemsResponse = await getJobDuration(capitalize(jobType), jobData.id);
      data.jobDurationDetails = jobItemsResponse.data.data
        .filter((job) => job.quantity > 0)
        .map((job) => ({ jobDurationId: job.id, quantity: job.quantity }));
    }
    let response = await addJob(data);
    if (response.status === 200 && response?.data?.data?.id) {
      clearFields();
      notify("Job created successfully", "success");
      sessionData(response.data.data.id);
      setTimeout(() => {
        setNotification(null);
        setLoader(false);
        addPage(ScheduleJobMetadata.id, { jobData: response.data.data, expandJobVendorID: isExpandJob ? jobData.vendorId : "" });
      }, 5000);
    } else {
      setLoader(false);
      notify("Job creation failed due to an unexpected error", "error");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const handleJobUpdate = async (data) => {
    updateNewrelicStats();
    data.jobDetails[0].id = jobData.jobDetails[0].jobDetailId;
    setLoader(true);
    let response = await updateJob(jobData.id, data);
    if (response.status === 200 && response?.data?.data?.id) {
      clearFields();
      notify("Job updated successfully", "success");
      sessionData(response.data.data.id);
      setTimeout(() => {
        setNotification(null);
        setLoader(false);
        addPage(ScheduleJobMetadata.id, { jobData: response.data.data, expandJobVendorID: "" });
      }, 5000);
    } else {
      setLoader(false);
      notify("Job update failed due to an unexpected error", "error");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const clearFields = () => {
    setExistingJobQuantityList([]);
    setName({ value: "", error: false, success: false, errorText: "Enter name" });
    setPhone({ value: "", error: false, success: false, errorText: "Enter phone" });
    setEmail({ value: "", error: false, success: false, errorText: "Enter email" });
    setCsp({ value: { id: "", name: "", owner: "" }, error: false, errorText: "Unable to fetch csp details due to an unexpected error" });
    setCaseInfo({ value: { id: "", number: "", owner: "" }, error: false, errorText: "Unable to fetch case details due to an unexpected error" });
    setWorkTicketNumber({ value: "", error: false, success: false, errorText: "Enter work ticket number" });
    setStartDate({ value: handleStartDate(), error: false, success: false, errorText: "Select start date" });
    setVehicleCount({ value: "", error: false, success: false, errorText: "Enter vehicle count" });
    jobDurationDetails.setJobDurationDetails([]);
    jobLengthHours.setJobLengthHours(0);
    jobLengthMinutes.setJobLengthMinutes(0);
    setAddress({
      verified: false,
      historicalAddress: { value: "", error: "false", errorText: "", success: false },
      address1: { value: "", error: false, errorText: "Enter address 1", success: false },
      address2: { value: "", error: false, errorText: "Enter address 2", success: false },
      city: { value: "", error: false, errorText: "Enter city", success: false },
      state: { value: "", error: false, errorText: "Select state", success: false },
      zip: { value: "", error: false, errorText: "Enter zip code", success: false },
      country: "",
    });
  };

  const splitAddress = (address) => {
    const addressParts = address.split(",");
    let address1 = "";
    let address2 = "";
    let city = "";
    let state = "";
    let zipCode = "";
    if (addressParts.length <= 5) {
      address1 = addressParts[0].trim();
      city = addressParts[addressParts.length - 3].trim();
      const stateZip = addressParts[addressParts.length - 2].trim().split(" ");
      state = stateZip.slice(0, stateZip.length - 1).join(" ");
      zipCode = stateZip.slice(-1).join("");
      if (addressParts.length > 4) {
        address2 = addressParts.slice(1, -3).join(",").trim();
      }
    }
    const addressObj = { address1, address2, city, state, zipCode };
    return addressObj;
  };

  const handleNewAddress = (addressValidationSuccess = true) => {
    setAddressFieldsIsDisable(false);
    setVerifyAddressIsShown(true);
    addressValidationSuccess
      ? setAddress({
          verified: false,
          historicalAddress: { value: "", error: false, errorText: "", success: false },
          address1: { value: "", error: false, errorText: "Enter address 1", success: false },
          address2: { value: "", error: false, errorText: "Enter address 2", success: false },
          city: { value: "", error: false, errorText: "Enter city", success: false },
          state: { value: "", error: false, errorText: "Select state", success: false },
          zip: { value: "", error: false, errorText: "Enter zip code", success: false },
          country: "",
        })
      : setAddress((prevState) => ({
          ...prevState,
          historicalAddress: { value: "", error: false, errorText: "", success: false },
        }));
    setIsFieldsDisabled(true);
    setCheckAvailabilityIsDisable(true);
    suggestionsIsShown && setSuggestionsIsShown(false);
  };

  // const handleSchFeesModal = (state) => {
  //   let showModal =
  //     chargeableStates?.chargeableStates &&
  //     chargeableStates?.chargeableStates.length !== 0 &&
  //     chargeableStates?.chargeableStates.find((item) => item === state) !== undefined;
  //   showModal && setSchedulingFeesModal(<SchedulingFeesModal handleCancel={() => resetTab()} handleConfirm={() => setSchedulingFeesModal(null)} />);
  // };

  const handleHistoricalAddressSelection = (address, verifiedAddress = false) => {
    if (address === "Add New") {
      handleNewAddress();
    } else {
      let splittedAddress = splitAddress(address);
      // handleSchFeesModal(splittedAddress.state);
      setAddress((prevState) => ({
        ...prevState,
        historicalAddress: { value: address, error: false, errorText: "", success: true },
        address1: { value: splittedAddress.address1, error: false, errorText: "Enter address 1", success: true },
        address2: { value: splittedAddress.address2, error: false, errorText: "Enter address 2", success: true },
        city: { ...prevState.city, value: splittedAddress.city, error: false, success: true },
        state: { ...prevState.state, value: splittedAddress.state, error: false, success: true },
        zip: { value: splittedAddress.zipCode, error: false, errorText: "Enter zip code", success: true },
        verified: verifiedAddress ? true : false,
      }));
      setVerifyAddressIsShown(false);
      suggestionsIsShown && setSuggestionsIsShown(false);
    }
  };

  return (
    <>
      {jobLengthModal && (
        <JobLengthModal
          jobLengthModal={jobLengthModal}
          setJobLengthModal={setJobLengthModal}
          setApptLength={setApptLength}
          jobData={jobData}
          jobType={jobType}
          isNewJob={isNewJob}
          setExistingJobQuantityList={setExistingJobQuantityList}
          notification={() => {
            notify("Unable to fetch Job Dutation Details due to an unexpected error.", "error");
            setTimeout(() => {
              setNotification(null);
            }, 5000);
          }}
        />
      )}
      <Loader active={isLoading} fullscreen />
      <Header>Job Details</Header>
      {notification}
      <CustomerDetails />
      <FlexRow>
        <SmallText bold css={{ marginBottom: "0" }}>
          {source.source === "case" ? "Support Agent: " : "CSP Owner Name: "}&nbsp;
        </SmallText>
        <SmallText> {source.source === "case" ? caseInfo.value.owner || "--" : csp.value.owner || "--"}</SmallText>
      </FlexRow>
      <UltraMediumText css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
        Installation Address
      </UltraMediumText>
      <FlexRow>
        <div css={HistoricalAddressRow}>
          <CustomDropdown
            type="text"
            disabled={isExpandJob}
            errorText={address.historicalAddress.errorText}
            error={address.historicalAddress.error}
            success={address.historicalAddress.success}
            value={
              address.historicalAddress.value ? address.historicalAddress.value : "Please select an address or choose Add New to add a new address"
            }
            onChange={(event) => handleHistoricalAddressSelection(event.target.value)}
          >
            {historicalAddressDetails.historicalAddressDetails.length > 0 ? (
              <>
                <option disabled>Please select an address or choose Add New to add a new address</option>
                {historicalAddressDetails.historicalAddressDetails.map((address) => (
                  <option key={generate()}>{address}</option>
                ))}
                <option>Add New</option>
              </>
            ) : (
              <>
                <option disabled>Please select an address or choose Add New to add a new address</option>
                <option disabled>No Address Available</option>
                <option>Add New</option>
              </>
            )}
          </CustomDropdown>
        </div>
        {isValidatingHistoricalAddress && (
          <FlexColumn css={CustomHistoricalLoader}>
            <Loader active fullscreen={false} />
            <SmallText bold css={{ paddingLeft: "2rem" }}>
              Verifying address...
            </SmallText>
          </FlexColumn>
        )}
      </FlexRow>
      <Grid>
        <Row css={{ marginTop: "1.25rem" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <CustomInput
              type="text"
              label="Address 1"
              disabled={addressFieldsIsDisable}
              error={address.address1.error}
              errorText={address.address1.errorText}
              success={address.address1.success}
              value={address.address1.value}
              onChange={(event) => handleAdressFields("Address 1", event.target.value)}
            />
          </CompactCol>
        </Row>
        <Row>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <CustomInput
              type="text"
              label="Address 2 (Optional)"
              disabled={addressFieldsIsDisable}
              error={address.address2.error}
              errorText={address.address2.errorText}
              success={address.address2.success}
              value={address.address2.value}
              onChange={(event) => handleAdressFields("Address 2", event.target.value)}
            />
            <Grid>
              <Row>
                <CompactCol colSizes={{ mobile: 4, tablet: 10, desktop: 4 }}>
                  <CustomInput
                    width="99%"
                    type="text"
                    label="City"
                    disabled={addressFieldsIsDisable}
                    error={address.city.error}
                    errorText={address.city.errorText}
                    success={address.city.success}
                    value={address.city.value}
                    onChange={(event) => handleAdressFields("City", event.target.value)}
                  />
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 10, desktop: 4 }}>
                  <CustomDropdown
                    width="99%"
                    marginBottom="0.6rem"
                    paddingRight="5px"
                    type="text"
                    label="State"
                    disabled={addressFieldsIsDisable}
                    disableValue={!address.state.value}
                    error={address.state.error}
                    errorText={address.state.errorText}
                    value={address.state.value ? address.state.value : "Please Select"}
                    onChange={(event) => handleAdressFields("State", event.target.value)}
                  >
                    <>
                      <option disabled style={{ backgroundColor: "lightgrey" }}>
                        Please Select
                      </option>
                      {countryStates.map((stateCode) => (
                        <option key={stateCode}>{stateCode}</option>
                      ))}
                    </>
                  </CustomDropdown>
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 10, desktop: 4 }}>
                  <CustomInput
                    width="99%"
                    type="text"
                    label="Zip"
                    disabled={addressFieldsIsDisable}
                    error={address.zip.error}
                    errorText={address.zip.errorText}
                    success={address.zip.success}
                    value={address.zip.value}
                    onChange={(event) => handleAdressFields("Zip", event.target.value)}
                  />
                </CompactCol>
              </Row>
            </Grid>

            <FlexRow>
              {verifyAddressIsShown && (
                <CustomSLButton disabled={verifyAddressIsDisable} onClick={handleAddessVerification}>
                  Verify address
                </CustomSLButton>
              )}
              {isValidatingAddress && (
                <FlexColumn css={CustomLoader}>
                  <Loader active fullscreen={false} />
                  <SmallText bold css={{ paddingLeft: "2rem" }}>
                    Verifying address...
                  </SmallText>
                </FlexColumn>
              )}
            </FlexRow>

            {suggestionsIsShown && (
              <>
                <UltraMediumText eDSFont fontSize="18px" marginBottom="0" marginTop="1rem">
                  Choose an option
                </UltraMediumText>
                <SmallText>We couldn&apos;t verify the entered address. We found a close match</SmallText>
                <RadioButtonGroupWrapper>
                  <RadioButtonGroup
                    onChange={() => handleAddressSelection(true)}
                    error={false}
                    data={[
                      {
                        name: "group",
                        label: (
                          <>
                            <span>Use suggested address</span>
                            <mark css={{ fontSize: "10px", backgroundColor: "#d8dada", padding: "0rem 0.313rem", marginLeft: "0.313rem" }}>
                              Verified
                            </mark>
                          </>
                        ),
                        children: suggestedAddress.address,
                        value: "radioOne",
                        ariaLabel: "radio one",
                      },
                    ]}
                  />
                  <RadioButtonGroup
                    onChange={() => handleAddressSelection()}
                    error={false}
                    data={[
                      {
                        name: "group",
                        label: (
                          <>
                            <span>Use address as typed</span>
                          </>
                        ),
                        children: typedAddress,
                        value: "radioTwo",
                        ariaLabel: "radio two",
                      },
                    ]}
                  />
                </RadioButtonGroupWrapper>
              </>
            )}
            {/* {schedulingFeesModal} */}
            <UltraMediumText eDSFont fontSize="18px" marginBottom="0" marginTop="2rem">
              Contact Details
            </UltraMediumText>
            <SmallText>Customer contact details for install address</SmallText>
            <CustomInput
              width="85%"
              type="text"
              label="Contact Name (Optional)"
              disabled={isFieldsDisabled}
              error={name.error}
              errorText={name.errorText}
              success={name.success}
              value={name.value}
              maxLength={50}
              onChange={(event) => handleFields("Name", event.target.value)}
            />
            <CustomInput
              width="75%"
              type="text"
              label="Phone (Optional)"
              disabled={isFieldsDisabled}
              error={phone.error}
              errorText={phone.errorText}
              success={phone.success}
              value={phone.value}
              onChange={(event) => handleFields("Phone", event.target.value)}
            />
            <CustomInput
              width="85%"
              type="text"
              label="Email (Optional)"
              disabled={isFieldsDisabled}
              error={email.error}
              errorText={email.errorText}
              success={email.success}
              value={email.value}
              onChange={(event) => handleFields("Email", event.target.value)}
            />
            <UltraMediumText eDSFont fontSize="18px" marginBottom="0.4rem" marginTop="2.2rem">
              Job Details
            </UltraMediumText>
            {source.source === "case" ? (
              <>
                <SmallText color={isFieldsDisabled ? "#d8dada" : ""} css={{ marginTop: "0.8rem" }}>
                  Case
                </SmallText>
                <SmallText color={isFieldsDisabled ? "#d8dada" : ""} css={{ marginRight: "1rem", marginBottom: "0.8rem" }}>
                  {caseInfo.value.number || "N/A"}
                </SmallText>
              </>
            ) : (
              <CustomDropdown
                width="60%"
                type="text"
                label="CSP"
                marginBottom="0.3rem"
                disabled={isFieldsDisabled}
                disableValue={!csp.value.name}
                error={csp.error}
                errorText={csp.errorText}
                value={csp.value.name ? csp.value.name : "Please select"}
                onChange={(event) => handleFields("CSP", event.target.value)}
              >
                <>
                  {cspDetails.cspDetails.length ? (
                    cspDetails.cspDetails.sort().map((item) => (
                      <option key={item.CSPName} value={item.CSPName}>
                        {item.CSPName}
                      </option>
                    ))
                  ) : (
                    <option disabled style={{ backgroundColor: "lightgrey" }}>
                      Please select
                    </option>
                  )}
                </>
              </CustomDropdown>
            )}
            <CustomInput
              width="75%"
              type="text"
              label="Work Ticket Number"
              disabled={isFieldsDisabled}
              error={workTicketNumber.error}
              errorText={workTicketNumber.errorText}
              success={workTicketNumber.success}
              value={workTicketNumber.value}
              onChange={(event) => handleFields("Work Ticket Number", event.target.value)}
            />
            <CustomDropdown
              width="60%"
              label="Job Type"
              errorText="Select a Job Type"
              marginBottom="0.3rem"
              disabled={isFieldsDisabled}
              disableValue={!jobType}
              error={false}
              value={jobType ? jobType : "Please Select"}
              onChange={(event) => setJobType(event.target.value)}
            >
              {source.source === "case" ? (
                <>
                  <option disabled style={{ backgroundColor: "lightgrey" }}>
                    Please Select
                  </option>
                  <option key="Support/RMA" value="Support/RMA">
                    Support/RMA
                  </option>
                  <option key="Support/De-Re" value="Support/De-Re">
                    Support/De-Re
                  </option>
                  <option key="3G-4G/Migration" value="3G-4G/Migration">
                    3G-4G/Migration
                  </option>
                </>
              ) : (
                <option key="INSTALL" value="INSTALL">
                  INSTALL
                </option>
              )}
            </CustomDropdown>
            <CalendarWrapper>
              <CustomInput
                width="88%"
                type="calendar"
                label="Select Job Start Date"
                disabled={isFieldsDisabled}
                error={startDate.error}
                errorText={startDate.errorText}
                success={startDate.success}
                value={startDate.value}
                selectedDate={new Date(startDate.value)}
                minDate={new Date(handleStartDate())}
                onChange={(event) => handleFields("Start Date", event)}
              />
            </CalendarWrapper>
            <CustomInput
              width="75%"
              type="text"
              label="Vehicle Count"
              helperText="Enter a whole number."
              disabled={isFieldsDisabled}
              error={vehicleCount.error}
              errorText={vehicleCount.errorText}
              success={vehicleCount.success}
              value={vehicleCount.value}
              onChange={(event) => handleFields("Vehicle Count", parseInt(event.target.value))}
            />
            <SmallText color={isFieldsDisabled ? "#d8dada" : ""}>Job Length (Hours)</SmallText>
            <FlexRow>
              {jobLengthHours.jobLengthHours > 0 && (
                <MediumText color={isFieldsDisabled ? "#d8dada" : ""} css={{ marginRight: "1rem" }}>
                  {jobDurationFormatter(jobLengthHours.jobLengthHours)}
                </MediumText>
              )}
              <TextLink disabled={isFieldsDisabled || !jobType} onClick={() => setJobLengthModal(true)}>
                {jobLengthHours.jobLengthHours ? `Recalculate` : `Calculate`}
              </TextLink>
            </FlexRow>
          </CompactCol>
        </Row>
      </Grid>
      <FlexRow css={{ marginTop: "1rem" }}>
        <SLButton onClick={handleSubmit} disabled={checkAvailabilityIsDisable}>
          Check availability
        </SLButton>
        <SLButton secondary onClick={() => resetTab()}>
          Cancel
        </SLButton>
      </FlexRow>
    </>
  );
}

export const CreateNewJobMetadata = {
  name: "Create New Job",
  id: "create_new_job",
  component: lazy(() => import("./CreateNewJob")),
  route: "/create_new_job",
};
