import { getProperty } from "../../utility/getProperty";
import { postRequest } from "../RestServices";
import * as urls from "../../api/checkin/index";
export const updateVtuTable = async (payload) => {
  try {
    const _response = await postRequest(urls.UPDATE_SERVICE_REQUEST_NOTE, payload);
    return _response;
  } catch (err) {
    return err;
  }
};
