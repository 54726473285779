import { getRequest } from "../RestServices";
import * as urls from "../../api/aggregator";
import { getProperty } from "../../utility/getProperty";

export const batchHistory = async () => {
  try {
    const _response = await getRequest(urls.UPLOADUNITS);
    const _VtuDetails = getProperty(_response, "data.data", []);
    return { rows: _VtuDetails };
  } catch (err) {
    return {
      error: err,
    };
  }
};
