import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Icon } from "@vds/icons";
import { Title, Body } from "@vds/typography";
import { useEffect, useState, forwardRef } from "react";
import { Header, SLButton } from "../../components/core/Controls";
import { FlexColumn, FlexRow, MediumText, SmallText, Spacer, UltraMediumText } from "../../components/core/Utility";
import { StyledModal } from "./RescheduleModal";
import { CustomDropdown } from "./CreateNewJob";
import { Dropdown } from "primereact/dropdown";
import { CustomisedInput } from "./CompleteJob";

const RescheduleReasonModal = ({ rescheduleReasonModalStatus, setRescheduleReasonModalStatus, handleReschedule }) => {
  const [reason, setReason] = useState({});

  const changeRescheduleReason = (e) => {
    let selectedReason = rescheduleReasonModalStatus.reasonList.filter((item) => item.description === e.target.value)[0];
    setReason(selectedReason);
  };
  return (
    <Modal
      id="Reschedule Reason Modal"
      ariaLabel="Reschedule Reason Modal"
      opened={true}
      fullScreenDialog={false}
      disableOutsideClick={true}
      css={StyledModal}
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <Title size="medium">Reschedule Job?</Title>
          <div
            onClick={() => setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false })}
            css={{ marginRight: "1rem", marginTop: "0.3rem" }}
          >
            <Icon name="close" size="medium" color="#000000" style={{ cursor: "pointer", outline: "none" }} />
          </div>
        </FlexRow>
      }
    >
      <ModalBody css={{ paddingLeft: "0", paddingRight: "0" }}>
        <Body>
          The job will be rescheduled for {rescheduleReasonModalStatus.slotTime} {rescheduleReasonModalStatus.timezone}
        </Body>
        <Spacer top="1rem" />
        <Dropdown
          value={reason.description || "Please select"}
          options={rescheduleReasonModalStatus.reasonList}
          onChange={(e) => changeRescheduleReason(e)}
          css={[CustomisedInput, { width: "75%" }]}
          scrollHeight="7.5rem"
          optionLabel="description"
          optionValue="description"
          placeholder="Please select"
        />
      </ModalBody>
      {/* <ModalFooter> */}
      <FlexRow css={{ justifyContent: "flex-end", paddingTop: "6.5rem" }}>
        <SLButton size="large" css={{ width: "10rem" }} onClick={() => handleReschedule(reason)} disabled={!reason.code}>
          Confirm
        </SLButton>
        <SLButton
          size="large"
          secondary
          css={{ width: "10rem" }}
          onClick={() => setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false })}
        >
          Cancel
        </SLButton>
      </FlexRow>
      {/* </ModalFooter> */}
    </Modal>
  );
};

export default RescheduleReasonModal;
