//3RD PARTY LIBRARIES / FUNCTIONS.
import React, { useState, useEffect, useRef } from "react";
import noop from "lodash/noop";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import { withTheme } from "@emotion/react";

//REACT COMPONENTS.
import { Icon } from "@vds/icons";
import {
  StyledTab,
  TabIcon,
  TabLabel,
  TabWithCross,
  TabLabelWrapper,
  CloseIcon,
  TabTypeCount,
  MoreTabTypeCount,
} from "../../../pages/HomePage/HomePage.style";

//UTILITIES.
import { getProperty } from "../../../utility/getProperty";
import { getPageMetadataById, updateAddress } from "../../../utility/navigation";
import { DropdownMarketingOption } from "@vds/selects";
import { CustomDropdown } from "./LeftNavigation/LeftNavigation.style";

//CONSTANTS.
const SMALL_WIDTH = 170; // reduced width of the tab when we have more tabs.
/** const LARGE_WIDTH = 193; // normal fixed width of tab when tab can accommodate in visible area.*/
const MORE_BTN_WIDTH = 120;
const ICONS_WIDTH = 150;

const BlockTabs = ({ tabsData = [], selectedIndex = 0, onChange = noop, areAllTabsClosed = true, isOpenSM = false, theme }) => {
  const ADD_BTN_WIDTH = isOpenSM ? 0 : 50;

  //COMPONENT STATE = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  const [state, setState] = useState({
    maxVisibleItems: 0, //Maximum number of tabs that can be shown.
    showHiddenList: false, //If hidden tabs is to be shown.
    allowRender: false,
  });

  //REFERENCES = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  const visibleSelectedIndex = useRef(selectedIndex); //Index of the selected visible tab.
  const tabsDataWithUpdatedOrder = useRef([]); //Copy of master tabs data in order as per the local configuration.

  //HANDLER FUNCTIONS = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

  /**
   * @description When visible tab is selected,
   * If the tab is not already selected, inform parent about the selection.
   * @param {*} itemIndex
   */
  const onVisibleTabSelect = (itemIndex) => {
    if (itemIndex !== selectedIndex) {
      onChange(cloneDeep(tabsDataWithUpdatedOrder.current), itemIndex);
    }
  };

  /**
   * @description When visible tab is closed,
   * Prevent event from going up to the "document" object.
   * If the tab is after the current selected tab, no impact on the selected tab index.
   * If the tab is before the current selected tab, the seleted tab should move behind by one unit.
   * Remove the tab from master data and pass updated values to the parent.
   * @param {*} event
   * @param {*} itemIndex
   */
  const onCloseVisibleTab = (event, itemIndex) => {
    event && event.stopPropagation();
    const data = cloneDeep(tabsDataWithUpdatedOrder.current);

    let newSelectedIndex = visibleSelectedIndex.current;
    if (itemIndex < newSelectedIndex) {
      newSelectedIndex = visibleSelectedIndex.current - 1;
    } else if (itemIndex === visibleSelectedIndex.current) {
      newSelectedIndex = visibleSelectedIndex.current - 1 > 0 ? visibleSelectedIndex.current - 1 : 0;
    }
    data.splice(itemIndex, 1);
    onChange(data, newSelectedIndex);
  };

  /**
   * @description When Tab close button is Clicked.
   * This function provides option for a callback to
   * Parent Component before force close of tab*
   * @param {*} tabDetails, itemIndex
   */

  /**
   * @description When hidden tab is selected,
   * Find the tab's index in the parent master data.
   * Swap active tab data with hidden tab data.
   * Update parent about the new data and selected index.
   * @param {*} itemIndex
   */
  const onHiddenTabSelect = (itemIndex) => {
    const parentIndex = state.maxVisibleItems + itemIndex;
    const masterData = cloneDeep(tabsDataWithUpdatedOrder.current);
    const temp = cloneDeep(masterData[visibleSelectedIndex.current]);
    masterData[visibleSelectedIndex.current] = cloneDeep(tabsDataWithUpdatedOrder.current[parentIndex]);
    masterData[parentIndex] = cloneDeep(temp);
    onChange(masterData, visibleSelectedIndex.current);
  };

  /**
   * @description, When hidden tab is closed,
   * Find the index of the hidden tab in master data.
   * Remove hidden tab from master data.
   * Update parent about new data and index.
   * @param {*} event
   * @param {*} itemIndex
   */
  const onCloseHiddenTab = (event, itemIndex) => {
    event && event.stopPropagation();
    const parentIndex = state.maxVisibleItems + itemIndex;
    const masterData = cloneDeep(tabsDataWithUpdatedOrder.current);
    masterData.splice(parentIndex, 1);
    onChange(masterData, visibleSelectedIndex.current);
  };

  /**
   * @description When "More" button is clicked,
   * Toggle the visibility of the hidden lsit.
   */
  /**
   * @description When the click event is triggered anywhere in the document,
   * If the hidden list is visible, hide it.
   */
  /**
   * @description When the window is resized,
   * Calculate how many tabs can be shown without hiding.
   * Based on that update the width of each tab.
   */
  const onWindowResize = throttle(() => {
    const el = document.querySelector("main");
    const iconSection = document.querySelector("#iconWrapper");
    if (el) {
      const availableArea = el.offsetWidth - (ADD_BTN_WIDTH + MORE_BTN_WIDTH + getProperty(iconSection, "offsetWidth", ICONS_WIDTH));
      const maxTabsAllowed = Math.floor(availableArea / SMALL_WIDTH);
      setState({
        ...state,
        maxVisibleItems: maxTabsAllowed,
      });
    }
  }, 500);

  //SIDE EFFECTS = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

  /**
   * @description When parent data changes,
   * Calculate how many tabs can be shown without hiding.
   * Calculate width of each tab.
   */
  useEffect(() => {
    const el = document.querySelector("main");
    const iconSection = document.querySelector("#iconWrapper");
    if (el) {
      const availableArea = el.offsetWidth - (ADD_BTN_WIDTH + MORE_BTN_WIDTH + getProperty(iconSection, "offsetWidth", ICONS_WIDTH));
      const maxTabsAllowed = Math.floor(availableArea / SMALL_WIDTH);
      setState({
        ...state,
        maxVisibleItems: maxTabsAllowed,
        allowRender: true,
      });
    }
  }, [tabsData.length]);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [onWindowResize]);

  //RENDER LOGIC = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

  const hideTabs = state.maxVisibleItems < tabsData.length; //If required to hide tabs?
  let visibleTabs = cloneDeep(tabsData); //Visible tabs data.
  let hiddenTabs = []; //Hiden tabs data.
  visibleSelectedIndex.current = selectedIndex; //Current visible selected tab index.

  if (hideTabs) {
    //If tabs are to be hidden,
    visibleTabs = tabsData.slice(0, state.maxVisibleItems); //Get visible tabs.
    hiddenTabs = tabsData.slice(state.maxVisibleItems); //Get hidden tabs.
  }

  const visibleLastIndex = visibleTabs.length - 1; //Last index of visible tabs.

  /**
   * If selected tab is in hidden list,
   * Swap the active item in visible list with the selected tab in hidden list.
   */
  if (selectedIndex >= state.maxVisibleItems && state.allowRender) {
    const hiddenSelectedIndex = hiddenTabs.length - 1;
    const temp = visibleTabs[visibleLastIndex];
    visibleTabs[visibleLastIndex] = cloneDeep(tabsData[selectedIndex]);
    hiddenTabs[hiddenSelectedIndex] = temp;
    visibleSelectedIndex.current = visibleLastIndex;
  }

  tabsDataWithUpdatedOrder.current = [...visibleTabs, ...hiddenTabs]; //Updated master data after order change.
  if (!state.allowRender) {
    return null;
  }

  return (
    !areAllTabsClosed && (
      <>
        <TabWithCross
          linePosition="none"
          id="tabWithCross"
          indicatorPosition="top"
          className="headerTab"
          selectedIndex={visibleSelectedIndex.current}
          onTabChange={(event, tabIndex) => {
            onVisibleTabSelect(tabIndex);
          }}
        >
          {visibleTabs.map((tabItem, index) => {
            return (
              tabItem && (
                <StyledTab
                  tabWidth={hiddenTabs.length > 0 ? SMALL_WIDTH : "max-content"}
                  key={getProperty(tabItem, "tabId", "")}
                  id={getProperty(tabItem, "tabId", "")}
                  label={
                    <div key={getProperty(tabItem, "tabId", "")} className="remove-link-style">
                      <TabLabelWrapper>
                        <TabLabel className="tabLabel">
                          {getProperty(tabItem, "typeCount", "") && <TabTypeCount>{getProperty(tabItem, "typeCount", "") || ""}</TabTypeCount>}
                          {getProperty(tabItem, "tabLabel", "")}
                        </TabLabel>
                        <TabIcon className="tabIcon" onClick={(e) => onCloseVisibleTab(e, index)}>
                          <Icon name="close" size="small" color={theme.text} backgroundColor="transparent" />
                        </TabIcon>
                      </TabLabelWrapper>
                    </div>
                  }
                ></StyledTab>
              )
            );
          })}
        </TabWithCross>
        {hiddenTabs.length > 0 && (
          <CustomDropdown placeholder="More" size="small" viewport="desktop" error={false} width="200px">
            {hiddenTabs.map((tabItem, index) => {
              return (
                tabItem && (
                  <DropdownMarketingOption>
                    <div onClick={() => onHiddenTabSelect(index)}>
                      {tabItem.typeCount && <MoreTabTypeCount>{tabItem.typeCount || ""}</MoreTabTypeCount>}
                      {tabItem.tabLabel}
                    </div>
                    <CloseIcon onClick={(e) => onCloseHiddenTab(e, index)}>
                      <Icon name="close" aria-label="close" size="small" alt="close-icon" color={theme.text} backgroundColor="transparent" />
                    </CloseIcon>
                  </DropdownMarketingOption>
                )
              );
            })}
          </CustomDropdown>
        )}
      </>
    )
  );
};
export default withTheme(BlockTabs);
