import { postRequest } from "../RestServices";
import * as urls from "../../api/checkin/index";
import { getProperty } from "../../utility/getProperty";
export const getLineItems = async (payload) => {
  try {
    const _response = await postRequest(urls.GET_LINE_ITEMS, payload);
    const _lineItems = getProperty(_response, "data", []);
    return { lineItems: _lineItems };
  } catch (err) {
    return {
      error: err,
    };
  }
};
