import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const addFeesJob = async (payload, jobId) => {
  try {
    return await postRequest(urls.ADDFEES_JOB + `/${jobId}`, payload);
  } catch (err) {
    return err?.response;
  }
};
