// Scheduler Endpoints

const BaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_WORKTICKET}`;

export const GET_CSP_DETAILS = `${BaseUrl}/cspAccountDetails`;
export const UPLOAD_CSV = `${BaseUrl}/uploadcsvfile`;
export const GET_VEHICLE_INTAKE_LIST = `${BaseUrl}/vehicleIntakeList`;
export const VALIDATE_ADDRESS = `${BaseUrl}/validateaddress`;
export const CREATE_WORK_TICKET = `${BaseUrl}/createworkticket`;
export const WORK_TICKET_TABLE_LIST = `${BaseUrl}/workticketlist`;
export const DELETE_WORK_TICKET = `${BaseUrl}/workticket`;
export const DELETE_VIN = `${BaseUrl}/vehicle`;
export const GET_MATERIAL_LIST = `${BaseUrl}/materiallist`;
export const GET_MATERIAL_DETAILS = `${BaseUrl}/material-details`;
export const SAVE_MATERIAL_DETAILS = `${BaseUrl}/save/material-contract-details`;
export const UPDATE_MATERIALS = `${BaseUrl}/materialcontract`;
export const GET_SAP_ID_LIST = `${BaseUrl}/contractinfo`;
export const SAVE_HARDWARE_CASE = `${BaseUrl}/hardware/add/CASE`;
export const SAVE_HARDWARE_CSP = `${BaseUrl}/hardware/add/CSP`;
export const UPDATE_HARDWARE_CASE = `${BaseUrl}/hardware/edit/CASE`;
export const UPDATE_HARDWARE_CSP = `${BaseUrl}/hardware/edit/CSP`;
export const HARDWARE_TABLE_LIST_CASE = `${BaseUrl}/hardwares/CASE`;
export const HARDWARE_TABLE_LIST_CSP = `${BaseUrl}/hardwares/CSP`;
export const GET_POSSUIRS = `${BaseUrl}/possuirs`;
export const GET_POSSUIRSREPORT = `${BaseUrl}/possuirsReport`;
export const ATP_CHECK = `${BaseUrl}/atpcheck`;
export const GET_FEATURE_LIST = `${BaseUrl}/features`;
export const GET_MMID_LIST = `${BaseUrl}/materialids`;
export const GET_MMID_DESCRIPTION_LIST = `${BaseUrl}/mmid-description`;
export const GET_SAPID_LIST = `${BaseUrl}/sapids`;
export const GET_SAPID_DESCRIPTION_LIST = `${BaseUrl}/sapid-description`;
export const GET_CONTRACT_DETAILS = `${BaseUrl}/contract-details`;
export const GET_SALESORG_CATEGORY_LIST = `${BaseUrl}/salesorgandcategorylist`;
export const ADD_VEHICLE = `${BaseUrl}/vehicleintake`;
export const MMID_SEARCH_CASE = `${BaseUrl}/material-data/CASE`;
export const MMID_SEARCH_CSP = `${BaseUrl}/material-data/CSP`;
export const MMID_SEARCH_INDIVIDUALRETURN = `${BaseUrl}/material-data/INDIVIDUAL-RETURN`;
export const GET_CHANGE_HISTORY_USERS_LIST = `${BaseUrl}/audit-users`;
export const CHANGE_HISTORY_FILTER = `${BaseUrl}/mmidchangehistorybyfilter`;
export const FETCH_MMID_AUDIT_LOGS = `${BaseUrl}/mmid-audit-logs`;
export const EDIT_VEHICLE = `${BaseUrl}/editvehicleintake`;
/* Start - VZC42148-82 & VZC42148-292 */
export const QUANTITY_VALIDATION = `${BaseUrl}/qtyValidation`;
export const QUANTITY_TRANSACTION = `${BaseUrl}/qtyTransaction`;
export const FETCH_SAPID = `${BaseUrl}/sap-contract`;
/* End */

//return tickets
export const FETCH_RETURN_TICKETS = `${BaseUrl}/return`;
export const ADD_RETURN_ITEMS = `${BaseUrl}/return`;
export const UPDATE_RETURN_ITEMS = `${BaseUrl}/return`;
export const DELETE_RETURN_ITEMS = `${BaseUrl}/return`;
export const SUBMIT_RETURN_ITEM = `${BaseUrl}/submitreturn`;

export const GET_STATES_LIST = `${BaseUrl}/statelist`;
//addlostshipment
export const ADD_LOST_SHIPMENT = `${BaseUrl}/addlostshipment`;

//workticketUpdate
export const WORKTICKET_UPDATE = `${BaseUrl}/details/workticketUpdate`;

//cancelworkticket
export const CANCEL_WORKTICKET = `${BaseUrl}/cancelworkticket`;

export const GET_PROCESSING_CODES = `${BaseUrl}/processingcode`;
//individualreturn
export const INDIVIDUAL_RETURN = `${BaseUrl}/createreturn`;

//workticketMateriallist
export const GET_WORKTICKET_MATERIAL_LIST = `${BaseUrl}/workticketMateriallist`;
//workticketVinlist
export const GET_WORKTICKET_VIN_LIST = `${BaseUrl}/workticketVinlist`;
//vinmateriallist
export const GET_VIN_MATERIAL_LIST = `${BaseUrl}/vinmateriallist`;
//Processing Code
export const GET_MATERIAL_GROUP_LIST = `${BaseUrl}/materialgroups`;
export const UPDATE_MATERIAL_GROUPS = `${BaseUrl}/materialgroup`;
export const SEARCH_MATERIAL = `${BaseUrl}/materialMapping`;
export const SEARCH_CONTRACT = `${BaseUrl}/contract`;
