import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const installerIDSearch = async (installerID) => {
  try {
    return await getRequest(urls.INSTALLERID_SEARCH + `/${installerID}`);
  } catch (err) {
    return err;
  }
};
