// Constants
import { TabHeadings, TabsWrapper } from "./HomePage.style";

// Common Components
import BlockTabs from "../../components/features/HomePageHelperComponents/BlockTabs";

import { AddTab } from "../../components/features/HomePageHelperComponents/Add";

function TopHeaderPanel({ tabListData, selectedIndex, handleOpenTab, blockTabsUpdateCallbkack }) {
  return (
    <>
      <TabHeadings>
        <TabsWrapper id="tabWrapper">
          <BlockTabs
            tabsData={tabListData}
            selectedIndex={selectedIndex}
            areAllTabsClosed={!tabListData.length}
            onChange={blockTabsUpdateCallbkack}
          />
          <AddTab onClick={handleOpenTab} />
        </TabsWrapper>
      </TabHeadings>
    </>
  );
}

export default TopHeaderPanel;
