import { OktaAuth } from "@okta/okta-auth-js";
// eslint-disable-next-line import/no-unresolved
import { useIdleTimer } from "react-idle-timer";
import React, { useState, useRef, useEffect } from "react";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds/modals";
import config from "./config";
import jwt_decode from "jwt-decode";
import { updateToken } from "../utility/tokenOperations";

export default function IdleComponent({ logout }) {
  const [modalIsOpen, setModalisOpen] = useState(false);
  const sessionTimeoutRef = useRef(null);
  const accessTokenRequestRef = useRef(null);
  const oktaAuth = new OktaAuth(config.oidc);
  oktaAuth.start();
  const addRefreshToken = async () => {
    try {
      const renewToken = await oktaAuth.token.renewTokens();
      oktaAuth.tokenManager.setTokens(renewToken);
      updateToken();
    } catch (err) {
      updateToken();
      postCallFailed();
    }
  };

  useEffect(() => {
    let intervalTime = 240;
    const token = oktaAuth.getAccessToken();
    try {
      if (token) {
        var decoded = jwt_decode(token);
        intervalTime = decoded.exp - 40 - decoded.iat;
      }
    } catch (error) {
      intervalTime = 240;
    }
    accessTokenRequestRef.current = setInterval(addRefreshToken, intervalTime * 1000); // this will call every 60 seconds iinterval
    return () => {
      oktaAuth.stop();
      clearTimeout(accessTokenRequestRef.current);
    };
  }, []);
  const onIdle = () => {
    setModalisOpen(true);
    sessionTimeoutRef.current = setTimeout(logoff, 2 * 60 * 60 * 1000); //2 hours idle because of c360
  };
  const postCallFailed = () => {
    clearTimeout(accessTokenRequestRef.current);
    clearTimeout(sessionTimeoutRef.current);
    logout();
  };
  const stayLoggedIn = async () => {
    setModalisOpen(false);
    await addRefreshToken();
  };
  const logoff = () => {
    setModalisOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    clearTimeout(accessTokenRequestRef.current);
    logout();
  };
  useIdleTimer({
    crossTab: true,
    timeout: 2 * 60 * 60 *1000, // 2 hr idle
    onIdle: onIdle,
  });
  return (
    <Modal opened={modalIsOpen} role="dialog" ariaLabel="Session TimeOut" disableOutsideClick closeButton={null} contentLabel="Example Modal">
      <ModalTitle>Session TimeOut</ModalTitle>
      <ModalBody style={{ fontSize: "20px", marginTop: "10px" }}>
        You are being timed out due to inactivity. Please choose to stay signed in or to logoff. Otherwise, you will be logged off automatically.
      </ModalBody>
      <br />
      <ModalFooter
        buttonData={{
          primary: {
            ariaLabel: "Stay Logged In",
            role: "button",
            children: "Stay Logged In",
            onClick: () => stayLoggedIn(),
          },
          close: {
            role: "button",
            ariaLabel: "Log Out",
            children: "Log Out",
            onClick: () => logoff(),
          },
        }}
      />
    </Modal>
  );
}
