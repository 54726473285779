import { createContext, useState } from "react";

export const AssignCameraContext = createContext();
export const AssignCameraProvider = (props) => {
  const [vtuEsnDetails, setVtuEsnDetails] = useState({});
  const [cameraDetails, setCameraDetails] = useState([]);
  return (
    <AssignCameraContext.Provider value={{ vtuEsnDetails: { vtuEsnDetails, setVtuEsnDetails }, cameraDetails: { cameraDetails, setCameraDetails } }}>
      {props.children}
    </AssignCameraContext.Provider>
  );
};
