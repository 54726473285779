import styled from "@emotion/styled";
import { lazy, useContext, useState } from "react";
import { Header } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { VIEW_SERVICE_REQUESTS_COL } from "../../constants/CheckInConstants";
import { CheckinContext } from "../../context/checkinContext";
import { addPage } from "../../utility/navigation";
import { ViewServiceRequestsInfoPage } from "./ViewServiceRequestsInfo";

const ContentWrapper = styled.div`
  padding: 10px 10px;
`;

export default function ViewServiceRequests({ vtu }) {
  const { workTicket, workItems } = useContext(CheckinContext);
  const { setWorkTicket: setWorkTicketValue } = workTicket;
  const { workItems: workItemsValue } = workItems;
  const [workTickets] = useState(workItemsValue);

  const handleClickCallback = (data) => {
    setWorkTicketValue(data);
    addPage(ViewServiceRequestsInfoPage.id, { vtu });
  };

  return (
    <>
      <Header>Select Work Ticket</Header>
      <ContentWrapper>
        <GridComponent
          isExportEnabled={true}
          rows={workTickets}
          columns={VIEW_SERVICE_REQUESTS_COL}
          title="Work Tickets"
          hyperLinkClicked={handleClickCallback}
          noDataMessage="No Work Items associated with this ESN"
        />
      </ContentWrapper>
    </>
  );
}
export const ViewServiceRequestPage = {
  name: "View Service Request",
  id: "viewservicerequest",
  component: lazy(() => import("./ViewServiceRequests")),
  route: "/viewservicerequest",
};
