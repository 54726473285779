import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const WORK_VIEW_ITEM_TABLE_COL = [
  {
    key: "MMID",
    value: "MMID",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.lsmall,
  },
  {
    key: "Item Description",
    value: "itemDescription",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: true,
    width: "240px",
  },
  {
    key: "Type",
    value: "displayType",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.lsmall,
  },
  {
    key: "Contract Item",
    value: "contractItem",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "405px",
  },
  {
    key: "Quantity",
    value: "quantity",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: true,
    width: "100px",
  },
];
