import { messageBus } from "../../../utility/messageBus";
import { getProperty } from "../../../utility/getProperty";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { generate } from "shortid";
import { APPEND_TO_TAB_ID, ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE } from "../../../constants/ApplicationConstants";
import RouteComponent from "../../../pages/HomePage/RouteComponent";

export const TabWindowOverlay = () => {
  const [renderTab, setRenderTab] = useState("");

  const tabsRef = useRef([]);

  const addNewTabs = useCallback((tabList) => {
    let addTab = false;

    const startIndex = tabsRef.current.length > 0 ? tabsRef.current.length : 0;
    let cssShow = tabList.length - startIndex > 1 ? false : true;

    for (let index = startIndex; index < tabList.length; index++) {
      if (index === tabList.length - 1) cssShow = true;
      if (addNewTabToTabRef(tabList[index], cssShow)) addTab = true;
    }

    if (addTab) setRenderTab(generate());

    return addTab;
  }, []);

  const onAddNewTab = useCallback(
    (tabList) => {
      if (ifEmptyTabList(tabList)) return;

      removeTabIdIfNotPresent(tabList);

      const activeSelectedTab = getProperty(tabList, `[${tabList.length - 1}]`);
      if (!activeSelectedTab) return;

      addNewTabs(tabList);
      const tabId = getProperty(activeSelectedTab, "tabId");
      applyDisplayCss(tabId);
    },
    [addNewTabs]
  );

  const onChangeOfTabSelection = useCallback((tabList, selectedIndex) => {
    if (ifEmptyTabList(tabList)) return;

    removeTabIdIfNotPresent(tabList);

    const activeSelectedTab = getProperty(tabList, `[${selectedIndex}]`);
    if (!activeSelectedTab) return;

    applyDisplayCss(getProperty(activeSelectedTab, "tabId"));
  }, []);

  const ifEmptyTabList = (tabList) => {
    if (tabList && tabList.length === 0) {
      tabsRef.current = [];
      setRenderTab("");
      return true;
    }
    return false;
  };

  const removeTabIdIfNotPresent = (tabList) => {
    if (tabsRef.current.length > tabList.length) {
      tabsRef.current.forEach((tab, i) => {
        const found = tabList.find((list) => getProperty(list, "tabId") === getProperty(tab, "props.list.tabId", ""));
        if (!found) {
          tabsRef.current.splice(i, 1);
        }
      });
    }
  };

  const addNewTabToTabRef = (selectedTab, addDisplayCss) => {
    const present = tabsRef.current.find((s) => getProperty(selectedTab, "tabId") === getProperty(s, "props.list.tabId", ""));
    if (!present) {
      const screen = <RouteComponent key={selectedTab.tabId} list={selectedTab} isActive={true} addCss={addDisplayCss} props={selectedTab.props} />;
      tabsRef.current.push(screen);
      return true;
    }
    return false;
  };

  const applyDisplayCss = (tabId) => {
    let apply = true;
    tabsRef.current.forEach((s) => {
      const asElement = document.getElementById(`${getProperty(s, "props.list.tabId", "")}${APPEND_TO_TAB_ID}`);
      if (asElement) {
        tabId === getProperty(s, "props.list.tabId", "") ? asElement.classList.remove("sr-only") : asElement.classList.add("sr-only");
      } else {
        apply = false;
      }
    });

    return apply;
  };

  const syncPage = (payload) => {
    const updatedTab = payload.updatedTab;
    let component = tabsRef.current.find((item) => updatedTab.tabId === item.props.list.tabId);
    let clonedComponent = React.cloneElement(component, { list: updatedTab });
    tabsRef.current[tabsRef.current.indexOf(component)] = clonedComponent;
    setRenderTab(generate());
  };

  const resetTab = (payload) => {
    const prevTabId = payload.prevTabId;
    const selectedTab = payload.selectedTab;
    const tabIndex = tabsRef.current.findIndex((item) => item.props.list.tabId === prevTabId);
    tabsRef.current[tabIndex] = (
      <RouteComponent key={payload.selectedTab.tabId} list={selectedTab} isActive={true} addCss props={payload.selectedTab.props} />
    );
    applyDisplayCss(payload.selectedTab.tabId);
    setRenderTab(generate());
  };

  const updateTab = (payload) => {
    tabsRef.current[payload.selectedIndex] = (
      <RouteComponent key={payload.tab.tabId} list={payload.tab} isActive={true} addCss props={payload.tab.props} />
    );
    applyDisplayCss(payload.tab.tabId);
    setRenderTab(generate());
  };

  const handleTabEventMessage = useCallback(
    (payloadData = {}) => {
      const payload = cloneDeep(payloadData);
      const action = getProperty(payload, "type");

      switch (action) {
        case "ADD":
          onAddNewTab(getProperty(payload, "tabList", []));
          break;

        case "CHANGE":
          onChangeOfTabSelection(getProperty(payload, "tabList", []), getProperty(payload, "selectedIndex"));
          break;

        case "CLOSE":
          onChangeOfTabSelection(getProperty(payload, "tabList", []), getProperty(payload, "selectedIndex"));
          setRenderTab(generate());
          break;
        case "SYNC_PAGE":
          syncPage(payload);
          break;
        case "RESET_TAB":
          resetTab(payload);
          break;
        case "SYNC_ADDRESS":
          updateTab(payload);
          break;
        default:
          break;
      }
    },
    [onAddNewTab, onChangeOfTabSelection]
  );

  useEffect(() => {
    const _unSubscribeOpenClose = messageBus.on(ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE, handleTabEventMessage);
    return () => {
      _unSubscribeOpenClose();
    };
  }, [handleTabEventMessage]);

  return renderTab && tabsRef.current.length > 0 ? <>{tabsRef.current}</> : <></>;
};
