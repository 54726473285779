import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const getMileageList = async (salesOrg) => {
  try {
    return await getRequest(`${urls.GET_MILEAGE_LIST}/${salesOrg}`);
  } catch (error) {
    return error;
  }
};
