import { createContext, useState } from "react";

export const AccountDetailsContext = createContext();
export const AccountDetailsProvider = (props) => {
  const [accountId, setAccountId] = useState("");
  const [source, setSource] = useState("");
  const [accountDetails, setAccountDetails] = useState({
    customerName: "",
    customerNumber: "",
    vztSegment: "",
    sfdcUsername: "",
  });
  const [jobs, setJobs] = useState([]);
  const [cspDetails, setCspDetails] = useState([]);
  const [caseDetails, setCaseDetails] = useState([]);
  const [jobDurationDetails, setJobDurationDetails] = useState([]);
  const [jobLengthHours, setJobLengthHours] = useState(0);
  const [jobLengthMinutes, setJobLengthMinutes] = useState(0);
  const [historicalAddressDetails, setHistoricalAddressDetails] = useState([]);
  // const [chargeableStates, setChargeableStates] = useState([]);

  return (
    <AccountDetailsContext.Provider
      value={{
        accountId: { accountId, setAccountId },
        source: { source, setSource },
        accountDetails: { ...accountDetails, setAccountDetails },
        jobs: { jobs, setJobs },
        cspDetails: { cspDetails, setCspDetails },
        caseDetails: { caseDetails, setCaseDetails },
        jobDurationDetails: { jobDurationDetails, setJobDurationDetails },
        jobLengthHours: { jobLengthHours, setJobLengthHours },
        jobLengthMinutes: { jobLengthMinutes, setJobLengthMinutes },
        historicalAddressDetails: { historicalAddressDetails, setHistoricalAddressDetails },
        // chargeableStates: { chargeableStates, setChargeableStates },
      }}
    >
      {props.children}
    </AccountDetailsContext.Provider>
  );
};
