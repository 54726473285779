import React, { useState, useEffect, useContext } from "react";
import { css } from "@emotion/react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { TextLink } from "@vds/buttons";
import { Title } from "@vds/typography";
import { Icon } from "@vds/icons";
import { Table, TableHead, TableBody, TableRow, Cell, TableHeader } from "@vds/tables";
import { Loader } from "@vds/loaders";
import { capitalize } from "lodash";
import { InputNumber } from "primereact/inputnumber";
import { SLButton } from "../../components/core/Controls";
import { MediumText, SmallText, FlexRow } from "../../components/core/Utility";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { getJobDuration } from "../../services/scheduler/getJobDuration";
import { jobDurationFormatter } from "../../utility/sessionData";

export const CustomisedInput = css`
  border: 0.0625rem solid #d8dada;
  width: 80%;
  height: 2rem;
  align-items: center;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin-top: 0px;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
`;

const StyledModal = css`
  overflow: hidden;
  max-height: 99vmin;
  padding: 1rem;
  // padding-right: 1rem;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 46px 1rem;
    // padding-right: 2px;
  }
  & span {
    padding-right: 0px;
  }
  & div:nth-of-type(2) {
    overflow-y: unset;
  }
  // & div:nth-of-type(3) {
  //   width: 105%;
  //   padding: 0px;
  //   @media (max-width: 767px) {
  //     width: 95%;
  //   }
  // }
  div[data-testid="modal-body"] {
    padding: 0px;
    div {
      ::-webkit-scrollbar {
        display: block !important;
      }
      ::-webkit-scrollbar-thumb {
        display: inline-block !important;
        background-color: #c0c0c0 !important;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }
      ::-webkit-scrollbar {
        width: 5px;
      }
    }
  }
  & footer {
    padding: 1rem 0px 0px 0px;
    //margin-left: 1.3rem;
    //border-top: 1px solid black;
  }
  & mark {
    background-color: transparent !important;
  }
`;
const CustomTableHeader = css`
  // width: 95%;
  //margin-left: 0.5rem;
  margin-bottom: 0px;
  thead {
    border-bottom: 2px solid #000000;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0.4rem;
    }
  }
  td {
    padding: 5px 0px 5px 0px;
  }
`;

export const JobLengthModal = ({
  jobLengthModal,
  setJobLengthModal,
  setApptLength,
  jobData,
  jobType,
  isNewJob,
  setExistingJobQuantityList,
  notification,
}) => {
  const { jobDurationDetails, jobLengthHours, jobLengthMinutes } = useContext(AccountDetailsContext);
  const [jobDurationList, setJobDurationList] = useState([]);
  const [calculate, setCalculate] = useState(false);
  const [totalJobLength, setTotalJobLength] = useState(jobLengthHours.jobLengthHours);
  const [totalJobLengthMin, setTotalJobLengthMin] = useState(jobLengthHours.jobLengthHours);
  const [isLoading, setIsLoading] = useState(false);
  const kbArticle = "https://vzt-kb.vtitel.com/Reveal/Reveal_Support/Reveal_Scheduling/Scheduling_and_Shipping_Scenarios";

  useEffect(() => {
    jobDurationDetails.jobDurationDetails.length
      ? setJobDurationList(jobDurationDetails.jobDurationDetails)
      : getJobDurationFields(capitalize(jobType), jobData && jobData.id);
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setJobLengthModal(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const getJobDurationFields = async (installType, jobId) => {
    setIsLoading(true);
    let response = await getJobDuration(installType, jobId);
    if (response.status === 200 && response?.data?.data?.length) {
      setJobDurationList(response.data.data);
      if (!isNewJob) {
        setExistingJobQuantityList(response.data.data.filter((item) => item.quantity > 0));
      }
    } else {
      setJobLengthModal(false);
      notification();
    }
    setIsLoading(false);
  };

  const quantityChangeHandler = (minutes, jobList) => {
    if (minutes > 20 || minutes === null) {
      minutes = 0;
    }
    setJobDurationList(
      jobDurationList.map((job) => {
        return job === jobList ? { ...job, quantity: minutes } : job;
      })
    );
    setCalculate(true);
  };

  const handleCalculate = () => {
    setCalculate(false);
    let jobQuantityList = jobDurationList.filter((job) => job.quantity);
    let totalQuantity = 0;
    jobQuantityList.map((job) => {
      let oneJobQuantity = job.duration * job.quantity;
      totalQuantity += oneJobQuantity;
    });
    setTotalJobLengthMin(totalQuantity);
    // setTotalJobLength(Math.round(totalQuantity / 60));
    if (totalQuantity <= 60) {
      setTotalJobLength(Math.ceil(totalQuantity / 60));
    } else {
      let hours = parseInt(totalQuantity / 60);
      let remainingminutes = totalQuantity % 60;
      let minutesToAdd = 0;
      if (remainingminutes > 15 && remainingminutes < 46) {
        minutesToAdd = 30;
      } else if (remainingminutes > 45 && remainingminutes < 60) {
        minutesToAdd = 60;
      }
      let totalmins = hours * 60 + minutesToAdd;
      setTotalJobLength(totalmins / 60);
    }
  };

  const handleDone = () => {
    setJobLengthModal(false);
    setApptLength(totalJobLength);
    jobLengthMinutes.setJobLengthMinutes(totalJobLengthMin);
    jobLengthHours.setJobLengthHours(totalJobLength);
    jobDurationDetails.setJobDurationDetails(jobDurationList);
  };

  const handleClear = () => {
    setCalculate(false);
    setTotalJobLength(0);
    setJobDurationList(
      jobDurationList.map((job) => {
        return job.quantity ? { ...job, quantity: 0 } : job;
      })
    );
  };
  return (
    <Modal
      opened={jobLengthModal}
      disableOutsideClick
      css={StyledModal}
      closeButton={
        <>
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <Title size="medium">Job Length Calculator</Title>
            <div
              onClick={() => {
                !isLoading && setJobLengthModal(false);
              }}
              style={{ marginTop: "0.3rem" }}
            >
              <Icon name="close" size="medium" color="#000000" style={{ cursor: "pointer", outline: "none" }} />
            </div>
          </FlexRow>
        </>
      }
    >
      <ModalBody style={{ borderBottom: "1px solid #000000" }}>
        <SmallText style={{ margin: "1rem 1rem 0 0" }}>
          Minimum job duration is 1 hour and Maximum job duration is 10 hours. If the job duration exceeds 10 hours, split the remaining hours into
          another job.{" "}
          <TextLink href={kbArticle} tabIndex={0} role="link" target="_blank">
            Learn more
          </TextLink>
        </SmallText>
        <Table topLine="none" css={CustomTableHeader}>
          <TableHead>
            <TableHeader width="24%">
              <SmallText bold>Quantity</SmallText>
            </TableHeader>
            <TableHeader>
              <SmallText bold>Install Type</SmallText>
            </TableHeader>
            <TableHeader>
              <TextLink css={{ marginLeft: "60%", fontSize: "12px" }} disabled={!calculate} onClick={handleCalculate}>
                {totalJobLength ? `Recalculate` : `Calculate`}
              </TextLink>
            </TableHeader>
          </TableHead>
        </Table>
        <div css={{ height: "35vh", overflowY: "scroll !important" }}>
          <Loader active={isLoading} fullscreen={false} />
          <Table topLine="none" striped={false} css={CustomTableHeader}>
            <TableBody>
              {jobDurationList.map((jobList) => (
                <TableRow key={jobList.id}>
                  <Cell width="25%">
                    <InputNumber
                      value={jobList.quantity <= 20 ? jobList.quantity : 0}
                      min={0}
                      max={20}
                      showButtons
                      onChange={(e) => quantityChangeHandler(e.value, jobList)}
                      css={CustomisedInput}
                    ></InputNumber>
                  </Cell>
                  <Cell colSpan={2}>
                    <SmallText>{jobList.productDescription}</SmallText>
                  </Cell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <FlexRow css={{ justifyContent: "space-between", width: "100%" }}>
          <div>
            <SmallText bold>Job Length (Hours)</SmallText>
            <MediumText>{jobDurationFormatter(totalJobLength)}</MediumText>
          </div>
          <FlexRow>
            <SLButton
              primary
              style={{ paddingRight: "48px", marginRight: "20px" }}
              disabled={!isLoading && totalJobLength > 0 && totalJobLength <= 10 ? calculate : true}
              onClick={handleDone}
            >
              Done
            </SLButton>
            <SLButton secondary style={{ paddingRight: "48px", marginRight: "0px" }} onClick={handleClear} disabled={isLoading}>
              Clear
            </SLButton>
          </FlexRow>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
};
