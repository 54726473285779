import React from "react";
import moment from "moment";
import { SLButton } from "../../components/core/Controls";
import { durationUnitCalculation } from "../../utility/sessionData";

export const SlotButtons = (props) => {
  let durationUnit = durationUnitCalculation(props.availability);
  let slotTime =
    moment(props.availability.slotStartTime, "HHmm").format("hh:mm A").toString() +
    " " +
    "-" +
    " " +
    moment(props.availability.slotStartTime, "HHmm").add(durationUnit, props.availability.slotLength).format("hh:mm A").toString();

  return (
    <SLButton
      size="small"
      secondary={true}
      aria-label={slotTime}
      role="button"
      // key={props.indexValue}
      id="SlotButton"
      css={{
        borderColor: props.slotDetails.index === props.indexValue && props.slotDetails.vendor === props.key ? "#000000" : "#E0E0E0",
        float: "left",
        width: "175px",
      }}
      aria-disabled={
        props.reschedule &&
        moment(props.availability.availableDate).format("MM-DD-YYYY") === moment(props.jobData.scheduledDate).format("MM-DD-YYYY") &&
        moment(props.availability.slotStartTime, "HHmm").format("hh:mm A") === moment(props.jobData.scheduledTime, "HHmm A").format("hh:mm A")
          ? true
          : false
      }
      disabled={
        props.reschedule &&
        moment(props.availability.availableDate).format("MM-DD-YYYY") === moment(props.jobData.scheduledDate).format("MM-DD-YYYY") &&
        moment(props.availability.slotStartTime, "HHmm").format("hh:mm A") === moment(props.jobData.scheduledTime, "HHmm A").format("hh:mm A")
          ? true
          : false
      }
      onClick={() => props.handleSlot(props.availability, props.indexValue)}
    >
      {slotTime}
    </SLButton>
  );
};
