import { generate } from "shortid";
export const FORCE_CLOSE_TAB = "FORCE_CLOSE_TAB";
export const OPEN_TAB_EVENT = "OPEN_TAB_EVENT";
export const ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE = "ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE";
export const ADD_PAGE = "ADD_SUBPAGE";
export const CHANGE_PAGE = "CHANGE_SUBPAGE";
export const APPEND_TO_TAB_ID = "_TabMainId_" + generate();
export const RESET_TAB = "RESET_PAGE";
export const UPDATE_TAB = "UPDATE_TAB";
export const UPDATE_QUICK_LINKS = "UPDATE_QUICK_LINKS";

export const ACTIVATE_BATCH_UPLOAD_HISTORY = {
  0: "batchNumber",
  1: "Brand",
  2: "Units",
  3: "batchFilename",
  4: "Upload Date",
  5: "Uploaded By User",
};
export const SELECT_REVEAL_VEHICLES_HEADER = {
  0: "Account",
  1: "Box Type",
  2: "Reveal ID",
  3: "Label",
  4: "Make",
  5: "Model",
  6: "Reg #",
  7: "VIN",
  8: "Year",
};

export const UPLOAD_INACTIVE_UNITS = {
  0: "Batch #",
  1: "Brand",
  2: "Units",
  3: "Filename",
  4: "Upload Date",
  5: "Uploaded By User",
};

export const COUNTRYS = {
  USA: "United States",
  CAN: "Canada",
  MEX: "Mexico",
  AUS: "Australia",
  CHL: "Chile",
  GBR: "United Kingdom",
  IRL: "Ireland",
  PRT: "Portugal",
  DEU: "Germany",
  POL: "Poland",
  FRA: "France",
  NZL: "New Zealand",
  NLD: "Netherlands",
  ITL: "Italy",
};
export const SELECT_WORK_TICKET_HEADER = {
  0: "Work Ticket#",
  1: "Vendor",
  2: "Installation Address",
  3: "Ticket Type",
  4: "CRMReferenceID",
  5: "Status",
};
export const SELECT_LINE_ITEM_HEADER = {
  0: "Work Model#",
  1: "Line Items",
  2: "Vendor",
  3: "Installation Address",
  4: "Model Type",
  5: "Peripherals",
  6: "CRMReferenceID",
  7: "Status",
};
export const CHECKIN_SEARCH_DROPDOWN_VALUES = {
  empty: "",
  esn: "ESN",
  imei: "IMEI",
  meid: "MEID",
  phone: "Phone",
  workTicketId: "Work Ticket",
  iccid: "ICCID",
};

export const VIEW_BATCH = {
  0: "Select",
  1: "esn",
  2: "gsm",
  3: "iccid",
  4: "meid",
  5: "status",
  6: "unitType",
  7: "boxType",
};

export const CARRIER = {
  0: "",
  1: "AT&T",
  2: "Telstra",
  3: "Verizon",
  4: "Vodafone",
};

export const RATEPLAN = {
  0: "",
  1: "Telogis - 1MB Intl Plan",
  2: "Telogis - 25MB Intl Plan",
  3: "Telogis - 100MB Intl Plan",
  4: "Fleetmatics 1MB Pooled 3G Plan",
  5: "Fleetmatics NA 3MB Pooled Plan",
};

export const VERIZON_RATEPLAN = {
  0: "",
  1: "Verizon LTE",
  2: "Verizon 3G",
  3: "Vechile Data Device",
  4: "Equipment Asset Tracking Rate Plan",
  5: "FleetCamera4GPlan",
};

export const VIEW_BATCH_PROFILE = {
  0: "",
  1: "21",
  2: "20",
};
export const SELECT_REVEAL_CAMERAS_HEADER = {
  0: "Account ID",
  1: "Location",
  2: "Serial Number",
};

export const COLUMN_WIDTHS = {
  xsmall: "110px",
  small: "150px",
  medium: "220px",
  large: "320px",
  xmedium: "250px",
  lsmall: "180px",
};

export const EDIT_FIELD_TYPES = {
  dropDown: "DropDown",
  inputText: "inputText",
  inputNumber: "inputNumber",
  autoComplete: "autoComplete",
};
export const SCHEDULER_UPDATE_DROPDOWN_VALUES = {
  "": "",
  M1: "Milestone 1",
  M2: "Milestone 2",
  OC: "Order Create",
  CA: "Carrier_Activation",
};
export const SCHEDULER_VALUES_DROPDOWN_VALUES = {
  "": "",
  True: "true",
  False: "false",
};
export const RBAC_ACTION_VALUES = {
  "": "",
  assignroletogroup: "ASSING_ROLE_TO_GROUP",
  assigngrouptoprivilege: "ASSING_GROUP_TO_PRIVILEGE",
};

//added it as part of VZC42148-106
export const CUSTOM_FILTER_TYPE = {
  listBox: "List Box",
  dropDown: "Drop Down",
};
