// Hook to keep only logics from Milestone page

import { useCallback } from "react";

const States = {
  failed: "failed",
  completed: "success",
  progress: "progress",
};

const markError = (milestoneData, item) => {
  let visited = false;
  for (const key in milestoneData) {
    if (milestoneData[key] === States.progress) {
      if (!visited) {
        visited = true;
        milestoneData[key] = {};
        milestoneData[key].state = States.failed;
        milestoneData[key].errorMessage = item.description;
      } else {
        milestoneData[key] = States.failed;
      }
    }
  }
};

const useMilestone = ({ subType }) => {
  // Buyout Status
  const handleBuyoutStatus = (cancellationData, accordionData, buyOutData) => {
    if (buyOutData && Object.keys(buyOutData).length) {
      const buyOutStatus = buyOutData?.success;
      switch (buyOutStatus) {
        case "S":
          cancellationData.stage1 = States.completed;
          accordionData[9].state = States.progress;
          break;
        case "E":
          cancellationData.stage1 = States.failed;
          accordionData[9].state = States.failed;
          break;
        case "":
          cancellationData.stage1 = States.progress;
          accordionData[9].state = States.progress;
      }
    }
  };

  const handleCancellation = useCallback(
    (item, cancellationData, accordionData) => {
      if (subType === "Full Cancellation") {
        switch (item.state) {
          case "CancellationReady":
            cancellationData.stage2 = States.progress;
            accordionData[9].state = States.progress;
            break;
          case "CanAllLineItemsSucceeded":
            cancellationData.stage2 = States.completed;
            accordionData[9].state = States.progress;
            break;
          case "CanSomeLineItemsFailed":
            markError(cancellationData, item);
            cancellationData.stage2 = States.failed;
            accordionData[9].state = States.failed;
            break;
          case "CanUpdateFinStatusSucceeded":
            cancellationData.stage2 = States.completed;
            cancellationData.stage3 = States.completed;
            cancellationData.stage4 = States.progress;
            accordionData[9].state = States.progress;
            break;
          case "CanUpdateFinStatusFailed":
            markError(cancellationData, item);
            cancellationData.stage3 = States.failed;
            accordionData[9].state = States.failed;
            break;
          case "CanDecommAccountSucceeded":
            cancellationData.stage2 = States.completed;
            cancellationData.stage3 = States.completed;
            cancellationData.stage4 = States.completed;
            cancellationData.stage5 = States.progress;
            accordionData[9].state = States.progress;
            break;
          case "CanDecommAccountFailed":
            markError(cancellationData, item);
            cancellationData.stage4 = States.failed;
            accordionData[9].state = States.failed;
            break;
          case "CanSIMKillCasesSucceeded":
            cancellationData.stage2 = States.completed;
            cancellationData.stage3 = States.completed;
            cancellationData.stage4 = States.completed;
            cancellationData.stage5 = States.completed;
            cancellationData.stage6 = States.progress;
            accordionData[9].state = States.progress;
            break;
          case "CanSIMKillCasesFailed":
            markError(cancellationData, item);
            cancellationData.stage5 = States.failed;
            accordionData[9].state = States.failed;
            break;
          case "CanUpdateOppStatusSucceeded":
            cancellationData.stage2 = States.completed;
            cancellationData.stage3 = States.completed;
            cancellationData.stage4 = States.completed;
            cancellationData.stage5 = States.completed;
            cancellationData.stage6 = States.completed;
            cancellationData.stage7 = States.progress;
            accordionData[9].state = States.progress;
            break;
          case "CanUpdateOppStatusFailed":
            markError(cancellationData, item);
            cancellationData.stage6 = States.failed;
            accordionData[9].state = States.failed;
            break;
          case "CanCancelSucceeded":
            cancellationData.stage2 = States.completed;
            cancellationData.stage3 = States.completed;
            cancellationData.stage4 = States.completed;
            cancellationData.stage5 = States.completed;
            cancellationData.stage6 = States.completed;
            cancellationData.stage7 = States.progress;
            cancellationData.stage7 = States.completed;
            accordionData[9].state = States.completed;
            break;
        }
      } else {
        // Partial cancellation
        switch (item.state) {
          case "CancellationReady":
            cancellationData.stage1 = States.completed;
            cancellationData.stage2 = States.progress;
            accordionData[9].state = States.progress;
            break;
          case "CanAllLineItemsSucceeded":
            cancellationData.stage1 = States.completed;
            cancellationData.stage2 = States.completed;
            cancellationData.stage3 = States.progress;
            accordionData[9].state = States.progress;
            break;
          case "CanSomeLineItemsFailed":
            markError(cancellationData, item);
            cancellationData.stage2 = States.failed;
            accordionData[9].state = States.failed;
            break;
          case "CanUpdateOppStatusSucceeded":
            cancellationData.stage1 = States.completed;
            cancellationData.stage2 = States.completed;
            cancellationData.stage3 = States.completed;
            cancellationData.stage4 = States.progress;
            accordionData[9].state = States.progress;
            break;
          case "CanUpdateOppStatusFailed":
            markError(cancellationData, item);
            cancellationData.stage3 = States.failed;
            accordionData[9].state = States.failed;
            break;
          case "CanCancelSucceeded":
            cancellationData.stage1 = States.completed;
            cancellationData.stage2 = States.completed;
            cancellationData.stage3 = States.completed;
            cancellationData.stage4 = States.completed;
            accordionData[9].state = States.completed;
            break;
        }
      }
    },
    [subType]
  );

  return { handleBuyoutStatus, handleCancellation };
};

export default useMilestone;
