import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getSapIdList = async (salesOrgId) => {
  try {
    return await getRequest(urls.GET_SAP_ID_LIST + `/${salesOrgId}`);
  } catch (err) {
    return err;
  }
};
