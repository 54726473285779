import { COL_WIDTH } from "./ReportTable";

export const JOB_DETAILS_REPORT_COLUMNS = [
  {
    header: "JOB_ID",
    field: "jobID",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "ACCOUNT_ID",
    field: "accountId",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.medium },
  },
  {
    header: "JOB_STATUS",
    field: "jobStatus",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "VENDOR",
    field: "vendor",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "REQUEST_DATE",
    field: "requestDate",
    isFilterEnabled: false,
    dataType: "date",
    dateFormat: "MM/DD/YYYY",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "WORK_TICKETS",
    field: "workTicket",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "CUSTOMER_NAME",
    field: "customerName",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.large },
  },
  {
    header: "APPT_DATE",
    field: "apptDate",
    isFilterEnabled: false,
    dataType: "String",
    dateFormat: "YYYY-MM-DD",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "APPT_TIME",
    field: "appTime",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "ADDRESS_1",
    field: "address1",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.medium },
  },
  {
    header: "ADDRESS_2",
    field: "address2",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.medium },
  },
  {
    header: "CITY",
    field: "city",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "STATE",
    field: "state",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "ZIP",
    field: "zip",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "APPT_DURATION",
    field: "apptDuration",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "RT_MILES",
    field: "rtMiles",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.xsmall },
  },
  {
    header: "JOB_NOTES",
    field: "jobNotes",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "VEHICLE_COUNT",
    field: "vehicleCount",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "CREATED_BY",
    field: "createdBy",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.large },
  },
  {
    header: "MODIFIED_BY",
    field: "modifiedBy",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.large },
  },
  {
    header: "CSP_OWNER",
    field: "cspOwner",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "CASE_OWNER",
    field: "caseOwner",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.small },
  },
  {
    header: "CREATED_DATE(UTC)",
    field: "createdDate",
    isFilterEnabled: false,
    dataType: "date",
    dateFormat: "YYYY-MM-DD",
    style: { minWidth: COL_WIDTH.medium },
  },
  {
    header: "CREATED_TIME(UTC)",
    field: "createdTime",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.medium },
  },
  {
    header: "HAS_SLOTS_JOB",
    field: "hasSlotsJobs",
    isFilterEnabled: false,
    dataType: "String",
    style: { minWidth: COL_WIDTH.small },
  },
];
