import React, { lazy, useEffect, useState } from "react";
import { Title, Body } from "@vds/typography";
import { Notification } from "@vds/notifications";
import { Loader } from "@vds/loaders";
import { generate } from "shortid";
import { Line } from "@vds/lines";
import { Input } from "@vds/inputs";
import { SLButton } from "../../../components/core/Controls";
import { FlexRow, Spacer, MediumText } from "../../../components/core/Utility/index";
import { css } from "@emotion/react";
import ReportTable from "./ReportTable";
import { API_STATS_REPORT_COLUMNS } from "./ApiStatsReportConstants";
import { JOB_DETAILS_REPORT_COLUMNS } from "./JobDetailsReportConstants";
import { getReport } from "../../../services/scheduler/getReport";
import { Wrapper } from "./ReportTableStyle";
import styled from "@emotion/styled";
import useOutsideClick from "../../../customHook/use-outside-click";

const StyledDatePicker = css`
  .react-calendar {
    z-index: 500;
  }
`;

export const SLInput = (props) => {
  return (
    <div>
      <Input
        css={{ padding: "20px 0px" }}
        {...props}
        label={
          <Body size="large" primitive="span" bold>
            {props?.label ?? ""}
          </Body>
        }
      />
    </div>
  );
};

const StyledSLButton = css`
  margin: 20px 20px 20px 20px;
`;

const FlexCenter = styled.div`
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px 0px;
`;

const StyledGridWrapper = styled.div`
  width: calc(100vw - 165px);
  @media (max-width: 1024px) {
    width: 86%;
  }
`;

const NotificationStyle = styled.div`
  width: 85%;
  @media (max-width: 1024px) {
    width: 80%;
  }
`;

export default function SchedulerReport({ reportType }) {
  const [reporttype] = useState(reportType === "api_stats" ? "API Stats Report" : "Job Details Report");
  const [columnHeaders] = useState(reportType === "api_stats" ? API_STATS_REPORT_COLUMNS : JOB_DETAILS_REPORT_COLUMNS);
  const [rowData, setRowData] = useState([]);
  const [notification, setNotification] = useState(null);
  const [createdAtDate, setCreatedAtDate] = useState();
  const [shortFromDate, setShortFromDate] = useState();
  const [shortToDate, setShortToDate] = useState();
  const [isLoading, setLoader] = useState(false);
  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);

  const selectCreatedAtDate = (event) => {
    const selectedDate = new Date(event);
    const dateFormatConfig = { year: "numeric", month: "short", day: "numeric" };
    const fromDate = selectedDate.toLocaleDateString("en-us", dateFormatConfig);
    selectedDate.setDate(selectedDate.getDate() + 4);
    const toDate = selectedDate.toLocaleDateString("en-us", dateFormatConfig);
    setCreatedAtDate(event);
    setShortFromDate(fromDate);
    setShortToDate(toDate);
  };

  const getJobReport = () => {
    getReportData();
  };

  useEffect(() => {}, []);

  const notify = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const getReportData = async () => {
    setLoader(true);
    let response = await getReport(reportType, createdAtDate);
    if (response.status === 200 && response?.data?.data) {
      let headers = columnHeaders.map((item) => item.field);
      let tableRowData = response.data.data.map((item) => {
        let data = {};
        for (const header of headers) {
          data = { ...data, [header]: header === "modifiedBy" ? item[header] || item.createdBy : item[header] };
        }
        return data;
      });
      setRowData(tableRowData);
    } else {
      notify("Unable to fetch data due to an unexpected error", "error");
    }
    setLoader(false);
  };

  return (
    <Wrapper>
      <Loader active={isLoading} fullscreen />
      <NotificationStyle style={{ marginRight: "38rem" }}>{notification}</NotificationStyle>
      <Spacer top="1rem" bottom="1rem">
        <Title primitive="p" size="large">
          {reporttype}
        </Title>
      </Spacer>
      <Line type="light"></Line>
      <FlexRow>
        <SLInput
          type="calendar"
          label="Created at"
          aria-label="Created at"
          minDate={new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)}
          maxDate={new Date()}
          errorText={""}
          error={false}
          format="MM/DD/YYYY"
          value={createdAtDate}
          onChange={selectCreatedAtDate}
          css={StyledDatePicker}
        ></SLInput>
        <FlexCenter>
          <SLButton
            role="button"
            aria-label="Go to Add Job Screen"
            css={StyledSLButton}
            onClick={getJobReport}
            aria-disabled={!createdAtDate}
            disabled={!createdAtDate}
          >
            Go
          </SLButton>
        </FlexCenter>
      </FlexRow>
      {rowData && rowData?.length > 0 && (
        <StyledGridWrapper>
          <MediumText bold>{`See result from ${shortFromDate} to ${shortToDate}`}</MediumText>
          <ReportTable columnHeaders={columnHeaders} rowData={rowData} fileName={reporttype}></ReportTable>
        </StyledGridWrapper>
      )}
    </Wrapper>
  );
}

export const SchedulerReportMetadata = {
  name: "Scheduler Report",
  id: "scheduler_report",
  component: lazy(() => import("./SchedulerReport")),
  route: "/scheduler_report",
};
