import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";
export const getReport = async (reportType, startDate) => {
  const [month, day, year] = startDate ? startDate.split("/") : ["", "", ""];
  try {
    return await getRequest(urls.GET_REPORT + `?reportType=${reportType}&fromDate=${year}-${month}-${day}`);
  } catch (err) {
    return err;
  }
};
