import React from "react";
import styled from "styled-components";
import { SmallText } from "../utility";
const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
  ${
    "" /* &:hover > div:first-child {
    width: 80%;
  } */
  }
`;

export const ColumnHeader = ({ col, ariaLabel }) => {
  return (
    <Wrapper>
      <SmallText bold aria-label={ariaLabel}>
        {col}
      </SmallText>
    </Wrapper>
  );
};
