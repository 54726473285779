import { getRequest } from "../../../services/RestServices";
import { GET_CSP_DETAILS } from "../../api/scheduler/url";

export const getDistanceUnitData = async (accountId, cspOrCaseId, userId) => {
  try {
    return await getRequest(GET_CSP_DETAILS + `/${accountId}` + `/${cspOrCaseId}` + `/${userId}`);
  } catch (err) {
    return err;
  }
};
