import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getPOSSUIRSReport = async (payload) => {
  try {
    return await postRequest(urls.GET_POSSUIRSREPORT, payload);
  } catch (err) {
    return err;
  }
};
