import styled from "@emotion/styled";
import React, { lazy } from "react";
import { Header } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { BULK_CHECKIN_COL } from "../../constants/CheckInConstants";

const ContentWrapper = styled.div`
  padding: 10px 10px;
`;
export default function BulkCheckInResults({ response }) {
  return (
    <>
      <Header>Bulk CheckIn</Header>
      <ContentWrapper>
        <GridComponent rows={response} columns={BULK_CHECKIN_COL} isExportEnabled={true} title="Bulk CheckIn Results" />
      </ContentWrapper>
    </>
  );
}

export const BulkCheckInResultsPage = {
  name: "Bulk CheckIn",
  id: "bulkcheckin",
  component: lazy(() => import("./BulkCheckInResults")),
  route: "/bulkCheckin",
};
