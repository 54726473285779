import React from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds/modals";
import { MediumText } from "../Utility";

/**
 * Description placeholder
 * @date 4/11/2024 - 1:37:03 PM
 *
 * @param {{ isOpen: any; onClose: any; header: any; body: any; onContinue: any; }} param
 * @param {boolean} param.isOpen (required).
 * @param {string} param.header (required).
 * @param {string} param.body (required).
 * @param {function} param.onContinue (required).
 *  * @param {function} param.continueText
 * @returns {*}
 */
const ConfirmationModal = ({ isOpen, headerText, bodyText, onContinue, continueText }) => {
  if (!isOpen) return null;

  return (
    <Modal opened={isOpen} disableOutsideClick closeButton={null}>
      <ModalTitle>{headerText}</ModalTitle>
      <ModalBody>
        <MediumText primitive="span">{bodyText}</MediumText>
      </ModalBody>
      <ModalFooter
        buttonData={{
          primary: {
            width: "100%",
            children: continueText || "Continue",
            onClick: onContinue,
          },
        }}
      />
    </Modal>
  );
};

export default ConfirmationModal;
