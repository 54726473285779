import axios from "./RestServiceInterceptor";

export const getRequest = async (url) => {
  return axios.get(url);
};

export const getRequestWithParams = async (url, params) => {
  return axios.get(url, { params: params });
};

export const postRequest = async (url, body, options) => {
  return axios.post(url, body, options);
};

export const getRequestWithPathVariable = async (url, pathVariable) => {
  const requestUrl = `${url}/${pathVariable}`;
  return axios.get(requestUrl);
};

export const postRequestWithParams = async (url, params) => {
  return axios.post(url, null, { params: params });
};
export const putRequest = async (url, body) => {
  return axios.put(url, body);
};

export const postRequestFile = async (url, formData, headers) => {
  return axios.post(url, formData, headers);
};

export const deleteRequest = async (url) => {
  return axios.delete(url);
};