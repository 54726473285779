import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const ACTIVATE_BATCH_COLUMNS = [
  {
    key: "Batch #",
    value: "batchNumber",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Brand",
    value: "brand",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "VTUs",
    value: "count",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Filename",
    value: "batchFilename",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Upload Date",
    value: "uploadDate",
    isFilterEnabled: true,
    filterType: "Date",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    isDate: true,
  },
  {
    key: "Uploaded By User",
    value: "createdBy",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
];
