import * as urls from "../../api/C4S Decommission - Replacement/url";
import { getRequest } from "../../../services/RestServices";

export const getReturnTickets = async (workTicketId) => {
  try {
    return await getRequest(urls.FETCH_RETURN_TICKETS + `/${workTicketId}`);
  } catch (err) {
    return err;
  }
};
