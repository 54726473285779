import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const ADD_HARDWARE_CSP_COL = [
  {
    key: "MMID",
    value: "materialId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    //width: COLUMN_WIDTHS.small,
    width: "100px",
  },
  {
    key: " Item Description",
    value: "description",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: true,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Type",
    value: "displayType",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: "150px",
  },
  {
    key: "Order Type",
    value: "orderType",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Tech Ref ID",
    value: "techRefID",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: "100px",
  },
  {
    key: "Contract Item",
    value: "contractItem",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: true,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Contract Override",
    value: "upsellForUI",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Quantity",
    value: "orderQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
];

export const ADD_HARDWARE_CASE_COL = [
  {
    key: "MMID",
    value: "materialId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Description",
    value: "description",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Ordered Quantity",
    value: "orderQty",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Charged Amount/Currency",
    value: "chargeAmtCurrency",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.xmedium,
  },
];

export const RETURN_INDIVIDUAL_HARDWARE_COL = [
  {
    key: "MMID",
    value: "materialId",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Item Description",
    value: "itemDescription",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Type",
    value: "type",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Return Reason",
    value: "returnReason",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "ESN",
    value: "esn",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Quantity",
    value: "quantity",
    isFilterEnabled: false,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
];
