import { putRequest } from "../RestServices";
import * as urls from "../../api/aggregator";
export const updateBatchInfo = async (payload) => {
  try {
    const _response = await putRequest(urls.UPDATEBATCH, payload);
    return _response.status;
  } catch (err) {
    return 500;
  }
};
