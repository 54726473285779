import { useContext } from "react";
import { PrivilegeContext } from "../../../context/privilegeContext";

export const Privilege = ({ children, privilege, hide }) => {
  const { privileges } = useContext(PrivilegeContext);
  if (privilege && privileges.has(privilege)) {
    if (hide) {
      return null;
    }
    return children;
  } else {
    if (hide) {
      return children;
    }
    return null;
  }
};
