import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getVehicleIntakeList = async ({ accountId, cspId, caseId, vzcSegment }) => {
  try {
    return await getRequest(urls.GET_VEHICLE_INTAKE_LIST + `/${accountId}?cspId=${cspId}&caseId=${null}&vzcSegment=${vzcSegment}`);
  } catch (err) {
    return err;
  }
};
