import { ThemeProvider } from "@emotion/react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React from "react";
import { useHistory } from "react-router";
import { HashRouter as Router, Route } from "react-router-dom";
import { themes } from "./assets/styles/Theme.style";
import AuthRequiredModal from "./authentication/AuthRequiredModal";
import AuthRequiredModalDOS from "./authentication/AuthRequiredModalDOS";
import config from "./authentication/config";
import { PrivilegeContextProvider } from "./context/privilegeContext";
import { HomePage } from "./pages/HomePage";
// import Milestone from "./pages/Orders/milestone";
import { redirectToPageInAddress } from "./utility/navigation";
import IdleComponent from "./authentication/IdleTimerContainer";
import LoginError from "./authentication/LoginError";
const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);
  const [authRequiredModalOpenDOS, setAuthRequiredModalOpenDOS] = React.useState(false);
  const [hasErrorData, setErrors] = React.useState({ hasError: false, error: "", error_description: "" });
  const history = useHistory(); // example from react-router

  const logout = async () => {
    if (oktaAuth.isAuthenticated) {
      oktaAuth.tokenManager.clear();
      await oktaAuth.signOut({
        revokeAccessToken: true,
      });
    } else {
      await triggerLogin();
    }
  };

  const checkForCallBackErrors = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("error") && urlParams.has("error_description")) {
      setErrors({ hasError: true, error: urlParams.get("error"), error_description: urlParams.get("error_description") });
    }
  };
  React.useEffect(() => {
    checkForCallBackErrors();
  });

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    redirectToPageInAddress();
  };
  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (
      hasErrorData.hasError &&
      hasErrorData.error === "access_denied" &&
      hasErrorData.error_description === "User is not assigned to the client application."
    ) {
      setAuthRequiredModalOpenDOS(true);
    } else if (hasErrorData.hasError && hasErrorData.error_description !== "User is not assigned to the client application.") {
      history.push({ pathname: "/login/error", state: hasErrorData });
    } else {
      if (!previousAuthState || !previousAuthState.isAuthenticated) {
        /**  App initialization stage */
        await triggerLogin();
      } else {
        /**  Ask the user to trigger the login process during token autoRenew process */
        setAuthRequiredModalOpen(true);
      }
    }
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      {/** <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} /> */}
      <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
      <AuthRequiredModalDOS {...{ authRequiredModalOpenDOS }} />
      <IdleComponent {...{ logout }} />

      <ThemeProvider theme={themes["lightTheme"]}>
        <Router>
          <SecureRoute path="/*" exact>
            <PrivilegeContextProvider>
              <HomePage handleLogoutUser={logout} />
            </PrivilegeContextProvider>
          </SecureRoute>
        </Router>
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/login/error" component={LoginError} />
      </ThemeProvider>
    </Security>
  );
}

export default App;
