import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getVinMaterialList = async (workTicketId) => {
  try {
    return await getRequest(urls.GET_VIN_MATERIAL_LIST + `/${workTicketId}`);
  } catch (err) {
    return err;
  }
};
