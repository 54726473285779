import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexRow, MediumText, Spacer, UltraMediumText } from "../../components/core/utility";
import { Icon } from "@vds/icons";
import moment from "moment";
import { Input } from "@vds/inputs";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 2rem 2rem 2rem 2rem;
  padding-right: 2px;
  border-radius: 0.5rem !important;
  height: auto;

  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 2rem 2rem 2rem 2rem;
    padding-right: 2px;
  }
  div[data-testid="modal-body"] {
    padding: 0px;
    div {
      ::-webkit-scrollbar {
        display: block !important;
      }
      ::-webkit-scrollbar-thumb {
        display: inline-block !important;
        background-color: #c0c0c0 !important;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
    }
  }
  & footer {
    padding: 0;
  }
`;
const StyledDatePicker = css`
  .react-calendar {
    z-index: 5;
    position: fixed;
    margin-top: 0rem;
    @media screen and (min-width: 760px) {
      transform: translateY(-52%);
    }
  }
`;
const ConfirmDeliveryDateModal = ({
  confirmDeliveryDateModal,
  setConfirmDeliveryDateModal,
  handleATPCheck,
  partialTransitLostMMID = null,
  lostInTransit = false,
}) => {
  const [deliveryDate, setDeliveryDate] = useState();

  useEffect(() => {
    setDeliveryDate(getDefaultDate(true, 6));
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setConfirmDeliveryDateModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const handleDate = (e) => {
    setDeliveryDate(moment(e).format("MMMM D, yyyy"));
  };

  const getDefaultDate = (format = true, daysToAdd = 6) => {
    let startDate = new Date();
    let currentDay = new Date().getDay();
    // If current day is sunday then add one day to daysToAdd
    if (currentDay === 0) daysToAdd++;
    //Now, calculate the weekends
    if (currentDay + daysToAdd >= 6) {
      //Now, get the remaining working days by removing current week
      let workingDaysInaWeek = 5;
      let remainingWorkDays = daysToAdd - (workingDaysInaWeek - currentDay);
      //add current week saturday and sunday to daysToAdd
      daysToAdd += 2;
      if (remainingWorkDays > 5) {
        //Add two days(saturday+sunday) for each working week by calculating how many weeks
        daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
        //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
        if (remainingWorkDays % 5 === 0) daysToAdd -= 2;
      }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return format ? moment(startDate).format("MMMM D, yyyy") : moment(startDate);
  };

  return (
    <>
      <Modal
        role="dialog"
        ariaLabel={
          !lostInTransit ? (
            <>
              <Title size="medium">Confirm Requested</Title>
              <Title size="medium">Delivery Date</Title>
            </>
          ) : (
            <>
              <Title size="medium">Lost Shipment</Title>
            </>
          )
        }
        hideCloseButton={false}
        opened={confirmDeliveryDateModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div>
              {!lostInTransit ? (
                <>
                  <Title size="medium">Confirm Requested</Title>
                  <Title size="medium">Delivery Date</Title>
                </>
              ) : (
                <>
                  <Title size="medium">Lost Shipment</Title>
                </>
              )}
            </div>
            <div
              onClick={() => {
                setConfirmDeliveryDateModal(false);
              }}
              style={{ marginRight: "1rem", marginTop: "0.3rem" }}
            >
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ paddingLeft: "0", paddingRight: "0", marginTop: "0.5rem" }}>
          <Spacer top="1rem" />
          {lostInTransit && partialTransitLostMMID === null && (
            <>
              <UltraMediumText css={{ fontWeight: "normal" }}>
                By continuing you are validating the entire shipments were lost in transit.
              </UltraMediumText>
              <UltraMediumText css={{ fontWeight: "normal" }}>Please select Requested Delivery to create a new Work Ticket.</UltraMediumText>
              <Spacer bottom="1rem" />
            </>
          )}
          {lostInTransit && partialTransitLostMMID && partialTransitLostMMID.size > 0 && (
            <>
              <UltraMediumText tabIndex={0} css={{ fontWeight: "normal" }}>
                Please ensure the following information is valid.
              </UltraMediumText>
              <div css={{ height: "auto", maxHeight: "13rem", overflowY: "auto" }}>
                {[...partialTransitLostMMID.values()].map((items, idx) => (
                  <div key={idx} css={{ margin: "1rem 0 1rem 0" }}>
                    <FlexRow tabIndex={0} css={{ flexFlow: "nowrap !important" }}>
                      <UltraMediumText>Item Description:&nbsp;</UltraMediumText>
                      <UltraMediumText css={{ fontWeight: "normal" }}>{items?.materialName}</UltraMediumText>
                    </FlexRow>
                    {items?.esnList.length > 0 && (
                      <FlexRow css={{ flexFlow: "nowrap !important" }}>
                        <UltraMediumText>ESNs:&nbsp;</UltraMediumText>
                        <UltraMediumText css={{ fontWeight: "normal" }}>{items?.esnList.join(", ") || "--"}</UltraMediumText>
                      </FlexRow>
                    )}
                  </div>
                ))}
              </div>
              <Spacer bottom="1rem" />
            </>
          )}
          <Input
            type="calendar"
            label="Requested Delivery Date"
            aria-label="Requested Delivery Date"
            onChange={handleDate}
            format="MMMM D, yyyy"
            value={deliveryDate ? moment(deliveryDate).format("MMM D, yyyy") : ""}
            errorText={""}
            error={false}
            css={StyledDatePicker}
            //minDate={new Date(moment().add(2, "days").format("MMMM D, yyyy"))}
          ></Input>
          <Spacer bottom="1rem" />
        </ModalBody>
        <ModalFooter>
          <FlexRow>
            <CustomSLButton aria-label="Continue" role="button" primary onClick={() => handleATPCheck(deliveryDate)}>
              Continue
            </CustomSLButton>
            <CustomSLButton
              secondary
              aria-label="Cancel"
              role="button"
              onClick={() => {
                setConfirmDeliveryDateModal(false);
              }}
            >
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmDeliveryDateModal;
