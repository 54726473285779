import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getHardwareTableList = async (payload, source) => {
  try {
    if (source) return await postRequest(urls.HARDWARE_TABLE_LIST_CSP, payload);
    else return await postRequest(urls.HARDWARE_TABLE_LIST_CASE, payload);
  } catch (err) {
    return err;
  }
};
