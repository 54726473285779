import { createContext, useState } from "react";

export const TabContext = createContext();
export const TabContextProvider = (props) => {
  const [swapESN, setSwapESN] = useState("");
  return (
    <TabContext.Provider
      value={{
        swapDevicePage: { swapESN, setSwapESN },
      }}
    >
      {props.children}
    </TabContext.Provider>
  );
};
