import { putRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const rescheduleJob = async (jobId, payload) => {
  try {
    return await putRequest(urls.RESCHEDULE_JOB + `/${jobId}`, payload);
  } catch (error) {
    return error;
  }
};
