import { postRequest } from "../RestServices";
import * as urls from "../../api/aggregator";
import { getProperty } from "../../utility/getProperty";

export const viewBatch = async (payload) => {
  try {
    const _response = await postRequest(urls.VIEWBATCH, payload);
    return getProperty(_response, "data.data", []);
  } catch (err) {
    return {
      error: err,
    };
  }
};
