import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const UPDATE_ACTIVE_UNITS_COL = [
  {
    key: "ESN",
    value: "esn",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Activation Status",
    value: "status",
    isFilterEnabled: true,
    filterType: "Status",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Box Type",
    value: "boxType",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Box Model",
    value: "boxModel",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "ICCID",
    value: "iccid",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "MEID",
    value: "meid",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "IMEI",
    value: "imei",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "GSM / MDN",
    value: "gsm",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
];
export const UPDATE_ACTIVE_UNITS_CARRIER_DROPDOWN_VALUES = [
  // {
  //   key: "Telstra",
  //   value: "Telstra",
  // },
  {
    key: "ATT",
    value: "ATT",
  },
  {
    key: "Verizon",
    value: "Verizon",
  },
  {
    key: "Vodafone",
    value: "Vodafone",
  },
];

export const CHECK_STATUS_COL = [
  {
    key: "ESN",
    value: "esn",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "GSM",
    value: "gsm",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "MEID",
    value: "meid",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Date Submitted",
    value: "activationRequestedOn",
    isFilterEnabled: true,
    filterType: "Date",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
    isDate: true,
  },
  {
    key: "Activation Status",
    value: "status",
    isFilterEnabled: true,
    filterType: "Status",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "ICCID",
    value: "iccid",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Box Type",
    value: "boxType",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "IMEI",
    value: "imei",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Box Model",
    value: "boxModel",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
];

export const VIEW_BATCH_ACTIVATION_COL = [
  {
    key: "ESN",
    value: "esn",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Activation Status",
    value: "status",
    isFilterEnabled: true,
    filterType: "Status",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Box Type",
    value: "boxType",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "MEID/ICCID",
    value: "iccid",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "GSM / MDN",
    value: "gsm",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Reveal ID",
    value: "revealId",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
];
