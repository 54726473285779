import { COLUMN_WIDTHS } from "./ApplicationConstants";
import Bulk_CheckIn from "../assets/Files/Bulk_CheckIn.csv";

export const VIEW_SERVICE_REQUESTS_COL = [
  {
    key: "Work Ticket#",
    value: "WorkTicketID",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Vendor",
    value: "Vendor",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Installation Address",
    value: "InstallationAddress",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Ticket Type",
    value: "TicketType",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "CRMReferenceID",
    value: "CRMReferenceID",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Status",
    value: "Status",
    isFilterEnabled: true,
    filterType: "Status",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
];

export const VIEW_SERVICE_REQUESTS_INFO_COL = [
  {
    key: "Work Model#",
    value: "workTicketNumber",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Line Items",
    value: "productName",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: true,
    isList: true,
    itemToDisplayInList: "lineItem",
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "ProductId",
    value: "productId",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Vendor",
    value: "vendor",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Installation Address",
    value: "Address",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Model Type",
    value: "workTicketType",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Peripherals",
    value: "nonBasePeripherals",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "CRMReferenceID",
    value: "crmReferenceId",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Status",
    value: "status",
    isFilterEnabled: true,
    filterType: "Status",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
];
export const VIEW_SF_CASES_INFO_COL = [
  {
    key: "Case #",
    value: "caseID",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Status",
    value: "Status",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Date Opened",
    value: "dateOpened",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    width: COLUMN_WIDTHS.medium,
  },
];

export const BULK_CHECKIN_COL = [
  {
    key: "ESN",
    value: "esn",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    maxWidth: "10%",
  },
  {
    key: "Upload Result",
    value: "result",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    maxWidth: "10%",
  },
  {
    key: "Message",
    value: "resultMessage",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    maxWidth: "80%",
  },
];

export const BULK_CHECKIN_FILE = {
  Bulk_CheckIn: Bulk_CheckIn,
};
