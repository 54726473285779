import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexColumn, FlexRow, LargeText, MediumText, SmallText, Spacer } from "../../components/core/utility";
import { Icon } from "@vds/icons";
import { Header } from "../../components/core/Controls";
import { Line } from "@vds/lines";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { SAVE_HARDWARE_CASE_COL } from "../../constants/SaveHardwareConstants";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  border-radius: 0.5rem !important;
  width: 700px;
  max-width: 90vw;
  @media (max-width: 767px) {
    // width: 670px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }
  & footer {
    padding: 0;
  }
`;

const currencySymbol = {
  AUD: "$",
  NZD: "$",
  CAD: "$",
  USD: "$",
  MXN: "$",
  EUR: "€",
  GBP: "£",
};
const SaveHardwareModal = ({
  hardwareMMIDTableList,
  saveHardwareModal,
  setSaveHardwareModal,
  handleSaveHardware,
  addAdditionAddress,
  setAddAdditionalAddress,
  currency,
}) => {
  const [totalCharges, setTotalCharges] = useState(0);
  const currencyCodeSymbol = currencySymbol[currency];
  useEffect(() => {
    let totalCharges = 0;
    hardwareMMIDTableList.map((hardware) => (totalCharges += +hardware.orderQty * parseFloat(hardware.chargeAmt)));
    setTotalCharges(totalCharges);
    const handleEscape = (e) => e.key === "Escape" && closeHandler;
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const handleSaveConfirmation = () => {
    handleSaveHardware(addAdditionAddress);
    setSaveHardwareModal(false);
  };

  const closeHandler = () => {
    setAddAdditionalAddress(false);
    setSaveHardwareModal(false);
  };
  return (
    <>
      <Modal
        role="dialog"
        ariaLabel="Total Charge"
        hideCloseButton={false}
        opened={saveHardwareModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div>
              <Title size="medium">Total Charge</Title>
            </div>
            <div onClick={closeHandler} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ paddingLeft: "0", paddingRight: "0", marginTop: "0.5rem" }}>
          <GridComponent
            isExportEnabled={false}
            noDataMessage="No data available to display"
            rows={hardwareMMIDTableList}
            columns={SAVE_HARDWARE_CASE_COL}
            hideGlobalFilter={true}
          />
          <FlexRow css={{ marginTop: "1.2rem", marginLeft: "0.5rem" }} justifyContent="space-between">
            <FlexColumn css={{ width: "20%" }}>
              <MediumText bold>Order Total</MediumText>
              <SmallText>*Total Order taxes</SmallText>
            </FlexColumn>
            <MediumText bold css={{ width: "19.5%" }}>
              {currencyCodeSymbol}
              {totalCharges.toFixed(2)} {currency}
            </MediumText>
          </FlexRow>
        </ModalBody>
        <ModalFooter>
          <FlexRow style={{ paddingTop: "2rem" }}>
            <CustomSLButton aria-label="Confirm" role="button" primary onClick={handleSaveConfirmation}>
              Confirm
            </CustomSLButton>
            <CustomSLButton aria-label="Cancel" role="button" secondary onClick={closeHandler}>
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default SaveHardwareModal;
