import { putRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const deleteVehicle = async (data) => {
  try {
    return await putRequest(urls.DELETE_VIN, data);
  } catch (error) {
    return error;
  }
};
