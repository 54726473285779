import { MediumText, FlexRow } from "../../../../components/core/Utility";
import styled from "styled-components";

const MediumBoldText = styled.p({
  fontWeight: "bold",
  marginTop: "0px",
  marginBottom: "10px",
  marginLeft: "2%",
});

const circleDiv = {
  width: "75%",
  border: "1px solid",
  borderRadius: "5px",
  alignItems: "left",
  paddingTop: "5px",
  paddingLeft: "12px",
  fontSize: "18px",
  marginRight: "20px",
  justifyContent: "space-between",
  maxWidth: "580px",
};

function ViewUnitInfo(props) {
  return (
    <div css={circleDiv}>
      <FlexRow>
        <MediumBoldText>ESN:&nbsp;</MediumBoldText>
        <MediumText>{props.esn}</MediumText>
      </FlexRow>
      <FlexRow>
        <MediumBoldText>Vehicle Tracking Id:&nbsp;</MediumBoldText>
        <MediumText>{props.vehicleTrackingId}</MediumText>
      </FlexRow>
    </div>
  );
}

export default ViewUnitInfo;
