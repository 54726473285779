import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import React, { useContext, useEffect, useState } from "react";
import { Body, Title } from "@vds/typography";
import { css } from "@emotion/react";
import Icon from "@vds/icons";
import _, { isEqual } from "lodash";
import { Notification } from "@vds/notifications";
import { Loader } from "@vds/loaders";
import styled from "styled-components";
import { TextArea } from "@vds/inputs";
import { CustomSLButton, FlexRow, Spacer, SmallText, UltraMediumText } from "../../components/core/utility";
import { CustomDropdown, CustomInput } from "../C4S Decommission - Replacement/AddAVehicle";
import { CustomisedInput } from "./AddVendorModal";
import { AdminValidateAddress } from "./AdminValidateAddress";
import { generate } from "shortid";
import moment from "moment";
import { EMAIL_REGEX_C360 } from "../../constants/C4SValidationConstants";
import { addUpdateInstaller } from "../../services/scheduler/addUpdateInstaller";
import useOutsideClick from "../../customHook/use-outside-click";

const StyledModal = css`
  max-height: 34.5rem !important;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  height: 34.5rem !important;
  min-height: 34.5rem !important;
  min-width: 85vw !important;
  margin-left: 5rem;
  & div:nth-child(2) {
    overflow: hidden;
    & span {
      height: inherit !important;
    }
  }
  div[data-testid="modal-body"] {
    padding: 0px;
    div {
      ::-webkit-scrollbar {
        display: block !important;
      }
      ::-webkit-scrollbar-thumb {
        display: inline-block !important;
        background-color: #c0c0c0 !important;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
    }
  }
`;

const addressWarningCSS = css`
  div {
    div {
      span:nth-child(2) {
        display: none;
      }
    }
  }
`;

export const CustomNotification = styled(Notification)`
  & div {
    justify-content: flex-start;
    max-width: 100%;
    & span:first-child {
      flex: 20 1 0%;
    }
  }
`;

export const VALIDATION_REGEX =
  /^[a-zA-Z0-9àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡_/.~=[\]*$&",';: #\\-]+[a-zA-Z0-9àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡_/.~=[\]*$&",';: #\\-]$/;

export const dropdownValuesList = {
  booleanDropdown: ["Yes", "No"],
  tierDropdown: ["1", "2", "3"],
  vendorClassificationDropdown: ["Main Vendor", "Installer Vendor"],
  installerTypeDropdown: ["CMI", "VMI"],
  vehicleTypeDropdown: ["LCV", "HGV"],
};

export const AddInstallerModal = ({
  isInstallerModalOpened,
  setIsInstallerModalOpened,
  vendorDetails,
  editInstallerRecord,
  setEditInstallerRecord,
  getInstallerList,
}) => {
  const [installerName, setInstallerName] = useState({ value: "", error: false, errorText: "Enter Installer Name", success: false });

  const [vendorPhone, setVendorPhone] = useState({ value: "", error: false, errorText: "Enter Installer Vendor Name", success: false });
  const [activeOnMap, setActiveOnMap] = useState({ value: "", error: false, errorText: "Select Active On Map", success: false });
  const [vendorEmail, setVendorEmail] = useState({ value: "", error: false, errorText: "Enter Installer Vendor Email", success: false });
  const [address, setAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    country: { value: null, options: [], error: false, errorText: "Please select country", success: false },
    state: { value: null, options: [], allStateOptions: null, error: false, errorText: "Please select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
    locusId: "",
    latitude: { value: "", error: false, errorText: "Enter latitude", success: false },
    longitude: { value: "", error: false, errorText: "Enter longitude", success: false },
  });
  const [tierRating, setTierRating] = useState({ value: "", error: false, errorText: "Select Tier Rating", success: false });
  const [BPName, setBPName] = useState({ value: "", error: false, errorText: "Enter Business Partner Name", success: false });
  const [shopOnly, setShopOnly] = useState({ value: "", error: false, errorText: "Select Shop Only", success: false });
  const [technicianName, setTechnicianName] = useState({ value: "", error: false, errorText: "Enter Technician Name", success: false });
  const [legacyInstallerID, setLegacyInstallerID] = useState({ value: "", error: false, errorText: "Enter Legacy Installer ID", success: false });
  const [govtApproved, setGovtApproved] = useState({ value: "", error: false, errorText: "Select Govt Approved", success: false });
  const [garageOnly, setGarageOnly] = useState({ value: "", error: false, errorText: "Select Garage Only", success: false });
  const [installerType, setInstallerType] = useState({ value: "", error: false, errorText: "Select Installer Type", success: false });
  const [vehicleType, setVehicleType] = useState({ value: "", error: false, errorText: "Select Installer Type", success: false });
  const [immobilizer, setImmobilizer] = useState({ value: "", error: false, errorText: "Select Immobilizer", success: false });
  const [EVCertified, setEVCertified] = useState({ value: "", error: false, errorText: "Select EV Certified", success: false });
  const [comments, setComments] = useState("");

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [notification, setNotification] = useState(null);
  const updateInstaller = editInstallerRecord && Object.keys(editInstallerRecord)?.length ? true : false;
  const cspAccDetails = JSON.parse(sessionStorage.getItem("CSPDetails"));
  const changeOn = moment(new Date()).format("MM/DD/YYYY");
  const createdOn = updateInstaller ? moment(new Date(editInstallerRecord?.createdAt)).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
  const createdBy = updateInstaller ? editInstallerRecord?.createdBy : `${cspAccDetails?.firstName} ${cspAccDetails?.lastName}`;
  const partnerId = vendorDetails?.vendorId;
  const vendorName = vendorDetails?.vendorName;
  const installerId = updateInstaller ? editInstallerRecord.installerId : null;
  const [isLoading, setLoader] = useState(false);
  const [showAddressWarning, setShowAddressWarning] = useState(false);
  const handleClickOutside = () => {
    setNotification(null);
  };

  useEffect(() => {
    if (updateInstaller) {
      setComments(editInstallerRecord.comments);
    }
  }, [updateInstaller]);

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setIsInstallerModalOpened(false);
        setEditInstallerRecord(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    if (updateInstaller) {
      let installerType = [];
      let vehicleType = [];
      handleFields("Installer Name", editInstallerRecord.installerName);
      handleFields("Vendor Phone", editInstallerRecord.phone);
      handleFields("Vendor Email", editInstallerRecord.email);
      handleFields("Active On Map", editInstallerRecord.active);

      handleFields("Tier Rating", editInstallerRecord.tierRating);
      handleFields("Business Partner Name", editInstallerRecord.businessPartnerName);
      handleFields("Technician Name", editInstallerRecord.technicianName);
      editInstallerRecord.shopOnly && handleFields("Shop Only", editInstallerRecord.shopOnly);
      handleFields("Legacy Installer ID", editInstallerRecord.legacyInstallerId);
      editInstallerRecord.government && handleFields("Govt Approved", editInstallerRecord.government);
      editInstallerRecord.garageOnly && handleFields("Garage Only", editInstallerRecord.garageOnly);
      editInstallerRecord.lcv?.toLowerCase() === "yes" && vehicleType.push("LCV");
      editInstallerRecord.hgv?.toLowerCase() === "yes" && vehicleType.push("HGV");
      handleFields("Vehicle Type", vehicleType);
      editInstallerRecord.cmi?.toLowerCase() === "yes" && installerType.push("CMI");
      editInstallerRecord.vmi?.toLowerCase() === "yes" && installerType.push("VMI");
      handleFields("Installer Type", installerType);
      editInstallerRecord.immobilizer && handleFields("Immobilizer", editInstallerRecord.immobilizer);
      editInstallerRecord.evCertified && handleFields("EV Certified", editInstallerRecord.evCertified);
    }
  }, []);

  useEffect(() => {
    installerName.success &&
    vendorPhone.success &&
    vendorEmail.success &&
    activeOnMap.success &&
    address.verified &&
    address.latitude.success &&
    address.longitude.success &&
    BPName.success &&
    tierRating.success &&
    !tierRating.error &&
    !technicianName.error &&
    !shopOnly.error &&
    !legacyInstallerID.error &&
    !govtApproved.error &&
    !garageOnly.error &&
    !installerType.error &&
    !vehicleType.error &&
    !immobilizer.error &&
    !EVCertified.error
      ? setIsSaveDisabled(false)
      : setIsSaveDisabled(true);
  }, [
    installerName.success,
    vendorPhone.success,
    vendorEmail.success,
    activeOnMap.success,
    address.verified,
    address.latitude.value,
    address.longitude.value,
    BPName.success,
    tierRating.error,
    technicianName.error,
    shopOnly.error,
    legacyInstallerID.error,
    govtApproved.error,
    garageOnly.error,
    installerType.error,
    vehicleType.error,
    immobilizer.error,
    EVCertified.error,
    tierRating.success,
  ]);

  const handleFields = (field, value) => {
    switch (field) {
      case "Installer Name": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setInstallerName({ value, error: false, errorText: "Enter valid Installer Name", success: true });
          } else {
            setInstallerName({ value, error: true, errorText: "Enter valid Installer Name", success: false });
          }
        } else {
          setInstallerName({ value: "", error: true, errorText: "Enter Installer Name", success: false });
        }
        break;
      }
      case "Vendor Phone": {
        let phoneRegex = /^([^a-zA-Z])+$/;
        if (value && value.length) {
          if (phoneRegex.test(value)) {
            setVendorPhone({ value, error: false, errorText: "Enter valid Vendor Phone", success: true });
          } else {
            setVendorPhone({ value, error: true, errorText: "Enter valid Vendor Phone", success: false });
          }
        } else {
          setVendorPhone({ value: "", error: true, errorText: "Enter Vendor Phone", success: false });
        }
        break;
      }
      case "Vendor Email": {
        if (value && value.length) {
          if (EMAIL_REGEX_C360.test(value)) {
            setVendorEmail({ value, error: false, errorText: "Enter valid Vendor Email", success: true });
          } else {
            setVendorEmail({ value, error: true, errorText: "Enter valid Vendor Email", success: false });
          }
        } else {
          setVendorEmail({ value: "", error: true, errorText: "Enter Vendor Email", success: false });
        }
        break;
      }
      case "Active On Map": {
        if (value && value.length && dropdownValuesList.booleanDropdown.includes(value)) {
          setActiveOnMap({ value, error: false, errorText: "Select Active On Map", success: true });
        } else {
          setActiveOnMap({ value: "Please select", error: true, errorText: "Select Active On Map", success: false });
        }
        break;
      }
      case "Tier Rating": {
        if (value && value.length && dropdownValuesList.tierDropdown.includes(value)) {
          setTierRating({ value, error: false, errorText: "Select Tier Rating", success: true });
        } else {
          setTierRating({ value: "Please select", error: true, errorText: "Select Tier Rating", success: false });
        }
        break;
      }
      case "Business Partner Name": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setBPName({ value, error: false, errorText: "Enter valid Business Partner Name", success: true });
          } else {
            setBPName({ value, error: true, errorText: "Enter valid Business Partner Name", success: false });
          }
        } else {
          setBPName({ value: "", error: true, errorText: "Enter Business Partner Name", success: false });
        }
        break;
      }
      case "Technician Name": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setTechnicianName({ value, error: false, errorText: "Enter valid Technician Name", success: true });
          } else {
            setTechnicianName({ value, error: true, errorText: "Enter valid Technician Name", success: false });
          }
        } else {
          setTechnicianName({ value: "", error: false, errorText: "Enter Technician Name", success: false });
        }
        break;
      }
      case "Shop Only": {
        value = value && _.capitalize(value);
        if (value && value.length && dropdownValuesList.booleanDropdown.includes(value)) {
          setShopOnly({ value, error: false, errorText: "Select Shop Only", success: true });
        } else {
          setShopOnly({ value: "Please select", error: false, errorText: "Select Shop Only", success: false });
        }
        break;
      }
      case "Legacy Installer ID": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setLegacyInstallerID({ value, error: false, errorText: "Enter valid Legacy Installer ID", success: true });
          } else {
            setLegacyInstallerID({ value, error: true, errorText: "Enter valid Legacy Installer ID", success: false });
          }
        } else {
          setLegacyInstallerID({ value: "", error: false, errorText: "Enter Legacy Installer ID", success: false });
        }
        break;
      }
      case "Govt Approved": {
        value = value && _.capitalize(value);
        if (value && value.length && dropdownValuesList.booleanDropdown.includes(value)) {
          setGovtApproved({ value, error: false, errorText: "Select Govt Approved", success: true });
        } else {
          setGovtApproved({ value: "Please select", error: false, errorText: "Select Govt Approved", success: false });
        }
        break;
      }
      case "Garage Only": {
        value = value && _.capitalize(value);
        if (value && value.length && dropdownValuesList.booleanDropdown.includes(value)) {
          setGarageOnly({ value, error: false, errorText: "Select Garage Only", success: true });
        } else {
          setGarageOnly({ value: "Please select", error: false, errorText: "Select Garage Only", success: false });
        }
        break;
      }
      case "Installer Type": {
        if (value && value.length) {
          setInstallerType({ value, error: false, errorText: "Select Installer Type", success: true });
        } else {
          setInstallerType({ value, error: false, errorText: "Select Installer Type", success: false });
        }
        break;
      }
      case "Vehicle Type": {
        if (value && value.length) {
          setVehicleType({ value, error: false, errorText: "Select LCV / HGV", success: true });
        } else {
          setVehicleType({ value, error: false, errorText: "Select LCV / HGV", success: false });
        }
        break;
      }

      case "Immobilizer": {
        value = value && _.capitalize(value);
        if (value && value.length && dropdownValuesList.booleanDropdown.includes(value)) {
          setImmobilizer({ value, error: false, errorText: "Select Immobilizer", success: true });
        } else {
          setImmobilizer({ value: "Please select", error: false, errorText: "Select Immobilizer", success: false });
        }
        break;
      }
      case "EV Certified": {
        value = value && _.capitalize(value);
        if (value && value.length && dropdownValuesList.booleanDropdown.includes(value)) {
          setEVCertified({ value, error: false, errorText: "Select EV Certified", success: true });
        } else {
          setEVCertified({ value: "Please select", error: false, errorText: "Select EV Certifiede", success: false });
        }
        break;
      }
    }
  };

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(".p-multiselect-header > .p-checkbox");
    selectAllCheckbox?.after(" Select All");
  };

  const notify = (content, type, isNotificationWithTimer = true) => {
    setNotification(
      <>
        <CustomNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />
      </>
    );
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const handleInstallerSave = async () => {
    let data = {
      id: updateInstaller ? editInstallerRecord.id : null,
      vendorId: vendorDetails?.id ?? "",
      installerId: updateInstaller ? installerId : null,
      status: activeOnMap.value,
      installerName: installerName.value,
      installerPhone: vendorPhone.value,
      installerEmail: vendorEmail.value,
      vendorName: vendorName ?? "",
      installerAddress1: address.address1.value,
      installerAddress2: address.address2.value,
      installerCity: address.city.value,
      installerState: address.state.value,
      installerZipCode: address.zip.value,
      installerCountry: address.country.value.code,
      addressByPass: address.addressByPass,
      locationFound: address.locationFound,
      tierRating: tierRating.value,
      lcv: vehicleType.value.includes("LCV") ? "Yes" : "No",
      hgv: vehicleType.value.includes("HGV") ? "Yes" : "No",
      immobilizer: immobilizer.value,
      evCertified: EVCertified.value,
      businessPartnerName: BPName.value,
      technicianName: technicianName.value,
      shopOnly: shopOnly.value,
      garageOnly: garageOnly.value,
      cmi: installerType.value.includes("CMI") ? "Yes" : "No",
      vmi: installerType.value.includes("VMI") ? "Yes" : "No",
      legacyInstallerId: legacyInstallerID.value,
      comments: comments,
      locusId: address.locusId ? Number(address.locusId) : null,
      government: govtApproved.value,
      salesOrgs: vendorDetails?.salesOrg,
      latitude: Number(address.latitude.value),
      longitude: Number(address.longitude.value),
      createdBy: createdBy,
      modifiedBy: createdBy,
    };
    let missingFieldsArray = [];
    for (const key of Object.keys(data)) {
      if (key === "vendorId" || key === "vendorName") {
        if (data[key] === "" || data[key] === undefined || data[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      // setIsInstallerModalOpened(false);
      return setTimeout(() => {
        notify(
          "Unable to save vendor details due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    }
    setLoader(true);
    let response = await addUpdateInstaller(data);
    if (response?.status === 200 && response?.data?.data?.message?.toLowerCase() === "success") {
      notify("Vendor saved successfully", "success", false);
      setTimeout(() => {
        setNotification(null);
        setIsInstallerModalOpened(false);
        setEditInstallerRecord(null);
        setLoader(false);
        getInstallerList(vendorDetails?.id);
      }, 3000);
    } else {
      notify("We are currently experiencing issues, please try again", "error", false);
      setLoader(false);
    }
  };

  return (
    <>
      <Spacer top="10px" />
      <Modal
        role="dialog"
        ariaLabel="Add Installer"
        className="actionaction"
        hideCloseButton={false}
        opened={isInstallerModalOpened}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div css={{ paddingLeft: "1.5rem" }}>
              <Title size="medium">{updateInstaller ? installerId : `Add Installer`}</Title>
              <Spacer bottom="10px" />
              <SmallText>Please ensure all the details are filled and address is validated before saving.</SmallText>
            </div>
            <div
              onClick={() => {
                setIsInstallerModalOpened(false), setEditInstallerRecord(false);
              }}
              style={{ marginRight: "1rem", marginTop: "0.3rem" }}
            >
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ padding: "16px 0 16px 16px !important", overflowY: "none" }}>
          {/* <Spacer top="0.5rem" /> */}
          <div css={{ height: "23.5rem", overflowY: "scroll", borderTop: "1px solid gray" }}>
            {showAddressWarning && (
              <>
                <Notification
                  type="warning"
                  title="Warning: This address is not valid and will not be able to display on the installer map."
                  key={generate()}
                  hideCloseButton={true}
                  fullBleed={true}
                  css={addressWarningCSS}
                />
                <Spacer bottom="10px" />
              </>
            )}
            {notification}
            <Spacer bottom="10px" />
            <Loader active={isLoading} />
            <UltraMediumText eDSFont fontSize="16px" marginBottom="0.7rem">
              Installer Information
            </UltraMediumText>
            <FlexRow>
              {updateInstaller && (
                <CustomInput
                  formWidth="25%"
                  type="text"
                  label="Installer ID"
                  aria-label="Installer ID"
                  value={installerId}
                  disabled={true}
                  paddingRight="5px"
                  css={{ paddingBottom: "0.6rem" }}
                />
              )}
              <CustomInput
                formWidth="25%"
                type="text"
                label="Installer Name"
                aria-label="Installer Name"
                value={installerName.value}
                error={installerName.error}
                errorText={installerName.errorText}
                success={installerName.success}
                onChange={(e) => handleFields("Installer Name", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
              <CustomInput
                formWidth="25%"
                type="text"
                label="Partner ID"
                aria-label="Partner ID"
                value={partnerId}
                disabled={true}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
            </FlexRow>

            <UltraMediumText css={{ marginTop: "0.75rem" }} eDSFont fontSize="16px" marginBottom="0.7rem">
              Vendor Information
            </UltraMediumText>

            <FlexRow>
              <CustomInput
                formWidth="25%"
                type="text"
                label="Installer Vendor Name"
                aria-label="Installer Vendor Name"
                value={vendorName}
                disabled={true}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
              <CustomInput
                formWidth="25%"
                type="text"
                label="Installer Vendor Phone"
                aria-label="Installer Vendor Phone"
                value={vendorPhone.value}
                error={vendorPhone.error}
                errorText={vendorPhone.errorText}
                success={vendorPhone.success}
                onChange={(e) => handleFields("Vendor Phone", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
              <CustomInput
                formWidth="25%"
                type="text"
                label="Installer Vendor Email"
                aria-label="Installer Vendor Email"
                value={vendorEmail.value}
                error={vendorEmail.error}
                errorText={vendorEmail.errorText}
                success={vendorEmail.success}
                onChange={(e) => handleFields("Vendor Email", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
              <CustomDropdown
                width="25%"
                type="text"
                label="Active On Map"
                aria-label="Active On Map"
                value={activeOnMap.value ? activeOnMap.value : "Please select"}
                error={activeOnMap.error}
                errorText={activeOnMap.errorText}
                success={activeOnMap.success}
                css={{ paddingBottom: "0.6rem" }}
                onChange={(e) => handleFields("Active On Map", e.target.value)}
                paddingRight="5px"
              >
                <>
                  <option disabled>Please select</option>
                  {dropdownValuesList?.booleanDropdown?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              </CustomDropdown>
            </FlexRow>

            <AdminValidateAddress
              address={address}
              setAddress={setAddress}
              notify={notify}
              editInstallerRecord={editInstallerRecord}
              setLoader={setLoader}
              setShowAddressWarning={setShowAddressWarning}
            />

            <UltraMediumText css={{ marginTop: "0.75rem" }} eDSFont fontSize="16px" marginBottom="0.7rem">
              Other Information
            </UltraMediumText>
            <FlexRow>
              <CustomDropdown
                width="25%"
                type="text"
                label="Tier Rating"
                aria-label="Tier Rating"
                value={tierRating.value ? tierRating.value : "Please select"}
                error={tierRating.error}
                errorText={tierRating.errorText}
                success={tierRating.success}
                css={{ paddingBottom: "0.6rem" }}
                onChange={(e) => handleFields("Tier Rating", e.target.value)}
                paddingRight="5px"
                required
              >
                <>
                  <option disabled>Please select</option>
                  {dropdownValuesList?.tierDropdown?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              </CustomDropdown>
              <CustomInput
                formWidth="25%"
                type="text"
                label="Business Partner Name"
                aria-label="Business Partner Name"
                value={BPName.value}
                error={BPName.error}
                errorText={BPName.errorText}
                success={BPName.success}
                onChange={(e) => handleFields("Business Partner Name", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
              <CustomInput
                formWidth="25%"
                type="text"
                label="Technician Name"
                aria-label="Technician Name"
                value={technicianName.value}
                error={technicianName.error}
                errorText={technicianName.errorText}
                success={technicianName.success}
                onChange={(e) => handleFields("Technician Name", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
                required={false}
              />
              <CustomDropdown
                width="25%"
                type="text"
                label="Shop Only"
                aria-label="Shop Only"
                value={shopOnly.value ? shopOnly.value : "Please select"}
                error={shopOnly.error}
                errorText={shopOnly.errorText}
                success={shopOnly.success}
                css={{ paddingBottom: "0.6rem" }}
                onChange={(e) => handleFields("Shop Only", e.target.value)}
                paddingRight="5px"
                required={false}
              >
                <>
                  <option disabled>Please select</option>
                  {dropdownValuesList?.booleanDropdown?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              </CustomDropdown>
              <CustomInput
                formWidth="25%"
                type="text"
                label="Legacy Installer ID"
                aria-label="Legacy Installer ID"
                value={legacyInstallerID.value}
                error={legacyInstallerID.error}
                errorText={legacyInstallerID.errorText}
                success={legacyInstallerID.success}
                onChange={(e) => handleFields("Legacy Installer ID", e.target.value)}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
                required={false}
              />
              <CustomDropdown
                width="25%"
                type="text"
                label="Govt Approved"
                aria-label="Govt Approved"
                value={govtApproved.value ? govtApproved.value : "Please select"}
                error={govtApproved.error}
                errorText={govtApproved.errorText}
                success={govtApproved.success}
                css={{ paddingBottom: "0.6rem" }}
                onChange={(e) => handleFields("Govt Approved", e.target.value)}
                paddingRight="5px"
                required={false}
              >
                <>
                  <option disabled>Please select</option>
                  {dropdownValuesList?.booleanDropdown?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              </CustomDropdown>
              <CustomDropdown
                width="25%"
                type="text"
                label="Garage Only"
                aria-label="Garage Only"
                value={garageOnly.value ? garageOnly.value : "Please select"}
                error={garageOnly.error}
                errorText={garageOnly.errorText}
                success={garageOnly.success}
                css={{ paddingBottom: "0.6rem" }}
                onChange={(e) => handleFields("Garage Only", e.target.value)}
                paddingRight="5px"
                required={false}
              >
                <>
                  <option disabled>Please select</option>
                  {dropdownValuesList?.booleanDropdown?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              </CustomDropdown>
              <div css={{ paddingBottom: "0.6rem", width: "25%", paddingRight: "5px" }}>
                <SmallText css={{ marginTop: "0px" }}>
                  Installer Type{" "}
                  <Body size="small" primitive="span" color="#6F7171">
                    Optional
                  </Body>
                </SmallText>
                <CustomisedInput
                  label="Installer Type"
                  aria-label="Installer Type"
                  value={installerType.value}
                  error={installerType.error}
                  errorText={installerType.errorText}
                  success={installerType.success}
                  css={{ width: "100%", minHeight: "2.8rem" }}
                  placeholderPadding="0.8rem 0.5rem"
                  display="chip"
                  placeholder="Please select"
                  onShow={onShow}
                  options={dropdownValuesList?.installerTypeDropdown?.map((item) => {
                    return item;
                  })}
                  onChange={(e) => handleFields("Installer Type", e.value)}
                  required={false}
                />
              </div>
              <div css={{ paddingBottom: "0.6rem", width: "25%", paddingRight: "5px" }}>
                <SmallText css={{ marginTop: "0px" }}>
                  LCV / HGV{" "}
                  <Body size="small" primitive="span" color="#6F7171">
                    Optional
                  </Body>
                </SmallText>
                <CustomisedInput
                  label="LCV / HGV"
                  aria-label="LCV / HGV"
                  value={vehicleType.value}
                  error={vehicleType.error}
                  errorText={vehicleType.errorText}
                  success={vehicleType.success}
                  css={{ width: "100%", minHeight: "2.8rem" }}
                  placeholderPadding="0.8rem 0.5rem"
                  display="chip"
                  placeholder="Please select"
                  onShow={onShow}
                  options={dropdownValuesList?.vehicleTypeDropdown?.map((item) => {
                    return item;
                  })}
                  onChange={(e) => handleFields("Vehicle Type", e.value)}
                  required={false}
                />
              </div>
              <CustomDropdown
                width="25%"
                type="text"
                label="Immobilizer"
                aria-label="Immobilizer"
                value={immobilizer.value ? immobilizer.value : "Please select"}
                error={immobilizer.error}
                errorText={immobilizer.errorText}
                success={immobilizer.success}
                css={{ paddingBottom: "0.6rem" }}
                onChange={(e) => handleFields("Immobilizer", e.target.value)}
                paddingRight="5px"
                required={false}
              >
                <>
                  <option disabled>Please select</option>
                  {dropdownValuesList?.booleanDropdown?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              </CustomDropdown>
              <CustomDropdown
                width="25%"
                type="text"
                label="EV Certified"
                aria-label="EV Certified"
                value={EVCertified.value ? EVCertified.value : "Please select"}
                error={EVCertified.error}
                errorText={EVCertified.errorText}
                success={EVCertified.success}
                css={{ paddingBottom: "0.6rem" }}
                onChange={(e) => handleFields("EV Certified", e.target.value)}
                paddingRight="5px"
                required={false}
              >
                <>
                  <option disabled>Please select</option>
                  {dropdownValuesList?.booleanDropdown?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              </CustomDropdown>
              <CustomInput
                formWidth="25%"
                type="text"
                label="Change On"
                aria-label="Change On"
                value={changeOn}
                disabled={true}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
              <CustomInput
                formWidth="25%"
                type="text"
                label="Created On"
                aria-label="Created On"
                value={createdOn}
                disabled={true}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
              <CustomInput
                formWidth="25%"
                type="text"
                label="Created By"
                aria-label="Created By"
                value={createdBy}
                disabled={true}
                paddingRight="5px"
                css={{ paddingBottom: "0.6rem" }}
              />
            </FlexRow>
            <FlexRow>
              <div css={{ width: "50%", paddingBottom: "0.6rem" }}>
                <TextArea
                  label="Comments"
                  readOnly={false}
                  required={false}
                  disabled={false}
                  error={false}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  autoResize="none"
                  placeholder="Type here"
                  css={{ fontSize: "12px" }}
                />
              </div>
            </FlexRow>
          </div>
          <FlexRow style={{ paddingTop: "10px", borderTop: "1px solid gray" }}>
            <CustomSLButton
              css={{ padding: "0 10px 0 10px" }}
              primary
              aria-label="Save"
              role="button"
              disabled={isSaveDisabled}
              aria-disabled={isSaveDisabled}
              onClick={handleInstallerSave}
            >
              Save
            </CustomSLButton>
            <CustomSLButton
              aria-label="Cancel"
              role="button"
              secondary
              onClick={() => {
                setIsInstallerModalOpened(false), setEditInstallerRecord(false);
              }}
            >
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalBody>
      </Modal>
    </>
  );
};
