import * as urls from "../../api/C4S Decommission - Replacement/url";
import { putRequest } from "../../../services/RestServices";

export const updateReturnItem = async (data) => {
  try {
    return await putRequest(urls.UPDATE_RETURN_ITEMS, data);
  } catch (error) {
    return error;
  }
};
