import { createContext, useState } from "react";

export const CreateVehicleContext = createContext();
export const CreateVehicleProvider = (props) => {
  const [revealVehicle, setRevealVehicle] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [esn, setEsn] = useState("");
  return (
    <CreateVehicleContext.Provider
      value={{ revealVehicle: { revealVehicle, setRevealVehicle }, vehicle: { vehicle, setVehicle }, esn: { esn, setEsn } }}
    >
      {props.children}
    </CreateVehicleContext.Provider>
  );
};
