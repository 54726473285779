import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const cancelWorkticket = async (payload) => {
  try {
    return await postRequest(`${urls.CANCEL_WORKTICKET}`, payload);
  } catch (error) {
    return error;
  }
};
