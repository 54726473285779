//export const ESN_REGEX = "^[0-9]$|^[1-9A-FW][0-9A-FW]*$";
export const ESN_REGEX = "^[0-9a-zA-Z]+$";
export const PHONE_REGEX = "^[0-9]{10}$";
export const ICCID_REGEX = "^[0-9]+$";
export const IMEI_REGEX = "^[0-9]+$";
export const MEID_REGEX = "^[0-9A-Fa-f]+$";
export const WORK_TICKET_REGEX = "^[a-zA-Z0-9]{1,10}$";
export const ACCOUNT_REGEX = "^[0-9]{4}$|^[0-9]{10}$";
export const TECHNICALID_REGEX = "^[0-9A-Za-z\\._]{1,64}$";

export const ADDRESS_REGEX =
  /^[\wàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡/.~=[\]*$&",';: #’\\-]+[\wàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡/.~=[\]*$&",';: #’\\-]$/;
export const CITY_REGEX = /^[a-zA-Z\s]+$/;
export const STATE_REGEX = /^[a-zA-Z]+$/;
export const ZIP_REGEX = /^\d{5}$/;
export const NAME_REGEX =
  /^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡’' ]+[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡’' ]$/;
export const SCHEDULER_PHONE_REGEX = /^(\d)(?!\1+$)\d{9}$/;
export const EMAIL_REGEX = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
export const EMAIL_REGEX_C360 =
  /^([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡0-9_\-.’']+)@([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡0-9_\-.]+)\.([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡0-9]+)$/;
export const CSP_REGEX = /^[a-zA-Z\d]+$/;
export const VEHICLECOUNT_REGEX = /^(([01]?[0-9]{0,3})|(2000))$/;
export const APPTLENGTH_REGEX = /^(([0]?[0-9])|(10))$/;
// export const WORKTICKETNUMBER_REGEX = /(^(?!0{6})[0-9]\d{5}$)|(^(?!0{6})[0-9]\d{5}(,(?!0{6})[0-9]\d{5})+$)/;
// export const WORKTICKETNUMBER_REGEX = /(^W\d{9}$)|(^W\d{9}(,W\d{9})+$)/i;
//updated the regex for migrated data
export const WORKTICKETNUMBER_REGEX = /^[a-zA-Z\d]+(,[a-zA-Z\d]+)*$/i;
// export const WORKTICKETNUMBER_REGEX = /^[a-zA-Z\d]+(,[a-zA-Z\d]+)*$/;
export const OPPORTUNITY_ID = "^[a-zA-Z0-9]{18}";
export const SLOPPID_REGEX = "^[a-zA-Z0-9 _.-]*$";
export const VZCNUMBER_REGEX = /^[0-9a-zA-Z]+$/;
export const ESNS_REGEX = /(^[\da-zA-Z]*$)|(^[\da-zA-Z]*(,[\da-zA-Z]*)+$)/;
export const LETTERS_SC_REGEX = /(^,)|[-/?_!+.~=[\]*$"';: #\\-^%@()&{}\s]+/;
export const QUANTITY_REGEX = /^[1-9]+[0-9]*$/;
// export const ESNS_REGEX = /(^\d{10}$)|(^\d{10}(,\s\d{10})+$)/;
// export const LETTES_REGEX = /[a-zA-Z]+/;
export const ZIP_CODE_LIMIT = 8;
export const INSTALLER_VENDOR_ID = /^1\d{0,9}$/;
export const VENDOR_ID = /^1\d{9}$/;
export const ORIGINAL_PURCHASE_ORDER = /^(([0-9]{10})[,])*([0-9]{10})$/;
