import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const addVehicle = async (data) => {
  try {
    return await postRequest(urls.ADD_VEHICLE, data);
  } catch (err) {
    return err;
  }
};
