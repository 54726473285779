import { postRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const updateJobAutoToManual = async (jobId) => {
  try {
    return await postRequest(urls.UPDATE_JOB_ON_AUTO_TO_MANUAL + `/${jobId}`);
  } catch (err) {
    return err;
  }
};
