import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const VIEW_WORK_TICKET_COL = [
  {
    key: "Ticket ID",
    value: "ticketId",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    ellipsis: false,
    containsImage: true,
    width: "120px",
  },
  // {
  //   key: "Tracking ID",
  //   value: "trackingId",
  //   isFilterEnabled: false,
  //   filterType: "String",
  //   isCustomFilter: false,
  //   isSortable: true,
  //   isHyperLink: false,
  //   ellipsis: false,
  //   width: COLUMN_WIDTHS.small,
  // },
  {
    key: "Case",
    value: "caseNumber",
    checkSouce: true,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: "90px",
  },
  {
    key: "Created On",
    value: "createdOn",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    // width: COLUMN_WIDTHS.xsmall,
    isDate: true,
    dateFormat: "MMM D, yyyy",
    width: "85px",
  },
  {
    key: "Work Order Owner",
    value: "workOrderOwner",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: true,
    width: "135px",
    // width: "350px",
  },
  {
    key: "Install Type",
    value: "installType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: "85px",
  },
  {
    key: "Sales Org",
    value: "salesOrg",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    containsImage: true,
    width: "75px",
  },
  {
    key: "City, State",
    value: "cityState",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: true,
    width: "100px",
  },
];

export const WORK_VIEW_VEHICLE_TABLE_COL = [
  {
    key: "",
    value: "index",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isHyperLink: false,
    ellipsis: false,
    width: "35px",
    style: { maxWidth: "35px", minWidth: "35px" },
  },
  {
    key: "VIN",
    value: "vin",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Year",
    value: "year",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Make",
    value: "make",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Model",
    value: "model",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    ellipsis: false,
    width: COLUMN_WIDTHS.small,
  },
];
