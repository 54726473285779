import moment from "moment";

export const sessionData = (jobID) => {
  let sessionJobId = JSON.parse(sessionStorage.getItem("JobId")) || [];
  if (sessionJobId.includes(jobID)) {
    sessionJobId = sessionJobId.filter((jobid) => jobid !== jobID);
  }
  sessionJobId.push(jobID);
  sessionStorage.setItem("JobId", JSON.stringify(sessionJobId));
};

export const jobDurationFormatter = (jobLengthHours) => {
  return jobLengthHours ? `${Math.floor(jobLengthHours)}:${(jobLengthHours * 60) % 60 || "00"}` : jobLengthHours;
};

export const durationUnitCalculation = (slotData) => {
  return slotData.durationUnit?.toUpperCase() === "M" ? "minutes" : "hours";
};

export const jobItemsFormatter = (jobDurationDetails) => {
  let jobItems = [];
  jobDurationDetails.map((jobItem) => {
    jobItem.quantity && jobItems.push(`${jobItem.quantity} - ${jobItem.productDescription}`);
  });
  return jobItems.join(" | ");
};

export const handleStartDate = (format = true) => {
  return new Date().getDay() === 5
    ? format
      ? moment().add(4, "days").format("MM/DD/YYYY")
      : moment().add(4, "days")
    : format
    ? moment().add(2, "days").format("MM/DD/YYYY")
    : moment().add(2, "days");
};

export const handleERPError = (data, notify) => {
  if (!data?.data?.erpError) return notify(`${data.message} ${data?.data?.message ? `,\u00A0 ${data?.data?.message}` : ``}`, "error", false);
  let errorMsg = [];
  data?.data?.erpError?.map((list, idx) => {
    if (list?.SeverityCode === "3") {
      errorMsg.push(`Error ${idx + 1}: ${list?.Note}`);
    }
  });
  notify(
    <>
      {errorMsg.map((msg, idx) => (
        <div key={idx}>{msg}</div>
      ))}
    </>,
    "error",
    false
  );
};
