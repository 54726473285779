import React, { useEffect, useState } from "react";
import { CustomSLButton, FlexRow, UltraMediumText, SmallText, FlexColumn } from "../../components/core/utility";
//import { validateAddress } from "../../services/scheduler/validateAddress";
import { validateAddress } from "../../services/C4S Decommission - Replacement/validateAddress";
import { isEqual, groupBy } from "lodash";
import { CustomDropdown, CustomInput } from "../C4S Decommission - Replacement/AddAVehicle";
import { RadioButtonGroup } from "@vds/inputs";
import styled from "styled-components";
import { Loader } from "@vds/loaders";
import { css } from "@emotion/react";
import { getStatesList } from "../../services/C4S Decommission - Replacement/getStatesList";
import { getCountryList } from "../../services/scheduler/getCountryList";
import { ADDRESS_REGEX, ZIP_CODE_LIMIT, ZIP_REGEX } from "../../constants/ValidationConstants";

const RadioButtonGroupWrapper = styled.div`
  div[role="radiogroup"] {
    border: 0.0625rem solid #d8dada;
    width: 70vmin;
    margin-bottom: 0.6rem;
    > div > label {
      > div:first-child > div {
        height: 1rem;
        width: 1rem;
        transform: translate(50%, 100%);
      }
      > div:last-child {
        font-size: 12px;
        font-family: Verizon-NHG-eTX;
        padding: 0.313rem 0.5rem;
        > span > span {
          font-size: 12px;
          font-family: Verizon-NHG-eTX;
        }
      }
    }
  }
`;

const CustomLoader = css`
  div[aria-label="loader overlay"] {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    top: 1.5rem;
    > div {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

export const countryList = ["DEU", "GBR", "FRA", "CAN", "NLD", "IRL", "MEX", "NZL", "USA", "AUS", "AUT", "BEL"];

export const AdminValidateAddress = ({ address, setAddress, notify, editInstallerRecord, setLoader, setShowAddressWarning }) => {
  const [suggestedAddress, setSuggestedAddress] = useState({
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    locusId: "",
    latitude: "",
    longitude: "",
  });
  const [typedAddress, setTypedAddress] = useState("");
  const [isAddressSuggestionShown, setIsAddressSuggestionShown] = useState(false);
  const [isValidateAddressDisabled, setIsValidateAddressDisabled] = useState(true);
  const [isValidatingAddress, setIsValidatingAddress] = useState(false);
  const [zipCodeLimit, setZipCodeLimit] = useState("");
  const [zipRegex, setZipRegex] = useState("");
  const countryZipLimitsList = {
    DEU: [5, 5],
    GBR: [4, 8],
    FRA: [5, 5],
    CAN: [6, 6],
    NLD: [4, 4],
    IRL: [7, 7],
    MEX: [5, 5],
    NZL: [4, 4],
    USA: [5, 5],
    AUS: [4, 4],
    AUT: [4, 4],
    BEL: [4, 4],
  };

  useEffect(() => {
    handleAddress();
  }, []);

  useEffect(() => {
    if (typeof editInstallerRecord === "object" && Object.keys(editInstallerRecord).length > 0 && !editInstallerRecord?.locusId)
      setShowAddressWarning(true);
  }, []);

  useEffect(() => {
    if (
      address.address1.success &&
      !address.address2.error &&
      address.city.success &&
      address.state.success &&
      address.country.success &&
      address.zip.success
    ) {
      if (address.verified) {
        !isValidateAddressDisabled && setIsValidateAddressDisabled(true);
      } else {
        isValidateAddressDisabled && setIsValidateAddressDisabled(false);
        if (editInstallerRecord?.id && address.addressByPass === "NeedToValidateOnLoad") {
          if (!editInstallerRecord?.addressByPass) {
            handleAddressVerification(address);
          } else {
            setAddress((prevState) => ({
              ...prevState,
              verified: true,
              addressByPass: editInstallerRecord.addressByPass,
              locationFound: editInstallerRecord.locationFound,
            }));
          }
        } else {
          if (!suggestedAddress.address && isAddressSuggestionShown) {
            setIsAddressSuggestionShown(false);
            setAddress((prevState) => ({ ...prevState, verified: true }));
          }
        }
      }
    } else {
      !isValidateAddressDisabled && setIsValidateAddressDisabled(true);
      if (address.addressByPass === "NeedToValidateOnLoad") {
        setAddress((prevState) => ({
          ...prevState,
          addressByPass: null,
        }));
      } else {
        if (!suggestedAddress.address && isAddressSuggestionShown) {
          setIsAddressSuggestionShown(false);
          setAddress((prevState) => ({ ...prevState, verified: false }));
        }
      }
    }
  }, [
    address.verified,
    address.addressByPass,
    address.address1.value,
    address.address2.value,
    address.city.value,
    address.state.value,
    address.zip.value,
    address.country.value,
    suggestedAddress.address,
  ]);

  const getCountries = async () => {
    let countryOptions = [];
    // allCountryStateOptions = null;
    let countryListResponse = await getCountryList();
    if (countryListResponse.status === 200 && countryListResponse?.data?.data?.length) {
      countryOptions = countryListResponse.data.data;
      // ({ allCountryStateOptions } = await getStateList());
    } else {
      notify("Unable to fetch country list due to an unexpected error", "error", false);
    }
    setAddress((prevState) => ({
      ...prevState,
      country: {
        ...prevState.country,
        options: countryOptions,
        error: !countryOptions.length,
        errorText: countryOptions.length ? prevState.country.errorText : "Unable to fetch country list due to an unexpected error",
        success: false,
      },
      // state: {
      //   ...prevState.state,
      //   options: [],
      //   allStateOptions: allCountryStateOptions,
      //   error: !allCountryStateOptions,
      //   errorText: allCountryStateOptions ? prevState.state.errorText : "Unable to fetch state list due to an unexpected error",
      //   success: false,
      // },
    }));
    return {
      countryOptions,
      // , allCountryStateOptions
    };
  };

  // const getStateList = async () => {
  //   let allCountryStateOptions = null;
  //   let stateListResponse = await getStatesList();
  //   if (stateListResponse.status === 200 && stateListResponse?.data?.data?.length) {
  //     allCountryStateOptions = stateListResponse.data.data.map((state) => ({
  //       ...state,
  //       stateName: state.erpStateCode + " - " + state.stateCode,
  //     }));
  //     allCountryStateOptions = groupBy(allCountryStateOptions, "countryCode");
  //   } else {
  //     notify("Unable to fetch state list due to an unexpected error", "error", false);
  //   }
  //   return { allCountryStateOptions };
  // };

  const handleAddress = async () => {
    setLoader(true);
    let countryOptions = [];
    // allCountryStateOptions = null;
    if (address.country.options.length) {
      countryOptions = address.country.options;
      // allCountryStateOptions = address.state.allStateOptions ? address.state.allStateOptions : null;
    } else {
      ({
        countryOptions,
        // , allCountryStateOptions
      } = await getCountries());
    }
    setLoader(false);
    if (editInstallerRecord && Object.keys(editInstallerRecord)?.length) {
      handleAddressFields("Address 1", editInstallerRecord.address1?.trim());
      handleAddressFields("Address 2", editInstallerRecord.address2?.trim());
      handleAddressFields("City", editInstallerRecord.city?.trim());
      handleAddressFields(
        "Country",
        editInstallerRecord.country?.trim(),
        editInstallerRecord.state?.trim(),
        editInstallerRecord.zipCode?.trim(),
        countryOptions
        // allCountryStateOptions
      );
      handleAddressFields("State", editInstallerRecord.state?.trim());
      handleFields("Latitude", editInstallerRecord?.latitude?.toString());
      handleFields("Longitude", editInstallerRecord?.longitude?.toString());
      setAddress((prevState) => ({
        ...prevState,
        addressByPass: "NeedToValidateOnLoad",
      }));
    }
  };

  const handleAddressFields = (field, value, customValue1 = false, customValue2 = false, countryOptions = false, trim = false) => {
    if (!trim) {
      if (address.verified) {
        setAddress((prevState) => ({
          ...prevState,
          locusId: "",
          verified: false,
          // latitude: { ...prevState.latitude, value: "", error: prevState.latitude.error, errorText: "Enter latitude", success: false },
          // longitude: { ...prevState.longitude, value: "", error: prevState.latitude.error, errorText: "Enter longitude", success: false },
        }));
        // if (address.latitude.value || address.longitude.value) {
        //   setAddress((prevState) => ({
        //     ...prevState,
        //     latitude: { ...prevState.latitude, value: "", error: true, errorText: "Enter latitude", success: false },
        //     longitude: { ...prevState.longitude, value: "", error: true, errorText: "Enter longitude", success: false },
        //   }));
        // }
      }
    }
    switch (field) {
      case "Address 1": {
        if (value && value.length && value.toLowerCase() !== "null") {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: false, errorText: "Enter address 1", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: true, errorText: "Enter a valid address 1", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address1: { value: "", error: true, errorText: "Enter address 1", success: false } }));
        }
        break;
      }
      case "Address 2": {
        if (value && value.length && value.toLowerCase() !== "null") {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: false, errorText: "Enter address 2", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: true, errorText: "Enter a valid address 2", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address2: { value: "", error: false, errorText: "Enter address 2", success: false } }));
        }
        break;
      }
      case "City": {
        value && value.length && value.toLowerCase() !== "null"
          ? ADDRESS_REGEX.test(value)
            ? setAddress((prevState) => ({ ...prevState, city: { ...prevState.city, value, error: false, errorText: "Enter city", success: true } }))
            : setAddress((prevState) => ({
                ...prevState,
                city: { ...prevState.city, value, error: true, errorText: "Enter valid city", success: false },
              }))
          : setAddress((prevState) => ({
              ...prevState,
              city: { ...prevState.city, value: "", error: true, errorText: "Enter city", success: false },
            }));
        break;
      }
      case "State": {
        value && value.length && value.toLowerCase() !== "null"
          ? ADDRESS_REGEX.test(value)
            ? setAddress((prevState) => ({
                ...prevState,
                state: { ...prevState.state, value, error: false, errorText: "Enter state", success: true },
              }))
            : setAddress((prevState) => ({
                ...prevState,
                state: { ...prevState.state, value, error: true, errorText: "Enter valid state", success: false },
              }))
          : setAddress((prevState) => ({
              ...prevState,
              state: { ...prevState.state, value: "", error: true, errorText: "Enter state", success: false },
            }));
        break;
        // value = customValue1.find((state) => value === state.erpStateCode || value.toLowerCase() === state.stateCode.toLowerCase()) ?? null;
        // if (value) {
        //   setAddress((prevState) => ({
        //     ...prevState,
        //     state: { ...prevState.state, value, error: false, errorText: "Please select state", success: true },
        //   }));
        // } else {
        //   setAddress((prevState) => ({
        //     ...prevState,
        //     state: { ...prevState.state, value: null, error: true, errorText: "Please select state", success: false },
        //   }));
        // }
        // break;
      }
      case "Zip": {
        value = value.replace(/[^a-zA-Z0-9 ]/g, "");
        if (value && value.length && value.toLowerCase() !== "null") {
          if (customValue1.test(value)) {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: false, errorText: "Enter zip code", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: true, errorText: "Enter a valid zip code", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, zip: { value: "", error: true, errorText: "Enter zip code", success: false } }));
        }
        break;
      }
      case "Country": {
        countryOptions = countryOptions || address.country.options;
        // let stateOptions = allCountryStateOptions || address.state.allStateOptions;
        value =
          countryOptions.find(
            (country) => country.code.toLowerCase() === value?.toLowerCase() || country.description.toLowerCase() === value?.toLowerCase()
          ) ?? null;
        let { zipCodeRegex } = handleZipCodeBasedOnCountry(value?.code ?? null);
        if (value) {
          // stateOptions = stateOptions ? stateOptions[value.code] : [];
          setAddress((prevState) => ({
            ...prevState,
            country: { ...prevState.country, value, error: false, errorText: "Please select country", success: true },
            // state: {
            //   ...prevState.state,
            //   value: null,
            //   options: stateOptions,
            //   error: !stateOptions.length || prevState.state.value || prevState.state.error ? true : false,
            //   success: false,
            // },
          }));
          // stateOptions.length && customValue1 && handleAddressFields("State", customValue1, stateOptions);
        } else {
          setAddress((prevState) => ({
            ...prevState,
            country: {
              ...prevState.country,
              value: null,
              error: true,
              errorText: prevState.country.error ? prevState.country.errorText : "Please select country",
              success: false,
            },
            // state: {
            //   ...prevState.state,
            //   value: null,
            //   options: [],
            //   error: prevState.state.value || prevState.state.error ? true : false,
            //   success: false,
            // },
          }));
        }
        customValue2
          ? handleAddressFields("Zip", customValue2, zipCodeRegex)
          : address.zip.value && handleAddressFields("Zip", address.zip.value, zipCodeRegex);
        break;
      }
    }
  };

  const handleFields = (field, value) => {
    switch (field) {
      case "Latitude": {
        if (value && value.length) {
          if (isFinite(value) && Math.abs(value) <= 90) {
            setAddress((prevState) => ({
              ...prevState,
              latitude: { ...prevState.latitude, value, error: false, errorText: "Enter latitude", success: true },
            }));
          } else {
            setAddress((prevState) => ({
              ...prevState,
              latitude: { ...prevState.latitude, value, error: true, errorText: "Enter a valid latitude", success: false },
            }));
          }
        } else {
          setAddress((prevState) => ({
            ...prevState,
            latitude: { ...prevState.latitude, value: "", error: true, errorText: "Enter latitude", success: false },
          }));
        }
        break;
      }
      case "Longitude": {
        if (value && value.length) {
          if (isFinite(value) && Math.abs(value) <= 180) {
            setAddress((prevState) => ({
              ...prevState,
              longitude: { ...prevState.longitude, value, error: false, errorText: "Enter longitude", success: true },
            }));
          } else {
            setAddress((prevState) => ({
              ...prevState,
              longitude: { ...prevState.longitude, value, error: true, errorText: "Enter a valid longitude", success: false },
            }));
          }
        } else {
          setAddress((prevState) => ({
            ...prevState,
            longitude: { ...prevState.longitude, value: "", error: true, errorText: "Enter longitude", success: false },
          }));
        }
        break;
      }
    }
  };

  const handleZipCodeBasedOnCountry = (country) => {
    let zipCodeRegex = ZIP_REGEX,
      zipCodeLimit = ZIP_CODE_LIMIT;
    if (country) {
      let limits = countryZipLimitsList[country];
      (zipCodeRegex = new RegExp(`^\\d{${limits[0]},${limits[1]}}$`)), (zipCodeLimit = limits[1]);
      if (["GBR", "CAN", "IRL", "NLD"].includes(country)) {
        if (country === "IRL") {
          zipCodeRegex = new RegExp("^[A-Z0-9]{3} [A-Z0-9]{4}$");
          zipCodeLimit = limits[1] + 1;
        }
        if (country === "CAN") {
          zipCodeRegex = new RegExp("^[A-Z]\\d[A-Z] \\d[A-Z]\\d$");
          zipCodeLimit = limits[1] + 1;
        }
        if (country === "GBR") {
          zipCodeRegex = new RegExp(`^[a-zA-Z0-9 ]{${limits[0]},${limits[1]}}$`);
          zipCodeLimit = limits[1];
        }
        if (country === "NLD") {
          zipCodeRegex = new RegExp("^[0-9]{4} [A-Z]{2}$");
          zipCodeLimit = 7;
        }
      } else {
        zipCodeLimit = limits[1];
      }
    }
    setZipRegex(zipCodeRegex);
    setZipCodeLimit(zipCodeLimit);
    return { zipCodeRegex };
  };

  const handleAddressSelection = (verified = false) => {
    if (verified) {
      setAddress((prevState) => ({ ...prevState, locusId: suggestedAddress.locusId }));
      handleAddressFields("Address 1", suggestedAddress.address1);
      handleAddressFields("Address 2", suggestedAddress.address2);
      handleAddressFields("City", suggestedAddress.city);
      handleAddressFields("Country", suggestedAddress.country, suggestedAddress.state, suggestedAddress.zip);
      handleAddressFields("State", suggestedAddress.state);
      handleFields("Latitude", suggestedAddress.latitude);
      handleFields("Longitude", suggestedAddress.longitude);
    }
    setSuggestedAddress((prevState) => ({
      ...prevState,
      address: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      locusId: "",
      longitude: "",
      latitude: "",
    }));
    setTypedAddress("");
  };

  const handleAddressVerification = async (address) => {
    setIsValidatingAddress(true);
    let data = {
      inputAddress: {
        addressLine1: address.address1.value,
        addressLine2: address.address2.value ?? "",
        city: address.city.value,
        stateProvince: address.state.value,
        //?.erpStateCode,
        postalCode: address.zip.value,
        country: address.country.value?.code,
      },
    };
    !isValidateAddressDisabled && setIsValidateAddressDisabled(true);
    let response = await validateAddress(data);
    if (response?.status === 200 && response?.data?.message?.toLowerCase() === "success") {
      let responsedata = response?.data?.data,
        addressByPass = responsedata?.addressByPass,
        locationFound = responsedata?.locationFound,
        stdAddress = responsedata?.standardizedInfo?.standardizedAddress,
        userAddress = responsedata?.inputAddress,
        standardAddress = {
          address1: stdAddress?.addressLine1?.toLowerCase(),
          address2: stdAddress?.subLocation?.toLowerCase() ?? "",
          city: stdAddress?.city?.toLowerCase(),
          state: stdAddress?.stateProvince?.toLowerCase(),
          zip: stdAddress?.postalCodeBase?.toLowerCase(),
          country: stdAddress?.iso3CountryCode?.toLowerCase(),
        },
        userTypedAddress = {
          address1: userAddress?.addressLine1?.toLowerCase(),
          address2: userAddress?.addressLine2?.toLowerCase() ?? "",
          city: userAddress?.city?.toLowerCase(),
          state: userAddress?.stateProvince?.toLowerCase(),
          zip: userAddress?.postalCode?.toLowerCase(),
          country: userAddress?.country?.toLowerCase(),
        },
        isExactMatch = isEqual(standardAddress, userTypedAddress),
        latitude = stdAddress?.latLong?.latitude ?? "",
        longitude = stdAddress?.latLong?.longitude ?? "",
        locusId = responsedata.location?.locationId ?? "";
      setAddress((prevState) => ({ ...prevState, addressByPass, locationFound, locusId }));
      if (addressByPass === "YES") {
        if (locationFound) {
          setAddress((prevState) => ({ ...prevState, verified: true }));
          if (isExactMatch) {
            if (address.addressByPass !== "NeedToValidateOnLoad") {
              handleFields("Latitude", latitude);
              handleFields("Longitude", longitude);
            }
          }
        } else {
          setAddress((prevState) => ({ ...prevState, verified: true }));
          notify("Please note that the entered address is not verified and couldn't find a close match", "warning", false);
        }
      } else {
        if (locationFound) {
          if (isExactMatch) {
            setAddress((prevState) => ({ ...prevState, verified: true }));
            if (address.addressByPass !== "NeedToValidateOnLoad") {
              handleFields("Latitude", latitude);
              handleFields("Longitude", longitude);
            }
          } else {
            setIsAddressSuggestionShown(true);
            setSuggestedAddress({
              address: `${stdAddress?.addressLine1}, ${stdAddress?.subLocation ? stdAddress?.subLocation + ", " : ""}${stdAddress?.city}, ${
                stdAddress?.stateProvince
              }, ${stdAddress?.postalCodeBase}, ${stdAddress?.iso3CountryCode}`,
              address1: stdAddress?.addressLine1,
              address2: stdAddress?.subLocation,
              city: stdAddress?.city,
              state: stdAddress?.stateProvince,
              zip: stdAddress?.postalCodeBase,
              country: stdAddress?.iso3CountryCode,
              longitude,
              latitude,
              locusId,
            });
            setTypedAddress(
              `${userAddress?.addressLine1}, ${userAddress?.addressLine2 ? userAddress?.addressLine2 + ", " : ""}${userAddress?.city}, ${
                userAddress?.stateProvince
              }, ${userAddress?.postalCode}, ${userAddress?.country}`
            );
          }
        } else {
          notify("We couldn't verify the entered address and couldn't find a close match", "error", false);
        }
      }
    } else {
      notify("Address validation failed due to an unexpected error", "error", false);
    }
    setIsValidatingAddress(false);
  };

  return (
    <>
      <UltraMediumText css={{ marginTop: "0.75rem" }} eDSFont fontSize="16px" marginBottom="0.7rem">
        Installer Address
      </UltraMediumText>
      <FlexRow>
        <CustomInput
          formWidth="25%"
          type="text"
          label="Installer Vendor Address 1"
          aria-label="Installer Vendor Address 1"
          value={address.address1.value}
          error={address.address1.error}
          errorText={address.address1.errorText}
          success={address.address1.success}
          onChange={(e) => handleAddressFields("Address 1", e.target.value)}
          paddingRight="5px"
          maxLength={50}
          css={{ paddingBottom: "0.6rem" }}
          onBlur={(e) => handleAddressFields("Address 1", e.target.value?.trim(), false, false, false, true)}
        />
        <CustomInput
          formWidth="25%"
          type="text"
          label="Installer Vendor Address 2"
          aria-label="Installer Vendor Address 2"
          value={address.address2.value}
          error={address.address2.error}
          errorText={address.address2.errorText}
          success={address.address2.success}
          onChange={(e) => handleAddressFields("Address 2", e.target.value)}
          paddingRight="5px"
          maxLength={50}
          css={{ paddingBottom: "0.6rem" }}
          required={false}
          onBlur={(e) => handleAddressFields("Address 2", e.target.value?.trim(), false, false, false, true)}
        />
        <CustomDropdown
          width="25%"
          type="text"
          label="Installer Vendor Country / Region"
          aria-label="Installer Vendor Country / Region"
          value={address.country.value?.code || "Please select"}
          error={address.country.error}
          errorText={address.country.errorText}
          success={address.country.success}
          css={{ paddingBottom: "0.6rem" }}
          onChange={(e) => handleAddressFields("Country", e.target.value)}
          paddingRight="5px"
        >
          <>
            <option disabled>Please select</option>
            {address.country.options.map((country) => (
              <option key={country.code} value={country.code}>
                {country.code}
              </option>
            ))}
          </>
        </CustomDropdown>
        {/* <CustomDropdown
          width="25%"
          type="text"
          label="Installer Vendor State"
          aria-label="Installer Vendor State"
          value={address.state.value?.stateName || "Please select"}
          error={address.state.error}
          errorText={address.state.errorText}
          success={address.state.success}
          disabled={!address.country.value}
          css={{ paddingBottom: "0.6rem" }}
          onChange={(e) => handleAddressFields("State", e.target.value.split(" - ")[0], address.state.options)}
          paddingRight="5px"
        >
          <>
            <option disabled style={{ backgroundColor: "lightgrey" }}>
              Please select
            </option>
            {address.state.options.map((stateCode) => (
              <option key={stateCode.stateCode + stateCode.countryCode}>{stateCode.stateName}</option>
            ))}
          </>
        </CustomDropdown> */}
        <CustomInput
          formWidth="25%"
          type="text"
          label="Installer Vendor State"
          aria-label="Installer Vendor State"
          value={address.state.value}
          error={address.state.error}
          errorText={address.state.errorText}
          success={address.state.success}
          onChange={(e) => handleAddressFields("State", e.target.value)}
          paddingRight="5px"
          maxLength={50}
          css={{ paddingBottom: "0.6rem" }}
          onBlur={(e) => handleAddressFields("State", e.target.value?.trim(), false, false, false, true)}
        />
        <CustomInput
          formWidth="25%"
          type="text"
          label="Installer Vendor City"
          aria-label="Installer Vendor City"
          value={address.city.value}
          error={address.city.error}
          errorText={address.city.errorText}
          success={address.city.success}
          onChange={(e) => handleAddressFields("City", e.target.value)}
          paddingRight="5px"
          maxLength={50}
          css={{ paddingBottom: "0.6rem" }}
          onBlur={(e) => handleAddressFields("City", e.target.value?.trim(), false, false, false, true)}
        />
        <CustomInput
          formWidth="25%"
          type="text"
          label="Installer Vendor Zip/Postal Code"
          aria-label="Installer Vendor Postal Code"
          value={address.zip.value}
          error={address.zip.error}
          errorText={address.zip.errorText}
          success={address.zip.success}
          disabled={!address.country.value}
          onChange={(e) => handleAddressFields("Zip", e.target.value, zipRegex)}
          paddingRight="5px"
          maxLength={zipCodeLimit}
          css={{ paddingBottom: "0.6rem" }}
          onBlur={(e) => handleAddressFields("Zip", e.target.value?.trim(), zipRegex, false, false, true)}
        />
        <CustomInput
          formWidth="25%"
          type="text"
          label="Address Latitude"
          aria-label="Address Latitude"
          value={address.latitude.value}
          error={address.latitude.error}
          errorText={address.latitude.errorText}
          success={address.latitude.success}
          disabled={isValidatingAddress || isAddressSuggestionShown}
          onChange={(e) => handleFields("Latitude", e.target.value)}
          paddingRight="5px"
          css={{ paddingBottom: "0.6rem" }}
        />
        <CustomInput
          formWidth="25%"
          type="text"
          label="Address Longitude"
          aria-label="Address Longitude"
          value={address.longitude.value}
          error={address.longitude.error}
          errorText={address.longitude.errorText}
          success={address.longitude.success}
          disabled={isValidatingAddress || isAddressSuggestionShown}
          onChange={(e) => handleFields("Longitude", e.target.value)}
          paddingRight="5px"
          css={{ paddingBottom: "0.6rem" }}
        />
        {!isAddressSuggestionShown && (
          <CustomSLButton
            primary
            aria-label="Validate Address"
            role="button"
            disabled={isValidateAddressDisabled}
            aria-disabled={isValidateAddressDisabled}
            onClick={() => handleAddressVerification(address)}
          >
            Validate Address
          </CustomSLButton>
        )}
        {isValidatingAddress && (
          <FlexColumn css={CustomLoader}>
            <Loader active fullscreen={false} />
            <SmallText bold css={{ paddingLeft: "2rem" }}>
              Verifying address...
            </SmallText>
          </FlexColumn>
        )}
      </FlexRow>
      {/* <FlexRow> */}
      {isAddressSuggestionShown && (
        <>
          <UltraMediumText eDSFont fontSize="14px" marginBottom="0" marginTop="1rem">
            Choose an option
          </UltraMediumText>
          <SmallText>We couldn&apos;t verify the entered address. We found a close match</SmallText>
          <RadioButtonGroupWrapper>
            <RadioButtonGroup
              onChange={() => handleAddressSelection(true)}
              error={false}
              data={[
                {
                  name: "group",
                  label: (
                    <>
                      <span>Use suggested address</span>
                      <mark css={{ fontSize: "10px", backgroundColor: "#d8dada", padding: "0rem 0.313rem", marginLeft: "0.313rem" }}>Verified</mark>
                    </>
                  ),
                  children: suggestedAddress.address,
                  value: "radioOne",
                  ariaLabel: "Use suggested address",
                },
              ]}
            />
            <RadioButtonGroup
              onChange={() => handleAddressSelection()}
              error={false}
              data={[
                {
                  name: "group",
                  label: (
                    <>
                      <span>Use address as typed</span>
                    </>
                  ),
                  children: typedAddress,
                  value: "radioTwo",
                  ariaLabel: "Use address as typed",
                },
              ]}
            />
          </RadioButtonGroupWrapper>
        </>
      )}
      {/* </FlexRow> */}
    </>
  );
};
