import React from "react";
import { FlexColumn, SmallText, Spacer } from "../../components/core/utility";
import styled from "@emotion/styled";
import { Grid, Row } from "@vds/grids";
import { getFullName, TYPE_DROPDOWN_VALUES } from "./utils";
import { Table, TableBody, TableRow, Cell, TableHeader, TableHead } from "@vds/tables";
import { css } from "@emotion/react";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { VEHICLE_LIST_TABLE_FEATURE } from "../../constants/VehicleListConstants";

const MMIDColumn = styled.div`
  padding: 0.5rem;
  padding-left: 3.5em;
  width: 15.5%;
`;
const ItemDescriptionColumn = styled.div`
  padding: 0.5rem;
  width: 28%;
`;
const TypeColumn = styled.div`
  padding: 0.5rem;
  width: 13%;
  white-space: normal;
`;
const TechRefIDColumn = styled.div`
  padding: 0.5rem;
  width: 17%;
`;
const OrderTypeColumn = styled.div`
  padding: 0.5rem;
  width: 17%;
`;

// table design for JAWS access

export const CustomSmallText = styled(SmallText)`
  white-space: nowrap;
`;

const ReviewViewFlyOutMenu = ({ rowData }) => {
  let feature = rowData.features
    ?.map((feature) => {
      return feature?.feature;
    })
    .filter((val) => val)
    .join(", ");

  const rowDataValue = rowData?.materials?.map((material) => {
    return {
      materialId: material.materialId,
      materialName: material.materialName,
      vmfType: material.vmfType && getFullName(TYPE_DROPDOWN_VALUES, material.vmfType),
      techRefId: material.techRefId,
      orderType: material.orderType,
    };
  });

  return (
    <>
      <FlexColumn css={{ marginBottom: "2rem", paddingTop: "1rem", marginRight: "0.5rem", width: "100%" }}>
        <div css={{ paddingLeft: "4em" }}>
          <SmallText bold>Features</SmallText>
          <SmallText>{feature || "--"}</SmallText>
        </div>
        <Spacer top="1rem" />
        <div>
          <GridComponent
            isExportEnabled={false}
            noDataMessage="No data available to display"
            rows={rowDataValue}
            columns={VEHICLE_LIST_TABLE_FEATURE}
            hideGlobalFilter={true}
            paginator={false}
            whiteSpace="normal"
            flyOutMenu={true}
            border="1px solid #e9ecef"
          />
        </div>
      </FlexColumn>
    </>
  );
};
export default ReviewViewFlyOutMenu;
