import { putRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const addServiceJob = async (jobId, payload) => {
  try {
    return await putRequest(urls.ADD_SERVICE_JOB + `/${jobId}`, payload);
  } catch (err) {
    return err;
  }
};
