import React from "react";
import styled from "@emotion/styled";
import { Table, TableBody, TableRow, Cell, TableHeader, TableHead } from "@vds/tables";
import { SmallText } from "../../components/core/utility";

export const CustomisedTable = styled(Table)`
  border-top: unset;
  font-family: Verizon-NHG-eTX;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  table-layout: fixed;
  & thead {
    border: 1px solid #d6d2d2;
    & tr {
      & th {
        padding: unset;
        height: 1.85rem;
        padding: 4px 14px 2px 14px;
        border-right: 1px solid #d6d2d2;
      }
    }
  }
  & tbody {
    border: 1px solid #d6d2d2;
    & tr {
      & td {
        padding: unset;
        height: 1.85rem;
        padding: 4px 14px 2px 14px;
        border-right: 1px solid #d6d2d2;
      }
    }
  }
`;
export const CustomSmallText = styled(SmallText)`
  white-space: nowrap;
  text-wrap: wrap;
`;

export const ContractDetailsData = ({ data }) => {
  return (
    <>
      <CustomisedTable role="table" tabIndux={0}>
        <TableHead role="row" tabIndux={0}>
          <TableHeader tabIndux={0} role="columnheader" id="contractId" width="25%">
            <CustomSmallText tabIndux={0} bold>
              Contract ID
            </CustomSmallText>
          </TableHeader>
          <TableHeader role="columnheader" id="contractName" width="30%">
            <CustomSmallText bold>Contract Name</CustomSmallText>
          </TableHeader>
          <TableHeader role="columnheader" id="orderQuantity" width="25%">
            <CustomSmallText bold>Order Quantity</CustomSmallText>
          </TableHeader>
          <TableHeader role="columnheader" id="techRefId" width="30%">
            <CustomSmallText bold>Tech Ref ID</CustomSmallText>
          </TableHeader>
          <TableHeader role="columnheader" id="orderType" width="25%">
            <CustomSmallText bold>Order Type</CustomSmallText>
          </TableHeader>
          <TableHeader role="columnheader" id="bundleId" width="25%">
            <CustomSmallText bold>Bundle ID</CustomSmallText>
          </TableHeader>
        </TableHead>
        <TableBody role="rowgroup" tabIndux={0}>
          {data.map((item) => {
            return (
              <TableRow role="row" key={item.SAPProductID}>
                <Cell role="cell" width="25%">
                  <SmallText aria-label={item.SAPProductID}>{item.SAPProductID ? item.SAPProductID : "--"}</SmallText>
                </Cell>
                <Cell role="cell" width="30%">
                  <SmallText aria-label={item.ProductName}>{item.ProductName ? item.ProductName : "--"}</SmallText>
                </Cell>
                <Cell role="cell" width="25%">
                  <SmallText aria-label={item.Quantity}>{item.Quantity ? item.Quantity : "--"}</SmallText>
                </Cell>
                <Cell role="cell" width="30%">
                  <SmallText aria-label={item.TechRefId}>{item.TechRefId ? item.TechRefId : "--"}</SmallText>
                </Cell>
                <Cell role="cell" width="25%">
                  <SmallText aria-label={item.OrderDetailType}>{item.OrderDetailType ? item.OrderDetailType : "--"}</SmallText>
                </Cell>
                <Cell role="cell" width="25%">
                  <SmallText aria-label={item.BundleId}>{item.BundleId ? item.BundleId : "--"}</SmallText>
                </Cell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomisedTable>
    </>
  );
};

export default ContractDetailsData;
