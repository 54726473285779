import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/C4S Decommission - Replacement/url";

export const getProcessingCodes = async (transactionId) => {
  try {
    if (transactionId) return await getRequest(urls.GET_PROCESSING_CODES + `/${transactionId}`);
    else return await getRequest(urls.GET_PROCESSING_CODES);
  } catch (err) {
    return err;
  }
};
