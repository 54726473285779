export const PARTIAL_COMPLETION_REASONS = [
  "Action Needed - Contract Update",
  "Action Needed - Fallout",
  "Action Needed - Unresponsive",
  "Cancelled By Leading System",
  "Customer Action Needed",
  "Customer Waiting On Equipment",
  "Financial Inquiries",
  "Installation Completed",
  "Lack Of Engagement",
  "Ready To Schedule",
  "Scheduled",
  "Sidetracked - Call Back Request",
  "Sidetracked - Customer Delay",
  "Sidetracked - Inventory Shortage",
  "Sidetracked - No Vehicle",
];
export const SCHEDULE_TIME_TIMEZONE = {
  EST: "Eastern Standard Time",
  PST: "Pacific Standard Time",
  MT: "Mountain Standard Time",
  CT: "Central Standard Time",
  HST: "Hawaii Standard Time",
  AKST: "Alaska Standard Time",
};
