import { Button } from "@vds/buttons";
import { TextArea } from "@vds/inputs";
import { Line } from "@vds/lines";
import { Loader } from "@vds/loaders";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Notification } from "@vds/notifications";
import { Body, Title } from "@vds/typography";
import { lazy, useEffect, useState, useCallback, useContext, forwardRef, useRef } from "react";
import { generate } from "shortid";
import { ReactComponent as Check } from "../../assets/icons/check-circle.svg";
import { ReactComponent as Idle } from "../../assets/icons/circle-fill.svg";
import { ReactComponent as CreateAccount } from "../../assets/icons/Create Account.svg";
import { ReactComponent as DocumentSign } from "../../assets/icons/Document Sign.svg";
import { ReactComponent as Error } from "../../assets/icons/exclamation-triangle.svg";
import { ReactComponent as MilestoneProcess } from "../../assets/icons/Milestone Process.svg";
import { ReactComponent as OpportunityClose } from "../../assets/icons/Opportunity close & sale.svg";
import { ReactComponent as OpportunityCreate } from "../../assets/icons/Opportunity Create.svg";
import { ReactComponent as SetPresentingStage } from "../../assets/icons/Set to presenting stage.svg";
import { ReactComponent as UpdateOrderNumber } from "../../assets/icons/Update Order Number.svg";
import { FlexColumn, FlexRow, MediumText, Spacer } from "../../components/core/Utility";
import { Accordion } from "../../components/features/Accordion";
import { AccordionItem, AccordionItemContext } from "../../components/features/Accordion/AccordionItem";
import { Colors } from "../../constants/Colors";
import { getMilestoneData, postComment } from "../../services/reports/reportMilestonesInfo";
import { hasPrivilege } from "../../utility/privileges";
import { ReactComponent as Person } from "../../assets/icons/person-fill.svg";
import { cloneDeep } from "lodash";
import CloseIcon from "../../assets/icons/closeBtn.png";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";

import { Accordion as PrimeAccordion, AccordionTab as PrimeAccordionTab } from "primereact/accordion";
import styled from "@emotion/styled";
import moment from "moment/moment";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { COLUMN_WIDTHS } from "../../constants/ApplicationConstants";
import { fetchGridComponentState, saveGridComponentState } from "../../utility/persistGridComponentState";
import { PrivilegeContext } from "../../context/privilegeContext";
import { errorBody } from "./errorModal";
import { ProgressFlow } from "../../components/features/ProgressFlow";
import { cameraColumns, vehicleColumns } from "./vehicleAndCameraTableColumns";

const parseDate = (date, dateFormat) => {
  if (date) {
    if (dateFormat) {
      return moment(date).format(dateFormat);
    } else {
      if (date.endsWith("Z")) {
        return moment(date).utc().format("MM/DD/YYYY, h:mm:ss A");
      }
      return new Date(date).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    }
  }
  return "--";
};

const PrimeAccordionContainer = styled.div`
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
  }
`;

const SmallPrimeAccordionContainer = styled.div`
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 10px;
  }
  .p-accordion {
    border: 1px solid black;
  }
`;

const makeInfo = (data) => {
  const leftSectionInfo = {};
  leftSectionInfo["Account ID"] = data?.opportunityInfo?.salesForceAccountId ?? "";
  leftSectionInfo["Customer Name"] = data?.opportunityInfo?.accountName ?? "";
  leftSectionInfo["Account Number"] = data?.opportunityInfo?.salesForceAccountNo ?? "";
  leftSectionInfo["ERP BP Number"] = data?.opportunityInfo?.bpNumber ?? "";
  leftSectionInfo["Order Type"] = data?.opportunityInfo?.orderType ?? "";
  leftSectionInfo["Salesforce Opportunity Id"] = data?.opportunityInfo?.salesForceOpportunityId ?? "";
  leftSectionInfo["Opportunity Processed Date"] = parseDate(data?.opportunityInfo?.opportunityCreatedDate);
  leftSectionInfo["Opportunity Closed Date"] = parseDate(data?.opportunityInfo?.opportunityCloseDate, "MM/DD/YYYY");
  // leftSectionInfo["SL Opportunity Id"] = data?.superLibraOpportunityId ?? "";

  const result = {};
  result["leftSectionInfo"] = leftSectionInfo;
  result["accountInfo"] = data?.accountInfo;
  result["contractAccountInfo"] = data?.contractAccountInfo;
  result["contactInfo"] = data?.contactInfo;

  return result;
};

const States = {
  failed: "failed",
  completed: "success",
  progress: "progress",
};
const Status = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  PROGRESS: "PROGRESS",
  UNDEFINED: "UNDEFINED",
};
const progressData = [
  {
    title: "Opportunity Created",
  },
  {
    title: "Account Setup",
  },
  {
    title: "Document Signed",
  },
  {
    title: "Sale Confirmed",
  },

  {
    title: "Order Created",
  },
  {
    title: "Reveal Account Created",
  },

  {
    title: "Order Fulfillment",
  },
  {
    title: "Order Completed",
  },
];

const rowExpansionTemplate = (data) => {
  return (
    <FlexRow height="100px" justifyContent="center" alignItems="center">
      <div css={{ paddingLeft: "50px" }}>
        <h2>This is info for esn {data.esn}</h2>
      </div>
    </FlexRow>
  );
};

const milestones = [
  {
    first: true,
    title: "Opportunity Created",
    description: "Opportunity Created in SFDC",
    svgIcon: OpportunityCreate,
  },
  {
    title: "Set to presenting stage",
    description: "Opportunity set to presenting stage in SFDC",
    svgIcon: SetPresentingStage,
  },
  {
    title: "Initial account setup",
    description: "",
    svgIcon: MilestoneProcess,
  },
  {
    title: "Document Sign",
    description: "Document Sign in SFDC",
    svgIcon: DocumentSign,
  },
  {
    title: "Opportunity close & sale",
    description: "Opportunity closed and sale confirmed in SFDC",
    svgIcon: OpportunityClose,
  },
  {
    title: "Contract Signing",
    description: "",
    svgIcon: CreateAccount,
  },
  {
    title: "Order Confirmation",
    description: "",
    svgIcon: UpdateOrderNumber,
  },
  {
    title: "Account Creation in Reveal",
    description: "",
    svgIcon: UpdateOrderNumber,
  },
  {
    title: "Order Fulfillment Installation and Billing",
    description: "",
    svgIcon: UpdateOrderNumber,
    // expandable: true,
    // color: Colors.blue
  },
];
const ErrorMessage = ({ text, callback, top }) => {
  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: top ?? "72px",
          left: "569px",
          minHeight: "200px",
          width: "300px",
          borderRadius: "5px",
          boxShadow: "5px 5px 20px 0px black",
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <FlexRow justifyContent="space-between" css={{ padding: "10px", borderBottom: "1px solid black" }}>
          <Body bold size="large">
            Error Details
          </Body>
          <img
            src={CloseIcon}
            width="20px"
            height="20px"
            onClick={() => {
              callback(null);
            }}
          ></img>
        </FlexRow>
        <div css={{ padding: "10px 17px" }}>
          <Body size="large">{text}</Body>
        </div>
      </div>
    </>
  );
};
const Para = ({ stage, children, anchor, setAddonElement, ...rest }) => {
  const [color, setColor] = useState(Colors.gray);
  const [icon, setIcon] = useState("");
  useEffect(() => {
    const check = <Check width="20px" height="20px"></Check>;
    const error = <Error width="20px" height="20px"></Error>;
    const idle = <Idle width="20px" height="20px"></Idle>;
    let localStage = stage;
    if (typeof stage === "object") {
      localStage = localStage.state;
    }
    if (localStage === States.completed) {
      setColor(Colors.green);
      setIcon(check);
    } else if (localStage === States.failed) {
      setColor(Colors.orange);
      setIcon(error);
    } else if (localStage === States.progress) {
      setColor(Colors.blue);
      setIcon(check);
    } else {
      setColor(Colors.gray);
      setIcon(idle);
    }
  }, [stage]);
  let text = null;
  if (anchor) {
    text = (
      <a href="javascript:void(0)" css={{ paddingLeft: "10px", color: "black" }}>
        {children}
      </a>
    );
  } else {
    text = (
      <span css={{ color: "black" }}>
        {children}
        {stage?.errorMessage && (
          <InfoIcon
            css={{ marginLeft: "10px", transform: "translateY(4px)" }}
            color="black"
            width="20px"
            height="20px"
            onClick={() => setAddonElement(<ErrorMessage text={stage.errorMessage} callback={setAddonElement}></ErrorMessage>)}
          ></InfoIcon>
        )}
      </span>
    );
  }
  return (
    // <div css={{ padding: "10px" }}>
    //   {icon}
    //   <div css={{ display: "inline" }}>
    //     <span>{children}</span>
    //   </div>
    // </div>
    <FlexRow padding="10px" color={color} {...rest} flexWrap="nowrap" alignItems="flex-start">
      <div css={{ marginTop: "2px" }}>{icon}</div>
      <FlexRow css={{ paddingLeft: "10px" }} flexWrap="wrap" alignItems="center">
        {text}
      </FlexRow>
    </FlexRow>
  );
};

const AccordionEntry = ({ label, text }) => {
  let content = text ?? "";
  return (
    <div css={{ padding: "10px" }}>
      <Body bold primitive="span">
        {label}:{" "}
      </Body>{" "}
      <Body primitive="span">{content}</Body>{" "}
    </div>
  );
};

const Info = ({ info }) => {
  return (
    <div css={{ width: "400px", marginRight: "100px", border: "1px solid black", borderRadius: "5px" }}>
      {Object.entries(info.leftSectionInfo).map((item, index) => {
        return (
          <div key={index} css={{ borderBottom: "1px solid gray", padding: "20px" }}>
            {item[0]}
            {" : "}{" "}
            <Body size="large" bold>
              {item[1]}
            </Body>
          </div>
        );
      })}
      <PrimeAccordionContainer>
        <PrimeAccordion multiple>
          <PrimeAccordionTab header="Account Info">
            <AccordionEntry label="Created At" text={parseDate(info?.accountInfo?.createdAt)} />
            <AccordionEntry label="Modified At" text={parseDate(info?.accountInfo?.modifiedAt)} />
          </PrimeAccordionTab>
          <PrimeAccordionTab header="Contract Account Info">
            <AccordionEntry label="SF Contract Account Id" text={info?.contractAccountInfo?.sfContractAccountId} />
            <AccordionEntry label="Shipping UUID" text={info?.contractAccountInfo?.shippingUUID} />
            <AccordionEntry label="Billing UUID" text={info?.contractAccountInfo?.billingUUID} />
            <AccordionEntry label="ERP Contract Account Number" text={info?.contractAccountInfo?.erpContractAccountNumber} />
            <AccordionEntry label="Created At" text={parseDate(info?.contractAccountInfo?.createdAt)} />
            <AccordionEntry label="Modified At" text={parseDate(info?.contractAccountInfo?.modifiedAt)} />
          </PrimeAccordionTab>
          <PrimeAccordionTab header="Contact Info">
            <SmallPrimeAccordionContainer>
              <PrimeAccordion multiple>
                {info?.contactInfo?.map((item, index) => {
                  return (
                    <PrimeAccordionTab key={index} header={item.sfContactId}>
                      <AccordionEntry label="SF Contact ID" text={item?.sfContactId} />
                      <AccordionEntry label="Contact Business Partner ID" text={item?.contactBusinessPartnerId} />
                      <AccordionEntry label="Contact Role" text={item?.contactRole} />
                      <AccordionEntry label="Created At" text={parseDate(item?.createdAt)} />
                      <AccordionEntry label="Modified At" text={parseDate(item?.modifiedAt)} />
                    </PrimeAccordionTab>
                  );
                })}
              </PrimeAccordion>
            </SmallPrimeAccordionContainer>
          </PrimeAccordionTab>
        </PrimeAccordion>
      </PrimeAccordionContainer>
    </div>
  );
};

const InfoTitle = ({ title, height = "50px", centerAlign, small }) => {
  let Content = null;
  if (small) {
    Content = (
      <Body size="large" bold>
        {title}
      </Body>
    );
  } else {
    Content = <Title>{title}</Title>;
  }
  return (
    <FlexRow
      css={{
        backgroundColor: "#ededed",
        height: height,
        alignItems: "center",
        paddingLeft: centerAlign ? 0 : "20px",
        justifyContent: centerAlign ? "center" : "start",
      }}
    >
      {Content}
    </FlexRow>
  );
};

const InfoEntry = ({ label, text, inline = false, minHeight = "60px", minWidth = "290px" }) => {
  text = text ?? "--";
  let Label = null;
  let Text = null;
  if (inline) {
    Label = <Body size="medium">{`${label}: `} &nbsp;</Body>;
    Text = (
      <Body size="medium" bold>
        {text}
      </Body>
    );
  } else {
    Label = <MediumText css={{ paddingBottom: "4px" }}>{`${label}: `}</MediumText>;
    Text = (
      <Body size="large" bold>
        {text}
      </Body>
    );
  }
  return (
    <div css={{ minWidth, minHeight, display: inline ? "flex" : "block", alignItems: "center" }}>
      {Label}
      {Text}
    </div>
  );
};

const HorizontalInfo = ({ info }) => {
  const contactCardWidth = "258px";
  return (
    <FlexColumn css={{ border: "1px solid black", borderRadius: "5px" }}>
      <InfoTitle title="Information" />
      <FlexRow padding="20px" flexWrap="wrap">
        {Object.entries(info.leftSectionInfo).map((item, index) => {
          return <InfoEntry key={index} label={item[0]} text={item[1]} />;
        })}
      </FlexRow>
      <InfoTitle title="Account Info" />
      <FlexRow padding="20px" flexWrap="wrap">
        <InfoEntry label="Created At" text={parseDate(info?.accountInfo?.createdAt)} />
        <InfoEntry label="Modified At" text={parseDate(info?.accountInfo?.modifiedAt)} />
      </FlexRow>
      <InfoTitle title="Contract Account Info" />
      <FlexRow padding="20px" flexWrap="wrap">
        <InfoEntry label="SF Contract Account Id" text={info?.contractAccountInfo?.sfContractAccountId} />
        <InfoEntry label="Shipping UUID" text={info?.contractAccountInfo?.shippingUUID} />
        <InfoEntry label="Billing UUID" text={info?.contractAccountInfo?.billingUUID} />
        <InfoEntry label="ERP Contract Account Number" text={info?.contractAccountInfo?.erpContractAccountNumber} />
        <InfoEntry label="Created At" text={parseDate(info?.contractAccountInfo?.createdAt)} />
        <InfoEntry label="Modified At" text={parseDate(info?.contractAccountInfo?.modifiedAt)} />
      </FlexRow>

      <InfoTitle title="Contact Info" />
      <FlexRow padding="20px" flexWrap="wrap" gap="30px">
        {info?.contactInfo?.map((item, index) => {
          return (
            <div key={index} css={{ border: "1px solid#e3e3e3", borderRadius: "5px" }}>
              <InfoTitle title={item?.sfContactId} height="30px" centerAlign small />
              <FlexColumn padding="5px">
                <InfoEntry label="Business Partner ID" text={item?.contactBusinessPartnerId} inline minHeight="32px" minWidth={contactCardWidth} />
                <InfoEntry label="Role" text={item?.contactRole} inline minHeight="32px" minWidth={contactCardWidth} />
                <InfoEntry label="Created At" text={parseDate(item?.createdAt)} inline minHeight="32px" minWidth={contactCardWidth} />
                <InfoEntry label="Modified At" text={parseDate(item?.modifiedAt)} inline minHeight="32px" minWidth={contactCardWidth} />
              </FlexColumn>
            </div>
          );
        })}
      </FlexRow>
    </FlexColumn>
  );
};

const MilestoneProcess1 = (props) => {
  const { stage1, stage2, stage3, stage4, setAddonElement } = props;
  return (
    <>
      <Para stage={stage1} setAddonElement={setAddonElement}>
        Opportunity pulled into SL
      </Para>
      <Para stage={stage2} setAddonElement={setAddonElement}>
        Business Partner Request sent to MDG
      </Para>
      <Para stage={stage3} setAddonElement={setAddonElement}>
        MDG sent Business Partner to SL
      </Para>
      <Para stage={stage4} setAddonElement={setAddonElement}>
        SL updates Business Partner to SFDC
      </Para>
    </>
  );
};
const Contacts = ({ contacts }) => {
  return (
    <>
      {contacts.map((item, index) => {
        return (
          <FlexRow key={index} padding="0 0 5px 50px">
            <Person width="15px" height="15px" />
            <span css={{ paddingLeft: "10px" }}>{item}</span>
          </FlexRow>
        );
      })}
    </>
  );
};

const MilestoneProcess2 = ({ stage1, stage2, stage3, stage4, stage5, stage6, stage7, rerender, setAddonElement }) => {
  const [contacts, setContacts] = useState();

  const handler = () => {
    // setContacts(["ash", "ashi", "ashir"]);
    rerender();
  };
  return (
    <>
      <Para stage={stage1} setAddonElement={setAddonElement}>
        Create or Update Contract Account in SFDC
      </Para>
      <Para stage={stage2} setAddonElement={setAddonElement}>
        Create AddressUUIDs for Contract Account in MDG
      </Para>
      <Para stage={stage3} setAddonElement={setAddonElement}>
        Update Contact BusinessPartnerId in SFDC
      </Para>
      <Para stage={stage4} setAddonElement={setAddonElement}>
        Associate Contact to Account in MDG
      </Para>
      <Para stage={stage5} setAddonElement={setAddonElement}>
        Update AddressUUIDs in SFDC
      </Para>
      <Para stage={stage6} setAddonElement={setAddonElement}>
        Create Contract Account in MDG
      </Para>
      <Para stage={stage7} setAddonElement={setAddonElement}>
        Update Contract Account ERPContractAccountNumber in SFDC
      </Para>
    </>
  );
};

const mapState = (data, state) => {
  switch (state) {
    case States.completed:
      data.status = Status.SUCCESS;
      break;
    case States.failed:
      data.status = Status.FAILED;
      break;
    case States.progress:
      data.status = Status.PROGRESS;
      break;
  }
};
const markError = (milestoneData, item) => {
  let visited = false;
  for (const key in milestoneData) {
    if (milestoneData[key] === States.progress) {
      if (!visited) {
        visited = true;
        milestoneData[key] = {};
        milestoneData[key].state = States.failed;
        milestoneData[key].errorMessage = item.description;
      } else {
        milestoneData[key] = States.failed;
      }
    }
  }
};
const Table = forwardRef(({ title, rows, columns, prefix, onCustomSaveState, onCustomRestoreState }, ref) => {
  return (
    <>
      {rows && rows.length > 0 && (
        <GridComponent
          ref={ref}
          rowExpansionTemplate={rowExpansionTemplate}
          rowExpansionPosition="beginning"
          isSelectionEnabled={false}
          noDataMessage="No data found"
          rows={rows}
          columns={columns}
          title={title}
          paginator
          enableGlobalSearch
          isExportEnabled
          stateStorage="custom"
          customSaveState={(state) => onCustomSaveState(state, prefix)}
          customRestoreState={async (state) => onCustomRestoreState(state, prefix)}
          enableColumnSelection
          reorderableColumns
          showResetButton
        ></GridComponent>
      )}
    </>
  );
});
Table.displayName = "Table";

const ErrorMessageDisplay = ({ errorMessage }) => {
  const { setAddonElement } = useContext(AccordionItemContext);
  return (
    <>
      <InfoIcon
        color="black"
        width="20px"
        height="20px"
        onClick={() => setAddonElement(<ErrorMessage text={errorMessage} top="0px" callback={setAddonElement}></ErrorMessage>)}
      ></InfoIcon>
    </>
  );
};
const HoverComponent = ({ totalQuantity, shippedQuantity, showShipped }) => {
  return (
    <>
      <FlexRow justifyContent="space-between" css={{ borderBottom: showShipped ? "1px solid gray" : null, padding: "20px" }}>
        <Body bold large>
          Total Quantity{" "}
        </Body>
        <Body small> {totalQuantity} </Body>
      </FlexRow>
      {showShipped && (
        <FlexRow justifyContent="space-between" css={{ padding: "20px" }}>
          <Body bold large>
            Shipped Quantity{" "}
          </Body>
          <Body small> {shippedQuantity} </Body>
        </FlexRow>
      )}
    </>
  );
};

export default function Milestone({ opportunityId }) {
  const [info, setInfo] = useState(makeInfo(null));
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState();
  const [failed, setFailed] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [commentsContent, setCommentsContent] = useState("");
  const [salesforceOpportunityId, setSalesforceOpportunityId] = useState("");
  const [milestoneData, setMilestoneData] = useState(progressData);
  const [vehicleData, setVehicleData] = useState(null);
  const [cameraData, setCameraData] = useState(null);
  const { userInfo } = useContext(PrivilegeContext);
  const vehicleTable = useRef();
  const cameraTable = useRef();
  const onCustomSaveState = (state, prefix) => {
    // state.userId = userInfo.email;
    saveGridComponentState(state, prefix);
  };
  const onCustomRestoreState = async (state, prefix) => {
    return await fetchGridComponentState(userInfo.email, prefix);
  };
  const markMilestone1Complete = useCallback((accordionData, milestone1Data) => {
    milestone1Data.stage1 = States.completed;
    milestone1Data.stage2 = States.completed;
    milestone1Data.stage3 = States.completed;
    milestone1Data.stage4 = States.completed;
    accordionData[0].state = States.completed;
    accordionData[1].state = States.completed;
    accordionData[2].state = States.completed;
  }, []);
  const markMilestone2Complete = useCallback((accordionData, milestone2Data) => {
    milestone2Data.stage1 = States.completed;
    milestone2Data.stage2 = States.completed;
    milestone2Data.stage3 = States.completed;
    milestone2Data.stage4 = States.completed;
    milestone2Data.stage5 = States.completed;
    milestone2Data.stage6 = States.completed;
    milestone2Data.stage7 = States.completed;
    accordionData[3].state = States.completed;
    accordionData[4].state = States.completed;
    accordionData[5].state = States.completed;
  }, []);
  const updateErrorInTitle = useCallback((updatedData, errorMessage) => {
    updatedData.title = (
      <p>
        {milestones[8].title} <ErrorMessageDisplay errorMessage={errorMessage} />
      </p>
    );
  }, []);
  const preprocessData = useCallback((data) => {
    // const states = data.states;
    const updatedData = cloneDeep(progressData);
    // const errorMessage = data?.opportunityInfo?.errorMessage;
    switch (data?.opportunityInfo?.stage) {
      case "M1InProgress":
        updatedData[0].state = States.completed;
        // updatedData[1].state = States.completed;
        updatedData[1].state = States.progress;
        break;
      case "M1Completed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        // updatedData[2].state = States.completed;
        break;
      case "M1Failed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.failed;
        // updatedData[2].state = States.failed;
        // updateErrorInTitle(updatedData[2], errorMessage);
        break;
      case "M2InProgress":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.progress;
        // updatedData[5].state = States.progress;
        break;
      case "M2Completed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        // updatedData[5].state = States.completed;
        break;
      case "M2Failed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.failed;
        // updatedData[5].state = States.failed;
        // updateErrorInTitle(updatedData[5], errorMessage);

        break;
      case "OrderCreationInProgress":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.progress;
        // updatedData[5].state = States.completed;
        // updatedData[6].state = States.progress;
        break;
      case "OrderCreationCompleted":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        // updatedData[5].state = States.completed;
        // updatedData[6].state = States.completed;
        break;
      case "OrderCreationFailed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.failed;
        // updatedData[5].state = States.completed;
        // updatedData[6].state = States.failed;
        // updateErrorInTitle(updatedData[6], errorMessage);

        break;
      case "RevealAccountCreationInProgress":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.progress;
        // updatedData[6].state = States.completed;
        // updatedData[7].state = States.progress;
        break;
      case "RevealAccountCreationCompleted":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        // updatedData[6].state = States.completed;
        // updatedData[7].state = States.completed;
        break;
      case "RevealAccountCreationFailed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.failed;
        // updatedData[6].state = States.completed;
        // updatedData[7].state = States.failed;
        // updateErrorInTitle(updatedData[7], errorMessage);

        break;
      case "OrderFulfillmentInProgress":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        updatedData[6].state = States.progress;
        // updatedData[7].state = States.completed;
        // updatedData[8].state = States.progress;
        break;
      case "OrderFulfillmentCompleted":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        updatedData[6].state = States.completed;
        // updatedData[7].state = States.completed;
        // updatedData[8].state = States.completed;
        break;
      case "OrderFulfillmentFailed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        updatedData[6].state = States.failed;
        // updatedData[7].state = States.completed;
        // updatedData[8].state = States.failed;
        // updateErrorInTitle(updatedData[8], errorMessage);
        break;
      case "OrderCompleted":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        updatedData[6].state = States.completed;
        updatedData[7].state = States.completed;
        break;
    }

    updatedData.forEach((item) => {
      mapState(item, item.state);
      delete item.state;
    });
    // if (data.totalQuantity && data.shippedQuantity) {
    //   updatedData[8].description = `Shipped qty - ${data.shippedQuantity} Total qty - ${data.totalQuantity}`;
    // }
    updatedData[4].id = `${opportunityId}OrderCreatedReportMilestone`;
    updatedData[4].HoverComponent = <HoverComponent totalQuantity={data?.opportunityInfo?.totalQuantity ?? 0}></HoverComponent>;
    updatedData[6].id = `${opportunityId}OrderFulfillmentReportMilestone`;
    updatedData[6].HoverComponent = (
      <HoverComponent
        totalQuantity={data?.opportunityInfo?.totalQuantity ?? 0}
        shippedQuantity={data?.opportunityInfo?.shippedQuantity ?? 0}
        showShipped
      ></HoverComponent>
    );
    setMilestoneData(updatedData);
  }, []);

  const notify = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} />);
  };
  const commentsChangeCallback = (e) => {
    setCommentsContent(e.target.value);
  };
  const retry = () => {
    setCommentsContent("");
    setShowRetryModal(true);
  };
  const skip = () => {
    setCommentsContent("");
    setShowSkipModal(true);
  };
  const submitRetrySkipComment = () => {
    const data = {};
    data.comments = commentsContent;
    data.opportunityId = opportunityId;
    data.retryFlag = showRetryModal;
    setShowRetryModal(false);
    setShowSkipModal(false);
    setCommentsContent("");
    setLoading(true);
    postComment(data).then((response) => {
      if (response.status === 200) {
        notify("Comment successfully posted");
      } else {
        notify("Error occurred posting comment", "error");
      }
      setLoading(false);
    });
  };
  const formatVehicleAndCameraData = useCallback((data) => {
    return data?.map((item) => {
      item.inboundShipmentDate = item.inboundShipmentDate && new Date(item.inboundShipmentDate);
      item.outboundShipmentDate = item.outboundShipmentDate && new Date(item.outboundShipmentDate);
      item.installDate = item.installDate && new Date(item.installDate);
      item.billingStartDate = item.billingStartDate && new Date(item.billingStartDate);
      item.activationDate = item.activationDate && new Date(item.activationDate);
      item.vehicleCreationDate = item.vehicleCreationDate && new Date(item.vehicleCreationDate);
      item.revealCameraCreationDate = item.revealCameraCreationDate && new Date(item.revealCameraCreationDate);
      item.selfCheckIn = item.selfCheckIn === false ? "No" : "Yes";
      return item;
    });
  }, []);
  const fetchData = () => {
    setLoading(true);
    getMilestoneData(opportunityId).then((response) => {
      if (response.status === 200) {
        setInfo(makeInfo(response.data.data));
        preprocessData(response.data.data);
        setFailed(response?.data?.data?.retry ?? false);
        setSalesforceOpportunityId(response?.data?.data?.salesForceOpportunityId ?? "");
        setVehicleData(formatVehicleAndCameraData(response?.data?.data?.vehicleData));
        setCameraData(formatVehicleAndCameraData(response?.data?.data?.cameraData));
      } else {
        notify("Error fetching data", "error");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <FlexRow justifyContent="space-between" padding="10px 0">
        <Title primitive="p" size="medium">
          Order Details - Opportunity Id : {opportunityId}
        </Title>
        <FlexRow
          css={{ justifyContent: "center", alignItems: "center", backgroundColor: "gray", width: "35px", height: "35px", borderRadius: "5px" }}
          onClick={fetchData}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.7875 2.20312C11.4281 0.84375 9.5625 0 7.49062 0C3.34687 0 0 3.35625 0 7.5C0 11.6438 3.34687 15 7.49062 15C10.9875 15 13.9031 12.6094 14.7375 9.375H12.7875C12.0187 11.5594 9.9375 13.125 7.49062 13.125C4.3875 13.125 1.86562 10.6031 1.86562 7.5C1.86562 4.39688 4.3875 1.875 7.49062 1.875C9.04687 1.875 10.4344 2.52188 11.4469 3.54375L8.42812 6.5625H14.9906V0L12.7875 2.20312Z"
              fill="white"
            />
          </svg>
        </FlexRow>
      </FlexRow>
      <Line type="light"></Line>
      {notification}
      <Loader active={loading} />
      {/* <FlexRow alignItems="flex-start" justifyContent="space-between" paddingTop="30px">
        <FlexRow alignItems="flex-start">
          <Info info={info}></Info>
          <Accordion>
            {milestoneData.map((item, index) => {
              return (
                <AccordionItem key={index} {...item} index={index + 1}>
                  {item.data}
                </AccordionItem>
              );
            })}
          </Accordion>
        </FlexRow>
        {hasPrivilege("ENABLE_PAGES_ORDER_MILESTONES_RETRY") && failed && (
          <FlexRow>
            <Button size="small" css={{ marginRight: "10px" }} onClick={retry}>
              Retry
            </Button>
            <Button size="small" onClick={skip}>
              Skip
            </Button>
          </FlexRow>
        )}
      </FlexRow> */}
      <div css={{ padding: "50px 50px 165px" }}>
        <ProgressFlow data={milestoneData} />
      </div>
      <Table
        ref={vehicleTable}
        title="VTUs"
        rows={vehicleData}
        prefix="reports_milestone_vehicle"
        columns={vehicleColumns}
        onCustomSaveState={onCustomSaveState}
        onCustomRestoreState={onCustomRestoreState}
      ></Table>
      <div css={{ marginBottom: "30px" }}></div>
      <Table
        ref={cameraTable}
        title="Cameras"
        rows={cameraData}
        columns={cameraColumns}
        prefix="reports_milestone_camera"
        onCustomSaveState={onCustomSaveState}
        onCustomRestoreState={onCustomRestoreState}
      ></Table>
      {/* <Info info={info}></Info> */}
      <div css={{ marginTop: "60px" }} />
      <HorizontalInfo info={info} />

      <Modal opened={showRetryModal || showSkipModal} disableOutsideClick closeButton={null}>
        <ModalBody>
          <Body size="large" bold>
            Please provide the comments below:
          </Body>
          <TextArea
            readOnly={false}
            required={false}
            disabled={false}
            error={false}
            helperTextPlacement="bottom"
            value={commentsContent}
            onChange={commentsChangeCallback}
            maxLength={200}
          />
        </ModalBody>
        <ModalFooter
          buttonData={{
            primary: {
              children: "Submit",
              onClick: () => {
                submitRetrySkipComment();
              },
            },
            close: {
              children: "Cancel",
              onClick: () => {
                setShowRetryModal(false);
                setShowSkipModal(false);
              },
            },
          }}
        ></ModalFooter>
      </Modal>
    </>
  );
}

export const ReportsMilestoneMetadata = {
  name: "Order Details",
  id: "reports_order_details",
  component: lazy(() => import("./ReportsMilestone.jsx")),
  route: "/reports_order_details",
};
