//checkin endponits
const checkInBaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VTU}`;
export const CHECK_IN_SEARCH = checkInBaseUrl + `/checkin-search`;
export const GET_LINE_ITEMS = checkInBaseUrl + `/view-worktickets-info`;
export const CHECK_ESN_STATUS = checkInBaseUrl + `/check-esn-status`;
export const GET_VTU_DETAILS = checkInBaseUrl + `/vehicleDetailsbyesn`;
export const UPDATE_VTU_DETAILS = checkInBaseUrl + `/vtu`;
export const UPDATE_VEHICLE_DETAILS = checkInBaseUrl + `/checkin`;
export const UPDATE_SERVICE_REQUEST_NOTE = checkInBaseUrl + `/checkin/vtu`;
export const GET_CASES = checkInBaseUrl + `/casesForWorkTicket`;
export const VEHICLE_DETAILS_BY_VIN = checkInBaseUrl + `/vehiclesdetailsbyvin`;
export const CHECK_VEHICLE_DETAILS_EXIST = checkInBaseUrl + `/check-vehicle-details-already-exists`;
export const UPLOAD_CSV_CHECKIN = checkInBaseUrl + `/bulkCheckIn`;
